import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineCheck, AiOutlineCheckCircle, AiOutlineDownload } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { MdOutlineEdit, MdOutlineAttachFile, MdOutlineDownloading } from "react-icons/md";
import MoneyFlowIcon from "../../../assets/img/money-flow.png";
import payment_validation_inprogress from "../../../assets/img/payment_validation_inprogress.png";
import DollarIcon from "../../../assets/img/dollarIcon.png";
import Text, { Heading } from "app/components/atoms/typography";
import { USER_ROLES } from "store/reducers/auth/utils";
import _ from "lodash";
import {
  FileUpload,
  FileUploaded,
  OutcomeCircle,
} from "app/components/atoms/divs/div.styles";
import {
  INVOICE_ACTIONS,
  INVOICE_TYPE,
  INVOICE_STATUS,
  ROUTES,
  INVOICE_PAYMENT_STATUS,
} from "utils/constants/constants.utils";
import { Flex, FlexInvoiceActions } from "app/pages/dashboard/dashboard.styles";
import { useCheckAdmin, useViewport } from "hooks";
import React, { useState } from "react";
import Chat from "app/components/chat/chat.component";
import { useDispatch, useSelector } from "react-redux";
import { BaseButton } from "app/components/atoms/button/button.styles";
import { SettingsActions, SettingsSelectors } from "store/reducers/settings/settingsSlice";
import {
  paymentActions,
  paymentSelectors,
} from "store/reducers/payment/paymentSlice";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import {
  ResponsiveBaseButton,
  ResponsiveInvertedButton,
} from "app/pages/receipt/receipt.style";

import { invoiceActions, invoiceSelectors } from "store/reducers/invoice/invoiceSlice";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { InvoiceTextArea } from "app/components/atoms/textArea/textArea";
import UpdateStatus from "app/components/cell/updateInvoiceStatus/update-status.component";
import { IoIosChatbubbles } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import ActionButton from "app/components/molecules/action-btn/action-btn.component";
import BaseInvoiceDetails from "./BaseInvoiceDetails";
import LoadingDots from "app/components/atoms/loading-dots/loading-dots.component";
import CustomModal from "app/components/atoms/modal/modal.component";


const ViewInvoice = () => {

  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const [paymentSucesss, setPaymentSucesss] = useState(false);
  const [paymentComment, setPaymentComment] = useState("");
  const [paymentValidationComment, setPaymentValidationComment] = useState("");
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [confirmValidation, setConfirmValidation] = useState(false);
  const [validatePayment, setValidatePayment] = useState(false);
  const [miniConfirmation, setMiniConfirmation] = useState(false);
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [declineValidation, setDeclineValidation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(null);

  const { uploadFile } = SettingsActions;
  const { setHeaderText } = appDataActions;
  const { getDetails } = invoiceActions;
  const { handleApiResponse, handleDownload } = useHandleApiResponse();
  const { initiateInvoicePayment, updateInvoicePaymentStatus } = paymentActions;

  const invoice = useSelector(invoiceSelectors.invoice);
  const loading = useSelector(invoiceSelectors.isLoading);
  const isFlieUploadLoading = useSelector(SettingsSelectors.isLoading);
  const isPaymentLoading = useSelector(paymentSelectors.isLoading);
  const receiptId = useSelector(paymentSelectors.receiptId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { mobile } = useViewport();
  const { role } = useCheckAdmin();

  useEffect(() => {

    dispatch(getDetails(id)).then((res) => {
      dispatch(setHeaderText({ value: res.payload.invoiceNo, type: 'setHeaderText' }));
    });

    // eslint-disable-next-line
  }, []);

  //#region handle events

  const handleGetActionsByRole = (invoiceStatus, invoicePaymentStatus) => {
    switch (role) {
      case USER_ROLES.CLIENT_USER:
        if (
          invoicePaymentStatus === INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED
        ) {
          return clientUserPaymentAction;
        }

        if (invoicePaymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) {
          return invoiceStatus?.toLowerCase().includes("change")
            ? editActions
            : [];
        }
        break;

      case USER_ROLES.ORGANIZATION_ADMIN:
      case USER_ROLES.ORGANIZATION_USER:
        if (
          invoiceStatus === INVOICE_STATUS.APPROVED &&
          invoicePaymentStatus === INVOICE_PAYMENT_STATUS.UNPAID
        ) {
          return organizationUserPaymentAction;
        }

        if (
          invoiceStatus === INVOICE_STATUS.APPROVED &&
          invoicePaymentStatus === INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED
        ) {
          return organizationUserPaymentValidationAction;
        }

        if (invoiceStatus === INVOICE_STATUS.PENDING) {
          return organizationActions;
        }
        break;

      default:
        break;
    }

    return [];
  };

  const handleEdit = () => {
    navigate(`${ROUTES.EDIT_INTERNAL_INVOICE_ROUTE_HEAD}/${invoice.id}`);
  };

  const handleUpdateStatus = (status) => {
    setVisible(!visible);
    setUpdateStatus(status);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(uploadFile(file)).then((res) => {
        handleApiResponse(res);
        setFile(res?.payload?.data);
        setFileName(file.name);
      });
    }
  };

  const handleDisplaySendPaymentConfirmationModal = () => {
    setPaymentConfirmation(true);
    setPaymentComment("");
    setFileName("");
    setFile(null);
  };

  const handleSendPaymentConfirmation = () => {
    let payload = {
      fileUrl: file,
      comment: paymentComment,
      invoiceId: id,
    };

    dispatch(initiateInvoicePayment(payload)).then(() => {
      setPaymentSucesss(true);
      setPaymentValidationComment("");
      setFileName("");
      setFile(null);
      setUpdate(!update);
      dispatch(getDetails(id));
    });

    setPaymentConfirmation(false);
  };

  // client

  const handleClientConfirmation = (e) => {
    setValidatePayment(true);
  };

  const handleDisplayClientConfirmationModalFalse = (e) => {
    setConfirmValidation(false);
    setMiniConfirmation(true);
    setConfirmationStatus(false)
  };

  const handleDisplayClientConfirmationModalTrue = (e) => {
    setConfirmValidation(false);
    setMiniConfirmation(true)
    setConfirmationStatus(true)
  }

  const handleNavigateToReceiptPage = () => {
    navigate(`/receipts/${receiptId}`);
  };

  const handleSubmitClientConfirmation = () => {

    let payload = {
      paymentStatus: confirmationStatus,
      comment: paymentValidationComment,
      invoiceId: id,
      fileUrl: file,
    };

    dispatch(updateInvoicePaymentStatus(payload)).then(() => {
      setUpdate(!update);
      setPaymentValidationComment("");
      setFileName("");
      setFile(null);
      setMiniConfirmation(false)
      dispatch(getDetails(id));

      if (confirmationStatus === true) {
        setSuccessfulValidation(true);
      }
      else {
        setDeclineValidation(true)
      }
    });
  };

  //#endregion

  //#region Actions
  const organizationActions = [
    {
      name: INVOICE_ACTIONS.APPROVE,
      icon: <AiOutlineCheckCircle />,
      iconColor: "#5CBC77",
      action: handleUpdateStatus,
    },
    {
      name: INVOICE_ACTIONS.DECLINE,
      icon: <GiCancel />,
      iconColor: "#E21B1B",
      action: handleUpdateStatus,
    },
    {
      name: INVOICE_ACTIONS.REQUEST_CHANGE,
      icon: <MdOutlineEdit />,
      iconColor: "var(--primary1)",
      action: handleUpdateStatus,
    },
  ];

  const editActions = [
    {
      name: "Edit",
      icon: <MdOutlineEdit />,
      iconColor: "var(--grey1)",
      action: handleEdit,
    },
  ];

  const clientUserPaymentAction = [
    {
      name: "Confirm Payment",
      icon: <img src={DollarIcon} alt="Confrim Payment" width="18%" />,
      action: handleClientConfirmation,
    },
  ];

  const organizationUserPaymentAction = [
    {
      name: "Send Payment Confirmation Request",
      icon: <img src={MoneyFlowIcon} alt="Payment Confirmation" width="7%" />,
      iconColor: "#FE9900",
      action: handleDisplaySendPaymentConfirmationModal,
    },
  ];

  const organizationUserPaymentValidationAction = [
    {
      name: "Payment Validation Requested",
      icon: (
        <img
          src={payment_validation_inprogress}
          alt="Payment Confirmation"
          width="7%"
        />
      ),
      iconColor: "#FE9900",
    },
  ];

  const actions = handleGetActionsByRole(invoice?.statusName, invoice?.invoicePaymentStatusName);

  //#endregion

  if (loading || _.isEmpty(invoice)) {
    return <LoadingDots text={"Hold on"} />;
  }

  return (
    <BaseInvoiceDetails
      invoice={invoice}
      loading={loading}
      backRoute={-1}
      extraComponent={
        <>
          <FlexInvoiceActions padding="64px 0">
            <Flex style={{ display: actions.length === 0 ? 'none' : 'flex' }}>
              {actions?.map((action, index) => (
                  <ActionButton style={{ margin:'5px'}}
                    height={mobile ? 13.33 : 40}
                    key={index}
                    iconColor={action?.iconColor}
                    onClick={() => {
                      action?.action(action.name);
                    }}
                  >
                    {action?.icon}
                    <Text size={mobile ? 12 : ""}>{action?.name}</Text>
                  </ActionButton>
                )
              )}
            </Flex>
            <div>
              {invoice.typeName !== INVOICE_TYPE.External &&
                (
                  <ActionButton
                  svgHeight="16x"
                  svgWidth="16px"
                  padding="10px 14px"
                    border="0.50px solid #DBE7FF "
                    iconColor={"#292D32"}
                    onClick={() => {
                      setShowChat(!showChat);
                    }}
                  >
                    <IoIosChatbubbles size={"16px"} />
                  </ActionButton>
                )
              }

            </div>
            <ActionButton
              border="0.50px solid #DBE7FF "
              height={mobile ? "20px" : "40px"}
              iconColor={"var(--accent)"}
              onClick={() => {
                handleDownload(invoice?.invoicePdfUrl, invoice?.invoiceNo)
              }}
            >
              <FiDownload />
              <Text size={mobile ? 12 : ""}>Download as PDF</Text>
            </ActionButton>
          </FlexInvoiceActions>

          <UpdateStatus
              visible={visible}
              setVisible={setVisible}
              status={updateStatus}
              id={id}
            />

            <Chat
              display={showChat}
              handleClose={setShowChat}
              chatId={invoice?.invoiceNo}
              paidInvoice={invoice?.invoicePaymentStatusName === INVOICE_PAYMENT_STATUS.PAID}
            />
          {paymentConfirmation && (
            <CustomModal
              isOpen={paymentConfirmation}
              className="modal modal-del overflow"
              close={setPaymentConfirmation}
            >
              <form
                style={{ position: "relative" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSendPaymentConfirmation();
                }}
              >
                <Flex justifyContent="center" flexDirection="column">
                  <Heading responsiveFontSize={18} level={2}>
                    Payment Confirmation Request
                  </Heading>
                  <Text size={mobile ? 12 : ""}>
                    Send proof of payment to your client to enable them confirm your
                    payment.
                  </Text>
                </Flex>
                <div style={{ marginTop: "30px" }}>
                  <Text responsiveFontSize={12}>
                    Comment <span style={{ color: "red" }}>*</span>
                  </Text>
                  <InvoiceTextArea
                    value={paymentComment}
                    required
                    onChange={(e) => setPaymentComment(e.target.value)}
                  ></InvoiceTextArea>
                </div>
                <FileUpload>
                  <input
                    id="fileInput"
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="fileInput">
                    {!isFlieUploadLoading && <MdOutlineAttachFile />}
                    {isFlieUploadLoading && <MdOutlineDownloading/>}
                  </label>
                </FileUpload>
                <div>
                  {file && (
                    <FileUploaded>
                      <Text responsiveFontSize={12} color>
                        {fileName}
                      </Text>
                      <AiOutlineDownload style={{ fontSize: "23px" }} />
                    </FileUploaded>
                  )}
                </div>
                <Flex>
                  <ResponsiveInvertedButton
                    marginRight="1rem"
                    onClick={(e) => {
                      e.preventDefault();
                      setPaymentConfirmation(false);
                    }}
                  >
                    Cancel Request
                  </ResponsiveInvertedButton>
                  <ResponsiveBaseButton
                    disabled={isPaymentLoading}
                    isLoading={isPaymentLoading}
                    type="submit">
                    Send Request
                  </ResponsiveBaseButton>
                </Flex>
              </form>
            </CustomModal>
          )}

          {paymentSucesss && (
            <CustomModal
              isOpen={paymentSucesss}
              className="modal modal-del overflow"
              close={setPaymentSucesss}
            >
              <Flex margin="30px" justifyContent="center" flexDirection="column">
                <OutcomeCircle>
                  <AiOutlineCheck />
                </OutcomeCircle>
                <Heading responsiveFontSize={18} level={2}>
                  Request Sent Successfully
                </Heading>
              </Flex>
            </CustomModal>
          )}

          {/*client validation page PAGE 1 */}

          {validatePayment && (
            <CustomModal
              isOpen={validatePayment}
              className="modal modal-del overflow"
              close={setValidatePayment}
            >
              <Flex justifyContent="center" flexDirection="column" margin="40px 0">
                <Heading responsiveFontSize={18} level={2}>Payment Confirmation Request</Heading>
                <Text responsiveFontSize={12} >
                  <span style={{ fontWeight: "bolder" }}>{invoice?.from}</span> has initiated a payment confirmation request for #<span
                    style={{ fontWeight: "bolder" }}> {invoice?.invoiceNo}</span>. Please check the chat box for more details.
                </Text>

                <Flex margin="1rem 0">
                  <ResponsiveInvertedButton
                    marginRight="1rem"
                    fontSize="13px"
                    responsiveFontSize=".6rem"
                    onClick={(e) => {
                      e.preventDefault();
                      setValidatePayment(false)
                    }}
                  >
                    Cancel
                  </ResponsiveInvertedButton>
                  <ResponsiveBaseButton
                    fontSize="13px"
                    responsiveFontSize=".6rem"
                    onClick={(e) => {
                      e.preventDefault();
                      setValidatePayment(false)
                      setConfirmValidation(true);
                    }}
                  >
                    Validate Payment
                  </ResponsiveBaseButton>
                </Flex>
              </Flex>
            </CustomModal>
          )}

          {/* PAGE 2 */}
          {confirmValidation && (
            <CustomModal
              isOpen={confirmValidation}
              className="modal modal-del overflow"
              close={setConfirmValidation}
            >
              <form
                style={{ position: "relative" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleDisplayClientConfirmationModalTrue()
                }}
              >
                <Flex justifyContent="center" flexDirection="column">
                  <Heading responsiveFontSize={18} level={2}> Validate Payment</Heading>
                  <Text responsiveFontSize={12}>Validate your payment status</Text>
                </Flex>
                <div style={{ marginTop: "30px" }}>
                  <Text responsiveFontSize={12}>
                    Comment <span style={{ color: "red" }}>*</span>
                  </Text>
                  <InvoiceTextArea
                    value={paymentValidationComment}
                    required
                    onChange={(e) => setPaymentValidationComment(e.target.value)}
                  >
                  </InvoiceTextArea>
                </div>

                <FileUpload>
                  <input
                    id="fileInput"
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="fileInput">
                    {!isFlieUploadLoading && <MdOutlineAttachFile />}
                    {isFlieUploadLoading && <MdOutlineDownloading/>}
                  </label>
                </FileUpload>
                <div>
                  {file && (
                    <FileUploaded>
                      <Text responsiveFontSize={12} color>
                        {fileName}
                      </Text>
                      <AiOutlineDownload style={{ fontSize: "23px" }} />
                    </FileUploaded>
                  )}
                </div>

                <Flex>
                  <ResponsiveInvertedButton
                    marginRight="1rem"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDisplayClientConfirmationModalFalse()
                    }}
                  >
                    Decline Payment
                  </ResponsiveInvertedButton>

                  <ResponsiveBaseButton>
                    Confirm Payment
                  </ResponsiveBaseButton>
                </Flex>
              </form>
            </CustomModal>
          )
          }

          {/* MINI CONFRIMATION */}
          {
            miniConfirmation && (
              <CustomModal
                isOpen={miniConfirmation}
                className="modal modal-del"
                close={setMiniConfirmation}
              >
                <Flex justifyContent="center" flexDirection="column">
                  <Text color={confirmationStatus ? "black" : "red"} size="20px">
                    {confirmationStatus
                      ? "Are you sure you want to Confirm payment"
                      : "Are you sure you want to Decline payment"}
                  </Text>
                  <div className="btn" style={{ marginTop: "25px" }}>

                    <ResponsiveBaseButton
                      isLoading={isPaymentLoading}
                      onClick={() => {
                        handleSubmitClientConfirmation();
                      }}
                    >
                      {confirmationStatus
                        ? "Confirm"
                        : "Decline"}
                    </ResponsiveBaseButton>
                  </div>
                </Flex>
              </CustomModal>
            )
          }

          {/* CONFRIMATION */}
          {
            successfulValidation && (
              <CustomModal
                isOpen={successfulValidation}
                className="modal modal-del"
                close={setSuccessfulValidation}
              >
                <Flex justifyContent="center" flexDirection="column">
                  <OutcomeCircle>
                    <AiOutlineCheck style={{ fontSize: "5rem" }} />
                  </OutcomeCircle>
                  <Heading responsiveFontSize={18} level={2} margin="0 0 30px">Payment Confirmation Successful</Heading>
                  <BaseButton
                    width
                    marginRight="40px"
                    onClick={() => {
                      handleNavigateToReceiptPage();
                    }}
                  >

                    View Receipt
                  </BaseButton>
                </Flex>
              </CustomModal>
            )
          }

          {/* REJECTION */}
          {
            declineValidation && (
              <CustomModal
                isOpen={declineValidation}
                className="modal modal-del"
                close={setDeclineValidation}
              >
                <Flex justifyContent="center" flexDirection="column">
                  <OutcomeCircle decline>
                    <FaTimes style={{ fontSize: "5rem" }} />
                  </OutcomeCircle>
                  <Heading responsiveFontSize={18} level={2}>Payment Decline Successful</Heading>
                  <BaseButton
                    width
                    margin="30px 0"
                    onClick={() => {
                      setDeclineValidation(false);
                    }}
                  >
                    Go back
                  </BaseButton>
                </Flex>
              </CustomModal>
            )
          }
        </>
      }
    />
  );
};

export default ViewInvoice;