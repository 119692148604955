import { USER_ROLES } from "./userRoleConstant";

/**
 * Checks if user is an admin
 * @param {USER_ROLES} role 
 * @returns {boolean}
 */
export const isAdmin = (role) => {
  return [USER_ROLES.EASEI_ADMIN, USER_ROLES.ORGANIZATION_ADMIN].includes(role);
};

export const isEaseiUser = (role) => {
  return [USER_ROLES.EASEI_ADMIN, USER_ROLES.EASEI_USER].includes(role);
};
