import React, { useState } from 'react'
import { Flex } from '../dashboard/dashboard.styles'
import { BsFilter, BsSearch } from 'react-icons/bs'
import Text, { Heading } from 'app/components/atoms/typography'
import { PeopleMobileWrapper } from './people.styles'
import { CardStyle } from 'app/components/atoms/card/card.component'
import { Vortex } from 'react-loader-spinner'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { USER_ROLES } from 'store/reducers/auth/utils'
import { getPeopleAction } from 'utils/helpers/getPeopleAction/getPeopleAction'
import ReactPaginate from 'react-paginate'
import { getActiveStatusName, getBooleanStatusStyle } from 'utils/helpers/createStatusStyle/createStatusStyle'

const PeopleMobileView = ({
    setFilterInput,
    users,
    activeStatus,
    setActiveStatus,
    userActivation,
    setId,
    handleEditUser,
    setApproveDel,
    loading,
    role,
    setDropShow,
    dropShow,
    availableRoles,
    selectedRole,
    setSelectedRole,
    onChangePage,
    pageCount
}) => {
    const [page, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");

    const basicMenu = (row) => 
        getPeopleAction(row, role, userActivation, setActiveStatus, activeStatus, handleEditUser, setApproveDel, setId);

    return (
        <>
            <CardStyle margin="32px 0 0 0 ">
                <PeopleMobileWrapper>
                    <div className='filter_container'>
                        {role !== USER_ROLES.CLIENT_USER && (
                            <div
                                className="role_filter"
                                style={{ position: "relative" }}
                                onClick={() => setDropShow(!dropShow)}
                            >
                                <BsFilter color="var(--accent)" />
                                <Text margin="0" color="var(--grey2)" size={12}>
                                    {selectedRole ? selectedRole : "Role"}
                                </Text>
                                <RiArrowDropDownLine size={30} color="var(--grey2)" />
                                {dropShow && (
                                    <div
                                        className="dropdown"
                                        style={{
                                            position: "absolute",
                                            top: "30px",
                                            zIndex: "1",
                                            left: "0",
                                            right: "0",
                                        }}
                                    >
                                        {availableRoles?.map((role) => (
                                            <div
                                                key={role}
                                                className="dropdown__content"
                                                onClick={() => {
                                                    setSelectedRole(role);
                                                }}
                                            >
                                                {role}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className="search_filter field">
                            <BsSearch className='icon' onClick={()=>{setFilterInput(searchInput)}}/>
                            <input
                                type="text"
                                style={{ outline: "none" }}
                                placeholder="Search"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "50vh",
                            }}
                        >
                            <Vortex
                                height="80"
                                width="80"
                                ariaLabel="vortex-loading"
                                wrapperClass="vortex-wrapper"
                                colors={["red", "blue", "yellow", "#fe9900"]}
                            />
                        </div>
                    ) : (
                        <div>
                            <div style={{ borderBottom: ".5px solid var(--primary2)", marginBottom: "10px" }}>
                                <Flex justifyContent="flex-start" alignItems="center" textAlign="start" gap="10px">
                                    <div>
                                        <Heading margin="0" color="var(--primary2)" size={14}>{role === USER_ROLES.CLIENT_USER
                                            ? "All Clients"
                                            : "All Staff"}</Heading>
                                        <Text margin="0" color="var(--grey2)" size={12}>View all your {role === USER_ROLES.CLIENT_USER ? "client" : "staff"} information here.</Text>
                                    </div>
                                </Flex>
                            </div>

                            {users?.map((user, index) => {
                                return (
                                        <div
                                            key={index}
                                            style={{ borderBottom: ".5px solid var(--primary2)", marginBottom: "15px" }}>
                                            <Flex justifyContent="flex-start" alignItems="center" textAlign="start" gap="10px">
                                                <div style={{ flex: 1 }}>
                                                    <Flex>
                                                        <Heading style={{flex: "60%"}} margin="0" color="var(--primary2)" textAlign="start" size={14}>{user.fullName}</Heading>
                                                        <Text color={getBooleanStatusStyle(user?.isActive)} margin="0px 0px 0px 0px">
                                                            {getActiveStatusName(user?.isActive)}
                                                        </Text>
                                                        <div>{basicMenu(user)}</div>
                                                    </Flex>
                                                   
                                                    <div>
                                                        <Text margin="0" color="var(--grey2)" size={12}>{user.role}</Text>
                                                        <Flex wrap="wrap" wordWrap="break-word">
                                                            <Text margin="0" color="var(--grey2)" wordBreak="break-word" textAlign="start" size={14}>{user.email}</Text>
                                                            <Text margin="0" color="var(--grey2)" size={14}>{user.phoneNumber}</Text>
                                                        </Flex>
                                                    </div>
                                                </div>
                                            </Flex>
                                        </div>
                                )
                            })}
                        </div>
                    )}
                 
                    </PeopleMobileWrapper>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
                        <ReactPaginate
                        breakLabel="..."
                        previousLabel={"←"}
                        nextLabel={"→"}
                        forcePage={page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={(e) => { 
                            setPage(e.selected);
                            onChangePage(e.selected + 1) }
                        }
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        />
                        </div>
            </CardStyle>
        </>
    )
}

export default PeopleMobileView;