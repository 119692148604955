import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "feature";

export const initialState = {
  loading: false,
  featureList: [],
};

const getFeature = createAsyncThunk(`${entity}/list`, async () => {
  const res = await api.feature.getFeature();
  return res.data.data;
});

const createFeature = createAsyncThunk(`${entity}`, async (input) => {
  const res = await api.feature.createFeature(input);
  return res.data;
});

const updateFeature = createAsyncThunk(`${entity}/update`, async (input) => {
  const res = await api.feature.updateFeature(input);
  return res.data;
});

const featureSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeature.fulfilled, (state, action) => {
        state.featureList = action.payload;
        state.loading = false;
      })
      .addCase(getFeature.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getFeature.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(createFeature.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(createFeature.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(createFeature.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(updateFeature.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(updateFeature.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updateFeature.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const featureActions = {
  ...featureSlice.actions,
  getFeature,
  createFeature,
  updateFeature
};

export const featureSelector = {
  isLoading: (state) => state.feature.loading,
  featureList: (state) => state.feature.featureList,
};

export default featureSlice.reducer;
