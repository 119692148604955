import styled from "styled-components";
import Text from "app/components/atoms/typography";

export const FaqContainer = styled.div`
margin-bottom:2.75rem;
cursor: pointer;
`; 

export const FaqQst = styled.div`
  display: flex;
  align-items: center;
  gap:1rem;
  margin-bottom:15px;
`;

export const FaqAnswer = styled(Text)`
   display: flex;
   align-items: start;
`

export const Icon = styled.div`
  margin:10px 10px 0 0 ;
`
export const FaqParagraph = styled(Text)`
  margin-bottom:136px;
`