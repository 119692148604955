import Verify from "app/layouts/auth.layout/verify";

export const EmailVerification = () => {
  return (
    <Verify
      title="Verify your email"
      message="Almost there! We’ve sent a verification email to email@example.com"
      buttonText="Verify Email"
      question="Questions? Email us at"
      response="easei@gmail.com"
    />
  );
};
