import Text, { Heading, Span } from "app/components/atoms/typography";
import { useEffect } from "react";
import Spinner from "app/components/atoms/spinner/spinner.component";
import { FormContent, OrganizationSettingsContainer, UplodedImg } from "../settings.styles";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import * as Yup from "yup";

import {
  SettingsActions,
  SettingsSelectors,
} from "store/reducers/settings/settingsSlice";
import { Form, Formik } from "formik";
import FormInput from "app/components/atoms/form-input/form-input.component";
import CountryStateDropdowns from "../CountryStateDropdown";
import Button from "app/components/atoms/button/button.component";
import SecurityPassword from "../securityPassword.component";
import CloseAccount from "../closeAccount.component";
import { BsFillPersonFill } from "react-icons/bs";
import { useViewport } from "hooks";

const validation = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.number().required(),
  street: Yup.string().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
});
const PersonalInfoSettings = () => {
  const { mobile } = useViewport();
  const dispatch = useDispatch();
  const { handleApiResponse } = useHandleApiResponse();
  const user = useSelector(SettingsSelectors.user);
  const { getUserDetails, updateUserDetails, updateProfilePicture } = SettingsActions;
  const defaultFormFields = {
    id: user.id || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    street: user.street || "",
    state: user.state || "",
    country: user.country || "",
    zipCode: user.zipCode || "",
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadImage = (file) => {

    if (file) {
      dispatch(updateProfilePicture(file)).then(() => {
        dispatch(getUserDetails());
      })
    }
  }

  return (
    <>
      <OrganizationSettingsContainer>
        <Heading level={2} color="var(--primary2)">
          Personal information
        </Heading>
        <Text color="#7B8599">
          Provide as much or as little information as you’d like. Easie will never
          share or sell individual personal information or personally identifiable
          details.
        </Text>
        {user.hasOwnProperty("id") ? (
          <Formik
            initialValues={defaultFormFields}
            validationSchema={validation}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              dispatch(updateUserDetails(values)).then((res) => {
                handleApiResponse(res, null);
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => {
              return (
                <Form className="form">
                  <FormContent className="name">
                    <Text>
                      Name <Span color="red">*</Span>
                    </Text>

                    <FormInput
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      margin={mobile ? "20px 0" : ""}
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",

                      }}
                      value={values["firstName"]}
                      onChange={(value) =>
                        setFieldValue("firstName", value.value)
                      }
                    />
                    <FormInput
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",
                      }}
                      value={values["lastName"]}
                      onChange={(value) => setFieldValue("lastName", value.value)}
                    />
                  </FormContent>
                  <FormContent>
                    <Text>
                      Email <Span color="red">*</Span>
                    </Text>
                    <FormInput
                      type="text"
                      name="email"
                      placeholder="Enter email address"
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",
                      }}
                      readOnly={true}
                      value={values["email"]}
                      onChange={(value) => setFieldValue("email", value.value)}
                    />
                  </FormContent>
                  <FormContent>
                    <Text>
                      Phone Number <Span color="red">*</Span>
                    </Text>
                    <FormInput
                      className="postal"
                      type="text"
                      name="phoneNumber"
                      placeholder="000-000-000"
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",
                      }}
                      value={values["phoneNumber"]}
                      onChange={(value) =>
                        setFieldValue("phoneNumber", value.value)
                      }
                    />
                  </FormContent>
                  <FormContent>
                    <Text>
                      Street <Span color="red">*</Span>
                    </Text>
                    <FormInput
                      type="text"
                      name="street"
                      placeholder="Enter street address"
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",
                      }}
                      value={values["street"]}
                      onChange={(value) => setFieldValue("street", value.value)}
                    />
                  </FormContent>
                  <CountryStateDropdowns
                    country={values["country"]}
                    state={values["state"]}
                  />

                  <FormContent>
                    <Text>
                      Postal/Zip Code <Span color="red">*</Span>
                    </Text>
                    <FormInput
                      className="postal"
                      type="text"
                      name="zipCode"
                      placeholder="Enter street address"
                      style={{
                        backgroundColor: "white",
                        padding: "25px 20px",
                      }}
                      value={values["zipCode"]}
                      onChange={(value) => setFieldValue("zipCode", value.value)}
                    />
                  </FormContent>
                  <FormContent
                    alignItem="center">
                    <div className="file">
                      <div className="custom-file-upload">
                        <label htmlFor="file-upload">
                          Choose Files
                        </label>
                        <input
                          type="file"
                          id="file-upload"
                          name="file"
                          style={{ display: "none" }}
                          onChange={(e) => { handleUploadImage(e.target.files[0]) }}
                        />
                      </div>
                      <UplodedImg>
                        {user?.profilePicture ? (
                          <img src={user?.profilePicture} alt="ProfilePicture" className="img" />
                        ) : (
                          <BsFillPersonFill className="icon" />
                        )}
                      </UplodedImg>
                    </div>
                  </FormContent>

                  <FormContent className="cheat">
                    <div></div>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                    >
                      Save Changes
                    </Button>
                  </FormContent>
                </Form>
              );
            }}
          </Formik>

        ) : (
          <Spinner />
        )}
      </OrganizationSettingsContainer>
      <SecurityPassword />
      <CloseAccount />
    </>
  );
};

export default PersonalInfoSettings;
