import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import Text from "app/components/atoms/typography";
import _ from "lodash";
import {
  INVOICE_TYPE,
  ROUTES,
} from "utils/constants/constants.utils";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import { useViewport } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ActionButton from "app/components/molecules/action-btn/action-btn.component";
import LoadingDots from "app/components/atoms/loading-dots/loading-dots.component";
import { recurringInvoiceActions, recurringInvoiceSelectors } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import BaseRecurringInvoiceDetails from "./base-details";
import { SettingsActions, SettingsSelectors } from "store/reducers/settings/settingsSlice";


const ViewRecurringInvoice = () => {
  const { getDetails } = recurringInvoiceActions;
  const { getAccountDetails } = SettingsActions;
  const accountDetails = useSelector(SettingsSelectors.account);
  const recurringInvoice = useSelector(recurringInvoiceSelectors.recurringInvoice);
  const loading = useSelector(recurringInvoiceSelectors.isLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const { mobile } = useViewport();

  useEffect(() => {
    dispatch(getDetails(id));
    dispatch(getAccountDetails());
    // eslint-disable-next-line
  }, []);


  //#region handle events

  const handleEdit = () => {
    if(recurringInvoice.typeName === INVOICE_TYPE.External){
        navigate(`${ROUTES.EDIT_EXTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${recurringInvoice.id}`);
    }else{
        navigate(`${ROUTES.EDIT_EXTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${recurringInvoice.id}`);
    }
  };

  //#endregion

  if (loading || _.isEmpty(recurringInvoice)) {
    return <LoadingDots text={"Hold on"} />;
  }

  return (
    <BaseRecurringInvoiceDetails
      recurringInvoice={recurringInvoice}
      loading={loading}
      accountInformation={accountDetails}
      backRoute={-1}
      extraComponent={
            <Flex style={{margin:'20px'}}>
                <ActionButton
                    height={mobile ? 13.33 : 40}
                    iconColor={"var(--grey1)"}
                    onClick={handleEdit}>
                    <MdOutlineEdit />
                    <Text size={mobile ? 12 : ""}>
                        Edit
                    </Text>
                </ActionButton>
            </Flex>
      }
    />
  );
};

export default ViewRecurringInvoice;