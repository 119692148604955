import { Heading } from 'app/components/atoms/typography';
import React from 'react'

const ProfilePicture = ({imageSource, name})=>{
    return <>
        {imageSource ? (
        <img
            src={imageSource}
            alt="profilePic"
            style={{ width: "100%" }}
        />
        ) : (
            <Heading weight={500} size={20}>
            {name.includes(" ") ? `${name.split(" ")[0].charAt(0)}${name.split(" ")[1]?.charAt(0)}`
            : name.charAt(0)}

          </Heading>
          
        )}
    </>
}

export default ProfilePicture;