import React, { useEffect, useState } from 'react'
import Text, { Heading } from 'app/components/atoms/typography'
import CheckList from './plan-checkList'
import { PlanContentBox, CheckListContainer, WriteIcon, WriteIcon2 } from './plan.style'
import { FaPencilAlt } from 'react-icons/fa'
import { FiArrowUpRight } from 'react-icons/fi'
import { MOBILE_WINDOW_SIZE, ROUTES } from "utils/constants/constants.utils";
import { useNavigate } from 'react-router-dom'

const PlanContentContainer = ({
  id,
  name,
  description,
  price,
  duration,
  lists
}) => {

  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handlesMobileContent = () => {
    navigate(ROUTES.PLANS_DETAILS, { state: { name, price, description, duration, lists } })
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
      <PlanContentBox >
        <Heading responsiveFontSize={14} size={32}>{name}</Heading>
        <Text responsiveFontSize={14}>{description}</Text>
        <Heading responsiveFontSize={14} size={32}>{price}</Heading>
        <Text responsiveFontSize={14} borderBottom=" 1px solid red">{duration}</Text>
        {windowWidth < MOBILE_WINDOW_SIZE && (
          <div style={{ height: "1px", width: "75%", backgroundColor: "var(--primary2)" }}></div>
        )}
        {windowWidth > MOBILE_WINDOW_SIZE && (
          <CheckListContainer >
            <Heading level={4} color="black" weight="500" margin="40px 0 0"> All the tools you need:</Heading>
            {lists?.map(({ description, id }) => {
              return <CheckList key={id} checkList={description} />
            })}
          </CheckListContainer>
        )}

        <div style={{ position: "relative" }}>
          <WriteIcon>
            <FaPencilAlt
              onClick={() => { navigate(`/plans/${id}`) }}
            />
          </WriteIcon>
          {windowWidth < MOBILE_WINDOW_SIZE && (
            <WriteIcon2>
              <FiArrowUpRight
                onClick={handlesMobileContent}
              />
            </WriteIcon2>
          )}
        </div>
      </PlanContentBox>
  )
}

export default PlanContentContainer