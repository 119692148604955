import styled from "styled-components";

export const RecurringInvoiceTopBanner = styled.div`
background: white;
box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
border-top: 2px solid #dbe7ff;
width: 100%;

.recurring-invoice__new {
  display: flex;
  justify-content: space-between;
  div {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.recurring-invoice-details__close {
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    opacity: 0.65;
    filter: alpha(opacity=65);
    color: #808080;
    width: 25px;
    height: 25px;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
}
`;

export const CustomRecurringInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  gap: 20px;
  // @media screen and (min-width: 850px) {
  //   margin: 30px 0;
  // }
`;