import styled from "styled-components";

export const ReminderContainer = styled.div`
  margin-inline: auto;
  /* 
  button {
        margin-top: 0;
    margin-inline: auto;
  } */
  p {
    margin: 0;
  }
  @media screen and (min-width: 700px) {
    button {
      margin-top: 2em;
      margin-inline: 0;
    }
    .noReminderCard {
      max-width: 80%;
      margin-inline: auto;
    }
  }
`;

export const ReminderContainerHeader = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 700px) {
    align-items: center;
    flex-direction: row;
    gap: 50px;
  }
  .month {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    padding: 5px 10px;
    @media screen and (max-width: 350px) {
      padding: 2px 3px;
    }
  }
  .calendar {
    position: absolute;
    z-index: 3;
    top: 1.5em;
  }
  .month > div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .arrow:hover {
    cursor: pointer;
  }
`;

export const FilterButton = styled.div`
  background: ${({ active }) => (active ? "var(--grey4)" : "")};
  color: ${({ active }) => (active ? "var(--primary2)" : "var(--grey2)")};
  padding: ${({ padding }) => padding || "10px 24px"};
  border-radius: 16px;
  padding: 5px 15px;
  border: 1px solid var(--grey4);
  margin: ${({ margin }) => margin};
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`;

export const MonthWeekDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  gap: 20px;
  border-radius: 5px;
  color: var(--grey2);
  border: 1px solid var(--primary8);
  :hover {
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 2.5em;
  }
`;

export const ReminderContainerBody = styled.div`
  padding: 1em 0;
  row-gap: 2em;
  column-gap: 1em;
  text-align: center;
  
  .card {
    position: relative;
    background: #fafafa;
    padding: 2em 0.5em;
    align-items: center;
    border-radius: 20px;
    .view {
      color: var(--primary);
      position: absolute;
      bottom: 0;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 650px) {
    position: relative;
    background: none;
  }

  @media screen and (min-width: 1036px) {
    .card {
      max-height: 260px;
    }
  }

  .expand {
    overflow-y: scroll;
  }
  .card__content {
    display: flex;
    align-items: center;
    padding: 1em 0.5em;
    flex-direction: column;
    background-color: #ffffff;
    gap: 0.5em;
    border-radius: 8px;
    width: 100%;
    img {
      width: 32px;
      height: 32px;
    }
    > .dp img + img {
      margin-left: -10px;
    }
    cursor: pointer;
  }
  .inactive {
    background: #d5d7db;
  }
  .view {
    color: var(--primary);
    }
  @media screen and (max-width: 650px) {
    .card__content {
      width: 80%;
      background-color: #d5d7db;
      padding: 0 10px;
    }
  }
`;

export const MonthPicker = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  /* overflow-y: scroll; */
  background: white;
  text-align: center;
  border-radius: 10px;
  z-index: 1;
  .select {
    background-color: white;
    border:0.49px solid #7B8599;
    border-radius: 2px;
    max-height: 200px;
    cursor: pointer;
    padding: 2px 5px;
    width: 100%;
    :hover {
      background-color: var(--grey5);
    }
  }
`;

export const ReminderDesktopSubContainer = styled.div`    
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns with equal width */
  grid-template-rows: auto; /* Automatically sizes rows based on content */
  gap: 10px; /* Adds a gap between grid items */
  margin-left: 50px`
;

export const ReminderDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 20px;
  background: #fff;
  align-items: start;
  margin: 60px 0px;
  padding: 20px 30px;
  border-radius: 20px;
  cursor: pointer;
`;

export const ReminderMobileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`;

export  const SendReminderContainer = styled.div`
  margin-bottom: 20px;
  p {
    margin: 0;
  }
  .btn__submit[disabled="disabled"],
  .btn__submit:disabled {
    cursor: not-allowed;
    background-color: #3777ff;
  }

  @media screen and (max-width: 800px) {
    text-align: center;
    .btn-submit {
      justify-content: center;
      align-items: center;
    }
  }
  .card {
    position: relative;
    display: block;
    border-radius: 0;
    text-align: center;
    > :nth-child(4) {
      text-align: right;
    }
    margin-top: 30px;
    > * + * {
      margin-top: 24px;
    }
    @media screen and (max-width: 500px) {
      > :nth-child(4) {
        text-align: center;
      }
    }
    @media screen and (min-width: 900px) {
      padding: 30px 100px;
    }
  }
  .hide {
    display: none;
  }
  .close {
    position: absolute;
    top: 0.3em;
    right: 0;
    font-size: 2em;
    width: 1em;
    height: 1em;
    text-indent: 10em;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    background: transparent;
    &::after {
      position: absolute;
      content: "\u2715";
      line-height: 0.5;
      font-size: 1.5rem;
      top: 0.2em;
      left: 0.1em;
      text-indent: 0;
    }
  }
  .closeMobile {
    position: absolute;
    top: 3em;
    right: 0.3em;
    font-size: 2em;
    width: 1em;
    height: 1em;
    text-indent: 10em;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    background: transparent;
    &::after {
      position: absolute;
      content: "\u2715";
      line-height: 0.5;
      font-size: 1.5rem;
      top: 0.2em;
      left: 0.1em;
      text-indent: 0;
    }
  }
  .input {
    background-color: transparent;
    padding: 2em;
  }
  .form {
    text-align: left;
    margin-top: 24px;
  }

  .form__template {
    position: relative;
  }

  .recipient {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media screen and (min-width: 800px) {
    .recipient {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  }
  .activate {
    background: #5cbc77;
    border-radius: 50px;
    width: ${({ width }) => width || "112px"};
    height: ${({ width }) => width || "44px"};
    position: relative;
    display: inline-block;
    border: none;
    align-self: flex-end;
    ::after {
      content: "";
      position: absolute;
      right: 0.5em;
      top: 0.7em;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
    }
  }
  .inactive {
    background: #bf4545;
    ::after {
      left: 0.5em;
      right: auto;
    }
  }
`;

export const ReminderFormInput = styled.div`
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  background-color:${({ background }) => background || 'transparent'};
  padding: 2em;
  font-size: 14px;
  margin: 20px 0 10px;
  width: ${({ width }) => width || "100%"};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1em;
  .ellips {
    position: absolute;
    font-size: 30px;
    right: 8px;
    bottom: 0;
    color: var(--primary);
    cursor: pointer;
  }
`;

export const ReminderFormInputII = styled.textarea`
  background-color:${({background}) => background ||'transparent'};
  padding:${({padding}) => padding ||'2em'};
  font-size: 14px;
  display: block;
  width: ${({ width }) => width || "100%"};
  margin: 20px 0 10px;
  border:${({ border }) => border || '1px solid #dbe7ff'};
  border-radius: 5px;
`;

export const ReminderDateSelect = styled.div`
  background-color: transparent;
  padding: 1rem;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width || "100%"};
  margin: 20px 0 10px;
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  @media screen and (max-width: 381px) {
    padding: 1rem 0.2rem;
  }
  p {
    @media screen and (max-width: 345px) {
      font-size: 10px;
    }
  }
`;

export const ReminderOptionSelectCircle = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin: 0 3px 0 13px;
    border: ${({ border }) => border || "2px solid var(--accent)"};
  background-color: ${({ active }) => (active ? "var(--accent)" : "")};

  @media screen and (max-width: 345px) {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 800px) {
    padding: 0.1rem;
    width: 15px;
    height: 15px;
  }
`;


export const TemplateContainer = styled.div`
  overflow: scroll;
  overflow-y: visible;
  font-size: calc(1vw + 0.5em);
  padding-top: 50px;
  display: flex;

  gap: 20px;
  img {
    flex: 1;
    width: 10em;
    height: 15em;
  }
  .choose {
    transform: translateY(-30px);
  }
  @media screen and (min-width: 900px) {
    img {
      font-size: 1rem;
      width: auto;
      height: auto;
    }
  }
`;

export const MobileTemplateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
  width: fit-content;
  margin-top: 10px;
  img {
    width: 100%;
  }
  .choose {
    transform: translateY(-10px);
  }
`;

export const ScrollBtn = styled.div`
  background: #d9d9d9;
  border-radius: 100%;
  position: absolute;
  display: flex;
  z-index: 2;
  bottom: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #7b8599;
`;