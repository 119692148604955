import FormInput from "app/components/atoms/form-input/form-input.component";
import Text, { Heading, Span } from "app/components/atoms/typography";
import { Form, Formik } from "formik";
import { FormContent, OrganizationSettingsContainer } from "./settings.styles";
import * as Yup from "yup";
import { PasswordValidator } from "utils/validation/validation.utils";
import Button from "app/components/atoms/button/button.component";
import { useDispatch } from "react-redux";
import { authActions } from "store/reducers/auth/authSlice";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import VisibilityIcon from "utils/visibilityIcon";
import { useState } from "react";

const defaultFormFields = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const validator = Yup.object({
  currentPassword: PasswordValidator,
  newPassword: PasswordValidator,
  confirmPassword: PasswordValidator,
});
const SecurityPassword = () => {
  const dispatch = useDispatch();
  const { ChangePassword } = authActions;
  const { handleApiResponse } = useHandleApiResponse();

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const handleTogglePassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <OrganizationSettingsContainer>
      <Heading level={2} color="var(--primary2)">
        Security & Password
      </Heading>
      <Text color="#002776">Change Password</Text>

      <Formik
        initialValues={defaultFormFields}
        validationSchema={validator}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          dispatch(ChangePassword({ ...values })).then((res) => {
            handleApiResponse(res, null);
            setSubmitting(false);
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          return (
            <Form className="form">
              <FormContent>
                <Text>
                  Old Password <Span color="red">*</Span>
                </Text>
                <div style={{position:"relative"}}>
                <FormInput
                  type={showPassword.currentPassword ? "text" : "password"}
                  name="currentPassword"
                  placeholder="Enter old password"
                  style={{
                    backgroundColor: "white",
                    padding: "25px 20px",
                    letterSpacing: 0,
                  }}
                  value={values["currentPassword"]}
                  onChange={(value) =>
                    setFieldValue("currentPassword", value.value)
                  }
                />
                <VisibilityIcon
                  showPassword={showPassword.currentPassword}
                  onClick={() => handleTogglePassword("currentPassword")}
                />  
                </div>
              </FormContent>



              {values["currentPassword"].length > 0 &&
                errors.currentPassword && (
                  <Text color="red">{errors.currentPassword}</Text>
                )}
              <FormContent>
                <Text>
                  New Password <Span color="red">*</Span>
                </Text>
                <div style={{ position: "relative" }}>
                <FormInput
                  type={showPassword.newPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Enter new password"
                  style={{
                    backgroundColor: "white",
                    padding: "25px 20px",
                    letterSpacing: 0,
                  }}
                  value={values["newPassword"]}
                  onChange={(value) =>
                    setFieldValue("newPassword", value.value)
                  }
                />
                <VisibilityIcon
                  showPassword={showPassword.newPassword}
                  onClick={() => handleTogglePassword("newPassword")}
                />
                </div>
              </FormContent>
              {values["newPassword"].length > 0 && errors.newPassword && (
                <Text color="red">{errors.newPassword}</Text>
              )}
              <FormContent>
                <Text>
                  Confirm Password <Span color="red">*</Span>
                </Text>
                <div style={{ position: "relative" }}>
                <FormInput
                  type={showPassword.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Enter old password"
                  style={{
                    backgroundColor: "white",
                    padding: "25px 20px",
                    letterSpacing: 0,
                  }}
                  value={values["confirmPassword"]}
                  onChange={(value) =>
                    setFieldValue("confirmPassword", value.value)
                  }
                />
                <VisibilityIcon
                  showPassword={showPassword.confirmPassword}
                  onClick={() => handleTogglePassword("confirmPassword")}
                />
                </div>
              </FormContent>
              {values["confirmPassword"].length > 0 &&
                errors.confirmPassword && (
                  <Text color="red">{errors.confirmPassword}</Text>
                )}
              {values["confirmPassword"].length > 0 &&
                !(values["confirmPassword"] === values["newPassword"]) && (
                  <Text color="red">Passwords do not match</Text>
                )}

              <FormContent className="cheat">
                <div></div>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Set New Password
                </Button>
              </FormContent>
            </Form>
          );
        }}
      </Formik>
    </OrganizationSettingsContainer>
  );
};

export default SecurityPassword;
