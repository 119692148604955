import { createSlice } from "@reduxjs/toolkit";

const entity = "appData";

export const initialState = {
    headerText: '',
    loading: false,
    previewInvoice: {},
    previewRecurringInvoice: {}
  };

  const appDataSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        resetState: (state) => {
      state.headerText = '';
    },
    resetPreviewState: (state)=>{
      state.previewInvoice = {}
    },
    resetPreviewRecurringInvoiceState: (state)=>{
      state.previewRecurringInvoice = {}
    },
    setPreviewRecurringInvoiceState: (state, action)=>{
      state.previewRecurringInvoice = action.payload;
    },
    setPreviewInvoiceState: (state, action)=>{
      state.previewInvoice = action.payload;
    },
    setHeaderText:(state, action) =>{
        state.headerText = action.payload.value;
    }}
  });

  export const appDataActions = 
  {
  ...appDataSlice.actions
  };
  
  export const appdataSelector = {
    isLoading: (state) => state.appData.loading,
    headerText: (state) => state.appData.headerText,
    previewInvoice: (state) => state.appData.previewInvoice,
    previewRecurringInvoice: (state) => state.appData.previewRecurringInvoice
  };

  export default appDataSlice.reducer;