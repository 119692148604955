import React, { useState } from "react";
import { useOnClickOutside } from "hooks";
import DropdownComponent from "app/components/molecules/drop-down/drop-down.component";
import { RecurTypeData } from "utils/constants/constants.utils";

const RecurTypeSearch = ({ setRecurType, ...props }) => {
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const [selectedLabel, setSelectedLabel] = useState("");

  const clearPayment = (e) => {
    setRecurType(null);
    setSelectedLabel("");
    e.stopPropagation();
  };

  const toggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };
 
  return (
    <div ref={ref}>
          <DropdownComponent
          visible={visible}
          toggleVisible={toggleVisible}
          options={RecurTypeData.options}
          selectName={RecurTypeData.label}
          onSelect={(value) => {
            setRecurType(value);
              setSelectedLabel(RecurTypeData.options
                .find((option) => option.value === value)?.label || "");
            }}
            label={selectedLabel}
            clearSelection={clearPayment}
            {...props} 
          />
    </div>
  );
};

export default React.memo(RecurTypeSearch);
