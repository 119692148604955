import { AiOutlinePlus } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import Text from "app/components/atoms/typography";

export const CreateDropdown = ({handlePopupClicked}) =>
{
    return <>
            <AiOutlinePlus color="#fff" fill="#fff" style={{marginRight:"10px"}}/>
            <Text color="#fff" weight={700}>
                Create Invoice
            </Text>
            <button
            style={{
            border: "none",
            background: "none",
            cursor: "pointer",
            }}
            onClick={handlePopupClicked}
            >
            <IoMdArrowDropdown color="white" size="20px" />
            </button>
        </>
}