import { CardContainerI, CardImage, SettingsWrapper } from "../../../pages/settings/settings.styles";
import Button from "app/components/atoms/button/button.component";
import AccessDeniedImage from '../../../assets/img/AccessDenied.png';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";

const AccessDenied = () =>{

    const navigate = useNavigate();

    return (
    <SettingsWrapper className="dashboard-container">

        <CardContainerI>
        <CardImage src={AccessDeniedImage} alt="Access Denied" />
          <Button onClick={()=> navigate(ROUTES.DASHBOARD)} width="true">Go to Dashboard</Button>
          </CardContainerI>
    </SettingsWrapper>
    );
}

export default AccessDenied;