import { INVOICE_ACTIONS } from "utils/constants/constants.utils";

export const getInvoiceStatusValue = (status) => {
    switch (status) {
        case INVOICE_ACTIONS.PENDING:
            return 0;
        case INVOICE_ACTIONS.APPROVE:
            return 1;
        case INVOICE_ACTIONS.DECLINE:
            return 2;
        case INVOICE_ACTIONS.REQUEST_CHANGE:
            return 3;
        default:
            return 99;
    }
}