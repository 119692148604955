import React, { useState } from "react";
import { useOnClickOutside } from "hooks";
import DropdownComponent from "app/components/molecules/drop-down/drop-down.component";
import { INVOICE_PAYMENT_STATUS_DROPDOWN_DATA } from "utils/constants/constants.utils";

const PaymentSearch = ({ setPayment, ...props }) => {
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const [selectedLabel, setSelectedLabel] = useState("");

  const clearPayment = (e) => {
    setPayment(null);
    setSelectedLabel("");
    e.stopPropagation();
  };

  const toggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };
 
  return (
    <div ref={ref}>
          <DropdownComponent
          visible={visible}
          toggleVisible={toggleVisible}
          options={INVOICE_PAYMENT_STATUS_DROPDOWN_DATA.option}
          selectName={INVOICE_PAYMENT_STATUS_DROPDOWN_DATA.label}
          onSelect={(value) => {
              setPayment(value);
              setSelectedLabel(INVOICE_PAYMENT_STATUS_DROPDOWN_DATA.option
                .find((option) => option.value === value)?.label || "");
            }}
            label={selectedLabel}
            clearSelection={clearPayment}
            {...props} 
          />
    </div>
  );
};

export default React.memo(PaymentSearch);
