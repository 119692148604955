import React, {useContext, useEffect} from "react";
import Button from "app/components/atoms/button/button.component";
import CustomModal from "app/components/atoms/modal/modal.component";
import Text from "app/components/atoms/typography";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonContainer,
  FormContainer,
  BoxColumn
} from "./organization.styles";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import 'index.css';
import { useFormik } from "formik";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { merchantSelectors, merchantActions } from "store/reducers/merchant/merchantSlice";
import { OrganizationActions, organizationSelectors } from "store/reducers/organization/organizationSlice";
import OrganizationContext from "store/context/organizationContext";
import { TitleContainer } from "app/components/atoms/divs/div.styles";

const statusObj = [
    {
        label: "Active",
        value: true
    },
    {
        label: "In Active",
        value: false
    }
];

const Patnership = ({ visible, setVisible }) => {

    const dispatch = useDispatch();
    const { handleApiResponse } = useHandleApiResponse();
    const organization = useContext(OrganizationContext);
    const merchants = useSelector(merchantSelectors.merchantsForDropdown);
    const organizations = useSelector(organizationSelectors.organizationsForDropDown);

    const { searchMerchants } = merchantActions;
    const { getOrganizations, updatePartnership } = OrganizationActions;

    const isEdit = organization !== null;

    const handleOrganizationDropDownClicked = (e) =>{
        formik.setFieldValue("organizationId", e.value);
    }

    const handleMerchantDropDownClicked = (e) =>{
        formik.setFieldValue("merchantId", e.value);
    }

    const handleStatusDropDownClicked = (e) =>{
        formik.setFieldValue("status", e.value);
    }

    useEffect(() => 
    {
        dispatch(searchMerchants({
            status: null,
            name: null
          }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => 
    {
        dispatch(getOrganizations());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    enableReinitialize: true,
		initialValues: {
           organizationId: isEdit ? organization.id : 0,
           merchantId: isEdit ? organization.merchantId : 0,
           status: isEdit ? organization.invoiceFinancing : 0,
        },

        validate(values){

            const errors = {};

            if(values.merchantId === 0){
                errors.merchantId = "Select a Merchant";
            }

            if(values.organizationId === 0){
                errors.organizationId = "Select an Organization";
            }

            if(values.status === null){
                errors.status = "Select a status";
            }

            return errors;
        },

		onSubmit(values) {
            dispatch(updatePartnership(values)).then((res)=>
            {
                setVisible(false);
                handleApiResponse(res, null);
            });  
		},
	});

  return (
    <CustomModal isOpen={visible} close={setVisible}>
      <TitleContainer>
        <Text size={20} weight={700} color="#0148DE">
          Add / Update Partnership
        </Text>
      </TitleContainer>
      <FormContainer>
        <form onSubmit={formik.handleSubmit}>
       <table>
        <tbody>
            <tr>
                <BoxColumn>
                    <label htmlFor="organization">Organization</label>
                    <Dropdown
                    id = "organization"
                    options={organizations}
                    value={isEdit ? organizations.filter(function (item) { return item.value === organization.id; })[0] 
                            || null : null}
                    onChange={handleOrganizationDropDownClicked}
                    className={formik.errors.organizationId ? "error" : null}
                    controlClassName="dropdownLarge"/>
                </BoxColumn>

                <BoxColumn>
                    <label htmlFor="merchant">Merchant</label>
                    <Dropdown
                    id = "merchant"
                    options={merchants}
                    value={isEdit ? merchants.filter(function (item) { return item.value === organization.merchantId; })[0] 
                            || null : null}
                    onChange={handleMerchantDropDownClicked}
                    className={formik.errors.merchantId ? "error" : null}
                    controlClassName="dropdownLarge"/>
                </BoxColumn>

            </tr>
            <tr>
                <BoxColumn>
                    <label htmlFor="status">Status</label>
                    <Dropdown
                    id = "status"
                    options={statusObj}
                    value={isEdit ? statusObj.filter(function (item) { return item.value === organization.invoiceFinancing; })[0] 
                            || null : null}
                    onChange={handleStatusDropDownClicked}        
                    className={formik.errors.status ? "error" : null}            
                    controlClassName="dropdownLarge"/>
                </BoxColumn>

            </tr>
        </tbody>
       </table>
       <ButtonContainer>
        <Button type="submit">Save</Button>
      </ButtonContainer>
       </form>
      </FormContainer>
    </CustomModal>
  );
};

export default Patnership;
