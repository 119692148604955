import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "Merchant";

export const initialState = {
    data: [],
    totalCount: 0,
    merchant :{},
    loading: false,
  };

  const searchMerchants = createAsyncThunk(
    `${entity}/search`,
    
    async (input) => {
      const {
        page = 1,
        pageSize = 10,
        status,
        name
      } = input;
      const res = await api.merchant.search(
        page,
        pageSize,
        status,
        name
      );
      return res.data;
    }
  );

  const updateStatus = createAsyncThunk(
    `${entity}/update-status`,
    async (input) => {
      const {
        id,
        status
      } = input;
      const res = await api.merchant.updateStatus(
        id,
        status
      );
      return res.data;
    }
  );

  const getDetails = createAsyncThunk(`${entity}/getDetails`, async (id) => {
    const res = await api.merchant.getById(id);
    return res.data;
  });

  const createMerchant = createAsyncThunk(`${entity}/create`, async (input) =>{
    const res = await api.merchant.create(input);
    return res.data;
  });

  const updateMerchant = createAsyncThunk(`${entity}/update`, async (input) =>{
    const res = await api.merchant.update(input);
    return res.data;
  });

  const merchantSlice = createSlice({
    name: entity,
    initialState,
    reducers: { getMerchantCount: (state) => {
        state.merchantCount = state.data.length;
      }, },
    extraReducers: (builder) => {
      builder
        .addCase(searchMerchants.fulfilled, (state, action) => {  
          return { ...state, loading: false, ...action.payload };
        })
        .addCase(searchMerchants.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(searchMerchants.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(getDetails.fulfilled, (state, action) => {
          state.merchant = action.payload.data;
          state.loading = false;
        })
        .addCase(getDetails.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(getDetails.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(createMerchant.fulfilled, (state, action) => {
          state.merchant = action.payload.data;
          state.loading = false;
        })
        .addCase(createMerchant.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(createMerchant.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(updateMerchant.fulfilled, (state, action) => {
          state.merchant = action.payload.data;
          state.loading = false;
        })
        .addCase(updateMerchant.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(updateMerchant.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
    },
  });

  export const merchantActions = 
  {
  ...merchantSlice.actions,
    searchMerchants,
    updateStatus,
    getDetails,
    createMerchant,
    updateMerchant
  };
  
  export const merchantSelectors = {
    isLoading: (state) => state.merchants.loading,
    merchants: (state) => state.merchants.data,
    merchant: (state) => state.merchants.merchant,
    totalCount: (state) => state.merchants.totalCount,
    merchantsForDropdown : (state) => state.merchants.data?.map(function (item) {
      return { value: item.id, label: item.name };
  })
  };
  
  export default merchantSlice.reducer;