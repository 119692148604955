export const getOrdinalSuffix = (day) => {
    if (day === 11 || day === 12 || day === 13) {
      return 'th';
    }

    const lastDigit = day % 10;
  
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };