import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import ExternalUser from "app/components/molecules/external-user/external-user";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import { recurringInvoiceActions } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import BaseRecurringInvoiceSetup from "../baseSetup/baseSetupRecurringInvoice";

const CreateExternalRecurringInvoice = () => {
  
  const { createExternal } = recurringInvoiceActions;
  const { resetPreviewRecurringInvoiceState, setPreviewRecurringInvoiceState } = appDataActions;
  const recurringInvoice = useSelector(appdataSelector.previewRecurringInvoice);

  const { handleApiResponse } = useHandleApiResponse();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [externalUser, setExternalUser] = useState();
  
  const handleSave = (data, isPreview) =>{
    let payload = {
      externalUserId: externalUser.value,
      type: INVOICE_TYPE.External,
      ...data
    }

      if(isPreview){
        dispatch(setPreviewRecurringInvoiceState(payload));
        navigate(ROUTES.PREVIEW_RECURRING_INVOICE)
      }
    else{
      dispatch(createExternal(payload)).then((res)=>{
        dispatch(resetPreviewRecurringInvoiceState());
        handleApiResponse(res, ROUTES.RECURRINGINVOICE);
      })
    }
  }

return (
    <BaseRecurringInvoiceSetup
    recurringInvoice={recurringInvoice}
    ClientDropDown={
    <ExternalUser 
    invoice={recurringInvoice}
    externalUser={externalUser}
    setExternalUser={setExternalUser}/>
  }
    handleSave={handleSave}/>
)

};
export default CreateExternalRecurringInvoice;