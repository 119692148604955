import { appdataSelector } from "store/reducers/appData/appDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import { SettingsActions, SettingsSelectors } from "store/reducers/settings/settingsSlice";
import { useEffect } from "react";
import _ from 'lodash';
import BaseRecurringInvoiceDetails from "./base-details";

const PreviewRecurringInvoice = () => {

    const recurringInvoice = useSelector(appdataSelector.previewRecurringInvoice);
    const accountDetails = useSelector(SettingsSelectors.account);
    const { getAccountDetails } = SettingsActions;
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getAccountDetails());
        // eslint-disable-next-line
    },[])

    const generateInvoiceRoute = () => {
        if (_.isEmpty(recurringInvoice.id)) {
          if (recurringInvoice.type === INVOICE_TYPE.External) {
            return ROUTES.CREATE_EXTERNAL_RECURRING_INVOICE;
          } else {
            return ROUTES.CREATE_INTERNAL_RECURRING_INVOICE;
          }
        } else {
          if (recurringInvoice.type === INVOICE_TYPE.External) {
            return `${ROUTES.EDIT_EXTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${ROUTES.PREVIEW_ROUTE}`;
          } else {
            return `${ROUTES.EDIT_INTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${ROUTES.PREVIEW_ROUTE}`;
          }
        }
      };      

      return (
        <BaseRecurringInvoiceDetails
            backRoute={generateInvoiceRoute()}
            accountInformation={accountDetails}
            recurringInvoice={recurringInvoice}/>);
}

export default PreviewRecurringInvoice;