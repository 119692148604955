import { authSelectors } from "store/reducers/auth/authSlice";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";
import { CustomSelect, InputDataHouse, Label } from "app/components/atoms/generalInvoiceStyles/general.sytlyes";

const OrganizationSelectDropdown = ({ organization, setOrganization, isDisabled = false, invoice }) => {
  const organizations = useSelector(authSelectors.organizationsForDropdown);

  useEffect(() => {
    if (!_.isEmpty(invoice)) {
      setOrganization(organizations.filter((item) => item.value === invoice.organizationId)[0] || null);
    }
    // eslint-disable-next-line 
  }, [invoice]);

  return (
      <InputDataHouse>
        <Label>Customer</Label>
        <CustomSelect
          isDisabled={isDisabled}
          placeholder="Select Customer"
          className="react-select-container"
          classNamePrefix="react-select"
          options={organizations}
          onChange={(organization) => setOrganization(organization)}
          value={organization}
        />
      </InputDataHouse>
)
};

export default OrganizationSelectDropdown;