import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";
import invoiceSlice from "../invoice/invoiceSlice";

const entity = "dashboard";

export const initialState = {
  invoiceMetrics: [],
  lastFiveInvoices: [],
  timeData: {},
  loading: false,
};

const usersDashboard = createAsyncThunk(
  `${entity}/users-dashboard`,
  async (input) => {
    const { day, month, year, weekly = false } = input;
    const res = await api.dashboard.usersDashboard(day, month, year, weekly);
    return res.data.data;
  }
);

const dashboardSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(usersDashboard.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(usersDashboard.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(usersDashboard.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});
export const dashboardActions = {
  ...invoiceSlice.actions,
  usersDashboard,
};

export const dashboardSelectors = {
  isLoading: (state) => state.dashboard.loading,
  invoiceMetrics: (state) => state.dashboard.invoiceMetrics,
  lastFiveInvoices: (state) => state.dashboard.lastFiveInvoices,
  timeData: (state) => state.dashboard.timeData,
};
export default dashboardSlice.reducer;
