import React from 'react';
import { BsSearch } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { USER_ROLES } from 'store/reducers/auth/utils';

// Custom Components
import { ActionDivContainer, CustomTableSearch, MobileSearchComponentContainer, SearchComponentContainer } from 'app/components/atoms/divs/div.styles';
import { CustomDateInput } from 'app/components/atoms/date/dates';
import OrganizationSearchComponent from '../organizationSearch/organization-search.component';
import Text from 'app/components/atoms/typography';
import { useCheckAdmin, useViewport } from 'hooks';
import PaymentSearchComponent from '../paymentSearch/payment-search.component';
import { useState } from 'react';
import RecurTypeSearchComponent from '../recurTypeSearch/recur-type-search-component';

const SearchComponent = ({
  setSearchInput,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setOrganization,
  setPayment,
  setRecurType,
  isInvoice = false,
  isRecurring = false
}) => {
  const { role } = useCheckAdmin();
  const [input, setInput] = useState();
  const { mobile } = useViewport();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const findIndexByParent = (arr, parentValue) => arr.findIndex((item) => item?.name === parentValue);

  const searchComponents = [
    isRecurring ?
    {
      order: 1,
      component:
        <RecurTypeSearchComponent
        setRecurType={setRecurType} />,
     gridRow: '2',
     name: 'recurType',
     parent: 'recurType'
    } : null,

    isInvoice ?
    {
      order: 1,
      component: (
        <PaymentSearchComponent
        setPayment={setPayment} />),
     gridRow: '2',
     name: 'payment',
     parent: 'payment'
    } : null,

    role === USER_ROLES.CLIENT_USER ?
    {
      order: 2,
      component: (
        <OrganizationSearchComponent
        setOrganization={setOrganization}
      />),
     gridRow: '2',
     name: 'organization',
     parent: 'organization'
    } : null,

    {
      order: 3,
      component: (
        <ActionDivContainer>
        <FaRegCalendarAlt size={20} color="var(--accent)" />
        <DatePicker
          dateFormat={"dd/MM/YYY"}
          selected={startDate}
          onChange={handleStartDateChange}
          customInput={<CustomDateInput />}
        />
      </ActionDivContainer>),
     gridRow: '2',
     name: 'startDateComponent',
     parent: 'startDateComponent'
    } ,
    {
      order: 4,
      component: (
        <ActionDivContainer>
        <FaRegCalendarAlt size={20} color="var(--accent)" />
        <DatePicker
          dateFormat={"dd/MM/YYY"}
          selected={endDate}
          onChange={handleEndDateChange}
          customInput={<CustomDateInput />}
        />
      </ActionDivContainer>),
     gridRow: '2',
     name: 'endDateComponent',
     parent: 'endDateComponent'
    } ,
    {
      order: 5,
      component: (
        (
          <CustomTableSearch>
            <input
              type="text"
              placeholder="Search"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <BsSearch onClick={()=> setSearchInput(input)} className='search-icon'/>
          </CustomTableSearch>
        )),
     gridRow: '2',
     name: 'searchInput',
     parent: 'searchInput'
    } ,
    {
      order: 5,
      component: (
        <Text margin="40px 0 0 40px" size={20}>
        Start Date
      </Text>),
     gridRow: '1',
     name: 'startDate',
     parent: 'startDateComponent'
    },
    {
      order: 7,
      component: (
        <Text margin="40px 0 0 40px" size={20}>
        End Date
      </Text>),
     gridRow: '1',
     name: 'endDate',
     parent: 'endDateComponent'
    } 
  ].filter((componentProps) => componentProps !== null);

  const mobileSearchComponents =
  [
    {
      order: 1,
      component: (
        <div>
          <Text size={20} margin="0 0 0 20px">
        Start Date
      </Text>
        <ActionDivContainer>
        <FaRegCalendarAlt size={20} color="var(--accent)" />
        <DatePicker
          dateFormat={"dd/MM/YYY"}
          selected={startDate}
          onChange={handleStartDateChange}
          customInput={<CustomDateInput />}
        />
      </ActionDivContainer>
      </div>)
    },
    {
      order: 2,
      component: (
        <div>
           <Text size={20} margin="0 0 0 20px">
        End Date
      </Text>
      <ActionDivContainer>
        <FaRegCalendarAlt size={20} color="var(--accent)" />
        <DatePicker
          dateFormat={"dd/MM/YYY"}
          selected={endDate}
          onChange={handleEndDateChange}
          customInput={<CustomDateInput />}
        />
      </ActionDivContainer>
        </div>)
    },
    isInvoice ?
    {
      order: 3,
      component: (
        <PaymentSearchComponent
        setPayment={setPayment} />)
    } : null,

    isRecurring ?
    {
      order: 3,
      component: (
        <RecurTypeSearchComponent
        setRecurType={setRecurType} />)
    } : null,

    role === USER_ROLES.CLIENT_USER ?
    {
      order: 4,
      component: (
        <OrganizationSearchComponent
        setOrganization={setOrganization}
      />),
    } : null,
    {
      order: 5,
      component: (
        (
          <CustomTableSearch>
            <input
              type="text"
              placeholder="Search"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <BsSearch onClick={()=> setSearchInput(input)} className='search-icon'/>
          </CustomTableSearch>
        )),
    } ,
  ].filter((componentProps) => componentProps !== null);

 return mobile ? (
    <MobileSearchComponentContainer>
      {mobileSearchComponents
          .sort( ( prop => prop.order))
          .map((componentProps, index) => {

        return (
          <div key={index} style={{ gridColumn: 2, gridRow: index + 1}}>
            {componentProps.component}
          </div>
        );
      })}

    </MobileSearchComponentContainer>)
: 
    <SearchComponentContainer>
      {searchComponents
      .sort( ( prop => prop.order))
      .map((componentProps, index) => {

        const indexToUse = componentProps.parent === componentProps.name ? index : 
          findIndexByParent(searchComponents, componentProps.parent);

        return (
          <div key={index} style={{ gridColumn: indexToUse + 1, gridRow: componentProps.gridRow }}>
            {componentProps.component}
          </div>
        );
      })}

    </SearchComponentContainer>
};

export default SearchComponent;
