import styled from "styled-components";
import { Formik } from "formik";
import {
  GeneralValidator,
  NumberValidator,
  UnitValidator,
} from "utils/validation/validation.utils";
import * as Yup from "yup";
import FormInput from "app/components/atoms/form-input/form-input.component";
import Button from "app/components/atoms/button/button.component";
import Select from "react-select";
import { createOptionsObject } from "utils/helpers/createOptionsObject/createOptionsObject";
import { timeUnits, unitType } from "api/data/unit-type";
import { useState } from "react";

//#region styles

const CustomSelect = styled(Select)`
  width: 100%;
  .react-select {
    &__control {
      background-color: #f2f6ff;
    }
    &__option {
      font-size: 14px;
    }
    &__value-container {
      font-size: 14px;
    }
    &__menu-list {
      display: flex;
      flex-direction: column;
    }
  }
`;
const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
`;

const FormInputLabel = styled.label`
  color: #3a3e47;
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const UnitDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  div: first-child {
    flex: 1;
  }
`;

const ValueDiv = styled.div`
@media screen and (max-width: 768px) {
  width: 65px;
}
`;

//#endregion

const unitOptions = createOptionsObject(unitType, "id", "name");
const durationOptions = createOptionsObject(timeUnits, "id", "name");

const defaultFormFields = {
  serviceName: "",
  description: "",
  unitType: 0,
  itemUnit: 1,
  cost: '',
  tax: '',
};

const validate = Yup.object({
  serviceName: GeneralValidator,
  description: GeneralValidator,
  unitType: UnitValidator,
  cost: NumberValidator,
  tax: NumberValidator,
  itemUnit: Yup.string()
    .when("unitType", {
      is: (unitType) => unitType === 0,
      then: Yup.string().test(
        "isValidQuantity",
        "The Quantity is invalid",
        (value) => !isNaN(value)
      ),
    })
    .when("unitType", {
      is: (unitType) => unitType === 1,
      then: Yup.string()
        .required()
        .test("isValidDurationUnit", "The Duration is invalid", (value) => {
          let itemUnit = value.split("-");
          return itemUnit.Length > 1 && !isNaN(itemUnit[0]);
        }),
    }),
});

export const NewInvoiceItem = ({ data, setData }) => {
  const [showUnits, setShowUnits] = useState(false);

  const handleSubmit = (values) => {
    const { cost, unitType, itemUnit, tax } = values;
    let totalAmount = 0;

    if (unitType === 0) {
      totalAmount = itemUnit * cost + tax;
    }
    if (unitType === 1) {
      var unit = itemUnit.Split("-");
      if (unit.Length < 1 || !isNaN(unit[0])) {
        totalAmount = unit[0] * cost + tax;
      }
    }

    const newValues = { ...values, totalAmount };
    setData([...data, newValues]);
  };
  
  return (
    <>
      <Formik initialValues={defaultFormFields} validationSchema={validate}>
        {({ isSubmitting, resetForm, values }) => (
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(values);
              resetForm();
            }}
          >
            <FormContainer>
              <div>
                <FormInputLabel>Order Name</FormInputLabel>
                <FormInput
                  placeholder="What is the invoice for?"
                  type="text"
                  name="serviceName"
                  required
                />
              </div>
              <div>
                <FormInputLabel>Description</FormInputLabel>
                <FormInput
                  placeholder="Add a description"
                  type="text"
                  name="description"
                  required
                />
              </div>
              <UnitDiv>
                <div>
                  <FormInputLabel>Unit Type</FormInputLabel>
                  <CustomSelect
                    placeholder="Select unit type"
                    options={unitOptions}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="unitType"
                    onChange={(e) => {
                      setShowUnits(e.label === "Duration");
                    }}
                  />
                </div>
                {showUnits && (
                  <div>
                    <FormInputLabel>Unit</FormInputLabel>
                    <CustomSelect
                      placeholder="Select"
                      options={durationOptions}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="unit"
                    />
                  </div>
                )}
                <ValueDiv>
                  <FormInputLabel>Value</FormInputLabel>
                  <FormInput
                    placeholder="0"
                    type="number"
                    name="itemUnit"
                    required
                  />
                </ValueDiv>
              </UnitDiv>
              <div>
                <FormInputLabel>Price</FormInputLabel>
                <FormInput
                  placeholder="Enter price"
                  type="number"
                  name="cost"
                  required
                />
              </div>
              <div>
                <FormInputLabel>Tax</FormInputLabel>
                <FormInput
                  placeholder="Add Tax"
                  type="number"
                  name="tax"
                  required
                />
              </div>
              <AddButtonContainer>
                <Button disabled={isSubmitting} type="submit">
                  Add
                </Button>
              </AddButtonContainer>
            </FormContainer>
          </form>
        )}
      </Formik>
    </>
  );
};