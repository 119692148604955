import PageMessage from "app/components/molecules/page-message/page-message.component";
import { CardContainerI, CardImage, CardText, CardTextI, SettingsWrapper } from "./settings.styles";
import Button from "app/components/atoms/button/button.component";
import  paymentConfirmation from '../../assets/img/paymentConfirmation.png';
import paymentFailure from '../../assets/img/paymenFailure.png';
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";
import _ from "lodash";
import { useState } from "react";
import { Vortex } from "react-loader-spinner";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { useDispatch, useSelector } from "react-redux";

const PaymentConfirmation = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const isLoading = useSelector(planSelectors.isLoading);
    const [confirmed, setConfirmed] = useState(false);
    const { verifyPayment } = planActions
    const reference = searchParams.get('reference');

    useEffect(()=>{
        if(!_.isEmpty(reference)){
            dispatch(verifyPayment(reference))
            .then((res)=>{
              if(res?.payload?.data?.succeeded){
                setConfirmed(true);
              }
              else if(res?.payload?.data?.succeeded === false){
                setConfirmed(false);
              }
            });
        }
        // eslint-disable-next-line
    }, [reference])

    return (<>
    <SettingsWrapper className="dashboard-container">
      <div style={{marginBottom: '20px'}} >
        <PageMessage heading="Payment Confirmation"/>
      </div>

        {isLoading ?  <Vortex
                height="200"
                width='100%'
                display= 'flex'
                justifyContent='center'
                alignItems= 'center'
                ariaLabel="vortex-loading"
                wrapperClass="vortex-wrapper"
                colors={['red', 'blue', 'yellow', '#fe9900',]}
              /> :
       
        <CardContainerI>
        <CardImage src={confirmed === true ? paymentConfirmation : paymentFailure} alt="Confimred" />
        <CardTextI>{ confirmed === true && 'Your payment is successful'}</CardTextI>
          { !confirmed &&   <CardText >Payment was not successful from the provider
          but do not panic if we receive the funds we will update your plan in less than 1 hour</CardText> }        
          { confirmed === true && 
          <CardText>Your payment has been received and Basic plan activated.
          </CardText>}
          <Button onClick={()=> navigate(ROUTES.DASHBOARD)} width="true">Go to Dashboard</Button>
          </CardContainerI>
          }
    </SettingsWrapper>
    </>);
}

export default PaymentConfirmation;