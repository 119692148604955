import BaseInvoiceSetup from "../baseSetup/baseSetupInvoice";

const BaseEditInvoice = ({handleSave, ClientDropDown, invoice, backRoute}) => {

    return (
        <BaseInvoiceSetup
        invoice={invoice}
        backRoute={backRoute}
        handleSave={handleSave}
        ClientDropDown={ClientDropDown}>
        </BaseInvoiceSetup>)
};

export default BaseEditInvoice;