import React, { useEffect } from 'react'
import PlanContentContainer from './plan-content-container'
import { Heading } from 'app/components/atoms/typography'
import { PlanContentContainers } from './plan.style'
import { useDispatch, useSelector } from 'react-redux'
import { planActions, planSelectors } from 'store/reducers/plan/planSlice'
import { addCommasToNumber } from "utils/helpers/addCommaToNumber/addCommaToNumbers";
import { setDecimalPlaces } from "utils/helpers/setDecimalPlaces/setDecimalPlaces";

const PlanContainers = () => {
    const dispatch = useDispatch();
    const organizationPlans = useSelector(planSelectors.organizationPlan) || [];
    const clientUserPlans = useSelector(planSelectors.clientUserPlan) || [];
    const { getPlans } = planActions;

    useEffect(() => {
        dispatch(getPlans({}))
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div style={{ marginTop: "40px" }}>
                <div style={{ marginBottom: "40px" }}>
                    <Heading size={32} color="var(--primary3)">
                        Organization
                    </Heading>
                </div>
                <PlanContentContainers columnTemplate="repeat(2, 1fr)" >
                    {organizationPlans?.map(({ id, name, description, cost, features }) => {
                        return (
                            <PlanContentContainer
                                key={id}
                                id={id}
                                name={name}
                                description={description}
                                price={"₦ " + addCommasToNumber(setDecimalPlaces(cost))}
                                duration="Per Month"
                                lists={features}
                            />
                        )
                    })}

                </PlanContentContainers>
            </div>

            <div style={{ marginTop: "40px" }}>
                <div style={{ marginBottom: "40px" }}>
                    <Heading size={32} color="var(--primary3)">
                        Freelancer
                    </Heading>
                </div>
                <PlanContentContainers >
                        {clientUserPlans?.map(({ name, description, cost, features, id }) => {
                            return (
                                <PlanContentContainer
                                    key={id}
                                    id={id}
                                    name={name}
                                    description={description}
                                    price={"₦ " + addCommasToNumber(setDecimalPlaces(cost))}
                                    duration="Per Month"
                                    lists={features}
                                />
                            )
                        })}
                </PlanContentContainers>
            </div>
        </>
    )
}

export default PlanContainers