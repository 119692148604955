import Text, { Heading, Span } from "app/components/atoms/typography";
import PageMessage from "app/components/molecules/page-message/page-message.component";
import { CardContainer } from "app/components/molecules/people/people-card.component";
import { Form, Formik } from "formik";
import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import template1 from "app/assets/img/template1.png";
import template2 from "app/assets/img/template2.png";
import template3 from "app/assets/img/template3.png";
import Button from "app/components/atoms/button/button.component";
import { useRef } from "react";
import moment from "moment";
import ClientsTable from "./clientsTable";
import { useEffect } from "react";
import { useViewport } from "hooks";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; 
import { Calendar } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import {
  invoiceReminderSelectors,
  invoiceReminderActions,
} from "store/reducers/invoiceReminder/invoiceReminderSlice";
import * as Yup from "yup";
import {
  nameValidator,
  TextAmountValidator,
} from "utils/validation/validation.utils";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { ButtonContainer } from "app/components/atoms/modal/modal.styles";
import { Flex } from "../dashboard/dashboard.styles";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MobileBaseButton } from "app/components/atoms/button/button.styles";
import {
   MobileTemplateContainer, ReminderDateSelect,
   ReminderFormInput, ReminderFormInputII,
   ReminderOptionSelectCircle, ScrollBtn,
   SendReminderContainer, TemplateContainer } from "./reminder.styles";
import { GeneralDivIII, SelectOccurance } from "app/components/atoms/divs/div.styles";
import { REMINDER_SCHEDULE } from "utils/constants/constants.utils";
import { isValidDate } from "utils/helpers/validateDate/validateDate";

const validator = Yup.object({
  title: nameValidator("Title"),
  text: TextAmountValidator,
});

const SendReminder = ({ setNewReminder, reminder }) => {

  let defaultFormFields = {
    title: "",
    clients: [],
    text: "",
    time: "",
    template: 0,
    schedule: 1,
  };

  if (reminder.data.hasOwnProperty("id")) {
    defaultFormFields = {
      title: reminder.data.title,
      clients: reminder.data.clients,
      text: reminder.data.text,
      time: reminder.data.time,
      template: reminder.data.template,
      schedule: reminder.data.schedule,
    };
  }

  const [period, setPeriod] = useState(
    reminder.data.hasOwnProperty("id") ? reminder.data.schedule : 1
  );
  
  const [selectedRows, setSelectedRows] = useState(
    reminder.data.hasOwnProperty("id") ? reminder?.data?.clients?.map(item => ({
      id: item.clientId,
      fullName: item.fullName,
      profilePicture: item.profilePicture
  })) : []
  );
  const [active, setActive] = useState(
    reminder.data.hasOwnProperty("id") && reminder.data.isActive
  );
  const [reminderTemplate, setReminderTemplate] = useState(
    reminder.data.hasOwnProperty("id") ? reminder.data.template : 0
  );
  const [date, setDate] = useState(
    reminder.data.hasOwnProperty("id") ? new Date(reminder.data.time) : null
  );

  const [showCalendar, setShowCalendar] = useState(false);
  const [elips, setElips] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const [selectClient, setSelectClient] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();

  const { mobile } = useViewport();
  const { width } = useViewport();
  const { handleApiResponse } = useHandleApiResponse();

  const { create, updateStatus, update } = invoiceReminderActions;
  const isLoading = useSelector(invoiceReminderSelectors.isLoading);
 

  const handleTemplate = (e, id) => {
    e.target.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    setReminderTemplate(id);
  };

  const handleClientSelect = () => {
    setSelectClient(true);
    setError("");
  };

  const handleReminderStatus = () => {
    const input = {
      id: reminder.data.id,
      status: !active,
    };

    setActive(!active);

    dispatch(updateStatus(input)).then((res) => {
      handleApiResponse(res);
    });
  };

  useEffect(() => {
    if (width >= 1080) {
      if (selectedRows && selectedRows.length > 9) {
        setElips(true);
      } else {
        setElips(false);
      }
    } else if (width >= 601) {
      if (selectedRows && selectedRows.length > 4) {
        setElips(true);
      } else {
        setElips(false);
      }
    } else {
      if (selectedRows && selectedRows.length > 4) {
        setElips(true);
      } else {
        setElips(false);
      }
    }
  }, [width, selectedRows]);


  return (
    <SendReminderContainer>
      {mobile ? (
        <div style={{ maxWidth: "320px", margin: "0 auto" }}>

<button
            className="closeMobile"
            onClick={() => setNewReminder({ value: false, data: {} })}
          >
            Close
          </button>
          <PageMessage
            heading="Send Out Reminders"
            color={"var(--primary2)"}
            size={18}
          />
          <Text size={10}>

            Your contact will receive an email that reminds them to send their
            invoice.
          </Text>
          
          <div>
            {reminder.data.hasOwnProperty("isActive") && (
              <button style={{width:"90px"}}
                disabled={isLoading}
                onClick={handleReminderStatus}
                className={`activate ${active ? "" : "inactive"}`}
              ></button>
            )}
          </div>
        </div>
      ) : (
        <>
          <PageMessage heading="Send Reminder" color={"var(--primary2)"} />
          <Text>Easily send reminders to other users</Text>
        </>
      )}

      {selectClient && (
        <ClientsTable
          setSelectClient={setSelectClient}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          reminder={reminder}
        />
      )}

      <CardContainer className={`card ${selectClient ? "hide" : ""}`}>
        <div style={{ display: mobile ? "none" : "block" }}>
          <Heading
            level={2}
            color={"var(--primary)"}
            style={{ marginTop: "40px" }}
          >
            Send Out Reminders
          </Heading>

          <Text>
            Your contact will receive an email that reminds them to send their
            invoice.
          </Text>
          <button
            className="close"
            onClick={() => setNewReminder({ value: false, data: {} })}
          >
            Close
          </button>
          <div>
            {reminder.data.hasOwnProperty("isActive") && (
              <button
                disabled={isLoading}
                onClick={handleReminderStatus}
                className={`activate ${active ? "" : "inactive"}`}
              ></button>
            )}
          </div>
        </div>

        <Formik
          initialValues={defaultFormFields}
          validationSchema={validator}
          onSubmit={(values, { setSubmitting }) => {

            setSubmitting(true);

            const input = {
              ...values,
              clients: selectedRows?.map((row) => {
                return row.id;
              }),

              time: moment(date).format(moment.HTML5_FMT.DATE),

              template: reminderTemplate,

              schedule: period,
            };

            if (input.clients.length === 0) {
              setError("Required");
              setSubmitting(false);
              return;
            }

            if (!isValidDate(input.time)) {
              setDateError("Date is required");
              setSubmitting(false);
              return;
            }

            if (reminder.data.hasOwnProperty("id")) {
              dispatch(update({ ...input, id: reminder.data.id })).then(
                (res) => {
                  handleApiResponse(res);
                  setNewReminder({ value: false, data: {} });
                  setSubmitting(false);
                }
              );

            } else {
              dispatch(create(input)).then((res) => {
                handleApiResponse(res);
                setNewReminder({ value: false, data: {} });
                setSubmitting(false);
              });
            }
          }}
        >
          {({ isSubmitting, values, handleChange, errors, touched }) => {
            return (
              <Form>
                <div className="form">
                  <Heading color="var(--grey1)" size={mobile ? 12 : 18.75}>
                    Title
                  </Heading>
                  <ReminderFormInputII
                    name="title"
                    value={values["title"]}
                    onChange={handleChange}
                    placeholder="Reminder Title">
                  </ReminderFormInputII>
                  {errors.title && touched.title ? (
                    <Text color="red">{errors.title}</Text>
                  ) : null}
                </div>

                <div className="form">
                  <Flex>
                    <Heading
                      color="var(--grey1)"
                      size={mobile ? 12 : 18.75}
                      className="recipient"
                    >
                      Recipients
                    </Heading>
                    <Heading
                      color="var(--primary)"
                      size={mobile ? 12 : 24}
                      style={{ cursor: "pointer" }}
                      onClick={handleClientSelect}
                    >
                      Select from contacts
                    </Heading>
                  </Flex>
                  <ReminderFormInput>
                    {elips ? (
                      <div className="ellips" onClick={handleClientSelect}>
                        ...
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedRows && selectedRows.length === 0 && (
                      <Text
                        color="var(--grey2)"
                        size={mobile ? 12 : 24}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        No users selected
                      </Text>
                    )}

                    {selectedRows &&
                      selectedRows?.map((client) => {
                        return (
                          <GeneralDivIII key={client.id}>
                            <Text>{client.fullName}</Text>
                            <div
                              className="recipient__cancel"
                              onClick={() => {
                                setSelectedRows(
                                  selectedRows.filter(
                                    (row) => row.id !== client.id
                                  )
                                );
                              }}
                            ></div>
                          </GeneralDivIII>
                        );
                      })}
                  </ReminderFormInput>
                  {error ? <Text color="red">{error}</Text> : null}
                </div>

                <div className="form">
                  <div>
                    <Heading size={mobile ? 12 : 24}>Message</Heading>
                    <span style={{ fontSize: mobile ? "12px" : "16px" }}>

                      (10 - 250 characters)
                    </span>
                  </div>

                  <ReminderFormInputII
                    name="text"
                    value={values["text"]}
                    onChange={handleChange}
                    placeholder="Write your message..."
                  ></ReminderFormInputII>
                  {errors.text && touched.text ? (<Text color="red">{errors.text}</Text>) : null}
                </div>
                <div className="form">
                  {mobile ? (
                    <>
                      <Text color="var(--grey1)" size={12}>
                        Select Date
                      </Text>
                      <Text color="var(--grey2)" size={12}>
                        (This reminder will be sent every week or month on this
                        date)
                      </Text>
                    </>
                  ) : (
                    <Heading level={3}>
                      Select Date
                      <Span>
                        (This reminder will be sent every week or month on this
                        date)
                      </Span>
                    </Heading>
                  )}
                  <>
                    <ReminderDateSelect className="reminder-date">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 6,
                            width: "fit-content",
                          }}
                          onClick={(e) => {
                            setShowCalendar(true);
                            e.stopPropagation();
                          }}
                        >
                          <FaRegCalendarAlt size={15} color="var(--accent)" />
                          <Text color="var(--primary)" size={mobile ? 12 : 18}
                            weight={700}>
                            {date
                              ? moment(date).format("dddd, Do MMMM")
                              : "Reminder Date"}
                          </Text>
                          {dateError ? <Text color="red">{dateError}</Text> : null}
                        </div>
                      </div>

                      <Flex>
                      <Flex onClick={() => {setPeriod(REMINDER_SCHEDULE.Monthly) }}>
                        
                          <ReminderOptionSelectCircle
                            active={period === REMINDER_SCHEDULE.Monthly}>
                          </ReminderOptionSelectCircle>

                          <SelectOccurance margin="0 15px 0 0">
                            Monthly
                          </SelectOccurance>
                        </Flex>

                        <Flex onClick={() => {setPeriod(REMINDER_SCHEDULE.Weekly) }}>
                          <ReminderOptionSelectCircle
                            active={period === REMINDER_SCHEDULE.Weekly}>
                            </ReminderOptionSelectCircle>
                          <SelectOccurance margin="0 0 0 15px">
                            Weekly
                          </SelectOccurance>
                        </Flex>
                      </Flex>

                    </ReminderDateSelect>
                    {showCalendar && (
                      <Calendar
                        className="calendar"
                        date={date}
                        onChange={(date) => {
                          setDate(date);
                          setDateError(null);
                          setShowCalendar(false);
                        }}
                      />
                    )}
                  </>

                </div>
                <div className="form form__template">
                  <Heading size={mobile ? 12 : 18.72}>Select Template</Heading>
                  {mobile ? (
                    <MobileTemplateContainer>
                      <img
                        onClick={(e) => handleTemplate(e, 0)}
                        className={`${reminderTemplate === 0 ? "choose" : ""}`}
                        src={template1}
                        alt="template1"
                      />
                      <img
                        onClick={(e) => handleTemplate(e, 1)}
                        src={template2}
                        className={`${reminderTemplate === 1 ? "choose" : ""}`}
                        alt="template2"
                      />
                      <img
                        onClick={(e) => handleTemplate(e, 2)}
                        src={template3}
                        className={`${reminderTemplate === 2 ? "choose" : ""}`}
                        alt="template3"
                      />
                    </MobileTemplateContainer>
                  ) : (
                    <TemplateContainer ref={ref}>
                      <ScrollBtn
                        style={{ left: "-10px" }}
                        onClick={() => {
                          ref.current.scrollTo({
                            top: 0,
                            left: -1000,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <BsChevronLeft style={{ color: "var(--grey2)" }} />
                      </ScrollBtn>

                      <ScrollBtn
                        style={{ right: "-10px" }}
                        onClick={() => {
                          ref.current.scrollTo({
                            top: 0,
                            left: 1000,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <BsChevronRight style={{ color: "var(--grey2)" }} />
                      </ScrollBtn>

                      <img
                        onClick={(e) => handleTemplate(e, 0)}
                        className={`${reminderTemplate === 0 ? "choose" : ""}`}
                        src={template1}
                        alt="template1"
                      />
                      <img
                        onClick={(e) => handleTemplate(e, 1)}
                        src={template2}
                        className={`${reminderTemplate === 1 ? "choose" : ""}`}
                        alt="template2"
                      />
                      <img
                        onClick={(e) => handleTemplate(e, 2)}
                        src={template3}
                        className={`${reminderTemplate === 2 ? "choose" : ""}`}
                        alt="template3"
                      />
                    </TemplateContainer>
                  )}
                </div>
                <ButtonContainer className="btn-submit">
                  {mobile ? (
                    <MobileBaseButton
                      padding=".6rem 2rem"
                      style={{ marginTop: "24px" }}
                      type="submit"
                      className="btn__submit"
                      isLoading={isSubmitting || isLoading}
                      disabled={
                        isSubmitting ||
                        !values.text ||
                        !values.title ||
                        isLoading ||
                        selectedRows.length === 0
                      }
                    >
                      {reminder.data.hasOwnProperty("id")
                        ? "Update Reminder"
                        : "Send Reminder"}
                    </MobileBaseButton>
                  ) : (
                    <Button
                      style={{ marginTop: "24px" }}
                      type="submit"
                      className="btn__submit"
                      isLoading={isSubmitting || isLoading}
                      disabled={
                        isSubmitting ||
                        !values.text ||
                        !values.title ||
                        isLoading ||
                        selectedRows.length === 0
                      }
                    >
                      {reminder.data.hasOwnProperty("id")
                        ? "Update Reminder"
                        : "Send Reminder"}
                    </Button>
                  )}
                </ButtonContainer>
              </Form>
            );
          }}
        </Formik>

      </CardContainer>

    </SendReminderContainer>
  );
};

export default SendReminder;
