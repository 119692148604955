import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useHandleApiResponse = () => {
  const navigate = useNavigate();

  const handleApiResponse = (response, navigateTo, position) => {
    if (response?.payload?.succeeded) {
      toast.success(response.payload.message, {
        position: position
          ? toast.POSITION.BOTTOM_RIGHT
          : toast.POSITION.TOP_RIGHT
        });
      setTimeout(() => {
        if (navigateTo !== null) {
          navigate(navigateTo);
        }
      }, 2000);
    }
  };

  const handledownloadFile = async (url, name) => {
    const fileName = `${name}${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}_${new Date().getSeconds()}`;
  
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:');
    }
  };

  const handleDownload = (url, name) => {
    url ? handledownloadFile(url, name) :
      toast.warning('We are processing your pdf, it will be ready in minutes', {
        position: toast.POSITION.TOP_RIGHT
      });
  };

  return { handleApiResponse, handleDownload };
};
