import Text, { Heading } from "app/components/atoms/typography";
import React, { useEffect, useState } from "react";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";

import stat4 from "app/assets/img/stat4.png";
import stat2 from "app/assets/img/stat2.png";
import styled from "styled-components";
import { useViewport } from "hooks";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Card from "../atoms/card/card.component";

const Metric = styled.div`
  position: relative;
  margin-top: 32px;
`;

const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
  .scrollBtn{
    background-color: var(--primary1);
    color: var(--accent);
  font-size: 40px;
  padding:5px;
    border-radius: 100%;
     position: absolute;
     display: flex;
     z-index:2;
     bottom: 40%;
     align-items: center;
     justify-content: center;
  }
  .left {
 left:-20px;
  }
  .right {
   right: -20px;
  }
  /* .left {
    position: absolute;
    top: 3.8em;
    left: -1.5em;
    @media screen and (min-width: 850px) {
      left: -2.7em;
    }
  }
  .right {
    position: absolute;
    top: 3.8em;
    right: -1.5em;
    @media screen and (min-width: 850px) {
      right: -2.7em;
    } 
  }*/
`;

const CarouselWrapper = styled.div`
  display: flex;

  p {
    text-align: left;
  }

  .slide {
    flex: 0 0 auto;
    width: ${({ width }) => width || "100%"};
    margin-right: 10px;
  }
  .stat {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &__inner {
      justify-content: flex-start;
      * + * {
        margin-left: 10px;
      }
      p {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const Carousel = ({ invoiceMetrics, admin = false }) => {
  const [cardWidth, setCardWidth] = useState(null);
  const totalMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Total";
    })[0] || {};
  const totalPaidMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "TotalPaid";
    })[0] || {};
  const approvedMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Approved";
    })[0] || {};
  const declinedMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Declined";
    })[0] || {};
  const pendingMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Pending";
    })[0] || {};
  const changeRequestedMetric =
    invoiceMetrics?.filter(function (item) {
      return item.name === "ChangesRequested";
    })[0] || {};
  const TotalNoOrganizations =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Total No. Of Organizations";
    })[0] || {};
  const TotalNoUsers =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Total No. Of Users";
    })[0] || {};
  const TotalNoRec =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Total No. Of Recurring Invoices";
    })[0] || {};
  const TotalNoRem =
    invoiceMetrics?.filter(function (item) {
      return item.name === "Total No. Of Reminders";
    })[0] || {};

  const { width, mobile } = useViewport();
  const [isDisabled, setIsDisabled] = useState(false);

  let cards = [
    <Card border="1px solid #DBE7FF" pM="10px 10px 0" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Total Invoices</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>{totalMetric?.count || 0}</Heading>
        <img src={totalMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {totalMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {totalMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {totalMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
    <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Total Paid</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>{totalPaidMetric?.count || 0}</Heading>
        <img src={totalPaidMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16} >
          {totalPaidMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {totalPaidMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {totalPaidMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
    <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Approved Invoices</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>{approvedMetric?.count || 0}</Heading>
        <img src={approvedMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {approvedMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {approvedMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {approvedMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
    <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Declined Invoices</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>{declinedMetric?.count || 0}</Heading>
        <img src={declinedMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {declinedMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {declinedMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {declinedMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
    <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Pending Invoices</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>{pendingMetric?.count || 0}</Heading>
        <img src={pendingMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {pendingMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {pendingMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {declinedMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
    <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
      <Text color="var(--grey2)" size={mobile ? 12 : 16}>Changes Requested</Text>
      <div className="stat">
        <Heading color="var(--grey1)" size={mobile ? 16 : 32}>
          {changeRequestedMetric?.count || 0}
        </Heading>
        <img src={changeRequestedMetric?.Metric === 1 ? stat4 : stat2} alt="" />
      </div>
      <div className="stat stat__inner">
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {changeRequestedMetric?.metric === 1 ? (
            <FiArrowUpRight />
          ) : (
            <FiArrowDownRight />
          )}
          {changeRequestedMetric?.percentage || 0}%
        </Text>
        <Text color="var(--grey3)" size={mobile ? 10 : 16}>
          {changeRequestedMetric?.metric === 1 ? "Increase" : "Decrease"} this month
        </Text>
      </div>
    </Card>,
  ];
  if (admin) {
    cards = [
      <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
        <Text color="var(--grey2)">Total No. of Organizations</Text>
        <div className="stat">
          <Heading color="var(--grey1)">
            {TotalNoOrganizations?.count || 0}
          </Heading>
          <img
            src={TotalNoOrganizations?.Metric === 1 ? stat4 : stat2}
            alt=""
          />
        </div>
        <div className="stat stat__inner">
          <Text color="var(--grey3)">
            {TotalNoOrganizations?.metric === 1 ? (
              <FiArrowUpRight />
            ) : (
              <FiArrowDownRight />
            )}
            {TotalNoOrganizations?.percentage || 0}%
          </Text>
          <Text color="var(--grey3)">
            {TotalNoOrganizations?.metric === 1 ? "Increase" : "Decrease"} this month
          </Text>
        </div>
      </Card>,
      <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
        <Text color="var(--grey2)">Total No. of Users</Text>
        <div className="stat">
          <Heading color="var(--grey1)">{TotalNoUsers?.count || 0}</Heading>
          <img src={TotalNoUsers?.Metric === 1 ? stat4 : stat2} alt="" />
        </div>
        <div className="stat stat__inner">
          <Text color="var(--grey3)">
            {TotalNoUsers?.metric === 1 ? (
              <FiArrowUpRight />
            ) : (
              <FiArrowDownRight />
            )}
            {TotalNoUsers?.percentage || 0}%
          </Text>
          <Text color="var(--grey3)">
            {TotalNoUsers?.metric === 1 ? "Increase" : "Decrease"} this month
          </Text>
        </div>
      </Card>,
      <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
        <Text color="var(--grey2)">Total No. of Recurring invoices</Text>
        <div className="stat">
          <Heading color="var(--grey1)">{TotalNoRec?.count || 0}</Heading>
          <img src={TotalNoRec?.Metric === 1 ? stat4 : stat2} alt="" />
        </div>
        <div className="stat stat__inner">
          <Text color="var(--grey3)">
            {TotalNoRec?.metric === 1 ? (
              <FiArrowUpRight />
            ) : (
              <FiArrowDownRight />
            )}
            {TotalNoRec?.percentage || 0}%
          </Text>
          <Text color="var(--grey3)">
            {TotalNoRec?.metric === 1 ? "Increase" : "Decrease"} this month
          </Text>
        </div>
      </Card>,
      <Card border="1px solid #DBE7FF" style={{ textAlign: "inherit" }}>
        <Text color="var(--grey2)">Total No of Reminders</Text>
        <div className="stat">
          <Heading color="var(--grey1)">{TotalNoRem?.count || 0}</Heading>
          <img src={TotalNoRem?.Metric === 1 ? stat4 : stat2} alt="" />
        </div>
        <div className="stat stat__inner">
          <Text color="var(--grey3)">
            {TotalNoRem?.metric === 1 ? (
              <FiArrowUpRight />
            ) : (
              <FiArrowDownRight />
            )}
            {TotalNoRem?.percentage || 0}%
          </Text>
          <Text color="var(--grey3)">
            {TotalNoRem?.metric === 1 ? "Increase" : "Decrease"} this month
          </Text>
        </div>
      </Card>,
    ];
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);

  const handleNext = () => {
    if (currentIndex === cards.length - 1) {
      setCurrentIndex(0);
      setTranslateValue(0);
    } else {
      setCurrentIndex(currentIndex + 1);
      setTranslateValue(translateValue - slideWidth() - 10);
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(cards.length - 1);
      setTranslateValue(-slideWidth() * (cards.length - 1) - 60);
    } else {
      setCurrentIndex(currentIndex - 1);
      setTranslateValue(translateValue + slideWidth() + 10);
    }
  };

  const slideWidth = () => {
    return document.querySelector(".slide").clientWidth;
  };

  useEffect(() => {
    setIsDisabled(false);
    if (width < 700) {
      setCardWidth("50%");
      if (currentIndex === 5 && !admin) {
        setIsDisabled(true);
      }

      if (admin && currentIndex === 3) {
        setIsDisabled(true);
      }
    } else if (width > 700 && width < 1120) {
      setCardWidth("50%");
      if (currentIndex === 4 && !admin) {
        setIsDisabled(true);
      }
      if (currentIndex === 2 && admin) {
        setIsDisabled(true);
      }
    } else if (width > 1120 && width < 1300) {
      setCardWidth("33%");
      if (currentIndex === 3 && !admin) {
        setIsDisabled(true);
      }
      if (currentIndex === 1 && admin) {
        setIsDisabled(true);
      }
    } else {
      setCardWidth("24.3%");
      if (currentIndex === 2 && !admin) {
        setIsDisabled(true);
      }
      if (currentIndex === 0 && admin) {
        setIsDisabled(true);
      }
    }
  }, [currentIndex, width, invoiceMetrics, admin]);

  return (
    <Metric>
      <CarouselContainer>
        <CarouselWrapper
          width={cardWidth}
          className="metric"
          style={{
            transform: `translateX(${translateValue}px)`,
            transition: "transform ease-out 0.45s",
          }}
        >
          {cards?.map((card, index) => (
            <div key={index} className="slide">
              {card}
            </div>
          ))}
        </CarouselWrapper>
        {currentIndex !== 0 && (
          <div
            className="scrollBtn left"
            onClick={handlePrev}>
            <BsChevronLeft />
          </div>

        )}

        {!isDisabled && (
          <div
            className="scrollBtn right"
            onClick={handleNext} >
            <BsChevronRight />
          </div>
        )}

      </CarouselContainer>
    </Metric>
  );
};

export default Carousel;
