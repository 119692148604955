import ContentContainer, {
  ContentCardsContainer,
  Header,
} from "app/components/atoms/about-content-layout/ContentContainer.component";
import FormInput from "app/components/atoms/form-input/form-input.component";
import Text, { Heading, Span } from "app/components/atoms/typography";
import { CardContainer } from "app/components/atoms/card/card.component";
import { Form, Formik } from "formik";
import styled from "styled-components";
import {
  EmailValidator,
  GeneralValidator,
  nameValidator,
  PhoneNumberValidator,
} from "utils/validation/validation.utils";
import Button from "app/components/atoms/button/button.component";
import "./contact.styles.css";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SettingsActions } from "store/reducers/settings/settingsSlice";
import { supportAction } from "store/reducers/support/supportSlice";
import { useState,useRef } from "react";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { validateFileType } from "utils/helpers/validateFileType/validateFileType";

const defaultFormField = {
  fullName: "",
  email: "",
  phoneNumber: "",
  details: "",
  fileUrl: "",
};

const validator = Yup.object({
  fullName: nameValidator(),
  email: EmailValidator,
  phoneNumber: PhoneNumberValidator,
  details: GeneralValidator,
});

export const ErrorText = styled.div`
  font-size: 12px;
  color: red;
`;

const FormContainer = styled.div`
  @media screen and (min-width: 1024px) {
    padding: 5rem 10rem;
  }
  > * + * {
    margin-top: 30px;
  }
  > * + * > * + * {
    margin-top: 8px;
  }
  > :last-child {
    margin-top: 56px;
  }
`;

const ContactUs = () => {
  const dispatch = useDispatch();
  const { uploadFile } = SettingsActions;
  const { contactUs } = supportAction;
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const { handleApiResponse } = useHandleApiResponse();
  const ref = useRef();

 const PostData = (values, fileUrl, setSubmitting, resetForm) => {
    dispatch(contactUs({ ...values, fileUrl: fileUrl})).then(
      (res) => {
        handleApiResponse(res, null, true);
        resetForm();
        ref.current.value = "";
        setFile("");
        setFileError("");
        setSubmitting(false);
      }
    );}

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    setSubmitting(true);

    if(file === "" || file === undefined || file === null){
      PostData(values, null, setSubmitting, resetForm);
    }
    else{
      if(validateFileType(file.type)){
        dispatch(uploadFile(file)).then((fileUrl) => {
          PostData(values, fileUrl.payload.data, setSubmitting, resetForm);
        });
      }
      else{
        setSubmitting(false);
        setFileError("Invalid File Type, accepted formats are: Jpeg, Jpg and png")
      }
    }
  };
 
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56" style={{ color: "#fe9900" }}>
            Get in Touch
          </Heading>
          <Text style={{ maxWidth: "600px" }}>
            For support, sales-related questions, or other inquiries, please
            send us a message through this form. We typically respond within
            minutes.
          </Text>
          <ContentCardsContainer>
            <CardContainer>
              <Formik
                initialValues={defaultFormField}
                validationSchema={validator}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => {
                  return (
                    <Form>
                      <FormContainer>
                        <FormInput
                          label={
                            <Text>
                              Full Name <Span color="red">*</Span>
                            </Text>
                          }
                          type="text"
                          name="fullName"
                          placeholder="Your Full name"
                          style={{
                            backgroundColor: "white",
                            padding: "25px 20px",
                          }}
                          value={values["fullName"]}
                          onChange={(value) =>
                            setFieldValue("fullName", value.value)
                          }
                        />
                        <FormInput
                          label={
                            <Text>
                              Email Address <Span color="red">*</Span>
                            </Text>
                          }
                          type="email"
                          name="email"
                          placeholder="Your email address"
                          style={{
                            backgroundColor: "white",
                            padding: "25px 20px",
                          }}
                          value={values["email"]}
                          onChange={(value) =>
                            setFieldValue("email", value.value)
                          }
                        />
                        <FormInput
                          label={
                          <Text>
                            Phone Number <Span color="red">*</Span>
                            </Text>}
                          
                          name="phoneNumber"
                          placeholder="Your phone number"
                          style={{
                            backgroundColor: "white",
                            padding: "25px 20px",
                          }}
                          value={values["phoneNumber"]}
                          onChange={(value) =>
                            setFieldValue("phoneNumber", value.value)
                          }
                        />
                        <FormInput
                          label={
                            <Text>
                              Suggestion, Question or Complaint{" "}
                              <Span color="red">*</Span>{" "}
                            </Text>
                          }
                          type="text"
                          el="textarea"
                          cols="50"
                          rows="10"
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                            padding: "25px 20px",
                          }}
                          name="details"
                          placeholder="details"
                          value={values["details"]}
                          onChange={(value) =>
                            setFieldValue("details", value.value)
                          }
                        />
                        <div className="file">
                          <Text>Send us a file, screenshot, or document</Text>
                          <input
                            type="file"
                            ref={ref}
                            name="file"
                            placeholder="Choose Files"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                          />
                          {fileError && <ErrorText id="text">{fileError}</ErrorText>}
                          <Button
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                          >
                            Send Message
                          </Button>
                        </div>
                      </FormContainer>
                    </Form>
                  );
                }}
              </Formik>
            </CardContainer>
          </ContentCardsContainer>
        </Header>
      </div>
    </ContentContainer>
  );
};

export default ContactUs;


