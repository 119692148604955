import CryptoJS from 'crypto-js';

export const encryptData = (dataToEncrypt) => {

    dataToEncrypt.date = new Date().toISOString();

    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);  
    var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);  
    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(dataToEncrypt).slice(1, -1).replace(/\s/g, '')), key,  
    {
       keySize: 128 / 8,
       iv: iv,  
       mode: CryptoJS.mode.CBC,  
       padding: CryptoJS.pad.Pkcs7 
    });  

    return encryptedlogin.toString();
};

