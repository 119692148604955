import React from "react";
import Button from "app/components/atoms/button/button.component";
import Text from "app/components/atoms/typography";
import "./modal.styles.css";
import { useState } from "react";

import bro from "app/assets/img/bro.png";
import InviteForm from "./inviteForm.component";
import CustomModal from "app/components/atoms/modal/modal.component";
import { Empty } from "app/components/atoms/divs/div.styles";

const InviteContacts = ({ visible, setVisible, byCsv }) => {
  const [fromImport, setFromImport] = useState(byCsv || false);
  const [inviteSuccessFull, setInviteSuccessFull] = useState(false);

  return (
    <CustomModal
      className="modal2 modal-del overflow"
      isOpen={visible}
      close={setVisible}
      inviteSuccessFull={inviteSuccessFull}
      fromImport={fromImport}
      setFromImport={setFromImport}
    >
      {inviteSuccessFull ? (
        <Empty>
          <img src={bro} alt="success" />
          <Text size={24} weight={700} style={{ marginTop: "20px" }}>
            Your invites have been sent successfully
          </Text>
          <Text
            size={16}
            weight={400}
            color={"#7B8599"}
            style={{ marginTop: "20px" }}
          >
            Your staff will receive an email on how to join your workspace.
          </Text>

          <Button
            style={{ minWidth: "0", height: "auto" }}
            onClick={() => {
              setInviteSuccessFull(false);
              setFromImport(false);
            }}
          >
            Invite More staff
          </Button>
        </Empty>
      ) : (
        <InviteForm
          fromImport={fromImport}
          setInviteSuccessFull={setInviteSuccessFull}
        />
      )}
    </CustomModal>
  );
};

export default InviteContacts;
