import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "externalUser";

export const initialState = {
  externalUsers: [],
  externalUser: {},
  totalCount: 0,
  loading: false,
};

const getExternalUsers = createAsyncThunk(`${entity}`, async () => {
  const res = await api.externalUser.getExternalUsers();
  return res.data;
});
const getPaginatedExternalUsers = createAsyncThunk(
  `${entity}/paginated`,
  async () => {
    const res = await api.externalUser.getPaginatedExternalUsers();
    return res.data;
  }
);

const createExternalUser = createAsyncThunk(
  `${entity}/create`,
  async (input) => {
    const res = await api.externalUser.create(input);
    return res.data;
  }
);

const updateExternalUser = createAsyncThunk(
  `${entity}/update`,
  async (input) => {
    const res = await api.externalUser.update(input);
    return res.data;
  }
);

const deleteExternalUser = createAsyncThunk(
  `${entity}/delete`,
  async (input) => {
    const res = await api.externalUser.delete(input);
    return res.data;
  }
);
const externalUserSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getExternalUsers.fulfilled, (state, action) => {
        state.externalUsers = action.payload.data;
        state.loading = false;
      })
      .addCase(getExternalUsers.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getExternalUsers.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(createExternalUser.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(createExternalUser.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createExternalUser.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          ...action.payload,
        };
      });
    builder
      .addCase(getPaginatedExternalUsers.fulfilled, (state, action) => {
        state.externalUsers = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.loading = false;
      })
      .addCase(getPaginatedExternalUsers.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPaginatedExternalUsers.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          ...action.payload,
        };
      });
    builder
      .addCase(deleteExternalUser.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteExternalUser.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteExternalUser.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          ...action.payload,
        };
      });
    builder
      .addCase(updateExternalUser.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(updateExternalUser.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateExternalUser.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          ...action.payload,
        };
      });
  },
});

export const externalUserActions = {
  ...externalUserSlice.actions,
  getExternalUsers,
  createExternalUser,
  getPaginatedExternalUsers,
  deleteExternalUser,
  updateExternalUser,
};

export const externalUserSelectors = {
  isLoading: (state) => state.externalUser.loading,
  externalUser: (state) => state.externalUser.externalUser,
  externalUsers: (state) => state.externalUser.externalUsers,
  totalCount: (state) => state.externalUser.totalCount,
  pagExternalUsers: (state) => state.externalUser.externalUsers,
  externalUsersForDropdown: (state) =>
    state?.externalUser?.externalUsers?.map(function (item) {
      return {
        value: item.id,
        label: item.fullName,
        phoneNumber: item.phoneNumber,
        email: item.email,
      };
    }),
};

export default externalUserSlice.reducer;
