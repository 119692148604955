import { getOrdinalSuffix } from "../getOrdinalSuffix/getOrdinalSuffix";

export const formatDateForPlanExpiry = (date) => {
      
    const day = date.getDate();

    const suffix = getOrdinalSuffix(day);

    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
  
    const formattedText = `Till: ${day}${suffix} ${month}, ${year}`;
  
    return formattedText;
  };