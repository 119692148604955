import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'
import Text, { Heading } from "app/components/atoms/typography";
import { FaqQst, FaqAnswer, Icon, FaqContainer } from "../FAQ/faq.styled.component";

const FAQatom = ({ question, answer, links }) => {
    const [isOpen, setIsOpen] = useState(false);
  const renderAnswer = (answer) => {
    const words = answer.split(' ');
    return words?.map((word, index) => {
      const matchingLink = links.find((link) => link.text === word);
      if (matchingLink) {
        return (
          <a key={index} 
            href={matchingLink.url} target="_blank" 
            style={{color:'blue'}}
            rel="noopener noreferrer">
             {word}
          </a>
        );
      }
      return word + ' ';
    });
  };
    return (
        <>
        <FaqContainer>

            <FaqQst
                onClick={() => setIsOpen(!isOpen)}>
                
            <span >{isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span> 

                <Heading level={2}>{question} </Heading>
              </FaqQst>
          
            {isOpen && 
            <FaqAnswer>
              <Icon>•</Icon>
              <Text>{renderAnswer(answer)}</Text>
            </FaqAnswer>
           }
         </FaqContainer> </>
    );
};

export default FAQatom;