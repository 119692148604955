import Button from "app/components/atoms/button/button.component";
import Text from "app/components/atoms/typography";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";
import { useViewport } from "hooks";

const JoinUsContainer = styled.div`
width: 100%;
max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
    margin: 0 9.75rem;
  background-color: var(--white);
  background-size: 100%;
  position: relative;
  padding: 40px 0;
  top: -70px;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(0, 39, 118, 0.15);
@media screen and (max-width: 768px) {
   padding: 15px 0;
}
`;
const Circle = styled.div`
  width: 399px;
  height: 399px;
  border-radius: 50%;
  background-color: var(--primary8);
margin-top: -130px;
  
  @media screen and (max-width: 768px) {
    width: 168px;
    height: 168px;
    margin-top: -200px 0 0 0;
  }
`;
const JoinUsSection = styled.section`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
position: relative;
margin-bottom: 85px;
padding: 0 156px;
 @media screen and (max-width: 768px) {
  margin-bottom: 0;
  padding: 0 29px;
 }
`
const JoinUs = () => {

  const navigate = useNavigate();
  const { mobile } = useViewport();

  return (
    <>
      <JoinUsSection>
        <JoinUsContainer>
          <div>
            <Text weight="500" size={28} responsiveFontSize={14}>
              10,000 Freelancers uses our invoice generator
            </Text>
          </div>
          <div>
            {mobile ?
              <Text
                weight={700}
                size={16}
                margin="0"
                color="var(--primary)"
                onClick={() => {
                  navigate(ROUTES.SIGN_UP.CLIENT_USER);
                }}>Join Us</Text>
              :
              <Button
                onClick={() => {
                  navigate(ROUTES.SIGN_UP.CLIENT_USER);
                }}
              >
                Join Us
              </Button>
            }
          </div>
        </JoinUsContainer>
        <Circle></Circle>
      </JoinUsSection>
    </>
  );
};

export default JoinUs;
