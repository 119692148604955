import HorizontalNav from "app/components/molecules/horizontal-nav/horizontal-nav.component";
import { Outlet } from "react-router-dom";

const menuItems = [
  {
    name: "Personal Information",
    url: "/settings/profile/personal-information",
  },
  {
    name: "Security & Password",
    url: "",
  },
  {
    name: "Close Your Account",
    url: "",
  },
];

const ProfileSettings = ({ children }) => {
  return (
    <>
      <HorizontalNav menuItems={menuItems} />
      {children}
      <Outlet />
    </>
  );
};

export default ProfileSettings;
