import styled from "styled-components";
import Card from "app/components/atoms/card/card.component";

export const FormSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 40px;
  max-width: 500px;
`;

export const FormElementLabel = styled.label`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #3a3e47;
`;

export const FormElementInput = styled.input`
  width: 250px;
  height: 40px;
  border: 1px solid #b8c6e4;
  border-radius: 4px;
`;

export const TableStyle = styled.table`
  border-collapse: separate;
  border-spacing: 0.8em;
`;

export const OrganizationSettingsContainer = styled.div`
  padding: 40px 0;
  @media screen and (min-width: 1040px) {
    padding: 40px;
  }
  /* * + * {
    margin-top: 30px;
  } */

  button[disabled="disabled"],
  button:disabled {
    cursor: not-allowed;
    background-color: var(--primary7);
  }
`;

export const SettingsWrapper = styled.div`
  div:first-child {
    p,
    h1 {
      margin: 0;
    }
  }
`;

export const FormContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: ${({ alignItem }) => alignItem};
  margin: 20px;
  .close-btn {
    background: #f2f6ff;
    color: #0148de;
  }
  &.cheat {
    * {
      margin-top: 0;
    }
  }
  align-items: center;
  p {
    color: var(--grey1);
  }
  .try {
    span: 2;
  }
  /* .custom-file-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #cbd2e2;
    padding: 2px 10px; */
  /* Grey shade 2 */
  /* max-width: 30rem;
    border: 1px solid #7b8599;
    border-radius: 8px;
  } */

  .dropdown {
    background-color: white;
    padding: 15px 20px;
    font-size: 14px;
    border: 1px solid #a4b0c9;
    border-radius: 5px;

    &.active {
      color: black;
    }
  }

  .file {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    gap: 20px;
    @media screen and (min-width: 700px) {
      flex-direction: row;
      width: 20rem;
    }
  }
  .custom-file-upload {
    background: #cbd2e2;
    border-radius: 8px;
    border: 1px solid #7b8599;
    padding: 2px 5px;
    cursor: pointer;
    justify-content: center;
    label {
      font-size: 10px;
    }
    @media screen and (min-width: 600px) {
      font-size: 16px;
      padding: 10px 15px;
    }
  }
  button {
    min-width: 100%;
    padding: 0 4px;
  }
  gap: 16px;
  @media screen and (min-width: 1125px) {
    grid-template-columns: 200px 1fr 1fr;
    max-width: 100%;
    gap: 16px;
    &.name {
      width: 100%;
    }
    .dropdown,
    .postal {
      max-width: 100%;
      margin-top: 0;
    }

    > div,
    input {
      margin-top: 0;
    }
    button {
      max-width: 50%;
    }
  }
`;
export const UplodedImg = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #cbd2e2;
  position: relative;
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    color: #cbd2e2;
    object-fit: cover;
    font-size: 4.5rem;
  }
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const CardText = styled.p`
  color: var(--grey-shade-2, #7b8599);
  text-align: center;
  font-size: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const CardTextI = styled.p`
  color: var(--primary-shade-2, #002776);
  font-size: 36px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const CardButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
`;

export const CardContainerI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ b }) => b || "#ffffff"};
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  border: ${({ border }) => border || ""};
  padding: ${({ p }) => p || "10px 25px 20px"};
  height: 80vh;
  margin: ${({ m }) => m || "0"};
  max-width: ${({ maxWidth }) => maxWidth || ""};
  width: ${({ width }) => width || "100%"};
`;

export const PlanDetails = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Plan = styled(Card)`
  box-shadow: none;
  min-width: 0;
  border: 1px solid #b8c6e4;
  width: 80%;
  padding: 10px;
  @media screen and (min-width: 700px) {
    padding: 40px;
    grid-template-columns: 1fr 1fr;
  }
  &.premium {
    margin-top: 0;
    background-color: var(--primary8);
    /* button {
      width: 100%;
      background: #f2f6ff;
      color: var(--grey2);
      min-width: 0;
    } */
  }
  .plan_features {
    p,
    svg {
      font-size: 12px;
    }
  }
`;

export const PlanContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ gap }) => gap || "100px"};
  @media screen and (min-width: 1125px) {
    display: grid;
    grid-template-columns: ${({ columnTemplate }) =>
      columnTemplate || "repeat(2, 1fr)"};
    justify-content: ${({ justifyContent }) =>
      justifyContent || "space-center"};
    gap: ${({ gap }) => gap || "10px"};
    margin: ${({ margin }) => margin};
  }
`;

export const StatusBar = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid;
  border-radius: 6px;
  font-weight: bold;
  padding: 0px 10px;
  align-items: flex-start;
  &.Active {
    color: #5cbc77;
    border-color: #5cbc77;
  }
  &.Expired {
    color: red;
    border-color: red;
  }
`;
export const PaymentPlatform = styled.div`
  width: 80%;
  margin: 3rem 0;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
  @media screen and (max-width: 1040) {
    width: 100%;
  }
  .payment_platform_details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    flex-direction: column;
  }
  p {
    margin: 0;
    font-size: 12px;
    text-align: start;
  }
  label {
    color: var(--grey2);
    font-size: 12px;
    span {
      color: red;
    }
  }
  input:last-child {
    width: 5rem;
    padding: 7px 3px;
    border: 0.5px solid #b8c6e4;
    border-radius: 4px;
  }
  @media screen and (min-width: 768px) {
    margin: 5rem 0;
    .payment_platform_details {
      flex-direction: row;
    }
    label,
    p {
      font-size: 16px;
    }
  }
`;
