import { BaseButton } from "app/components/atoms/button/button.styles";
import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  svg {
    width: 250px;
    height: 60%;
    align-self: center;
  }
  @media screen and (min-width: 650px) {
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 650px) {
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 20px 30px;
  padding: 10px 50px;
  p {
    margin: 0;
  }
  @media screen and (min-width: 650px) {
  }
`;

export const ImportContactContainer = styled.div`
  cursor: pointer;
  margin-top: 10px;
  p:hover {
    color: black;
  }
`;

export const PeopleContainer = styled.div`
 padding:10px;
  @media screen and (min-width: 650px) {
    padding: 20px 80px 20px 40px;
  }
`;

export const FormContainer = styled.div`
  text-align: left;
  margin: 40px 0;
  input {
    margin-top: 5px;
  }
  .hidden_email {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const PeopleFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const DownloadButton = styled(BaseButton)`
  width: fit-content;
  background-color: var(--primary2);
  border-radius: 8px;
  color: white;
  padding: 16px 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 26px 0 76px 0;

  p {
    color: white;
    margin: 0;
    font-weight: 700;
  }
  @media screen and (max-width: 650px) {
    p {
      font-size: 12px;
    }
  }
`;

export const PeopleRoleSelect = styled.div`
  background-color: #f6f6f6;
  padding: 1rem 2rem;
  font-size: 14px;
  display: block;
  width: ${({ width }) => width || "100%"};
  margin: 20px 0 10px;
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  .dropdown {
    position: absolute;
    top: 60px;
  }
`;

export const PeopleMobileWrapper = styled.div`
.filter_container{
  display: flex;
  justify-content: flex-start;
  gap:10px;
  width: 100%;
margin-bottom: 20px;
 @media screen and (max-width: 350px) {
  flex-direction: column;
 }
}
.role_filter, .search_filter{
 border: 0.25px solid var(--primary); 
    padding: 0 10px;
    border-radius: 12px; 
      width: 100%;
}
.role_filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .search_filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    @media screen and (max-width: 350px) {
      justify-content: center;
      margin: 0;
      padding: 5px;
      width: 100%;
    }
    .icon {
      margin-right: 5px;
    }
    input {
      border: none;
      width: 100%;
    }
  }
`;
