import Text from "../typography";

import styled from "styled-components";
import { ErrorMessage, useField } from "formik";
import { ErrorText } from "../form-input-two/form-input-two.component";
import { useViewport } from "hooks";
import { Link } from "react-router-dom";

const AgreementCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  p {
    font-size: 14px;
  }
`;

const AgreementCheckbox = ({setClicked, fields }) => {
  const [field] = useField(fields);
  const { mobile } = useViewport()

  return (
    <>
      <AgreementCheckboxContainer>
        <input type="checkbox" {...field} onClick={setClicked}></input>
        <Link to={"/terms"}>
          <Text size={mobile ? 10 : 20}>I agree to the Terms of Service and Privacy Policy.</Text>
        </Link>
      </AgreementCheckboxContainer>
      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </>
  );
};

export default AgreementCheckbox;
