import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "notification";

export const initialState = {
  page: 0,
  pageSize: 0,
  pageCount: 0,
  totalCount: 0,
  data: [],
  totalNew: 0,
  loading: true,
};

const getNotifications = createAsyncThunk(
  `${entity}/notification`,
  async (pageSize, page) => {
    const res = await api.notification.getNotifications(pageSize, page);
    return res.data;
  }
);

const getMoreNotifications = createAsyncThunk(
  `${entity}/moreMotification`,
  async (newPage) => {
    const res = await api.notification.getNotifications(20, newPage);
    return res.data;
  }
);

const updateNotifications = createAsyncThunk(
  `${entity}/update`,
  async (ids) => {
    const res = await api.notification.updateNotification(ids);
    return res.data;
  }
);

const notificationSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        action.payload.data = action.payload.data?.map((notification) => {
          return { ...notification };
        });

        return { ...state, loading: false,
          ...action.payload };
      })
      .addCase(getNotifications.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getNotifications.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getMoreNotifications.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          totalNew: action.payload.totalNew,
          loading: false,
          data: state.data.concat(
            action.payload.data?.map((notification) => {
              return { ...notification };
            })
          ),
          page: action.payload.page,
        };
      })
      .addCase(getMoreNotifications.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getMoreNotifications.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
      builder
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.totalNew = action.payload.data;
        state.loading = false;
      })
      .addCase(updateNotifications.pending, () => {})
      .addCase(updateNotifications.rejected, () => {});
  },
});

export const notificationActions = {
  ...notificationSlice.actions,
  getNotifications,
  getMoreNotifications,
  updateNotifications
};

export const notificationSelectors = {
  isLoading: (state) => state.notification.loading,
  notifications: (state) => state.notification,
  totalCount: (state) => state.notification.totalCount,
  pageSize: (state) => state.notification.pageSize,
  pageCount: (state) => state.notification.pageCount,
  page: (state) => state.notification.page,
  totalNew: (state) => state.notification.totalNew
};

export default notificationSlice.reducer;
