import BaseRecurringInvoiceSetup from "../baseSetup/baseSetupRecurringInvoice";

const BaseEditRecurringInvoice = ({handleSave, ClientDropDown, invoice: recurringInvoice, backRoute}) => {

    return (
        <BaseRecurringInvoiceSetup
        recurringInvoice={recurringInvoice}
        backRoute={backRoute}
        handleSave={handleSave}
        ClientDropDown={ClientDropDown}>
        </BaseRecurringInvoiceSetup>)
};

export default BaseEditRecurringInvoice;