import React from 'react';
import Text from "app/components/atoms/typography";
import { useOnClickOutside } from 'hooks';
import dp1 from "../../assets/img/dp1.png";
import dp2 from "../../assets/img/dp2.png";
import dp3 from "../../assets/img/dp3.png";
import dp4 from "../../assets/img/dp4.png";
import { ReminderMobileContainer } from './reminder.styles';

const ReminderCardMobile = ({ datesData, alternatingColorClass, setEditReminder }) => {

    const defaultDps = [dp1, dp2, dp3, dp4]
    const {visible: moreData, setVisible: setMoreData, ref} = useOnClickOutside(false);

    const handleViewClicked = () =>{
        setMoreData(!moreData);
    }

  return (
      <ReminderMobileContainer>
         <Text weight={500} size={14} color="var(--primary2)">
            {datesData?.dayOfWeekName?.slice(0, 3)}, {datesData.day}
          </Text>

        <div style={{width:"200px", marginLeft:"60px"}} ref={ref}>
          {datesData?.data?.map((reminder, index) =>  (moreData || index === 0) &&  (
            <div
                style={{backgroundColor: alternatingColorClass, marginTop:"15px" }}
                className={`card__content ${reminder.isActive ? "" : "inactive"}`}
                key={reminder.id}
                onClick={() => { setEditReminder({ value: true, data: reminder }) }}>

              <Text weight="700" size="12px" color="var(--grey1)">
                {reminder.title}
              </Text>

              <div className="dp">
                {defaultDps?.map((dp, index) => (
                  <img
                    key={index}
                    src={dp}
                    alt='dp'
                  />
                ))}
              </div>
            </div>
          ))}

            {datesData?.data?.length > 1 && !moreData && (
            <div
                style={{marginRight:"30px"}}
                className="view"
                onClick={handleViewClicked}>
                View All
            </div>
            )}

            <div style={{ borderBottom: "0.5px solid var(--primary5)", marginTop: "20px" }}></div>

          </div>
      </ReminderMobileContainer>
  );
};

export default ReminderCardMobile;
