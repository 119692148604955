
import React, { useEffect, useState } from "react";
import { useViewport } from "hooks";
import TotalIcon from "../../../assets/img/Total_invoice.png"
import PendingIcon from "../../../assets/img/pending-invoice-icon.png"
import ApprovedIcon from "../../../assets/img/Approved.png"
import ChangeIcon from "../../../assets/img/ChangeRequest.png"
import DeclineIcon from "../../../assets/img/decline-invoice-icon.png"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import InvoiceCards from "app/components/atoms/carousels/invoice-card";
import { CarouselContainer, CarouselWrapper } from "app/components/atoms/carousels/carousel.styles";
import { MetricDiv } from "app/components/atoms/divs/div.styles";

const InvoiceCarousel = ({ totalCount, approvedCount, pendingCount, changesRequested, declinedCount }) => {
  const [cardWidth, setCardWidth] = useState(null);
  const { width , mobile} = useViewport();
  const [isDisabled, setIsDisabled] = useState(false);

  const cards = [
    <InvoiceCards
    title="Total Invoices"
    count={totalCount}
    icon={TotalIcon}
    />,
    <InvoiceCards
      title="Approved"
      count={approvedCount}
      icon={ApprovedIcon}
    />,
    <InvoiceCards
      title="Change Requested"
      count={changesRequested}
      icon={ChangeIcon}
    />,
    <InvoiceCards
      title="Pending"
      count={pendingCount}
      icon={PendingIcon}
    />,
    <InvoiceCards
      title="Declined"
      count={declinedCount}
      icon={DeclineIcon}
    />,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);

  const handleNext = () => {
    if (currentIndex === cards.length - 1) {
      setCurrentIndex(0);
      setTranslateValue(0);
    } else {
      setCurrentIndex(currentIndex + 1);
      setTranslateValue(translateValue - slideWidth() - 10);
    }
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(cards.length - 1);
      setTranslateValue(-slideWidth() * (cards.length - 1) - 60);
    } else {
      setCurrentIndex(currentIndex - 1);
      setTranslateValue(translateValue + slideWidth() + 10);
    }
  };

  const slideWidth = () => {
    return document.querySelector(".slide").clientWidth;
  };

  useEffect(() => {

    setIsDisabled(false);

    if (width < 700) {
      setCardWidth("50%");
      if (currentIndex === 5) {
        setIsDisabled(true);
      }
    } else if (width > 700 && width < 1300) {
      setCardWidth("33%");
      if (currentIndex === 4) {
        setIsDisabled(true);
      }
    } else {
      setCardWidth("24.3%");
      if (currentIndex === 1) {
        setIsDisabled(true);
      }
    }
  }, [currentIndex, width, totalCount, pendingCount, declinedCount, approvedCount, changesRequested]);

  return (
    <MetricDiv>
      <CarouselContainer>
        <CarouselWrapper
          width={cardWidth}
          className="metric"
          style={{
            transform: `translateX(${translateValue}px)`,
            transition: "transform ease-out 0.45s",
          }}
        >
          {cards?.map((card, index) => (
            <div key={index} className="slide">
              {card}
            </div>
          ))}
        </CarouselWrapper>
        {currentIndex !== 0 && (
          <BsChevronLeft className="left" size={mobile ? 30 : 50} onClick={handlePrev} />
        )}
        {!isDisabled && (
          <BsChevronRight className="right" size={mobile ? 30 : 50} onClick={handleNext} />
        )}
      </CarouselContainer>
    </MetricDiv>
  );
};

export default InvoiceCarousel;