import React from "react";
import Text, { Heading } from "app/components/atoms/typography";
import { Vortex } from "react-loader-spinner";
import "../../../../src/index.css";
import { TableHeader } from "../reminder/clientsTable";
import { TableItem } from "./admin-dashboard-styles";
import { FlexedDiv } from "app/components/atoms/divs/div.styles";
import { CardStyle } from "app/components/atoms/card/card.component";
import { useNavigate } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { useViewport } from "hooks";

const AdminInvoiceListMobile = ({
  loading,
  topOrBottomFiveOrganizations,
  listView,
  setListView,
  topOrBottomFiveClients,
}) => {

  const navigate = useNavigate();
  const { mobile } = useViewport();
  const basicMenu = (row) => (
    <BsArrowUpRight
    onClick={() => { navigate(`/organization/${row.id}`); }}/>
  );

  return (
      <CardStyle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Vortex
              height="80"
              width="80"
              ariaLabel="vortex-loading"
              wrapperClass="vortex-wrapper"
              colors={["red", "blue", "yellow", "#fe9900"]}
            />
          </div>
        ) : (
          <>
            <CardStyle>
              <TableHeader>
                <div className="admin">
                  <TableItem
                    size={12}
                    active={listView === "org"}
                    onClick={() => {
                      setListView("org");
                    }}
                  >
                   { mobile ?  `Top Orgs (${topOrBottomFiveOrganizations.length})` : `Top Organizations (${topOrBottomFiveOrganizations.length})`}
                  </TableItem>
                  <TableItem
                    size={12}
                    active={listView === "client"}
                    onClick={() => {
                      setListView("client");
                    }}
                  >
                    Top Clients ({topOrBottomFiveClients.length})
                  </TableItem>
                </div>
              </TableHeader>
            </CardStyle>
            {listView === "org"
              ? topOrBottomFiveOrganizations?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      borderBottom: ".5px solid var(--primary5)",
                      marginTop: "20px",
                    }}
                  >
                    <FlexedDiv>
                      <Text size={10}>
                        Invoice count{" "}
                        <span style={{ fontWeight: 700 }}>
                          {item.invoiceCount}
                        </span>{" "}
                      </Text>
                      <Text size={10}>
                        User count{" "}
                        <span style={{ fontWeight: 700 }}>
                          {item.clientCount}
                        </span>
                      </Text>
                    </FlexedDiv>
                    <FlexedDiv>
                      <div>
                        <FlexedDiv justifyContent>
                          <Text size={16}>{index + 1}</Text>
                          <Heading size={14}>{item.name}</Heading>
                        </FlexedDiv>
                      </div>
                      <div>{basicMenu(item)}</div>
                    </FlexedDiv>

                    <FlexedDiv justifyContent>
                      <Text size={12}>
                        {new Date(item.dateRegistered).toLocaleDateString()}
                      </Text>
                      <Text size={12}>{item.phoneNumber}</Text>
                      <Text size={12}>{item.isActive}</Text>
                    </FlexedDiv>
                  </div>
                ))
              : topOrBottomFiveClients.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      borderBottom: ".5px solid var(--primary5)",
                      marginTop: "20px",
                    }}
                  >
                    <FlexedDiv>
                      <Text size={10}>
                        Invoice count{" "}
                        <span style={{ fontWeight: 700 }}>
                          {item.invoiceCount}
                        </span>{" "}
                      </Text>
                    </FlexedDiv>
                    <FlexedDiv>
                        <FlexedDiv justifyContent>
                          <Text size={16}>{index + 1}</Text>
                          <Heading size={14}>{item.name}</Heading>
                        </FlexedDiv>
                    </FlexedDiv>
                    <FlexedDiv justifyContent>
                      <Text size={12}>
                        {new Date(item.dateRegistered).toLocaleDateString()}
                      </Text>
                      <Text size={12}>{item.phoneNumber}</Text>
                      <Text size={12}>{item.isActive}</Text>
                  </FlexedDiv>
                  </div>
                ))}
          </>
        )}
      </CardStyle>
  );
};

export default AdminInvoiceListMobile;
