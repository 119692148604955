import Button from "app/components/atoms/button/button.component";
import Text from "app/components/atoms/typography";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SocialMediaPanel from "../social-media-panel/social-media-panel.component";
import { authSelectors } from "store/reducers/auth/authSlice";
import { useSelector } from "react-redux";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  gap: 20px;

  .signin-panel-social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 20px 0;
    width: 100%;

    p {
      margin: 0;
    }

    .divider {
      border-bottom: 1px solid var(--primary6);
      flex: 1;
    }

    @media screen and (min-width: 650px) {
      .divider {
        border-bottom: 1px solid var(--primary6);
      }
    }
  }

  .signin-panel-social-media-button-container {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 650px) {
    margin: 40px 0 0 0;
  }
`;

const SignInPanel = ({ isSubmitting }) => {
  const navigate = useNavigate();
  const isLoading = useSelector(authSelectors.isLoading);

  return (
    <ButtonContainer>
      <Button
        isLoading={isLoading}
        disabled={isSubmitting}
        type="submit"
        width="100%"
        height="44px"
      >
        Login
      </Button>
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Text>
          <span
            style={{
              color: "var(--primary)",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot Password?
          </span>
        </Text>
      </div>
      <div className="signin-panel-social-media-container">
        <div className="divider"></div>
        <div>
          <Text>or sign in with</Text>
        </div>
        <div className="divider"></div>
      </div>
      <div className="signin-panel-social-media-button-container">
        <SocialMediaPanel />
      </div>
    </ButtonContainer>
  );
};

export default SignInPanel;
