import { useState, useMemo, useCallback, useRef } from "react";
import Table from "app/components/molecules/table/table.component";
import Card from "app/components/atoms/card/card.component";
import Text, { Heading } from "app/components/atoms/typography";
import {
  createStatusStyle,
  createPaymentStatusStyle,
  getPaymentStatusName,
  getStatusName,
} from "utils/helpers/createStatusStyle/createStatusStyle";
import { InvertedButton } from "app/components/atoms/button/button.styles";
import { useCheckAdmin, useViewport } from "hooks";
import { USER_ROLES } from "store/reducers/auth/utils";
import { useNavigate } from "react-router-dom";
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  ROUTES,
} from "utils/constants/constants.utils";
import { TableHeader } from "app/pages/reminder/clientsTable";
import { invoiceActions } from "store/reducers/invoice/invoiceSlice";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import InvoiceSummaryLink from "../invoiceSummaryLink/invoiceSummaryLink";
import InvoiceMobileView from "../invoiceMobile/invoiceMobileView";

const Export = ({ onExport }) => (
  <InvertedButton onClick={(e) => onExport(e.target.value)} height="44px">
    Export
  </InvertedButton>
);

const convertArrayOfObjectsToCSV = (args) => {
  var result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ",";
  lineDelimiter = args.lineDelimiter || "\n";

  keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

const downloadCSV = (fileName = "export.csv", data) => {
  var link;
  var csv = convertArrayOfObjectsToCSV({
    data: data,
  });
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = "data:text/csv;charset=utf-8," + csv;
  }
  data = encodeURI(csv);

  link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", fileName);
  link.click();
}

const InvoiceList = ({
  invoices,
  pendingCount,
  approvedCount,
  declinedCount,
  changeRequestCount,
  totalCount,
  loading,
  statusInput,
  setStatusInput,
  tableHeader = null,
  pageSize,
  page,
  columnsOrg,
  onChangePage,
  ...rest
}) => {

  const componentRef = useRef();
  const { mobile } = useViewport();
  const [selectedRows, setSelectedRows] = useState([]);
  const { role } = useCheckAdmin();
  const navigate = useNavigate();
  const { advanceSearch } = invoiceActions;
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleEdit = (data) => {
    navigate(`${ROUTES.EDIT_INTERNAL_INVOICE_ROUTE_HEAD}/${data.id}`);
  };

  const contextActions = useMemo(
    () => <Export onExport={() => downloadCSV("invoice.csv", selectedRows)} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows]
  );

  const columns = (columnsOrg && Object.create(columnsOrg)) || [
    {
      name: "Date Created",
      selector: (row) => row.createdDate,
      format: (row) => new Date(row.createdDate).toLocaleDateString(),
      sortable: true,

    },
    {
      name: "Invoice #",
      selector: (row) => row.invoiceNo,
      grow: 1.5,
    },
    {
      name: "Client",
      selector: (row) => role === USER_ROLES.CLIENT_USER ? row.to : row.client,
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      format: (row) =>
        row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
    {
      name: "Status",
      selector: (row) => row.statusName,
      sortable: true,
      grow: 2,
      format: (row) => (
        <p
          style={{
            color: createStatusStyle(row.statusName),
          }}
        >
          {getStatusName(row.statusName)}
        </p>
      ),
    },
    {
      name: "Payment Status",
      sortable: true,
      selector: (row) => row.invoicePaymentStatusName,
      format: (row) => (
        <p
          style={{
            color: createPaymentStatusStyle(row.invoicePaymentStatusName),
          }}
        >
          {getPaymentStatusName(row.invoicePaymentStatusName)}
        </p>
      ),
    },
  ];

  const basicMenu = (row) => (
    <ActionMenu popupList={["View", "Download"]}
      row={row}
      downloadLink={row.invoicePdfUrl} />
  );

  const editMenu = (row) => (
    <ActionMenu
      popupList={["View", "Edit", "Download"]}
      row={row}
      downloadFileName={row.invoiceNo}
      downloadLink={row.invoicePdfUrl}
      handleEdit={handleEdit}
    />
  );

  const actionSelector = (row) => {
    return row.statusName === INVOICE_STATUS.CHANGE_REQUESTED &&
      row.typeName === INVOICE_TYPE.Internal
      ? editMenu(row)
      : basicMenu(row);
  };

  if (role === USER_ROLES.ORGANIZATION_ADMIN || role === USER_ROLES.ORGANIZATION_USER) {
    columns.push({
      name: "Action",
      selector: (row) => basicMenu(row),
      button: true,
    });
  } else if (role === USER_ROLES.CLIENT_USER) {
    columns.push({
      name: "Action",
      selector: actionSelector,
      button: true,
    });
  }

  return (
      <Card>
        <div ref={componentRef}>
          {tableHeader ? (
            tableHeader
          ) : (
            <TableHeader
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 0,
              }}
            >
              <div>
                <div style={{ borderBottom: "1px solid #F2F6FF", width: "100%" }}>
                  <Heading
                    color=" var(--primary2)"
                    size={mobile ? 12.24 : 24}>
                    Invoices
                  </Heading>
                  <Text
                    color="var(--grey2)"
                    size={mobile ? 10 : 16}
                    margin="0">
                    Monitor the progress of your invoice
                  </Text>
                </div>

              </div>
              <InvoiceSummaryLink
                pendingCount={pendingCount}
                approvedCount={approvedCount}
                declinedCount={declinedCount}
                changeRequestCount={changeRequestCount}
                totalCount={totalCount}
                statusInput={statusInput}
                setStatusInput={setStatusInput}
                advanceSearch={advanceSearch}
              />
            </TableHeader>
          )}
          {mobile ?
            <InvoiceMobileView
              role={role}
              invoices={invoices}
              pageCount={Math.ceil(totalCount / pageSize)}
              page={page}
              handleEdit={handleEdit}
              loading={loading}
              onChangePage={onChangePage}
            />
            :

            <Table
              columns={columns}
              data={invoices}
              onSelectedRowsChange={handleRowSelected}
              onChangePage={onChangePage}
              selectableRows
              pagination
              progressPending={loading}
              actions={<></>}
              contextActions={contextActions}
              paginationServer
              paginationTotalRows={totalCount}
              {...rest}
            />
          }
        </div>
      </Card>
  );
};

export default InvoiceList;