import { OrganizationSettingsContainer, FormContent } from "./settings.styles";
import Button from "app/components/atoms/button/button.component";
import {
  SettingsActions,
  SettingsSelectors,
} from "store/reducers/settings/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormInput from "app/components/atoms/form-input/form-input.component";
import Text, { Span } from "app/components/atoms/typography";
import CountryStateDropdowns from "./CountryStateDropdown";
import Spinner from "app/components/atoms/spinner/spinner.component";

const validation = Yup.object({
  businessName: Yup.string().required(),
  businessEmail: Yup.string().email().required(),
  businessPhoneNumber: Yup.number().required(),
  industry: Yup.string().required(),
  street: Yup.string().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
});
const OrganizationSettings = () => {
  const dispatch = useDispatch();
  const { handleApiResponse } = useHandleApiResponse();

  const organization = useSelector(SettingsSelectors.organization);
  const isLoading = useSelector(SettingsSelectors.isLoading);
  const { getOrganizationDetails, updateOrganizationDetails } = SettingsActions;

  const defaultFormFields = {
    id: organization.id || "",
    businessName: organization.businessName || "",
    businessEmail: organization.businessEmail || "",
    businessPhoneNumber: organization.businessPhoneNumber || "",
    industry: organization.industry || "",
    street: organization.street || "",
    state: organization.state || "",
    country: organization.country || "",
    zipCode: organization.zipCode || "",
    logo: organization.logo || "",
  };

  useEffect(() => {
    dispatch(getOrganizationDetails());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values) =>{

    dispatch(updateOrganizationDetails({
        ...values})).then((res) =>
        {
          handleApiResponse(res, null)
        });
  }

  return (
    <OrganizationSettingsContainer>
      <Text color="#7B8599">Manage your company account settings</Text>
      {organization.hasOwnProperty("id") ? (
        <Formik
          initialValues={defaultFormFields}
          validationSchema={validation}
          onSubmit={(values) => {
              handleSubmit(values)
          }}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form className="form">
                <FormContent>
                  <Text>
                    Company Name <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    type="text"
                    name="businessName"
                    placeholder="Enter name"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["businessName"]}
                    onChange={(value) =>
                      setFieldValue("businessName", value.value)
                    }
                  />
                </FormContent>
                <FormContent>
                  <Text>
                    Contact Email <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    readOnly
                    type="email"
                    name="businessEmail"
                    placeholder="Email address"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["businessEmail"]}
                    onChange={(value) =>
                      setFieldValue("businessEmail", value.value)
                    }
                  />
                </FormContent>
                <FormContent>
                  <Text>
                    Contact Number <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    type="text"
                    name="businessPhoneNumber"
                    placeholder="Enter phone number"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["businessPhoneNumber"]}
                    onChange={(value) =>
                      setFieldValue("businessPhoneNumber", value.value)
                    }
                  />
                </FormContent>
                <FormContent>
                  <Text>
                    Street <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    type="text"
                    name="street"
                    placeholder="Enter street address"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["street"]}
                    onChange={(value) => setFieldValue("street", value.value)}
                  />
                </FormContent>
                <CountryStateDropdowns
                  country={values["country"]}
                  state={values["state"]}
                />

                <FormContent>
                  <Text>
                    Postal/Zip Code <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    className="postal"
                    type="text"
                    name="zipCode"
                    placeholder="Enter street address"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["zipCode"]}
                    onChange={(value) => setFieldValue("zipCode", value.value)}
                  />
                </FormContent>
                <FormContent className="cheat">
                  <div></div>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    disabled={isLoading || Object.keys(errors).length > 0}
                  >
                    Save Changes
                  </Button>
                </FormContent>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </OrganizationSettingsContainer>
  );
};

export default OrganizationSettings;
