export const getBrowserDateTimeFromUTC = (utcDate) =>{

    // Get the user's local timezone offset in minutes
const timezoneOffset = new Date().getTimezoneOffset();

// Calculate the local timestamp by adding the offset to the UTC timestamp
const localTimestamp = utcDate.getTime() - timezoneOffset * 60 * 1000;

// Create a new Date object with the local timestamp
    return new Date(localTimestamp);
}