import { Span } from "app/components/atoms/typography";
import styled, { css } from "styled-components";

const subColor = "#fe9900";

const activeStyle = css`
  background: ${subColor};
`;

export const ProfileDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 3px;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: sub;
    width: 125px;
    margin-left:1rem;
    margin-right: 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

`;

export const ProfilePictureStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({minWidth }) => minWidth || "50px"};
  max-width: ${({ maxWidth }) => maxWidth || "50px"};
  height: ${({ height }) => height || "50px"};
  margin: ${({ margin }) => margin || 0 };
  border-radius: 50%;
  object-fit: cover;
  overflow:hidden;
  background: #d9d9d9;
  ${({ active }) => active && activeStyle};
  img {
    width: 100%;
        height: 100%;
        object-fit: cover;

  }
`
export const DropdownContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 4rem auto 2rem;

`;

export const UserName = styled(Span)`
  margin-right: 1rem;
`;

export const BarStyle = styled.div`
  font-size: 1.3rem;
  vertical-align: middle;
  margin: 0 0.75rem 0 -0.25rem;
  cursor: pointer;
`;
