import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";
import Text from "../typography";

const BackToLogin = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "30px" }}>
      <Text>
        Back to
        <span
          style={{
            color: "var(--primary)",
            cursor: "pointer",
            marginLeft: "5px",
          }}
          onClick={() => {
            navigate(ROUTES.SIGN_IN);
          }}
        >
          Login
        </span>
      </Text>
    </div>
  );
};

export default BackToLogin;
