export const getWeekRanges = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstOfMonth = new Date(year, month, 1);
  const lastOfMonth = new Date(year, month + 1, 0);
  const endOfWeek = new Date(firstOfMonth);
  endOfWeek.setDate(
    firstOfMonth.getDate() + ((6 - firstOfMonth.getDay() + 7) % 7)
  );

  let weekNo = 1;
  const weekRanges = [{ start: firstOfMonth, end: endOfWeek, weekNo }];
  let newWeek = new Date(endOfWeek);
  newWeek.setDate(endOfWeek.getDate() + 1);

  while (newWeek < lastOfMonth) {
    const endOfWeek = new Date(newWeek);
    endOfWeek.setDate(newWeek.getDate() + ((6 - newWeek.getDay() + 7) % 7));
    if (endOfWeek >= lastOfMonth) {
      weekNo++;
      weekRanges.push({ start: newWeek, end: lastOfMonth, weekNo });
      newWeek = new Date(endOfWeek);
    } else {
      weekNo++;
      weekRanges.push({ start: newWeek, end: endOfWeek, weekNo });
      newWeek = new Date(endOfWeek);
      newWeek.setDate(endOfWeek.getDate() + 1);
    }
  }

  const lastWeek = weekRanges[weekRanges.length -1];

  if(lastWeek.end.getDate() < lastOfMonth.getDate()){
    const newWeekStart = new Date(lastWeek.end);
    newWeekStart.setDate(newWeekStart.getDate() + 1)
    ;
    weekRanges.push({ start: newWeekStart, end: lastOfMonth, weekNo: weekNo++ });
  }

  return weekRanges;
};
