import ContentContainer, {
  ContentCardsContainer,
  Header,
} from "app/components/atoms/about-content-layout/ContentContainer.component";
import { CardContainer } from "app/components/atoms/card/card.component";
import Text, { Heading } from "app/components/atoms/typography";
import "./faq.styles.css";
import { FAQContent } from "utils/contents/faq.content";
import FAQatom from "./faq.atom";
import {FaqParagraph } from "../FAQ/faq.styled.component";

const FAQ = () => {
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56" color="#fe9900">
            Frequently Asked Questions
          </Heading>
          <Text>Last updated on January 09, 2023</Text>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Introduction</Heading>
            <FaqParagraph>At Easei, we believe your invoicing, payment and receipting process should never be a hassel so we've taken our time to develop a solution that meets your needs and takes out the pain points of invoice generation and receipting</FaqParagraph>

          </CardContainer>
        </ContentCardsContainer>

        <ContentCardsContainer>
          <CardContainer>
            <div>
              {FAQContent?.map(({ index, question, answer, links }) => (

                <FAQatom question={question} answer={answer} links={links} key={index} />

              ))}
            </div>

          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default FAQ;
