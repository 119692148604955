import styled from "styled-components";

const SMCardContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 40px;
  border: 1px solid var(--primary6);
  border-radius: 8px;
  background:  #fff;
  cursor: pointer;

  svg {
    width: 100%;
    font-size: 24px;
    color: ${({ color}) => color || ""};
    transition: transform .7s ease-in-out;
  }

  svg:hover {
    transform: rotate(360deg);
  }

  @media screen and (min-width: 650px) {
    border: 1px solid var(--primary7);
  }
`;

const SocialMediaCard = ({ logo, color, onClick }) => {
  return <SMCardContainer color={color} type="button" onClick={onClick}>{logo}</SMCardContainer>;
};

export default SocialMediaCard;
