import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { dashboardSelectors } from "store/reducers/dashboard/dashboardSlice";
import Spinner from "../atoms/spinner/spinner.component";
import { MONTH_NAMES } from "utils/constants/constants.utils";
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const BarChart = ({ timeData, admin = false }) => {
  const isLoading = useSelector(dashboardSelectors.isLoading);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (chartData) {
      const chartInstance = new Chart(chartRef.current, {
        type: "bar",
        data: chartData,
        options: {
          barPercentage: 1, // Decrease the bar percentage to increase the width of each bar
          categoryPercentage: 0.8, // Set the category percentage to 1 to use the full available space for each category
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
           x: {
            display: true,
              beginAtZero: false,
          },
          y: {
            display: true, 
            beginAtZero: false,
          },
          },
        },
      });
      return () => {
        chartInstance.destroy();
      };
    }
  }, [chartData]);

  useEffect(() => {
    const data = {
      labels: [],
    };

    let key = 0;
    if (!admin) {
      data.datasets = [
        {
          label: "Approved",
          data: [], // an array of data values for the "pending" bars
          backgroundColor: "#3777FF",
          borderColor: "#3777FF",
          borderWidth: 1,
        },
        {
          label: "Pending",
          data: [], // an array of data values for the "pending" bars
          backgroundColor: "#C99A0A",
          borderColor: "#C99A0A",
          borderWidth: 1,
        },
        {
          label: "Declined",
          data: [], // an array of data values for the "total" bars
          backgroundColor: "#BF4545",
          borderColor: "#BF4545",
          borderWidth: 1,
        },
      ];
      timeData?.data?.forEach((timeElementData) => {
        if (timeData?.timeType === "Month") {
          data.labels.push("Week " + [key + 1]);
        } else if (timeData?.timeType === "Week") {
          data.labels.push(dayNames[key]);
        } else {
          data.labels.push(MONTH_NAMES[key]);
        }
        data.datasets[0].data.push(timeElementData.totalApproved);
        data.datasets[1].data.push(timeElementData.totalPending);
        data.datasets[2].data.push(timeElementData.totalDeclined);
        key++;
      });
    } else {
      data.datasets = [
        {
          label: "Organizations",
          data: [], // an array of data values for the "pending" bars
          backgroundColor: "#6C98F3",
          borderColor: "#6C98F3",
          borderWidth: 1,
        },
        {
          label: "Clients",
          data: [], // an array of data values for the "pending" bars
          backgroundColor: "#FE9900",
          borderColor: "#FE9900",
          borderWidth: 1,
        },
      ];
      timeData.forEach((timeElementData) => {
        data.labels.push(MONTH_NAMES[key].slice(0, 3));
        data.datasets[0].data.push(timeElementData.organizations);
        data.datasets[1].data.push(timeElementData.clients);
        key++;
      });
    }
    setChartData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div style= {{height:"281px"}}>
      <canvas id="myChart" ref={chartRef}></canvas>
    </div>
  );
};

export default BarChart;
