import ContentContainer, {
  ContentCardsContainer,
  Header,
} from "app/components/atoms/about-content-layout/ContentContainer.component";
import { CardContainer } from "app/components/atoms/card/card.component";
import List from "app/components/atoms/list/list.component";
import Text, { Heading, Span } from "app/components/atoms/typography";
import "./terms.styles.css";
const Terms = () => {
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56" color="#fe9900">
            Terms of User
          </Heading>
          <Text>Last updated on October 01, 2023</Text>
          <div className="spaced__text">
            <Text>
              From everyone at Easei, thank you for using our product!
            </Text>
            <Text>
              Your use of this website and our product is subject to the
              following terms of use and our privacy policy. By visiting this
              website you acknowledge that you have read and agree to these
              terms.
            </Text>
            <Text>
              When we say “Company”, “we”, “our”, or “us” in this document, we
              are referring to Easei, owned by Blazor Technologies LLC.
            </Text>
            <Text>
              When we say “Service”, we mean Easei, whether delivered within a
              web browser, desktop application, mobile application, or another
              format.
            </Text>
            <Text>
              When we say “You” or “your”, we are referring to the people or
              organizations that own an account with Easei.
            </Text>
            <Text>
              We may update these Terms of Service in the future. When you use
              our Service, now or in the future, you are agreeing to the latest
              Terms of Service. That’s true for any of our existing and future
              products and all features that we add to our Service over time.
            </Text>
            <Text>
              There may be times when we do not exercise or enforce any right or
              provision of the Terms of Service; in doing so, we are not waiving
              that right or provision. These terms do contain a limitation of
              our liability.
            </Text>
            <Text>
              Please note that if you violate any of the terms, we may terminate
              your account.
            </Text>
          </div>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Account Terms</Heading>
            <List el={"ul"}>
              <Text>
                You are responsible for maintaining the security of your account
                and password. The Company cannot and will not be liable for any
                loss or damage from your failure to comply with this security
                obligation.
              </Text>
              <Text>
                You are responsible for all content posted and activity that
                occurs under your account.
              </Text>
              <Text>
                That includes content posted by others who either: (a) have
                access to your login credentials; or (b) have their own logins
                under your account.
              </Text>
              <Text>You must be a human.</Text>
              <Text>
                Accounts registered by “bots” or other automated methods are not
                permitted.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Restricted Purposes</Heading>
            <div className="restricted">
              <Text>
                You may not use the Services for any purpose outlined below.
              </Text>
              <List el={"ul"}>
                <Text>
                  <Span weight="600">Violence, or threats thereof:</Span> If an
                  activity qualifies as violent crime in Nigeria or where you
                  live, you may not use Easei products to plan, perpetrate, or
                  threaten that activity.
                </Text>
                <Text>
                  <Span weight="600">
                    Child exploitation, sexualization, or abuse:
                  </Span>
                  We don’t tolerate any activities that create, disseminate, or
                  otherwise cause child abuse.
                </Text>
                <Text>
                  <Span weight="600">Malware or spyware:</Span>
                  If you are using our products to make or distribute anything
                  that qualifies as malware or spyware — including remote user
                  surveillance — be gone.
                </Text>
                <Text>
                  <Span weight="600">
                    Phishing or otherwise attempting fraud:
                  </Span>
                  It is not okay to lie about who you are or who you affiliate
                  with to steal from, extort, or otherwise harm others.
                </Text>
                <Text>
                  <Span weight="600">Spamming:</Span>
                  No one wants unsolicited commercial emails. We don’t tolerate
                  folks using Easei products for spamming purposes.
                </Text>
                <Text>
                  <Span weight="600">Infringing on intellectual property:</Span>
                  You can’t use Easei products to make or disseminate work that
                  uses the intellectual property of others beyond the bounds of
                  fair use.
                </Text>
              </List>
              <Text>
                While our use restrictions are comprehensive, they can’t be
                exhaustive — it’s possible an offense could defy categorization,
                present for the first time, or illuminate a moral quandary we
                hadn’t yet considered. That said, we hope the overarching spirit
                is clear: Easei is not to be harnessed for harm, whether mental,
                physical, personal or civic.
              </Text>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Payments, Refunds, and Plan Changes</Heading>

            <List el={"ol"}>
              <Text>
                If you are using a paid service that offers a free trial, we
                communicate the length of trial when you sign up. After the
                trial period, you need to pay in advance to keep using the
                service. If you do not pay, you will not have access to anything
                more than the basic features. But not to worry we’ll remind you
                early enough.
              </Text>
              <Text>
                All fees are exclusive of all taxes, levies, or duties imposed
                by taxing authorities. Where required, we will collect those
                taxes on behalf of the taxing authority and remit those taxes to
                taxing authorities.
              </Text>
              <Text>
                We are not responsible for refunds if the Service has not been
                used. When a Easei account is canceled or deleted, the previous
                charges will not be refunded.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Subscription</Heading>

            <List el={"ol"}>
              <Text>
                Some of our services are billed on subscription, your
                subscription is not automatically renewed, because we do not
                store your card details directly or through any third party. We
                send reminders on subscription at least two (2) weeks before
                expiry and every 2 days in the final week.
              </Text>
              <Text>If you cancel, previous charges will not be refunded.</Text>
              <Text>
                Payment can either be done during registration or in the
                settings module under payment and subscription.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Modifications to the Service and Prices</Heading>

            <List el={"ul"}>
              <Text>
                Occasionally, we may change the pricing structure for our
                product. Whenever we do that, we tend to exempt existing
                customers from those changes. However, we may choose to change
                the prices for existing customers. If we do so, we will give at
                least 30 days notice and will notify you via the email address
                on record. We may also post a notice about changes on our
                website or the affected Service themselves.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Security and Privacy</Heading>

            <List el={"ol"}>
              <Text>
                Your use of the Service is at your sole risk. We provide these
                Service on an “as is” and “as available” basis. We do not offer
                service-level agreements for our Service, but do take uptime of
                our applications seriously.
              </Text>
              <Text>
                You are responsible for maintaining your own backups of your
                content. We do not provide backup services and we exclude
                liability for any lost content.
              </Text>
              <Text>
                When you use our Service, you entrust us with your data. We take
                that trust to heart. You agree that Easei may process your data
                as described in our Privacy Policy and for no other purpose.
              </Text>
              <Text>
                We use third party vendors and hosting partners to provide the
                necessary hardware, software, networking, storage, and related
                technology required to run the Service.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Copyright and Content Ownership</Heading>

            <List el={"ol"}>
              <Text>
                We claim no intellectual property rights over the material you
                provide to the Service. All materials uploaded remain yours.
              </Text>
              <Text>
                We do not pre-screen content/data, but reserve the right (but
                not the obligation) in our sole discretion to refuse or remove
                any content that is available via the Service.
              </Text>
              <Text>
                The names, look, and feel of the Service are copyright © to the
                Company. All rights reserved. You may not duplicate, copy, or
                reuse any portion of the HTML, CSS, JavaScript, visual design
                elements or otherwise without express written permission from
                the Company. You must request permission to use the Company’s
                logo or any Service logos for promotional purposes. Please email
                us requests to use logos. We reserve the right to rescind this
                permission if you violate these Terms of Service.
              </Text>
              <Text>
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service without the express written permission by
                the Company.
              </Text>
              <Text>
                You must not modify another website so as to falsely imply that
                it is associated with the Service or the Company.
              </Text>
            </List>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Liability</Heading>
            <Text>
              You expressly understand and agree that the Company shall not be
              liable, in law or in equity, to you or to any third party for any
              direct, indirect, incidental, lost profits, special,
              consequential, punitive or exemplary damages, including, but not
              limited to, damages for loss of profits, goodwill, use, data or
              other intangible losses (even if the Company has been advised of
              the possibility of such damages), resulting from: (i) the use or
              the inability to use the Service; (ii) the cost of procurement of
              substitute goods and services resulting from any goods, data,
              information or services purchased or obtained or messages received
              or transactions entered into through or from the Service; (iii)
              unauthorized access to or alteration of your transmissions or
              data; (iv) statements or conduct of any third party on the
              service; (v) or any other matter relating to this Terms of Service
              or the Service, whether as a breach of contract, tort (including
              negligence whether active or passive), or any other theory of
              liability.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Contact Us</Heading>
            <Text>
              You may contact us if you have general questions about our policy,
              terms and practices or questions about your profile or personal
              information. Please contact us here.
            </Text>
          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default Terms;
