export const monthlyData = [
    {
      "month": "Jan",
      "pending": 23,
      "pendingColor": "hsl(66, 70%, 50%)",
      "approved": 60,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 135,
      "DeclinedColor": "hsl(319, 70%, 50%)",
      "changes requested": 124,
      "changes requestedColor": "hsl(354, 70%, 50%)",
    },
    {
      "month": "Feb",
      "pending": 18,
      "pendingColor": "hsl(178, 70%, 50%)",
      "approved": 122,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 87,
      "DeclinedColor": "hsl(296, 70%, 50%)",
      "changes requested": 116,
      "changes requestedColor": "hsl(6, 70%, 50%)",
    },
    {
      "month": "Mar",
      "pending": 5,
      "pendingColor": "hsl(170, 70%, 50%)",
      "approved": 122,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 164,
      "DeclinedColor": "hsl(212, 70%, 50%)",
      "changes requested": 20,
      "changes requestedColor": "hsl(125, 70%, 50%)",
    },
    {
      "month": "Apr",
      "pending": 69,
      "pendingColor": "hsl(187, 70%, 50%)",
      "approved": 30,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 104,
      "DeclinedColor": "hsl(273, 70%, 50%)",
      "changes requested": 129,
      "changes requestedColor": "hsl(235, 70%, 50%)",
    },
    {
      "month": "May",
      "pending": 45,
      "pendingColor": "hsl(304, 70%, 50%)",
      "approved": 54,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 128,
      "DeclinedColor": "hsl(39, 70%, 50%)",
      "changes requested": 148,
      "changes requestedColor": "hsl(22, 70%, 50%)",
    },
    {
      "month": "Jun",
      "pending": 29,
      "pendingColor": "hsl(81, 70%, 50%)",
      "approved": 84,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 133,
      "DeclinedColor": "hsl(206, 70%, 50%)",
      "changes requested": 51,
      "changes requestedColor": "hsl(120, 70%, 50%)",
    },
    {
      "month": "July",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    },
    {
      "month": "Aug",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    },
    {
      "month": "Sep",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    },
    {
      "month": "Oct",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    },
    {
      "month": "Nov",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    },
    {
      "month": "Dec",
      "pending": 76,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 11,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 4,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 79,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    }
  ]

  export const weeklyData = [
    {
      "day": "Mon",
      "pending": 3,
      "pendingColor": "hsl(66, 70%, 50%)",
      "approved": 15,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 8,
      "DeclinedColor": "hsl(319, 70%, 50%)",
      "changes requested": 2,
      "changes requestedColor": "hsl(354, 70%, 50%)",
    },
    {
      "day": "Tue",
      "pending": 8,
      "pendingColor": "hsl(178, 70%, 50%)",
      "approved": 3,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 0,
      "DeclinedColor": "hsl(296, 70%, 50%)",
      "changes requested": 0,
      "changes requestedColor": "hsl(6, 70%, 50%)",
    },
    {
      "day": "Wed",
      "pending": 5,
      "pendingColor": "hsl(170, 70%, 50%)",
      "approved": 16,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 0,
      "DeclinedColor": "hsl(212, 70%, 50%)",
      "changes requested": 0,
      "changes requestedColor": "hsl(125, 70%, 50%)",
    },
    {
      "day": "Thurs",
      "pending": 12,
      "pendingColor": "hsl(187, 70%, 50%)",
      "approved": 23,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 2,
      "DeclinedColor": "hsl(273, 70%, 50%)",
      "changes requested": 1,
      "changes requestedColor": "hsl(235, 70%, 50%)",
    },
    {
      "day": "Fri",
      "pending": 8,
      "pendingColor": "hsl(304, 70%, 50%)",
      "approved": 12,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 6,
      "DeclinedColor": "hsl(39, 70%, 50%)",
      "changes requested": 1,
      "changes requestedColor": "hsl(22, 70%, 50%)",
    },
    {
      "day": "Sat",
      "pending": 4,
      "pendingColor": "hsl(81, 70%, 50%)",
      "approved": 12,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 6,
      "DeclinedColor": "hsl(206, 70%, 50%)",
      "changes requested": 4,
      "changes requestedColor": "hsl(120, 70%, 50%)",
    },
    {
      "day": "Sun",
      "pending": 6,
      "pendingColor": "hsl(217, 70%, 50%)",
      "approved": 0,
      "approvedColor": "hsl(228, 70%, 50%)",
      "Declined": 0,
      "DeclinedColor": "hsl(129, 70%, 50%)",
      "changes requested": 0,
      "changes requestedColor": "hsl(203, 70%, 50%)",
    }
  ]