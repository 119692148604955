import styled, { css } from "styled-components";

const subColor = "grey";
const mainColor = "#3A3E47";

const shrinkLabelStyle = css`
  top: -3px;
  left: 0px;
  color: ${mainColor};
`;

const errorStyle = css`
  background-color: pink;
  border: 1px solid red;
`;

export const FormInputLabel = styled.label`
  color: ${mainColor};
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: -3px;
  left: 0px;
  right: ${({ stretch }) => (stretch ? "0" : "initial")};
  transition: 300ms ease all;
`;

export const Input = styled.input`
  background: none;
  background-color: #f2f6ff;
  color: ${mainColor};
  font-size: 14px;
  padding: 10px 10px 10px 20px;
  display: block;
  width: ${({ width }) => width || "100%"};
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: 20px 0 10px;

  :invalid {
    ${({ error }) => error && errorStyle};
  }

  ::placeholder {
    color: ${subColor};
    opacity: 1;
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLabel} {
    ${shrinkLabelStyle};
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  position: relative;

  input[type="password"] {
    letter-spacing: 0.3em;
  }
   .passwordToggler{
    position: absolute;
    right: .5rem;
    top:2rem;
  }
`;
