import { Formik, Form } from "formik";
import * as Yup from "yup";

import { EmailValidator } from "utils/validation/validation.utils";

import { AuthLayout } from "app/layouts";
import Button from "../../../components/atoms/button/button.component";
import FormInputTwo from "app/components/atoms/form-input-two/form-input-two.component";

import Text, { Heading } from "../../../components/atoms/typography";

import {
  ButtonContainer,
  ChildrenStyle,
} from "../../../layouts/auth.layout/auth.styles";
import styled from "styled-components";
import BackToLogin from "app/components/atoms/back-to-login/back-to-login.component";
import { api } from "api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";

export const HeaderWrapper = styled.div`
  h1,
  p {
    margin: 0;
  }
  margin-bottom: 50px;
`;

export const ButtonWrapper = styled(ButtonContainer)`
  margin: 20px 0 0 0;
`;

const defaultFormFields = {
  email: "",
};

const validate = Yup.object({
  email: EmailValidator,
});

export const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <Formik
        initialValues={defaultFormFields}
        validationSchema={validate}
        onSubmit={async (values, { setSubmitting }) => {
          const res = await api.auth.forgotPassword(values);
          if(res.status === 200) {
            navigate(ROUTES.CHECK_EMAIL, {state: values.email});
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <>
            <HeaderWrapper>
              <Heading
                size={28}
                weight={700}
                level={1}
                text="Forgot Password?"
              />
              <Text
                size={16}
                weight={400}
                color="var(--grey1)"
                text="No worries, we’ll send you reset instructions.
                Enter the email associated with your Eazie account."
              />
            </HeaderWrapper>
            <Form>
              <ChildrenStyle>
                <FormInputTwo
                  label="Email Address"
                  type="email"
                  name="email"
                  required
                />
              </ChildrenStyle>
              <ButtonWrapper>
                <Button
                  type="submit"
                  width="100%"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Request reset link
                </Button>
              </ButtonWrapper>
              <BackToLogin />
            </Form>
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};
