import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useDispatch, useSelector } from "react-redux";
import BaseInvoiceDetails from "./BaseInvoiceDetails";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import { SettingsActions, SettingsSelectors } from "store/reducers/settings/settingsSlice";
import { useEffect } from "react";
import _ from 'lodash';

const PreviewInvoice = () => {

    const invoice = useSelector(appdataSelector.previewInvoice);
    const accountDetails = useSelector(SettingsSelectors.account);
    const { getAccountDetails } = SettingsActions;
    const { setHeaderText } = appDataActions;

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getAccountDetails());
        dispatch(setHeaderText({ value: 'Preview Invoice', type: 'setHeaderText' }));

        // eslint-disable-next-line
    },[])

    return (
        <BaseInvoiceDetails
            backRoute={
                _.isEmpty(invoice.id) ?
                invoice.type === INVOICE_TYPE.External
                ? ROUTES.CREATE_EXTERNAL_INVOICE
                : ROUTES.CREATE_INVOICE : `${ROUTES.EDIT_INTERNAL_INVOICE_ROUTE_HEAD}/${ROUTES.PREVIEW_ROUTE}`
            }
            invoice={{
                accountName: accountDetails.accountName,
                accountNumber: accountDetails.accountNumber,
                bankName: accountDetails.bankName,
                sortCode: accountDetails.sortCodeIBAN,
                ...invoice
            }}/>);
}

export default PreviewInvoice;