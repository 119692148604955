import { useField, ErrorMessage } from "formik";
import { FormGroup, FormInputLable } from "./form-input.styles";
import styled from "styled-components";

export const ErrorText = styled.div`
  font-size: 12px;
  color: red;
`;

export const Input = styled.input`
  width:${({ width }) => width || "100%"};
  height:${({ height }) => height || "40px"};
   margin:${({ margin }) => margin};
  font-size: 14px;
  color: #737373;
  background: none;
  background-color: ${({ color }) => color || "var(--primary8)"};
  padding: 10px;
  display: block;
  border: 1px solid #a4b0c9;
  border-radius: 5px;
`;

const FormInput = ({ label, onBlur, el, ...otherProps }) => {
  const [field, meta] = useField(otherProps);
  return (
    <FormGroup>
      {label && <FormInputLable htmlFor={field?.name}>{label}</FormInputLable>}
      <Input
        {...otherProps}
        as={el}
        {...field}
        error={meta.touched && meta.error}
        onBlur={onBlur}
      />
      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </FormGroup>
  );
};

export default FormInput;
