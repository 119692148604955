import styled from "styled-components";

export const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: ${({ padding }) => padding || "10px 25px 20px"};
  height: ${({ height }) => height || "100%"};
  height: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0"};
  min-height: ${({ minHeight }) => minHeight || ""};
  min-width: ${({ minWidth }) => minWidth || ""};
  max-width: ${({ maxWidth }) => maxWidth || ""};
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media screen and (min-width: 650px) {
  }
`;

export const ImageContainer = styled.div`
  display: contents;
  svg {
    width: 80%;
    height: 80%;
    align-self: center;
  }
  @media screen and (min-width: 650px) {
  }
`;

const PeopleCard = ({ children, ...props }) => {
  return <CardContainer {...props}>{children}</CardContainer>;
};

export default PeopleCard;
