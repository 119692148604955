import styled from "styled-components";
import { Flex } from "../dashboard/dashboard.styles";
import { InvertedButton } from "app/components/atoms/button/button.styles";
import Button from "app/components/atoms/button/button.component";

export const ListContainer = styled.div`
  .dropdown {
    padding: 0;
  }
  .dropdown__content {
    min-width: auto;
    width: 100%;
    padding: 16px;
    font-size: 20px;

    &:hover {
      background-color: #3777ff;
      color: #f2f6ff;
      border: none;
    }
  }
`;
export const Thead = styled.thead``;
export const ReceiptTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-radius: 20px;
  table-layout: fixed;
`;
export const TheadTr = styled.tr`
  background-color: #f2f6ff;
  border-radius: 40px;
`;
export const Th = styled.th`
  padding: 0.9rem;
  color: black;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 964px) {
    font-size: 0.6rem;
  }
  @media (max-width: 769px) {
    font-size: 0.4rem;
  }
  @media (max-width: 400px) {
    font-size: 0.3rem;
    padding: 0.4rem 0;
  }
`;
export const Tbody = styled.tbody`
  color: black;
  @media (max-width: 509px) {
    padding: 0.4rem 0;
  }
`;
export const TbodyTr = styled.tr`
  border: 1px solid #f2f6ff;
`;
export const Td = styled.td`
  padding: 15px;
  font-size: 1rem;
  color: #7b8599;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 964px) {
    font-size: 0.5rem;
  }
  @media (max-width: 769px) {
    font-size: 0.3rem;
    padding: 0.4rem 0;
  }
`;
export const Pagination = styled.div`
  background-color: #f6f6f6;
  box-shadow: inset 0px 1px 0px rgba(228, 229, 231, 0.5);
  border-radius: 0px 0px 8px 8px;
`;
export const PaidWatermark = styled.span`
  position: absolute;
  left: 20%;
  opacity: 0.5;
  font-size: 10rem;
  font-weight: 200;
  transform: rotate(-60deg);
  color: #76767638;
  @media (max-width:  990px) {
    left: 10%;
  }
  @media (max-width: 620px) {
    font-size: 7rem;
  }
`;

export const FlexReceiptButton = styled(Flex)`
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
export const ReceiptFooter = styled.div`
background-color: #DBE7FF;
padding: 25px;
font-size: 1.25rem;
margin: 10px -50px -30px -50px;
 @media (max-width: 768px) {
    font-size: 10px;
    margin: 10px -15px -30px -15px;
  }
`

export const ResponsiveInvertedButton = styled(InvertedButton)`
 &:hover {
    background-color: #d11a2a;
    color: #f2f6ff;
    border: none;
  }
  @media (max-width: 500px) {
  font-size: .75rem;
  border-radius: 25px;
      min-width:fit-content;
      height: auto;
  width: auto;
  padding: 0.6rem 1.5rem;
}
 @media (max-width: 400px) {
  padding: 0.5rem .7rem;
 }
`
export const ResponsiveBaseButton = styled(Button)`
  @media (max-width: 500px) {
  font-size: .75rem;
  border-radius: 25px;
  min-width:fit-content;
  width: auto;
  padding: ${({ padding }) => padding || "0.6rem 1.5rem"};
height: fit-content;
}
@media (max-width: 768px) {
  padding: ${({ mobilePadding }) => mobilePadding || "0.5rem .7rem"};
 }
`;

export const ReceiptSort = styled.div`
margin-left: 1.25rem;
display: grid;
grid-template-columns: repeat(4, 1fr);
grid-template-rows: repeat(2, 1fr);
justify-content: center;
column-gap:1rem;
align-items: center;
font-size: 1rem;
 @media screen and (max-width:1000px) {
column-gap: .5rem;
   }
`;
