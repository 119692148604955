import React from 'react';
import styled from 'styled-components';

export const DateInputField = styled.input`
border: none;
font-size: 1.2rem;
width: 8rem;
    &:focus {
    outline: none;
  }
`;

export const CustomDateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <DateInputField
      type="text"
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      placeholder="DD/MM/YYYY"
    />
  ));