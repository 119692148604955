import { Outlet } from "react-router-dom";

import Card from "app/components/atoms/card/card.component";
import PageMessage from "app/components/molecules/page-message/page-message.component";
import HorizontalNav from "app/components/molecules/horizontal-nav/horizontal-nav.component";
import { useCheckAdmin, useViewport } from "hooks";
import { USER_ROLES } from "store/reducers/auth/utils";
import { SettingsWrapper } from "app/pages/settings/settings.styles";

let menuItems = [];

const SettingsLayout = () => {

  const { mobile } = useViewport();
  const { role } = useCheckAdmin();

  if (role === USER_ROLES.ORGANIZATION_ADMIN) {
    menuItems = [
      {
        name: "Organization Settings",
        url: "/settings/organization",
      },
      {
        name: "Profile Settings",
        url: "/settings/profile",
      },
      {
        name: "Payment & Subscription",
        url: "/settings/payment",
      },
    ];
  }
  else if (role === USER_ROLES.ORGANIZATION_USER) {
    menuItems = [
      {
        name: "Profile Settings",
        url: "/settings/profile",
      }
    ];
  } else if (role === USER_ROLES.CLIENT_USER) {
    menuItems = [
      {
        name: "Account Settings",
        url: "/settings/account",
      },
      {
        name: "Profile Settings",
        url: "/settings/profile",
      },
      {
        name: "Payment & Subscription",
        url: "/settings/payment",
      },
    ];
  }

  return (
    <SettingsWrapper className="dashboard-container">
      <div>
        <PageMessage heading="Settings" text="Define your account settings" />
      </div>
      <Card
        height="100vh"
        p={mobile ? "10px" : ""}
        style={{
          margin: "40px 50px 0 0",
        }}
      >
        <HorizontalNav menuItems={menuItems} isMain={true} textWeight="400" />
        <div>
          <Outlet />
        </div>
      </Card>
    </SettingsWrapper>
  );
};

export default SettingsLayout;
