import Text, { Heading } from "app/components/atoms/typography";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import ReactPaginate from "react-paginate";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import { ProfilePictureStyle } from "app/components/molecules/user-profile/user-profile.styles";
import { recurringInvoiceActions } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import { useDispatch } from "react-redux";
import { convertCurrencyToSign } from "utils/helpers/convertCurrencyToSign/convertCurrencyToSign";
import ToggleSwitch from "app/components/atoms/toggle-switch/toggle-switch.component";
import { getRandomColor } from "utils/helpers/getRandomColor/getRandomColor";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";

const RecurringInvoiceMobileView = ({
    recurringInvoices,
    pageCount,
    page,
    setPage,
    onChangePage,
    reload,
    setReload,
    handleEdit
}) => {

    const dispatch = useDispatch();
    const { updateStatus } = recurringInvoiceActions;
    const { handleApiResponse } = useHandleApiResponse();
    const basicMenu = (row) => (
        <ActionMenu
            popupList={["View", "Edit"]}
            row={row}
            handleEdit={handleEdit}
            downloadFileName={row.invoiceNo}
            downloadLink={row.invoicePdfUrl}
        />
    );

    const handleUpdateStatus = (row) =>{
        dispatch(updateStatus({
            id: row.id,
            status: !row.isActive
        })).then((res)=>{
            handleApiResponse(res);
            setReload(!reload);
        })
    }

    return (
        <>
            <div style={{ marginTop: "14px," }}>
                {recurringInvoices?.map((row, index) => (
                    <div
                        key={index}
                        style={{
                            borderBottom: "0.5px solid #6C98F3",
                            paddingBottom: "10px",
                            margin: "10px",
                        }}
                    >
                        <>
                            <Flex>
                                <Text size={12} weight={700}>
                               {convertCurrencyToSign(row.currency)}
                               {" "}
                               {row.totalAmount + row.totalTax}
                            </Text>
                            <ToggleSwitch onChange={()=>handleUpdateStatus(row)} checked={row.isActive}/>    
                            </Flex>

                            <Flex>
                                <div>
                                    <Flex textAlign="start" gap="5px">
                                        <ProfilePictureStyle style={{backgroundColor: getRandomColor()}}>
                                            <Heading weight={500} size={20}>
                                                {row.billTo?.charAt(0)}
                                            </Heading>
                                        </ProfilePictureStyle>
                                        <Heading
                                                    margin="0" 
                                                    size={15}>
                                                    {row.billTo}
                                                </Heading>
                                        <div>
                                            <Text margin="0" size={12}>{row.client}</Text>
                                            <Flex gap="20px">
                                                <Text margin="0" size={12}>
                                                    {new Date(row.createdAt).toLocaleDateString()}
                                                </Text>
                                                <Text
                                                    margin="0" 
                                                    size={12}>
                                                    {row.recurTypeName}
                                                </Text>
                                            </Flex>
                                        </div>
                                    </Flex>
                                </div>

                                <div>{basicMenu(row)}</div>
                            </Flex>
                        </>
                    </div>
                ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>

                <ReactPaginate
                    breakLabel="..."
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    forcePage={page}
                    onPageChange={(e) => {
                        setPage(e.selected);
                        onChangePage(e.selected + 1)
                    }
                    }
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </div>
        </>
    );
};

export default RecurringInvoiceMobileView;