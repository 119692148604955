import React from "react";
import { BaseButton } from "app/components/atoms/button/button.styles";
import Text, { Heading } from "app/components/atoms/typography";
import { PlanContentBox, PlanContentContainers } from "../../pages/plans/plan.style";
import CheckList from "app/pages/plans/plan-checkList";
import { addCommasToNumber } from "utils/helpers/addCommaToNumber/addCommaToNumbers";
import { setDecimalPlaces } from "utils/helpers/setDecimalPlaces/setDecimalPlaces";
import { SignUpPlan } from "./auth.styles";

const SignUpPlanSelection = ({ plans, setPlan, plan, handleFinish }) => {
  
  return (
    <SignUpPlan >
      <div>
        <Heading>Choose Your Plan</Heading>
        <Text>Select a subscription plan that best suits your needs.</Text>
        <PlanContentContainers gap="20px" justifyContent="center">
          {
            plans?.map(({ id, name, description, cost, features }) => {
              return <PlanContentBox
                key={id}
                backgroundColor={plan === id ? 'var(--primary7)' : ''}
                maxWidth="25rem"
                padding="30px"
              >
                <Heading responsiveFontSize={14} size={32}>{name}</Heading>
                <Text responsiveFontSize={14}>{description}</Text>
                <Heading responsiveFontSize={14} size={32}>{"₦ " + addCommasToNumber(setDecimalPlaces(cost))}</Heading>
                <Text responsiveFontSize={14} borderBottom=" 1px solid red">Per Month</Text>
                <BaseButton 
                onClick={() => { setPlan(id) }}
                  backgroundColor="var(--primary2)">Choose Plan</BaseButton>
                {features?.map(({ description, id }) => {
                  return <CheckList key={id} checkList={description} />
                })}
              </PlanContentBox>
            })
          }
        </PlanContentContainers>

        <div style={{ display: "grid", placeItems: "center" }}>
          <BaseButton margin="70px 0 0 " width="40%" disabled={!plan} onClick={handleFinish}>Finish</BaseButton>
        </div>
      </div>
    </SignUpPlan>

  );
};

export default SignUpPlanSelection;


