import React from "react";
import { Heading } from "app/components/atoms/typography";
import { Flex } from "../dashboard/dashboard.styles";
import { PlanContainer } from "./plan.style";
import PlanContainers from "./plan-containers";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants/constants.utils";
import { useViewport } from "hooks";
import { MobileResponsiveButton } from "app/components/atoms/divs/div.styles";

const PlansComponent = () => {

  const navigate = useNavigate();
  const { mobile } = useViewport();
  const handlesMobileContent = () => {
    navigate(ROUTES.CREATE_PLAN)
  }

  return (
    <PlanContainer className="dashboard-container">
  
      <Flex>
        <Heading responsiveFontSize={mobile ? 20 : 28} size={mobile ? 10 : 32}>Subscription Plans</Heading>
        <MobileResponsiveButton
          onClick={handlesMobileContent}
        >
          Create new Plan
        </MobileResponsiveButton>
      </Flex>
      <PlanContainers/>
    </PlanContainer>
  );
};

export default PlansComponent;