import * as Yup from "yup";

export const ConfirmPasswordValidator = Yup.string()
  .oneOf(
    [Yup.ref("password"), Yup.ref("newPassword"), null],
    "Password must match"
  )
  .required("Confirm Password is required");

export const PasswordValidator = Yup.string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  )
  .required("Password is required");

  export const SoftPasswordValidator = Yup.string()
  .required("Password is required");

export const EmailValidator = Yup.string()
  .email("Email is invalid")
  .required("Email is Required");

export const nameValidator = (name = "") =>
  Yup.string().required(name ? `${name} is required` : "Required");

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PhoneNumberValidator = Yup.string()
  .matches(phoneRegExp, "Phone number is not valid")
  .required("Phone Number is Required");

export const GeneralValidator = Yup.string().required("Required");

export const ConsentValidator = Yup.bool()
  .test(
    "acceptTerms",
    "You have to agree with our Terms and Conditions!",
    (value) => value === true
  )
  .required("You have to agree with our Terms and Conditions!");

export const NumberValidator = Yup.number().required("Required");

export const AmountValidator = Yup.number()
  .required("Required")
  .min(1, "Must be at least 1");

  export const PositiveNumberValidator = Yup.number()
  .required("Required")
  .min(0, "Must be at least 0");

export const UnitValidator = Yup.number()
  .min(0, "Must be at least 0")
  .max(1, "Must be at most 1")
  .required("Required");

export const TextAmountValidator = Yup.string()
  .min(10, "Must be at least 10")
  .max(250, "Must be at most 250")
  .required("Required");

  export const ArrayCountValidator = (message = "") =>  Yup.array().min(1, message ? message : "Required");

  export const FileValidator = Yup.mixed()
    .test("fileType", "Invalid file format", (value) => {
      if (!value) return true; // Skip validation if no file is selected
      const allowedExtensions = [".csv", ".xls", ".xlsx"];
      const fileExtension = value.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    })
    .required("File is required");
  // NEW PLAN
  