export const peopleData = [
    {
      id: 1,
      name: "Henderson Samweyes",
      email: "hsamweyes0@addtoany.com",
      phone: "(805) 5225452",
      designation: "Sales Manager",
    },
    {
      id: 2,
      name: "Aldrich Itscowicz",
      email: "aitscowicz1@harvard.edu",
      phone: "(140) 6018201",
      designation: "Accountant",
    },
    {
      id: 3,
      name: "Briggs Doey",
      email: "bdoey2@mysql.com",
      phone: "(716) 4266325",
      designation: "Computer Systems Analyst I",
    },
    {
      id: 4,
      name: "Jobey Giraux",
      email: "jgiraux3@cnn.com",
      phone: "(545) 8018336",
      designation: "Software Engineer III",
    },
    {
      id: 5,
      name: "Alaine Brand",
      email: "abrand4@hexun.com",
      phone: "(282) 7061702",
      designation: "Technical Writer",
    },
    {
      id: 6,
      name: "Cheryl Comelli",
      email: "ccomelli5@multiply.com",
      phone: "(782) 6506752",
      designation: "General Manager",
    },
    {
      id: 7,
      name: "Desi Steggles",
      email: "dsteggles6@zdnet.com",
      phone: "(253) 5802465",
      designation: "Media Manager III",
    },
    {
      id: 8,
      name: "Roi Prettjohn",
      email: "rprettjohn7@flavors.me",
      phone: "(166) 9059284",
      designation: "Social Worker",
    },
    {
      id: 9,
      name: "Leeann Mardall",
      email: "lmardall8@state.gov",
      phone: "(737) 9652949",
      designation: "Office Assistant III",
    },
    {
      id: 10,
      name: "Clerkclaude Dinnis",
      email: "cdinnis9@latimes.com",
      phone: "(349) 9696439",
      designation: "Financial Advisor",
    },
    {
      id: 11,
      name: "Chucho Tailour",
      email: "ctailoura@twitpic.com",
      phone: "(148) 3891987",
      designation: "Account Executive",
    },
    {
      id: 12,
      name: "Anders Deacon",
      email: "adeaconb@google.nl",
      phone: "(448) 9858524",
      designation: "VP Product Management",
    },
    {
      id: 13,
      name: "Kerry Blakelock",
      email: "kblakelockc@kickstarter.com",
      phone: "(466) 7687279",
      designation: "VP Sales",
    },
    {
      id: 14,
      name: "Cicily McDougal",
      email: "cmcdougald@reddit.com",
      phone: "(819) 8959560",
      designation: "Office Assistant IV",
    },
    {
      id: 15,
      name: "Clareta Bartczak",
      email: "cbartczake@dagondesign.com",
      phone: "(422) 1587187",
      designation: "VP Marketing",
    },
    {
      id: 16,
      name: "Shanda Leghorn",
      email: "sleghornf@cnbc.com",
      phone: "(398) 3105562",
      designation: "Assistant Manager",
    },
    {
      id: 17,
      name: "Doris Golde",
      email: "dgoldeg@skyrock.com",
      phone: "(393) 3887788",
      designation: "Developer II",
    },
    {
      id: 18,
      name: "Broddy Cricket",
      email: "bcricketh@yandex.ru",
      phone: "(564) 2811969",
      designation: "Web Designer I",
    },
    {
      id: 19,
      name: "Henrieta Orcas",
      email: "horcasi@ehow.com",
      phone: "(834) 7741804",
      designation: "Web Designer II",
    },
    {
      id: 20,
      name: "Roxie Backler",
      email: "rbacklerj@bluehost.com",
      phone: "(857) 3963650",
      designation: "Web Designer III",
    },
  ];
  