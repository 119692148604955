import ContentContainer, {
  ContentCardsContainer,
  Header,
} from "app/components/atoms/about-content-layout/ContentContainer.component";
import Text, { Heading, Span } from "app/components/atoms/typography";
import { CardContainer } from "app/components/atoms/card/card.component";
import List from "app/components/atoms/list/list.component";
import { Form, Formik } from "formik";
import {
  EmailValidator,
  nameValidator,
} from "utils/validation/validation.utils";
import "./gdpr.styles.css";
import * as Yup from "yup";
import FormInput from "app/components/atoms/form-input/form-input.component";
import styled from "styled-components";
import Button from "app/components/atoms/button/button.component";
import { useDispatch } from "react-redux";
import { supportAction } from "store/reducers/support/supportSlice";
import { useState } from "react";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 30px;
  max-width: 80%;
  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 0;
  }
`;

const defaultFormField = {
  fullName: "",
  email: "",
  request: "",
};

const validator = Yup.object({
  fullName: nameValidator(),
  email: EmailValidator,
});

const GDPR = () => {
  const dispatch = useDispatch();
  const [request, setRequest] = useState("");
  const { gdpr } = supportAction;
  const { handleApiResponse } = useHandleApiResponse();
  const handleSubmit = (values, setSubmitting) => {
    dispatch(gdpr({ ...values, request: request })).then((res) => {
      handleApiResponse(res, null);
      setSubmitting(false);
    });
  };
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56" style={{ color: "#fe9900" }}>
            GDPR
          </Heading>
          <Text>Last updated on January 10, 2023</Text>
          <Text>Easei is commited to General Data Protection Regulation</Text>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Introduction</Heading>
            <div>
              <Text>
                Easei is committed to complying with laws generally applicable
                to personal data and the provision of the services in the
                jurisdiction where the personal data is processed and the GDPR
                for its service.
              </Text>
              <Text>
                Our Privacy Policy describes how Easei handles the personal
                information we collect about you. Personal information includes
                your name, email address and credit card detail that you provide
                when you try to login to our site or pay for our services.
              </Text>
              <Text>As the data processor, we promise to:</Text>
              <List el={"ul"}>
                <Text>Organization security policies</Text>
                <Text>Physical and environmental security</Text>
                <Text>Operational security processes</Text>
                <Text>Infrastructure Security</Text>
                <Text>Identity and access control</Text>
                <Text>Systems development and maintenance</Text>
                <Text>Vendor Management</Text>
                <Text>Regularly working with third-party security experts</Text>
              </List>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Third Party Systems</Heading>
            <div>
              <Text>
                Easei uses a couple of third-party providers to give you the
                sublime services.
              </Text>
              <List el={"ul"}>
                <Text>
                  Stripe:{" "}
                  <a
                    href="http://stripe.com/en-gb-us/privacy"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    stripe.com/en-gb-us/privacy
                  </a>
                </Text>
                <Text>
                  Paystack:{" "}
                  <a
                    href="http://paystack.com/terms"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    paystack.com/terms
                  </a>
                </Text>
              </List>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Exercise your GDPR rights</Heading>
            <div>
              <Text>
                You are very important to us, the reason why we take the
                security of your information provided to us by you and our
                third-party service providers, very seriously. We store personal
                information in our secured server managed by us and our service
                providers. The security measures include firewalls, data
                encryption, and information access authorization controls.
              </Text>
              <Formik
                initialValues={defaultFormField}
                validationSchema={validator}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  handleSubmit(values, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => {
                  return (
                    <Form>
                      <FormContainer>
                        <FormInput
                          label={"Full Name"}
                          type="text"
                          name="fullName"
                          placeholder="Your Full name"
                          style={{ backgroundColor: "white" }}
                          value={values["fullName"]}
                          onChange={(value) =>
                            setFieldValue("fullName", value.value)
                          }
                        />
                        <FormInput
                          label={"Email Address"}
                          type="email"
                          name="email"
                          placeholder="Your email address"
                          style={{ backgroundColor: "white" }}
                          value={values["email"]}
                          onChange={(value) =>
                            setFieldValue("email", value.value)
                          }
                        />
                      </FormContainer>
                      <Text>
                        What do you want to get done? <Span color="red">*</Span>
                      </Text>
                      <div className="radio__group">
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want Easei to delete my personal data"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want Easei to delete my personal data"
                              );
                            }}
                            checked={
                              request ===
                              "I want Easei to delete my personal data"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want Easei to delete my personal data
                          </Span>
                        </div>
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want Easei to stop using my personal data for marketing"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want Easei to stop using my personal data for marketing"
                              );
                            }}
                            checked={
                              request ===
                              "I want Easei to stop using my personal data for marketing"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want Easei to stop using my personal data for
                            marketing
                          </Span>
                        </div>
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want Easei to transfer my data to me or a third party"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            onChange={() => {
                              setRequest(
                                "I want Easei to transfer my data to me or a third party"
                              );
                            }}
                            id=""
                            checked={
                              request ===
                              "I want Easei to transfer my data to me or a third party"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want Easei to transfer my data to me or a third
                            party
                          </Span>
                        </div>
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want to rectify incorrect data Easei has about me"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want to rectify incorrect data Easei has about me"
                              );
                            }}
                            checked={
                              request ===
                              "I want to rectify incorrect data Easei has about me"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want to rectify incorrect data Easei has about me
                          </Span>
                        </div>

                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want Easei to keep my data, but stop processing it"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want Easei to keep my data, but stop processing it"
                              );
                            }}
                            checked={
                              request ===
                              "I want Easei to keep my data, but stop processing it"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want Easei to keep my data, but stop processing it
                          </Span>
                        </div>
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want to object to the way Easei is using my personal information"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want to object to the way Easei is using my personal information"
                              );
                            }}
                            checked={
                              request ===
                              "I want to object to the way Easei is using my personal information"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want to object to the way Easei is using my
                            personal information
                          </Span>
                        </div>
                        <div
                          onClick={(e) =>
                            setRequest(
                              "I want to know how Easei is using my personal information"
                            )
                          }
                        >
                          <input
                            type="radio"
                            name="gdpr"
                            id=""
                            onChange={() => {
                              setRequest(
                                "I want to know how Easei is using my personal information"
                              );
                            }}
                            checked={
                              request ===
                              "I want to know how Easei is using my personal information"
                            }
                          />
                          <Span style={{ marginLeft: "16px" }}>
                            I want to know how Easei is using my personal
                            information
                          </Span>
                        </div>
                      </div>
                      <Button
                        type="submit"
                        isLoading={isSubmitting}
                        style={{ marginTop: "20px" }}
                      >
                        Submit
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default GDPR;
