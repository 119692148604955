import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "RecurringInvoice";

export const initialState = {
    recurringInvoices: [],
    totalCount: 0,
    recurringInvoice :{},
    loading: false,
    activeCount: 0,
    inActiveCount: 0,
    pageCount: 1,
  };

  const createExternal = createAsyncThunk(`${entity}/external`, async (input) => {
    const res = await api.recurringInvoice.createExternal(input);
    return res.data;
  });
  
  const createInternal = createAsyncThunk(`${entity}/internal`, async (input) => {
    const res = await api.recurringInvoice.createInternal(input);
    return res.data;
  });
 
  const update = createAsyncThunk(`${entity}/update`, async (input) => {
    const res = await api.recurringInvoice.update(input);
    return res.data;
  });

  const searchRecurringInvoices = createAsyncThunk(
    `${entity}/search`,
    
    async (input) => {
      const {
        page = 1,
        pageSize = 20,
        status,
        startDate,
        endDate,
        client,
        recurType,
        organizationId
      } = input;
      const res = await api.recurringInvoice.search(
        page,
        pageSize,
        status,
        startDate,
        endDate,
        client,
        recurType,
        organizationId
      );
      return res.data;
    }
  );

  const updateStatus = createAsyncThunk(
    `${entity}/update-status`,
    async (input) => {
      const {
        id,
        status
      } = input;
      const res = await api.recurringInvoice.updateStatus(
        id,
        status
      );
      return res.data;
    }
  );

  const getDetails = createAsyncThunk(`${entity}/getDetails`, async (id) => {
    const res = await api.recurringInvoice.getById(id);
    return res.data;
  });

  const recurringInvoiceSlice = createSlice({
    name: entity,
    initialState,
    extraReducers: (builder) => {
      builder
        .addCase(searchRecurringInvoices.fulfilled, (state, action) => {
            state.recurringInvoices = action.payload?.data?.data;
            state.activeCount = action.payload?.data?.activeCount;
            state.inActiveCount = action.payload?.data?.inActiveCount;
            state.pageCount = action.payload?.data?.pageCount;
            state.totalCount = action.payload?.data?.totalCount;
            state.loading = false;
        })
        .addCase(searchRecurringInvoices.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(searchRecurringInvoices.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(getDetails.fulfilled, (state, action) => {
          state.recurringInvoice = action.payload.data;
          state.loading = false;
        })
        .addCase(getDetails.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(getDetails.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
      .addCase(createExternal.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(createExternal.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createExternal.rejected, (state) => {
        return { ...state, loading: false, };
      });
      builder
      .addCase(createInternal.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(createInternal.pending, (state) => {
        return { ...state, loading: true};
      })
      .addCase(createInternal.rejected, (state) => {
        return { ...state, loading: false };
      });
      builder
      .addCase(update.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(update.pending, (state) => {
        return { ...state, loading: true};
      })
      .addCase(update.rejected, (state) => {
        return { ...state, loading: false };
      });
    },
  });

  
export const recurringInvoiceActions = 
{
...recurringInvoiceSlice.actions,
  searchRecurringInvoices,
  updateStatus,
  getDetails,
  createExternal,
  createInternal,
  update
};

export const recurringInvoiceSelectors = {
  isLoading: (state) => state.recurringInvoices.loading,
  recurringInvoices: (state) => state.recurringInvoices.recurringInvoices,
  recurringInvoice: (state) => state.recurringInvoices.recurringInvoice,
  activeCount: (state) => state.recurringInvoices.activeCount,
  inActiveCount: (state) => state.recurringInvoices.inActiveCount,
  pageCount: (state) => state.recurringInvoices.pageCount,
  totalCount: (state) => state.recurringInvoices.totalCount
};

export default recurringInvoiceSlice.reducer;