import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useOnClickOutside } from "hooks";
import { authSelectors } from "store/reducers/auth/authSlice";
import { createOptionsObject } from "utils/helpers/createOptionsObject/createOptionsObject";
import DropdownComponent from "app/components/molecules/drop-down/drop-down.component";

const OrganizationSearch = ({ setOrganization, ...props }) => {
  const organizations = useSelector(authSelectors.organizations);
  const { visible, setVisible, ref } = useOnClickOutside(false);
  const [selectedLabel, setSelectedLabel] = useState("");

  const organizationOptions = useMemo(
    () => createOptionsObject(organizations || [], "id", "businessName"),
    [organizations]
  );

  const clearOrganization = (e) => {
    setOrganization("");
    setSelectedLabel("");
    e.stopPropagation();
  };

  const toggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };
  
  return (
    <div ref={ref}>
          <DropdownComponent
          visible={visible}
          toggleVisible={toggleVisible}
          options={organizationOptions || []}
          selectName={"Organization"}
          onSelect={(value) => {
              setOrganization(value);
              setSelectedLabel(organizationOptions.find((option) => option.value === value)?.label || "");
            }}
            label={selectedLabel}
            clearSelection={clearOrganization}
            {...props} 
          />
    </div>
  );
};

export default React.memo(OrganizationSearch);
