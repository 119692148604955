import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "payment";

export const initialState = {
    loading: false,
    receiptId: ''
};

const initiateInvoicePayment = createAsyncThunk(`${entity}/initiateInvoicePayment`, async (input) => {
    const res = await api.payment.initiateInvoicePayment(input)
    return res.data;
  });

const updateInvoicePaymentStatus = createAsyncThunk(`${entity}/updateInvoicePaymentStatus`, async (input) => {
    const res = await api.payment.UpdateInvoicePaymentStatus(input)
    return res.data.data;
  });

const paymentSlice = createSlice({
    name: entity,
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(initiateInvoicePayment.fulfilled, (state, action) => {  
            return { ...state, loading: false, ...action.payload };
        })
        .addCase(initiateInvoicePayment.pending, (state) => {
            return { ...state, loading: true };
        })
        .addCase(initiateInvoicePayment.rejected, (state) => {
            return {...state,  loading: false  };
        });
        builder
        .addCase(updateInvoicePaymentStatus.fulfilled, (state, action) => {  
            return { ...state, loading: false, ...action.payload };
        })
        .addCase(updateInvoicePaymentStatus.pending, (state) => {
            return { ...state, loading: true };
        })
        .addCase(updateInvoicePaymentStatus.rejected, (state) => {
            return {...state,  loading: false  };
        });
    }
});

export const paymentActions = 
  {
  ...paymentSlice.actions,
    initiateInvoicePayment,
    updateInvoicePaymentStatus
  };
  
  export const paymentSelectors = {
    isLoading: (state) => state.payment.loading,
    receiptId: (state) => state.payment.receiptId
  };
  
  export default paymentSlice.reducer;