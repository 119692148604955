import { Heading } from "../typography";
import "./loading-dots.styles.css";

const LoadingDots = ({text}) => {
  return (
    <div className="loading-dots">
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
        <Heading>{text}</Heading>
      </div>
      <div className="dot"></div>
    </div>
  );
};

export default LoadingDots;
