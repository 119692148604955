import CustomModal from "app/components/atoms/modal/modal.component";
import Text from "app/components/atoms/typography";
import { useEffect } from "react";
import { INVOICE_ACTIONS } from "utils/constants/constants.utils";
import {
  invoiceActions,
  invoiceSelectors,
} from "store/reducers/invoice/invoiceSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { useDispatch } from "react-redux";
import { getInvoiceStatusValue } from "utils/helpers/getInvoiceStatusValue/getInvoiceStatusValue";
import { useParams } from "react-router-dom";
import { TextArea } from "app/components/atoms/textArea/textArea";
import { InvertedStatusButton, StatusButton } from "app/components/atoms/button/button.styles";
import { TitleContainer } from "app/components/atoms/divs/div.styles";

const UpdateStatus = ({ visible, setVisible, status }) => {
  const { updateStatus } = invoiceActions;
  const { handleApiResponse } = useHandleApiResponse();
  const { isLoading } = invoiceSelectors;
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {}, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      invoiceId: params.id,
      status: getInvoiceStatusValue(status),
      comment: "",
    },

    validationSchema: Yup.object({
      comment:
        status === INVOICE_ACTIONS.REQUEST_CHANGE
          ? Yup.string().required()
          : null,
    }),

    onSubmit(values) {
      dispatch(updateStatus(values)).then((res) => {
        handleApiResponse(res, "/invoices");
      });
    },
  });

  return (
    <CustomModal isOpen={visible} close={setVisible} className="modal modal-del overflow">
      <form
        style={{ marginTop: "65px" }}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <TitleContainer>
          <Text size={20} weight={700} color="#0148DE">
            {status === INVOICE_ACTIONS.REQUEST_CHANGE
              ? `Are you sure you want to ${status} on this Invoice?`
              : `Are you sure you want to ${status} this Invoice?`}
          </Text>
        </TitleContainer>
        {status === INVOICE_ACTIONS.REQUEST_CHANGE && (
          <TextArea
            id="comment"
            placeholder="Reason"
            className={formik.errors.comment ? "error" : null}
            onChange={formik.handleChange}
          />
        )}
        <div
          style={{
            display: "flex",
            marginTop: "25px",
            justifyContent: "space-between",
            padding: "10px 0",
          }}
        >
          <InvertedStatusButton
            marginRight="15px"
            disabled={isLoading}
            onClick={() => setVisible(false)}
            as="div"
          >
            NO
          </InvertedStatusButton>
          <StatusButton disabled={isLoading} type="submit">
            YES
          </StatusButton>
        </div>
      </form>
    </CustomModal>
  );
};

export default UpdateStatus;