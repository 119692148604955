import { useNavigate } from "react-router-dom";
import { ReactComponent as MyLine } from "app/assets/svg/line.svg";
import Text, { Heading, Span } from "app/components/atoms/typography";
import { InvertedButton } from "app/components/atoms/button/button.styles";
import { ReactComponent as BannerImage } from "app/assets/svg/BannerImage.svg";
import { CreateAccountAdSection } from "./banner.styles";
import { ROUTES } from "utils/constants/constants.utils";
import { useViewport } from "hooks";

const Banner = () => {
  let navigate = useNavigate();

  const createAccountHandler = () => {
    navigate(ROUTES.SIGN_UP.CLIENT_USER);
  };
  const { mobile } = useViewport();

  return (
    <CreateAccountAdSection>
      <div className="container" name='Home'>
        <div >
          <Heading size={mobile ? 24 : 42}>
            Easei Way to
            <Span color="var(--accent)">Create & Manage your Invoices</Span>
          </Heading>
          <MyLine />
          {mobile ? 
            <Text size={ 12} maxWidth={"274px"} style={{margin:'0 auto'}}>Professional and easy-to-use invoicing software
            for business owners, clients and vendors.</Text> 
            : 
            <Text size={18}>
              All your invoices and receipting processes in one place
            </Text>
         } 
          <div className="banner-signup-button">
            <InvertedButton onClick={createAccountHandler}>
              Get Started
            </InvertedButton>
          </div>
        </div>
        <BannerImage width="100%" className="image" />
      </div>
    </CreateAccountAdSection>
  );
};

export default Banner;
