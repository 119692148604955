const { MONTH_NAMES, SHORT_MONTH_NAMES } = require("utils/constants/constants.utils");

export const getMonthInfo = (inputDate = new Date()) => {
    const month = inputDate.getMonth();
    return {
      monthName: MONTH_NAMES[month],
      shortMonthName: SHORT_MONTH_NAMES[month]
      };
  };

  export const addHoursToDate = (date, hoursToAdd) => {
    const millisecondsToAdd = hoursToAdd * 60 * 60 * 1000;
    const newTimestamp = date.getTime() + millisecondsToAdd;
    return new Date(newTimestamp);
  };
  
  export const getFirstAndLastDayOfNextMonth = (currentDate) => {
  
    const firstDayNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    if (firstDayNextMonth.getMonth() === 12) {
        firstDayNextMonth.setFullYear(currentDate.getFullYear());
        firstDayNextMonth.setMonth(0);
    }
  
    const lastDayNextMonth = new Date(firstDayNextMonth.getFullYear(), firstDayNextMonth.getMonth() + 1, 0);
  
    return {
        firstDay: firstDayNextMonth,
        lastDay: lastDayNextMonth
    };
  };
  
  export const getFirstAndLastDayOfPreviousMonth = (currentDate) => {
    const firstDayPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    if (firstDayPrevMonth.getMonth() === -1) {
        firstDayPrevMonth.setFullYear(currentDate.getFullYear() - 1);
        firstDayPrevMonth.setMonth(11);
    }
  
    const lastDayPrevMonth = new Date(firstDayPrevMonth.getFullYear(), firstDayPrevMonth.getMonth() + 1, 0);
  
    return {
        firstDay: firstDayPrevMonth,
        lastDay: lastDayPrevMonth
    };
  };

  export const getFirstAndLastDayOfCurrentMonth = (currentDate) => {
    const firstDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    return {
        firstDay: firstDayCurrentMonth,
        lastDay: lastDayCurrentMonth
    };
};
