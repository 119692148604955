import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "InvoiceReminder";

export const initialState = {
  data: [],
  loading: false,
};

const search = createAsyncThunk(`${entity}/search`, async (input) => {
  const { startDate, endDate } = input;
  const res = await api.invoiceReminder.search(startDate, endDate);
  return res.data;
});

const updateStatus = createAsyncThunk(
  `${entity}/update-status`,
  async (input) => {
    const { id, status } = input;
    const res = await api.invoiceReminder.updateStatus(id, status);
    return res.data;
  }
);

const create = createAsyncThunk(`${entity}/create`, async (input) => {
  const res = await api.invoiceReminder.create(input);
  return res.data;
});

const update = createAsyncThunk(`${entity}/update`, async (input) => {
  const res = await api.invoiceReminder.update(input);
  return res.data;
});

const invoiceReminderSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(search.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(search.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(search.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });

    builder
      .addCase(create.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(create.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(create.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(update.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(update.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(update.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const invoiceReminderActions = {
  ...invoiceReminderSlice.actions,
  search,
  updateStatus,
  create,
  update,
};

export const invoiceReminderSelectors = {
  isLoading: (state) => state.invoiceReminder.loading,
  invoiceReminders: (state) => state.invoiceReminder.data,
};

export default invoiceReminderSlice.reducer;
