import SocialMediaCard from "app/components/atoms/social-media-card/social-media-card.component";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { ReactComponent as MicrosoftSvg } from "app/assets/svg/icons8-microsoft.svg";
import styled from "styled-components";
import { useGoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { authActions } from "store/reducers/auth/authSlice";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { ROUTES } from "utils/constants/constants.utils";

const SMCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const FacebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const SocialMediaPanel = () => {
  const dispatch = useDispatch();
  const { handleApiResponse } = useHandleApiResponse();
  const { instance } = useMsal();
  const { googleSignIn, facebookSignIn, microsoftSignIn } = authActions;

  const responseFacebook = (err, data) => {
    try {
      dispatch(facebookSignIn({ accessToken: data.accessToken }))
      .then((res) => {
        handleApiResponse(res, ROUTES.DASHBOARD);
      });
    } catch (error) {
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(googleSignIn({ accessToken: tokenResponse.access_token }))
      .then((res) => {
        handleApiResponse(res, ROUTES.DASHBOARD);
      });
    }});

  const microsoftHandler = () => {
    instance.loginPopup({scopes: ["User.Read"]})
    .then((res)=>{
      dispatch(microsoftSignIn({ idToken: res.idToken, accessToken: res.accessToken }))
      .then((res) => {
        handleApiResponse(res, ROUTES.DASHBOARD);
      });
    });
  };

  return (
    <SMCardContainer>

      <SocialMediaCard 
      logo={<FcGoogle />} 
      onClick={() => login()}
      />

      <FacebookLogin
        appId={FacebookAppId}
        render={(renderProps) => (
          <SocialMediaCard
            logo={<BsFacebook />}
            onClick={renderProps.onClick}
            color={"#1877F2"}
          />
        )}
        onClick={responseFacebook}
        callback={responseFacebook}
        icon={<BsFacebook />}
      />

        <SocialMediaCard
            logo={<MicrosoftSvg />}
            onClick={microsoftHandler}
            color={"#1877F2"}
          />

    </SMCardContainer>
  );
};

export default SocialMediaPanel;