export const unitType = [
    {
      id: 0,
      name: "Quantity",
    },
    {
      id: 1,
      name: "Duration",
    }
  ];

  export const timeUnits = [
    {
      id: 1,
      name: "Minute(s)",
    },
    {
      id: 2,
      name: "Hour(s)",
    },
    {
      id: 3,
      name: "Day(s)",
    },
    {
      id: 4,
      name: "Month(s)",
    }
  ];