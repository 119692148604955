export const getRandomColor = () => {
  const min = 80; // Minimum value for each color channel (R, G, B)
  const max = 175; // Maximum value for each color channel (R, G, B)
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 3; i++) {
    const channelValue = Math.floor(Math.random() * (max - min + 1)) + min;
    color += letters[Math.floor(channelValue / 16)]; // Convert to hexadecimal digit
    color += letters[channelValue % 16];
  }

  return color;
};
