import Text from "../atoms/typography";

const ChartLabel = ({ color, invoice }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <div
        style={{
          width: "16px",
          height: "16px",
          borderRadius: "100%",
          backgroundColor: color,
        }}
      ></div>
      <Text size={'12px'} color="var(--grey2)"  margin="0">
        {invoice}
      </Text>
    </div>
  );
};
export default ChartLabel;
