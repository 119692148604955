import { useRef, useState, useEffect} from "react";
import Table from "app/components/molecules/table/table.component";
import Card from "app/components/atoms/card/card.component";
import { 
  getBooleanStatusStyle,
  getBooleanStatusName } from "utils/helpers/createStatusStyle/createStatusStyle";
import ActionMenu from "../action-menu/action-menu.component";
import { MOBILE_WINDOW_SIZE } from "utils/constants/constants.utils";
import OrganisationMobile from "./organisation-mobileView";

const OrganizationList = ({
    organizations,
    loading,
    totalCount,
    pageCount,
    onChangePage,
    ...rest
}) =>{
  const componentRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => { setWindowWidth(window.innerWidth); }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    
  const columns = [
        {
          name: "Date Registered",
          selector: (row) => row.createdAt,
          format: (row) => new Date(row.createdAt).toLocaleDateString(),
          sortable: true,
        },
        {
          name: "Name",
          selector: (row) => row.businessName,
          sortable: true,
          grow: 1.5
        },
        {
          name: "Email",
          selector: (row) => row.businessEmail,
          grow: 2
        },
        {
            name: "Plan",
            selector: (row) => row.plan,
            sortable: true,
        },
        {
            name: "Plan Expiry",
            selector: (row) => row.planEndDate,
            format: (row) => row.planEndDate == null ? "Not specified" :
                new Date(row.planEndDate).toLocaleDateString(),
            sortable: true,
          },
        {
          name: "Status",
          selector: (row) => row.isActive,
          format: (row) => (
            <p
              style={{
                color: getBooleanStatusStyle(row.isActive),
              }}
            >
              {getBooleanStatusName(row.isActive)}
            </p>
          ),
          sortable: true,
        },
        {
          name: "Action",
          selector: (row) => (
            <ActionMenu
            popupList={["view"]}
            row={row}/>
          ),
          button: true,
        },
      ];

      return(
        <div>
            {windowWidth < MOBILE_WINDOW_SIZE &&
              <OrganisationMobile
              organizations={organizations}
              onChangePage={onChangePage}
              totalCount={totalCount}
              pageCount={pageCount}
              loading={loading}/>
             }

            {windowWidth >= MOBILE_WINDOW_SIZE &&     
        <Card> 
          <div ref={componentRef}>
            <Table
              title="Organizations"
              columns={columns}
              data={organizations}
              paginated={true}
              progressPending={loading}
              actions={<></>}
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              onChangePage={onChangePage}
              {...rest}
            />
          </div> 
           </Card>
          }
      
      </div>
    );
}

export default OrganizationList;