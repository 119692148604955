import Tab from "app/components/atoms/tab/tab.component";
import { useViewport } from "hooks";
import { NavLink } from "react-router-dom";

import styled, { css } from "styled-components";

const mainNavLabelStyle = css`
  border-bottom: 4px solid var(--primary2);
`;

const MainHorizontalNav = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ mainNav }) => (mainNav ? "1px solid #B8C6E4" : "")};
  box-shadow: ${({ mainNav }) =>
    mainNav || "0px 4px 4px rgba(38, 50, 56, 0.05)"};
  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
`;

const NavBarLink = styled(NavLink)`
  height: 80px;
  display: flex;
  flex: 1;
  align-items: center;
  text-align: start;
  text-decoration: none;
  text-justify: left;
  color: var(--white);
   @media screen and (max-width:1405px) {
      font-size: 1.2rem;
    }
  p {
    color: #7b8599;
    @media screen and (max-width:1405px) {
      font-size: 1.2rem;
    }
        @media screen and (max-width:1236px) {
      font-size: 1rem;
    }
     @media screen and (max-width:1123px) {
      font-size: .9rem;
    }
      @media screen and (max-width:699px) {
      font-size: 24px
    }
      @media screen and (max-width:480px) {
      font-size: 1rem
    }
  }
  &.active {
    ${({ mainNav }) => mainNav && mainNavLabelStyle};
    background-color: #ffffff;
    p {
      color: var(--primary2);
    }
  }
`;

const HorizontalNav = ({ menuItems, isMain, textWeight }) => {

  const {mobile} = useViewport();
  
  return (
    <MainHorizontalNav mainNav={isMain}>
      {menuItems?.map((item, index) => {
        const { url, name } = item;
        return (
          <NavBarLink size={mobile ? 2 : ""} to={url} mainNav={isMain} key={index}>
            <Tab
              text={name}
              key={index}
              textWeight={textWeight}
              padding="20px "
            />
          </NavBarLink>
        );
      })}
    </MainHorizontalNav>
  );
};

export default HorizontalNav;
