import { useSelector } from "react-redux";
import { authSelectors } from "store/reducers/auth/authSlice";
import { isAdmin, isEaseiUser } from "store/reducers/auth/utils";

export const useCheckAdmin = () => {
  const role = useSelector(authSelectors.userRole);
  const isPlanActive = useSelector(authSelectors.isPlanActive);
  const allowedFeatures = useSelector(authSelectors.allowedFeatures);
  return { isAdmin: isAdmin(role), role , isEaseiUser: isEaseiUser(role),
    isPlanActive: isPlanActive, allowedFeatures: allowedFeatures};
};
