import styled from "styled-components";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const VisibilityIcon = ({ onClick, showPassword }) => {
  return (
    <Icon onClick={onClick}>
      {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
    </Icon>
  );
};

export default VisibilityIcon;

const Icon = styled.div`
  z-index: 100;
  position: absolute;
  left:90%;
  top: 1rem;
  cursor: pointer;
`;