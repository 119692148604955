export const convertCurrencyToSign = (currencyCode) => {
    const currencySigns = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        JPY: '¥',
        CNY: '¥',
        CAD: '$',
        AUD: '$',
        CHF: 'Fr',
        HKD: '$',
        SEK: 'kr',
        NZD: '$',
        MXN: '$',
        SGD: '$',
        NOK: 'kr',
        KRW: '₩',
        TRY: '₺',
        RUB: '₽',
        INR: '₹',
        BRL: 'R$',
        ZAR: 'R',
        TWD: 'NT$',
        AED: 'د.إ',
        SAR: 'ر.س',
        PLN: 'zł',
        THB: '฿',
        IDR: 'Rp',
        DKK: 'kr',
        MYR: 'RM',
        HUF: 'Ft',
        IQD: 'ع.د',
        CLP: '$',
        PHP: '₱',
        COP: '$',
        VND: '₫',
        BHD: '.د.ب',
        OMR: 'ر.ع.',
        QAR: 'ر.ق',
        KWD: 'د.ك',
        CRC: '₡',
        LKR: 'රු',
        NGN: '₦',
        JMD: '$',
        BBD: '$',
        TZS: 'TSh',
        GHS: '₵',
        UGX: 'USh',
        XOF: 'CFA',
        XAF: 'FCFA',
        KES: 'KSh'
      };
      
      
    return currencySigns[currencyCode] || currencyCode;
  };
  