import AgreementCheckbox from "app/components/atoms/agreement-checkbox/agreement-checkbox.component";
import Button from "app/components/atoms/button/button.component";
import Text from "app/components/atoms/typography";
import { useState } from "react";
import styled from "styled-components";
import SocialMediaPanel from "../social-media-panel/social-media-panel.component";

const SignupPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .signup-panel-agreement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    p {
      font-size: 14px;
    }
  }

  .signup-panel-social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 20px 0;
    width: 100%;

    p {
      margin: 0;
    }

    .divider {
      border-bottom: 1px solid var(--primary5);
      flex: 1;
    }

    @media screen and (min-width: 650px) {
      .divider {
        border-bottom: 1px solid var(--primary5);
      }
    }
  }

  .signup-panel-social-media-button-container {
    display: flex;
    justify-content: center;
  }
`;

const SignupPanel = ({ isSubmitting, showSocialMedia = true}) => {

  const [aggrementAccepted, setAgreementAccepted] = useState(false);

  const agreementProp = {
    name: "acceptTerms",
    value: false,
    checked: false,
  };

  return (
    <SignupPanelWrapper>
      <AgreementCheckbox setClicked={()=>{setAgreementAccepted(!aggrementAccepted)}} fields={agreementProp}/>
      <div>
        <Button
          type="submit"
          width="100%"
          isLoading={isSubmitting}
          disabled={isSubmitting || !aggrementAccepted}
          height="44px"
        >
          Sign Up
        </Button>
      </div>
      <div className="signup-panel-social-media-container">
        <div className="divider"></div>
        <div>
          <Text>or sign up with</Text>
        </div>
        <div className="divider"></div>
      </div>
      {showSocialMedia && 
      <div className="signup-panel-social-media-button-container">
        <SocialMediaPanel />
      </div>}
    </SignupPanelWrapper>
  );
};

export default SignupPanel;
