import Text, { Heading } from "app/components/atoms/typography";
import CheckList from "app/pages/plans/plan-checkList";
import { useViewport } from "hooks";
import styled, { css } from "styled-components";

const activeCardStyle = css`
  p,
  h1,
  ul,
  li {
    color: white;
  }
  background: #002776;
`;

const PlanCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  border: 1px solid var(--primary5);
  gap: 20px;
  background: var(--grey9);
  width: 500px;
  border-radius: 8px;
    @media screen  and (max-width:450px) {
padding: 40px 10px;
  }

  /* ${({ active }) => active && activeCardStyle}; */

  .plancard-container {
 }

   .plancard-content{
    display: flex;
align-items: center;
flex-direction: column;
  @media screen  and (max-width:450px) {
    align-items: flex-start;
  } 
   li{
   margin-top:16px
   }
  }
  .plancard-content-details{
  width: 100%;
  margin: 0 auto;
  }
`;

const GettingStartedButton = styled.button`
  min-width: 200px;
  width: 100%;
  height: 48px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 0 30px 0 30px;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  background-color: var(--primary);
  color: var(--white);
  font-family: "DM Sans";
  font-weight: regular;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
margin-bottom: 32px;
  button[disabled="disabled"],
  button:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #3777ff;
    color: #f2f6ff;
    border: none;
  }
`;
const PlansList = styled.div`
  ul {
    list-style: none;
    display: table;
    list-style-position: outside;
  }

  ul > li {
    display: table-row;
  }

  li span {
    position: relative;
    left: 10px;
  }

  ul li:before {
    content: "✓";
    display: table-cell; /* aha! */
    text-align: right;
  }
`;

const PlanCard = ({ plan, ...props }) => {
  const { name, cost, description, features } = plan;
  const { mobile } = useViewport()

  return (
    <PlanCardContainer {...props}>
      <div className="plancard-container">
        <div>
          <Heading size={mobile ? 14 : 32} weight={700} >{name}</Heading>
          <Text size={mobile ? 14 : 16} margin={0}>
           {description}
          </Text>
        </div>
        <div style={{ margin: "25px 0 16px" }}>
          <Heading size={mobile ? 14 : 32}>₦ {cost}</Heading>
          <Text size={mobile ? 14 : 16} margin={0} maxWidth="268px">
            Monthly
          </Text>
        </div>

        <div>
          <GettingStartedButton>Get Started</GettingStartedButton>
        </div>
        <PlansList className="plancard-content">
          <Heading size={mobile ? 14 : 16} margin="0 0 0 ">
            Manage tasks and personal to-dos:
            </Heading>
          <div>
           {features?.map((feature =>       
            <CheckList checkList={feature.description} key={feature.id} />
            ))}
          </div>
        </PlansList>
      </div>
    </PlanCardContainer>
  );
};

export default PlanCard;
