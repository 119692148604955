import React from 'react'
import Text, { Heading } from 'app/components/atoms/typography'
import { useViewport } from 'hooks'
import { CardStyle } from '../card/card.component';

const InvoiceCardStyles = ({ icon, count, title }) => {
    const { mobile } = useViewport();

    return (
        <CardStyle border="1px solid #DBE7FF" padding="10px 10px 5px" style={{ textAlign: "inherit" }}>
            <Text margin="0" size={mobile ? 12 : ""} color="var(--grey2)" >{title}</Text>
            <div className="stat">
                <Heading size={mobile ? 16 : ""} color="var(--grey1)">{count}</Heading>
                <img src={icon} alt="" width={mobile ? "30px" : ""} />
            </div>
        </CardStyle>
    )
}

export default InvoiceCardStyles;
