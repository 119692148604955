import { useCallback, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import differenceBy from "lodash/differenceBy";
import _ from "lodash";
import Button from "app/components/atoms/button/button.component";
import {
  BaseButton,
  InvertedButton,
  InvertedStatusButton,
  StatusButton,
} from "app/components/atoms/button/button.styles";
import Table, { NoData } from "app/components/molecules/table/table.component";
import Text, { Heading } from "app/components/atoms/typography";
import { RECUR_TYPES, ROUTES } from "utils/constants/constants.utils";
import { useCheckAdmin, useViewport } from "hooks";
import {
  CurrencyActions,
  CurrencySelectors,
} from "store/reducers/currency/currencySlice";
import { getUnitTypeName } from "utils/helpers/getUnitTypeName/getUnitTypeName";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import CustomModal from "app/components/atoms/modal/modal.component";
import {
  getInvoiceTotalAmount,
  getInvoiceTotalCost,
  getInvoiceTotalTax,
} from "utils/helpers/getInvoiceCalculations/getInvoiceCalculations";
import { FlexDiv } from "app/components/atoms/divs/div.styles";
import { NewInvoiceItem } from "app/components/cell/invoiceItem/invoiceItem.component";
import ModalHouse from "app/components/molecules/modals/modalHouse";
import { InvoiceRecordSummary } from "app/components/cell/invoiceRecordSummary/InvoiceRecordSummary";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { recurringInvoiceSelectors } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import { 
    ButtonContainer, 
    CustomSelect,
    GridContainer,
    InvoiceBody,
    InvoiceDataInput, 
    Label, 
    OptionsSection, 
    InputDataHouse, 
    SubjectContainer,
    InvoiceSubjectInput
} from "app/components/atoms/generalInvoiceStyles/general.sytlyes";

//#endregion

const BaseRecurringInvoiceSetup = ({handleSave, ClientDropDown, recurringInvoice, backRoute}) => {

  const { getCurrencies } = CurrencyActions;
  const { resetPreviewRecurringInvoiceState, setHeaderText } = appDataActions;

  const [isDeleteLoading] = useState(false);
  const isLoading = useSelector(recurringInvoiceSelectors.isLoading);
  const currencies = useSelector(CurrencySelectors.currencies);
  const isCurrencyLoading = useSelector(CurrencySelectors.isLoading);

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [subject, setSubject] = useState("");
  const [approveDel, setApproveDel] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [recurType, setRecurType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [recurDate, setRecurDate] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const { role } = useCheckAdmin();
  const { mobile } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (row) => {
    setApproveDel(true);
    setDeleteItem(row.serviceName);
  };

  const handleCancel = () =>{

    dispatch(resetPreviewRecurringInvoiceState());
    navigate(ROUTES.RECURRINGINVOICE);
  }

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.serviceName
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, "serviceName"));
      }
    };
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
        height="44px"
      >
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  const columns = [
    {
      name: "Order Name",
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 2,
    },
    {
      name: "UnitType",
      selector: (row) => getUnitTypeName(row.unitType),
    },
    {
      name: "Unit",
      selector: (row) => row.itemUnit,
    },
    {
      name: "Unit Price",
      selector: (row) => row.cost,
    },
    {
      name: "Unit Tax",
      selector: (row) => row.tax,
    },
    {
      name: "Price",
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: "",
      selector: (row) => (
        <ActionMenu
          popupList={["Delete"]}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  const mobileColumns = [
    {
      name: "Order Name",
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 3,
    },

    {
      name: "Unit",
      selector: (row) => row.itemUnit,
    },
    {
      name: "Price",
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: "",
      selector: (row) => (
        <ActionMenu
          popupList={["Delete"]}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDataSave = (isPreview) =>{
    const payload = {
      startDate,
      endDate,
      recurType : recurType.value,
      recurDate,
      subject,
      services: data,
      currency: currency?.label,
      additionalInformation,
      id: recurringInvoice?.id
    };
    
    handleSave(payload, isPreview)
  }

  const handleCloseModal = (e) => {
    setShowModal(false);
  };

  useEffect(() => {

    dispatch(setHeaderText({ value: 'Recurring Invoice', type: 'setHeaderText' }));

    dispatch(getCurrencies());
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (!_.isEmpty(recurringInvoice)) {

      setCurrency(
        currencies.filter(function (item) {
          return item.label === recurringInvoice.currency;
        })[0] || null
      );

      setData(recurringInvoice.services);
      setAdditionalInformation(recurringInvoice.additionalInformation);
      setSubject(recurringInvoice.subject || '');
      setRecurType(RECUR_TYPES.Options.filter(r => r.value === recurringInvoice?.recurType)[0]);
      setRecurDate(recurringInvoice.recurDate?.split('T')[0]);
      setStartDate(recurringInvoice.startDate?.split('T')[0]);
      setEndDate(recurringInvoice.endDate?.split('T')[0]);
    }

  }, [recurringInvoice, currencies]);

  return (
    <div style={{ margin: mobile ? "38px 10px" : "38px 100px" }}>
        {
            !mobile &&
            <FlexDiv>
                <Heading>{"Recurring Invoice"}</Heading>
                <BaseButton width="true" onClick={() => _.isEmpty(backRoute) ? navigate(-1) : navigate(backRoute)}>
                    <Text color="white">Back</Text>
                </BaseButton>
            </FlexDiv>
        }
        <OptionsSection>
            <GridContainer>
                {ClientDropDown}
                <InputDataHouse>
                <Label>{RECUR_TYPES.Label}</Label>
                <CustomSelect
                    placeholder="Select RecurType"
                    options={RECUR_TYPES.Options}
                    onChange={(e) => setRecurType(e)}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="recurType"
                    value={recurType}
                />
                </InputDataHouse>
                <InputDataHouse>
                    <Label>Currency</Label>
                    <CustomSelect
                    isDisabled={isCurrencyLoading}
                    isLoading={isCurrencyLoading}
                    placeholder="Select currency"
                    options={currencies}
                    onChange={handleCurrencyChange}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="currency"
                    value={currency}
                    />
                </InputDataHouse>
                <InputDataHouse>
                <Label>Recur Date</Label>
                <InvoiceDataInput
                    type="date"
                    onChange={(e) => setRecurDate(e.target.value)}
                    name="recurDate"
                    value={recurDate}
                />   
                </InputDataHouse>
                <InputDataHouse>
                <Label>Start Date</Label>
                <InvoiceDataInput
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    name="startDate"
                    value={startDate}
                />
                </InputDataHouse>
                <InputDataHouse>
                    <Label>End Date</Label>
                    <InvoiceDataInput
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    name="endDate"
                    value={endDate}
                    />
                </InputDataHouse>
            </GridContainer>

            <SubjectContainer>
                <Label>Subject</Label> 
                <InvoiceSubjectInput
                placeholder="what is the invoice for?"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                />
            </SubjectContainer>
        </OptionsSection>

        <InvoiceBody style={{ marginTop: "50px" }}>
            <Table
            title="Invoice Details"
            className="table"
            columns={mobile ? mobileColumns : columns}
            data={data}
            persistTableHead
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            actions={<></>}
            />
            <div className="details">
            <NoData onClick={() => setShowModal(!showModal)} />
            <ModalHouse
                className="modal modal__extra"
                close={handleCloseModal}
                isOpen={showModal}
                form={<NewInvoiceItem data={data} setData={setData} />}
                title="New Invoice"
                height="100%"
            />
            {data.length > 0 && (
                <InvoiceRecordSummary
                summaryDisplayCondition={data.length !== 0}
                totalAmount={getInvoiceTotalAmount(data)}
                subTotal={getInvoiceTotalCost(data)}
                vat={getInvoiceTotalTax(data)}
                currency={currency?.label}
                comment={additionalInformation}
                setComment={setAdditionalInformation}
                role={role}
                placeholder="Enter additional details..."
                />
            )}
            </div>
        </InvoiceBody>

        <ButtonContainer>
            {data.length > 0 && (
            <>
                <InvertedButton
                onClick={()=>{ handleDataSave(true) }}
                >
                Preview
                </InvertedButton>
                <Button
                onClick={()=>{ handleDataSave(false) }}
                isLoading={isLoading}
                >
                Save
                </Button>
            </>
            )}
            <div style={{ flex: data.length > 0 ? 1 : 3 }}></div>
            <InvertedButton
            className="cancel"
            onClick={handleCancel}
            >
            Cancel
            </InvertedButton>
        </ButtonContainer>
      
      {approveDel && (
        <CustomModal
          isOpen={approveDel}
          className="modal modal-del"
          close={setApproveDel}
        >
          <form
            style={{ marginTop: "65px", textAlign: "center" }}
            onSubmit={(e) => {
              e.preventDefault();
              setData(data.filter((item) => item.serviceName !== deleteItem));
              setApproveDel(false);
            }}
          >
            <Text>Are you sure you want to remove this item?</Text>
            <div className="btn" style={{ marginBottom: "20ox" }}>
              <InvertedStatusButton
                margin="0 10px 0 0"
                disabled={isDeleteLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setApproveDel(false);
                }}
              >
                {" "}
                NO
              </InvertedStatusButton>
              <StatusButton
                isLoading={isDeleteLoading}
                disabled={isDeleteLoading}
                type="submit"
              >
                YES
              </StatusButton>
            </div>
          </form>
        </CustomModal>
      )}
    </div>
  );
};

export default BaseRecurringInvoiceSetup;