import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  ConfirmPasswordValidator,
  PasswordValidator,
} from "utils/validation/validation.utils";

import { AuthLayout } from "app/layouts";
import Button from "../../../components/atoms/button/button.component";
import FormInputTwo from "app/components/atoms/form-input-two/form-input-two.component";

import Text, { Heading } from "../../../components/atoms/typography";

import {
  ButtonContainer,
  ChildrenStyle,
} from "../../../layouts/auth.layout/auth.styles";
import styled from "styled-components";
import BackToLogin from "app/components/atoms/back-to-login/back-to-login.component";
import { useParams } from "react-router-dom";
import { authActions, authSelectors } from "store/reducers/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "utils/constants/constants.utils";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";

export const HeaderWrapper = styled.div`
  h1,
  p {
    margin: 0;
  }
  margin-bottom: 50px;
`;

export const ButtonWrapper = styled(ButtonContainer)`
  margin: 20px 0 0 0;
`;

const validationSchema = Yup.object({
  newPassword: PasswordValidator,
  confirmPassword: ConfirmPasswordValidator,
});

export const ResetPassword = ({passwordVisible}) => {

  const { email, token } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(authSelectors.isLoading);
  const { handleApiResponse } = useHandleApiResponse();
  const { resetPassword } = authActions;

  const handlePasswordReset = async (values) => {
    dispatch(resetPassword(values)).then((res) => {
      handleApiResponse(res, ROUTES.SIGN_IN);
    });
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={{
          email: email,
          token: token,
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlePasswordReset(values);
        }}
      >
        {() => (
          <>
            <HeaderWrapper>
              <Heading
                size={28}
                weight={700}
                level={1}
                text="Reset Password?"
              />
              <Text
                size={16}
                weight={400}
                color="var(--grey1)"
                text="Your new password must be different from the previous one."
              />
            </HeaderWrapper>
            <Form>
              <ChildrenStyle>
                <FormInputTwo
                  label="New Password"
                  type={passwordVisible ? 'text' : 'password'}
                  name="newPassword"
                  required
                />
                <FormInputTwo
                  label="Confirm Password"
                  type={passwordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  required
                />
              </ChildrenStyle>
              <ButtonWrapper>
                <Button
                  type="submit"
                  width="100%"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Update and Proceed
                </Button>
              </ButtonWrapper>
              <BackToLogin />
            </Form>
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};
