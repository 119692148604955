import React from "react";
import { DropDownContainerStyle } from "app/components/atoms/dropdown/dropdow.styles";
import { GrClear } from "react-icons/gr";
import InputCard from "app/components/atoms/input-card/input-card.component";
import { RiArrowDropDownLine } from "react-icons/ri";

const iconStyle = {
  width: "20px",
  height: "20px",
};

const DropdownComponent = ({ options, onSelect, label, clearSelection, selectName, toggleVisible, visible, ...props }) => {
  
    return (
    <InputCard {...props} onClick={toggleVisible}>
    <div style={{ display: "flex", alignItems: "center" }}>
        {label && (<GrClear style={iconStyle} onClick={clearSelection} />)}
        {label ? <span style={{ marginLeft: "10px" }}> {label} </span> : selectName}
    </div>
    {visible && (
        <DropDownContainerStyle>
        <InputCard className="select">
          <DropDownContainerStyle>
            {options?.map(({ value, label }) => (
              <div
                className="content"
                key={value}
                onClick={() => {
                  onSelect(value);
                }}
              >
                {label}
              </div>
            ))}
          </DropDownContainerStyle>
        </InputCard>
      </DropDownContainerStyle>
      )}
      <RiArrowDropDownLine style={{ marginLeft: "20px" }} color="var(--grey2)" />
    </InputCard>
   
  );
};

export default DropdownComponent;
