import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import _ from "lodash";
import { invoiceActions, invoiceSelectors } from "store/reducers/invoice/invoiceSlice";
import OrganizationSelectDropdown from "app/components/molecules/organization/organization-dropdown";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate, useParams } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import BaseEditInvoice from "./base-edit-invoice.component";

const EditInternalInvoice = () => {
  const { update } = invoiceActions;
  const { resetPreviewState, setPreviewInvoiceState } = appDataActions;

  const [organization, setOrganization] = useState();
  const navigate = useNavigate();
  const { handleApiResponse } = useHandleApiResponse();
  
  const { id } = useParams();
  const { getDetails } = invoiceActions;
  const { setHeaderText } = appDataActions;

  const dispatch = useDispatch();
  const invoice = useSelector(invoiceSelectors.invoice);
  const previewInvoice = useSelector(appdataSelector.previewInvoice);

  useEffect(() => {

    if(id !== ROUTES.PREVIEW_ROUTE){
      dispatch(getDetails(id)).then((res)=>{
        dispatch(setHeaderText({value: res.payload.invoiceNo, type: 'setHeaderText'}));
      });
    }   
      // eslint-disable-next-line
    }, [id]);

  const handleSave = (data, isPreview) =>{
    let payload = {
        organizationId: organization.value,
        type: INVOICE_TYPE.Internal,
        ...data
    }

    if(isPreview){
        dispatch(setPreviewInvoiceState(payload));
        navigate(ROUTES.PREVIEW_INVOICE)
    }
    else{
      dispatch(update(payload)).then((res)=>{
        dispatch(resetPreviewState());
        handleApiResponse(res, '/invoices');
      })
    }
  }

  return (
      <BaseEditInvoice
        invoice={_.isEmpty(previewInvoice) ? invoice : previewInvoice}
        backRoute={_.isEmpty(previewInvoice) ? '' : `${ROUTES.EDIT_INTERNAL_INVOICE_ROUTE_HEAD}/${id}`}
        ClientDropDown={
          <OrganizationSelectDropdown
            invoice={_.isEmpty(previewInvoice) ? invoice : previewInvoice }
            organization={organization}
            isDisabled={true}
            setOrganization={setOrganization}
          />
        }
        handleSave={handleSave}
      />
  );
};
export default EditInternalInvoice;