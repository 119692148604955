import OrganizationList from "app/components/molecules/organization/organization-list";
import OrganizationSearch from "app/components/molecules/organization/organization-search";
import React , { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text, { Heading } from "app/components/atoms/typography";
import { OrganizationActions, organizationSelectors } from "store/reducers/organization/organizationSlice";
import { OrganizationTopBanner } from "./organization.styles";
import Patnership from "./organization-merchant-patnership";
import OrganizationContext from "store/context/organizationContext";
import { useViewport } from "hooks";

const defaultState = {
    isActive: null,
    planId: null,
    merchantId:null,
    activePlan: null,
    invoiceFinancing: null
  };

const Organization = () =>{

    const [searchParams, SetSearchParams] = useState(defaultState);
    const [isSearch, setIsSearch] = useState(false);
    const [isActive, setIsActive] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [activePlan, setActivePlan] = useState(null);
    const [name, setName] = useState(null);
    const [visible, setVisible] = useState(false);
    const [organization] = useState(null);
    const [pageSize, setPageSize] = useState(10);

    const {mobile} = useViewport();
    const dispatch = useDispatch();
  
    const { searchOrganizations } = OrganizationActions;
    const organizations = useSelector(organizationSelectors.organizations);
    const isLoading = useSelector(organizationSelectors.isLoading);
    const totalCount = useSelector(organizationSelectors.totalCount);
    const pageCount = useSelector(organizationSelectors.pageCount);

    // #region Handles

    const handleChange = (name, value) => {
        SetSearchParams({ ...searchParams, [name]: value });
      setIsSearch(!isSearch);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
      dispatch(searchOrganizations({...searchParams, page: page, pageSize: newPerPage }));
      setPageSize(newPerPage);
      handleChange();
    };

    const handlePageChange = (page) => {
      dispatch(searchOrganizations({...searchParams, page: page, pageSize: pageSize }));
    };

// #endregion

   // #region UseEffects

  useEffect(() => {
    handleChange("isActive", isActive)
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    handleChange("planId", planId)
  }, [planId]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    handleChange("activePlan", activePlan)
  }, [activePlan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleChange("name", name)
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(searchOrganizations(searchParams));
  }, [isSearch, visible]); // eslint-disable-line react-hooks/exhaustive-deps

  // #endregion

  return (
        <>
        <OrganizationContext.Provider value={organization}>
          {mobile ? 
          <>
            <div style={{margin:"20px 0 0 20px"}}>
              <div>
                <Heading size={18} color="#002776">Organizations</Heading>
                <Text size={12}>Easei is an organization driven application.</Text>
              </div>
            </div>
          </>
          : 
            <OrganizationTopBanner>
                <div className="dashboard-container organization__new">
                        <div>
                            <Heading color="#002776">Organizations</Heading>
                            <Text>Easei is an organization driven application.</Text>
                        </div>
                  </div>
                <Patnership visible={visible} setVisible={setVisible} />
            </OrganizationTopBanner>}
            
            <OrganizationSearch
            activePlan={activePlan}
            isActive={isActive}
            planId={planId}
            setActivePlan={setActivePlan}
            setIsActive={setIsActive}
            setName={setName}
            setPlanId={setPlanId}
            />

            <OrganizationList
            organizations={organizations}
            loading={isLoading}
            totalCount={totalCount}
            pageCount={pageCount}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            />
            </OrganizationContext.Provider>
        </>
  );
}

export default Organization;