import Text, { Heading } from "app/components/atoms/typography";

import { GrGroup } from "react-icons/gr";
import { AiFillIdcard } from "react-icons/ai";
import { HiBriefcase } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import { useViewport } from "hooks";
import styled from "styled-components";

const OrganizationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  border: 1px solid #dbe7ff;
  border-radius: 8px;
  width: 100%;
  height: 240px;
  padding: 2rem;
  gap: 20px;
  background: ${({ color3 }) => color3 || ""};

  .organization-card-group-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      margin: 0;
    }
  }
  .organization-card-group-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: double 1px transparent;
    border-radius: 50%;
    background-image: ${({ color1, color2, color3 }) =>
      color1 && color2 && color3
        ? `linear-gradient(${color3}, ${color3}), radial-gradient(circle at top left, ${color1}, ${color2})`
        : "linear-gradient(white, white),radial-gradient(circle at top left, #002776, #feba27)"};
    background-origin: border-box;
    background-clip: content-box, border-box;
    width: 64px;
    height: 64px;
    svg {
      font-size: 24px;
    } 
   }
  @media screen and (max-width: 375px) {
    padding:1rem;
       .organization-card-group-icon {
        width: 50px;
    height: 50px;
    svg{
      font-size:18px;
    }
  }
  }



`;

const BusinessTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 22px;
  gap: 20px;
  margin-top:-50px ;
   @media screen and (min-width: 768px) {
 margin-top:-200px ;
  }

  .business-type-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    p {
      text-align: center;
      max-width: 374px;
    }

    div {
      padding: 0 20px;
      text-align: center;
    }
  }

  .business-type-org-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  @media screen and (min-width: 950px) {
    padding: 80px 0;
       gap: 20px;

    .business-type-org-card-container {
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
    }

    .business-type-text-container {

      div {
        padding: 0 220px;

        h1 {
          max-width: 726px;
        }

        p {
          max-width: 557px;
        }
      }
    }
  }
`;

const contents = [
  {
    icon: <GrGroup color="#7524C7" />,
    name: "Organizations",
    description: "Invoicing Software for enterpreneurs",
    color1: "#CD9AFF",
    color2: "#D494EB",
    color3: "#F0E1FF",
  },
  {
    icon: <AiFillIdcard color="FEC047" />,
    name: "Freelancers",
    description: "Invoicing Software for enterpreneurs",
    color1: "#FEC047",
    color2: "#C2A438",
    color3: "#FEEECF",
  },
  {
    icon: <HiBriefcase color="#FF9999" />,
    name: "Contractors",
    description: "Invoicing Software for enterpreneurs",
    color1: "#FF9999",
    color2: "#FEC7C7",
    color3: "#FFE2E2",
  },
  {
    icon: <BsFillPersonFill color="#2BAFFC" />,
    name: "Self-Employed",
    description: "Invoicing Software for enterpreneurs",
    color1: "#2BAFFC",
    color2: "#3BB6FD",
    color3: "#D9F1FF",
  },
];

const OrganizationCard = ({ org }) => {
  const { name, description, icon, color1, color2, color3 } = org;
const {mobile} = useViewport()
  return (
    <OrganizationCardContainer color1={color1} color2={color2} color3={color3}>
      <div className="organization-card-group-icon">{icon}</div>
      <div className="organization-card-group-text">
        <Text
          weight={700}>{name}</Text>
        <Text size={mobile ? 10.102 : 14}>{description}</Text>
      </div>
    </OrganizationCardContainer>
  );
};

const BusinessType = () => {
  const { mobile } = useViewport();
  return (
    <BusinessTypeContainer className="container">
      <div className="business-type-text-container">
        <Heading size={mobile ? 20 : 40} textAlign="center"
          data-aos="fade-right"
          >
          Get support for your type of business
        </Heading>
       
          <Text size={mobile ? 16:20}>
            Professional and easy-to-use invoicing software for business owners,
            clients and vendors.
          </Text>
   
      </div>
      <div className="business-type-org-card-container">
        {contents?.map((org, index) => (
          <OrganizationCard key={index} org={org} />
        ))}
      </div>
    </BusinessTypeContainer>
  );
};

export default BusinessType;
