import styled from "styled-components";

import { ReactComponent as EmailIcon } from "app/assets/svg/Email-Icon.svg";
import Text, { Heading } from "app/components/atoms/typography";
import Button from "app/components/atoms/button/button.component";

const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--white);

  p,
  h1 {
    text-align: center;
  }

  svg {
    width: 100%;
    margin-bottom: 20px;
  }

  span {
    cursor: pointer;
    color: var(--primary);
  }

  .verification-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .verification-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  @media screen and (max-width: 375px) {

    h1 {
        font-size: 28px;
    }
    button {
        width: 100%;
        padding: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    button {
        width: 50%;
    }
  }
`;

const Verify = ({
  title,
  message,
  buttonText,
  question,
  response,
  subResponse,
  handleClick
}) => {
  return (
    <VerifyContainer className="container">
      <EmailIcon />
      <div className="verification-text-container">
        <Heading>{title}</Heading>
        <Text>{message}</Text>
      </div>
      <div className="verification-button-container">
        <Button onClick={handleClick}>{buttonText}</Button>
      </div>
      <div>
        <Text>
          {question} <span>{response}</span>
        </Text>
        {subResponse && <Text>{subResponse}</Text>}
      </div>
    </VerifyContainer>
  );
};

export default Verify;
