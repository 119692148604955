import Text from 'app/components/atoms/typography'
import React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { CheckListItem } from './plan.style'
import { useViewport } from 'hooks'

const CheckList = ({ checkList, weight }) => {
    const {mobile} = useViewport();
    
    return (
            <CheckListItem>
                <IoMdCheckmark color="var(--accent)" size="20px" />
                <Text size={mobile ? 14 : 16} weight={weight} margin="0 0 0 14px">{checkList}</Text>
            </CheckListItem>
    )
}

export default CheckList