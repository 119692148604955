import Text, { Heading } from "app/components/atoms/typography";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import ReactPaginate from "react-paginate";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import { ProfilePictureStyle } from "app/components/molecules/user-profile/user-profile.styles";
import { createStatusStyle, getPaymentStatusName, getStatusName } from "utils/helpers/createStatusStyle/createStatusStyle";
import { getRandomColor } from "utils/helpers/getRandomColor/getRandomColor";
import { USER_ROLES } from "store/reducers/auth/utils";
import { Vortex } from "react-loader-spinner";
import { INVOICE_STATUS } from "utils/constants/constants.utils";

const InvoiceMobileView = ({
    invoices,
    pageCount,
    page,
    onChangePage,
    handleEdit,
    loading,
    role
}) => {

    const basicMenu = (row) => {
        let popupList = ["View", "Download"];

        if(INVOICE_STATUS.CHANGE_REQUESTED === row.statusName && role === USER_ROLES.CLIENT_USER){
            popupList = ["View", "Edit", "Download"]
        }

        return  <ActionMenu
            popupList={popupList}
            row={row}
            downloadFileName={row.invoiceNo}
            downloadLink={row.invoicePdfUrl}
            handleEdit={handleEdit}/>
    }

    const handleGetClient = (row) =>{
        if(role === USER_ROLES.CLIENT_USER){
            return row.to;
        }
        else{
            return row.client;
        }
    }

    return (
        <>
        {loading ? 
            <div className="centered-container">
                <Vortex
                    height="80"
                    width="80"
                    ariaLabel="vortex-loading"
                    wrapperClass="vortex-wrapper"
                    colors={["red", "blue", "yellow", "#fe9900"]}/>
            </div>
                : 
            <div style={{ marginTop: "14px," }}>
                {invoices?.map((row, index) => (
                    <div
                        key={index}
                        style={{
                            borderBottom: "0.5px solid #6C98F3",
                            paddingBottom: "10px",
                            margin: "10px",
                        }}>
                        <>
                            <Flex>
                                <Text size={12} weight={700}>
                                    {row.invoiceNo}
                                </Text>

                                <Text size={12}
                                color={createStatusStyle(row.statusName)}
                                >{getStatusName(row.statusName)}</Text>
                            </Flex>

                            <Flex>
                                <div>
                                    <Flex textAlign="start" gap="5px">
                                        <ProfilePictureStyle style={{backgroundColor: getRandomColor()}}>
                                            <Heading weight={500} size={20}>
                                                {handleGetClient(row)?.charAt(0)}
                                            </Heading>
                                        </ProfilePictureStyle>
                                        <div>
                                            <Text margin="0" size={12}>{handleGetClient(row)}</Text>
                                            <Flex gap="20px">
                                                <Text margin="0" size={12}>
                                                    {new Date(row.createdDate).toLocaleDateString()}
                                                </Text>
                                                <Text
                                                    color={createStatusStyle(row.invoicePaymentStatusName)}
                                                    margin="0" 
                                                    size={12}>
                                                    {getPaymentStatusName(row.invoicePaymentStatusName)}
                                                </Text>
                                            </Flex>
                                        </div>
                                    </Flex>
                                </div>

                                <div>{basicMenu(row)}</div>
                            </Flex>
                        </>
                    </div>
                ))}
            </div>
        }
            
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>

                <ReactPaginate
                    breakLabel="..."
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    forcePage={page}
                    onPageChange={(e) => {
                        onChangePage(e.selected + 1)
                    }
                    }
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </div>
        </>
    );
};
export default InvoiceMobileView;