import { useEffect } from "react";
import * as Yup from "yup";

import {
  EmailValidator,
  nameValidator,
  PasswordValidator,
  PhoneNumberValidator,
} from "utils/validation/validation.utils";
import SignUp from "app/layouts/auth.layout/signup";
import { useDispatch, useSelector } from "react-redux";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { formActions, formSelectors } from "store/reducers/form/formSlice";
import { authActions, authSelectors } from "store/reducers/auth/authSlice";
import { ROUTES } from "utils/constants/constants.utils";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";

const defaultFormFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  plan: "",
};

const validate = Yup.object({
  firstName: nameValidator(),
  lastName: nameValidator(),
  email: EmailValidator,
  password: PasswordValidator,
  phoneNumber: PhoneNumberValidator
});

export const ClientUserSignUp = () => {
  const dispatch = useDispatch();

  const clientUserPlans = useSelector(planSelectors.clientUserPlan);
  const clientUserSignUpForm = useSelector(formSelectors.clientUserSignUpForm);
  const isLoading = useSelector(authSelectors.isLoading);
  const { clientUserSignUp } = authActions;
  const { getPlans } = planActions;
  const { getClientUserSignUpForm } = formActions;
  const { handleApiResponse } = useHandleApiResponse();

  const signUpHandler = async (values) => {
    dispatch(clientUserSignUp(values)).then((res) => {
      handleApiResponse(res, ROUTES.HOME);
    });
  };

  useEffect(() => {
    dispatch(getPlans());

    dispatch(getClientUserSignUpForm(clientUserPlans));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <SignUp
      formContent={clientUserSignUpForm}
      validationSchema={validate}
      defaultFormFields={defaultFormFields}
      handleSubmit={signUpHandler}
      showSocialMedia={true}
      plans={clientUserPlans}
      isLoading={isLoading}
    />
  );
};
