import styled, { css } from "styled-components";

const subColor = "grey";
const mainColor = "#3A3E47";

export const shrinkLabelStyle = css`
  top: -3px;
  left: 0px;
  color: ${mainColor};
`;

export const errorStyle = css`
  background-color: pink;
  border: 1px solid red;
`;

export const FormInputLable = styled.label`
  color: ${subColor};
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
`;

export const Input = styled.input`
  background: none;
  background-color: #f2f6ff;
  color: ${subColor};
  font-size: 18px;
  padding: 10px 10px 10px 20px;
  display: block;
  width: ${({ width }) => width || '100%'};
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: ${({ margin }) => margin || '20px 0 10px'};

  :invalid {
    ${({ error }) => error && errorStyle};
  }

  ::placeholder {
    color: #3777ff;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLable} {
    ${shrinkLabelStyle};
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  position: relative;

  input[type="password"] {
    letter-spacing: 0.3em;
  }
`;

