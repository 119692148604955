import ContentContainer, {
  ContentCardsContainer,
  Header,
} from "app/components/atoms/about-content-layout/ContentContainer.component";
import { CardContainer } from "app/components/atoms/card/card.component";
import List from "app/components/atoms/list/list.component";
import Text, { Heading } from "app/components/atoms/typography";
import { Link } from "react-router-dom";

const Security = () => {
  return (
    <ContentContainer>
      <div className="container">
        <Header>
          <Heading weight="400" size="56" style={{ color: "#fe9900" }}>
            Security
          </Heading>
          <Text>Last updated on January 10, 2023</Text>
          <Text>Easei’s commitment to your Data security.</Text>
        </Header>
        <ContentCardsContainer>
          <CardContainer>
            <Heading level={2}>Introduction</Heading>
            <div>
              <Text>
                We know the data you trust us to house is very private to you,
                and keeping them secure is our highest priority.
              </Text>
              <Text>
                Our security strategy covers all aspects of our business,
                including:
              </Text>
              <List el={"ul"}>
                <Text>Organization security policies</Text>
                <Text>Physical and environmental security</Text>
                <Text>Operational security processes</Text>
                <Text>Infrastructure Security</Text>
                <Text>Identity and access control</Text>
                <Text>Systems development and maintenance</Text>
                <Text>Vendor Management</Text>
                <Text>Regularly working with third-party security experts</Text>
              </List>
            </div>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>
              Easei Corporate Security Policies & Procedures
            </Heading>
            <Text>
              Every Easei employee is expected to respect the terms of our data
              confidentiality policies, available at{" "}
              <Link to={"/terms"}> easei.com/terms</Link> and
              <Link to="/privacy"> easei.com/privacy</Link>. Access rights are
              strictly based on the employee’s job function and role.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Infrastructure Security</Heading>
            <Text>
              Our infrastructure is hosted on secure cloud servers, isolated by
              firewalls, network, security groups amongst others. We are always
              practice defensive programming and we are keep our applications up
              to date with the latest package and framework updates to eliminate
              any vulnerabilities. We also have access to Microsoft security
              experts that provide advise on data protection and security. We’ve
              got you covered,
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Availability</Heading>
            <Text>
              We are committed to making Easei consistently available to you and
              your clients. Our systems have built-in redundancy to withstand
              failures and are constantly monitored to keep your work
              uninterrupted.
            </Text>
          </CardContainer>
          <CardContainer>
            <Heading level={2}>Conclusion</Heading>
            <Text>
              Security of your data is your right and a never-ending mission of
              Easei. We will continue to work hard to keep your data secure,
              like we always have. For any further queries on this topic,
              contact use here.
            </Text>
          </CardContainer>
        </ContentCardsContainer>
      </div>
    </ContentContainer>
  );
};

export default Security;
