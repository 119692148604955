import Button from "app/components/atoms/button/button.component";
import Text, { Heading } from "app/components/atoms/typography";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SettingsActions } from "store/reducers/settings/settingsSlice";
import { FormContent, OrganizationSettingsContainer } from "./settings.styles";

const CloseAccount = () => {
  const dispatch = useDispatch();
  const { closeAccount } = SettingsActions;
  const [isLoading, setIsLoading] = useState(false);
  const { handleApiResponse } = useHandleApiResponse();

  const handleAccountClosure = () => {
    setIsLoading(true);
    dispatch(closeAccount()).then((res) => {
      handleApiResponse(res, null);

      setIsLoading(false);
    });
  };
  return (
    <OrganizationSettingsContainer>
      <Heading level={2} color="var(--primary2)">
        Close Your Account
      </Heading>
      <Text color="#7B8599">
        Click the button below to delete your entire Easei account. This means
        you will lose access to all invoicing information from your clients or
        workspace.
      </Text>
      <Text color="#7B8599">This action cannot be undone.</Text>
      <FormContent>
        <div></div>
        <Button
          className="close-btn"
          onClick={handleAccountClosure}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Delete my Account
        </Button>
      </FormContent>
    </OrganizationSettingsContainer>
  );
};

export default CloseAccount;
