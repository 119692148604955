import Text, { Heading, Span } from "app/components/atoms/typography";
import { useEffect } from "react";
import Spinner from "app/components/atoms/spinner/spinner.component";
import { FormContent, OrganizationSettingsContainer } from "./settings.styles";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import * as Yup from "yup";

import {
  SettingsActions,
  SettingsSelectors,
} from "store/reducers/settings/settingsSlice";
import { Form, Formik } from "formik";
import FormInput, {
  Input,
} from "app/components/atoms/form-input/form-input.component";

import Button from "app/components/atoms/button/button.component";
import CustomModal from "app/components/atoms/modal/modal.component";
import { useState } from "react";
import styled from "styled-components";
import { useRef } from "react";
import { OTP_SOURCE } from "utils/constants/constants.utils";

const validation = Yup.object({
  accountName: Yup.string().required(),
  bankName: Yup.string().required(),
  accountNumber: Yup.number().required(),
});

const OTPBox = styled(Input)`
  height: 70px;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
`;
const OTPBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 70px);
  justify-content: space-between;
   input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AccountSettings = () => {
  const dispatch = useDispatch();
  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();

  const { handleApiResponse } = useHandleApiResponse();
  const [approveOTP, setApproveOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(['', '', '', '']);

  const account = useSelector(SettingsSelectors.account);
  const { getAccountDetails, updateAccountDetails, verifyOTP } = SettingsActions;

  const defaultFormFields = {
    accountName: account.accountName || "",
    bankName: account.bankName || "",
    accountNumber: account.accountNumber || "",
    sortCodeIBAN: account.sortCodeIBAN || "",
  };
  const isAllBoxesFilled = otpValues.every((value) => value !== '');

  useEffect(() => {
    dispatch(getAccountDetails());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitAccountDetails = (setSubmitting, values) =>{
    
    setSubmitting(true);

    dispatch(updateAccountDetails(values)).then((res) => {

      handleApiResponse(res, null);
      setSubmitting(false);

      if(res?.payload?.succeeded){
        setApproveOTP(true);
      }
    });
  }

  const handleVerifyOTP = (e) => {
    
    e.preventDefault();

    setIsLoading(true);

    const input = `${otp1.current.value}${otp2.current.value}${otp3.current.value}${otp4.current.value}`;
    
    dispatch( verifyOTP({ source: OTP_SOURCE.ACCOUNT_SETUP, token: input }))
      .then((res) => {

        handleApiResponse(res, null);

        if (res?.payload?.succeeded) {
          setApproveOTP(false);
          dispatch(getAccountDetails());
        }

        setIsLoading(false);
      });
   }

  return (
    <OrganizationSettingsContainer>
      <CustomModal
        isOpen={approveOTP}
        className="modal modal-del"
        close={setApproveOTP}
      >
        <form
          onSubmit={handleVerifyOTP}
        >
          <Heading level={2}>Verify Account</Heading>
          <Text color="var(--grey2)">
            Please enter the code we just sent to your email.
          </Text>
          <OTPBoxContainer>
            <OTPBox
              maxLength={1}
              type="number"
              ref={otp1}
              onChange={(e) => {
                const updatedOtpValues = [...otpValues];
                updatedOtpValues[0] = e.target.value;
                setOtpValues(updatedOtpValues);
                if (e.target.value.length > 0) {
                  otp2.current.focus();
                }
              }}
            />
            <OTPBox
              maxLength={1}
              type="number"
              ref={otp2}
              onChange={(e) => {
                const updatedOtpValues = [...otpValues];
                updatedOtpValues[1] = e.target.value;
                setOtpValues(updatedOtpValues);
                if (e.target.value.length > 0) {
                  otp3.current.focus();
                } else {
                  otp1.current.focus();
                }
              }}
            />
            <OTPBox
              maxLength={1}
              type="number"
              ref={otp3}
              onChange={(e) => {
                const updatedOtpValues = [...otpValues];
                updatedOtpValues[2] = e.target.value;
                setOtpValues(updatedOtpValues);
                if (e.target.value.length > 0) {
                  otp4.current.focus();
                } else {
                  otp2.current.focus();
                }
              }}
            />
            <OTPBox
              maxLength={1}
              type="number"
              ref={otp4}
              onChange={(e) => {
                const updatedOtpValues = [...otpValues];
                updatedOtpValues[3] = e.target.value;
                setOtpValues(updatedOtpValues);
                if (e.target.value.length === 0) {
                  otp3.current.focus();
                }
              }}
            />
          </OTPBoxContainer>
          <Button
            type="submit"
            isLoading={isLoading}
            style={{ marginTop: "30px", width: "100%", minWidth: "0" }}
            disabled={!isAllBoxesFilled}
          >
            Continue
          </Button>
        </form>
      </CustomModal>
      <Text color="#7B8599">Manage your company account settings</Text>
      {account.hasOwnProperty("accountName") ? (
        <Formik
          initialValues={defaultFormFields}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAccountDetails(setSubmitting, values)
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
              <Form className="form">
                <FormContent className="name">
                  <Text>
                    Account Name <Span color="red">*</Span>
                  </Text>

                  <FormInput
                    type="text"
                    name="accountName"
                    placeholder="Account name"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["accountName"]}
                    onChange={(value) =>
                      setFieldValue("accountName", value.value)
                    }
                  />
                </FormContent>
                <FormContent>
                  <Text>
                    Account Number <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    type="text"
                    name="accountNumber"
                    placeholder="Account number"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["accountNumber"]}
                    onChange={(value) =>
                      setFieldValue("accountNumber", value.value)
                    }
                  />
                </FormContent>
                <FormContent>
                  <Text>
                    Bank Name <Span color="red">*</Span>
                  </Text>
                  <FormInput
                    type="text"
                    name="bankName"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["bankName"]}
                    onChange={(value) => setFieldValue("bankName", value.value)}
                  />
                </FormContent>
                <FormContent>
                  <Text>SwiftCode / IBAN</Text>
                  <FormInput
                    type="text"
                    className="postal"
                    name="sortCodeIBAN"
                    style={{
                      backgroundColor: "white",
                      padding: "25px 20px",
                    }}
                    value={values["sortCodeIBAN"]}
                    onChange={(value) =>
                      setFieldValue("sortCodeIBAN", value.value)
                    }
                  />
                </FormContent>

                <FormContent className="cheat">
                  <div></div>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Save Changes
                  </Button>
                </FormContent>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </OrganizationSettingsContainer>
  );
};

export default AccountSettings;
