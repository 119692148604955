import Verify from "app/layouts/auth.layout/verify";
import {React, useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "store/reducers/auth/authSlice";
import { ROUTES } from "utils/constants/constants.utils";
import LoadingDots from "app/components/atoms/loading-dots/loading-dots.component";
import styled from "styled-components";
import _ from "lodash";

const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--white);

  p,
  h1 {
    text-align: center;
  }

  svg {
    width: 100%;
    margin-bottom: 20px;
  }

  span {
    cursor: pointer;
    color: var(--primary);
  }

  .verification-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .verification-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  @media screen and (max-width: 375px) {

    h1 {
        font-size: 28px;
    }
    button {
        width: 100%;
        padding: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    button {
        width: 50%;
    }
  }
`;

export const ConfirmRegistration = () => {

  let { email, token } = useParams();
  const [confirmed, setConfirmed] = useState(null);
  const { confirmEmail } = authActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(()=>
  {
    if(!_.isEmpty(email) && !_.isEmpty(token)){

    dispatch(confirmEmail({ email: email, emailConfirmationToken: token }))
    .then((res)=>
    {
      if(res?.payload?.succeeded){
        setConfirmed(true);
      }
      else if(res?.payload?.succeeded === false){
        setConfirmed(false);
      }
    });
  }
  })

  return confirmed !== null ? (<Verify
    title= 'Email Confirmation'
    message={ confirmed ? `Your Email has been successfully verified` : 
    `We were UNABLE to verify your email, a new verification mail has been sent to you,
     if your email exists in our system and is unverified.` }
    buttonText={ confirmed ? `Login` : `Home` }
    handleClick = {()=>{ navigate( confirmed ? ROUTES.SIGN_IN : ROUTES.HOME) }}
  />) : (
    <ConfirmContainer>
      <LoadingDots text="Confirming Email" />
    </ConfirmContainer>
  )
};
