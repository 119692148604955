import styled, { css } from "styled-components";
import img from "app/assets/img/Background.png";

const mobileBackground = css`
  background: url(${img});
  background-size: cover;
  background-color: var(--primary8);
`;

const DesktopBackground = css`
  background: var(--primary8);
`;

export const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;

  .auth-login-text {
    display: flex;
    justify-content: flex-start;
    color: #000000;
    margin-bottom: 20px;
    span {
      color: var(--primary);
      cursor: pointer;
    }
  }

  .auth-back-arrow {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 5px;
    left: 15px;
    cursor: pointer;
    font-size: 32px;
    color: black;
  }

  .auth-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 35px 15px;
    ${({ mobile }) => (mobile ? mobileBackground : DesktopBackground)};

    div: first-child {
      display: flex;
      justify-content: flex-start;
    }
  }

  .auth-image-container {
    display: none;
    background: url(${img});
    background-size: cover;
    background-color: #002776;

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .auth-image-container-text {
      display: flex;
      flex-direction: column;

      .auth-image-container-text-welcome {
        padding: 0 70px;
      }

      .auth-image-container-text-people {
        padding: 0 30px;
        display: flex;
        gap: 100px;
        align-items: center;
        justify-content: flex-start;

        .user-image-container {
          display: flex;
          height: 24px;
          position: relative;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }

        .auth-image-container-box {
          display: flex;
        }
      }

      p,
      h1 {
        color: #fff;
      }
    }

    @media screen and (min-width: 650px) {
      display: flex;
      align-items: center;
    }

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      svg {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 3fr;

    #auth-form-container-type {
      height: 48px;
      margin-bottom: 10px;
    }

    .auth-form-container {
      padding: 15px 120px;
    }

    .auth-layout-logo {
      display: flex;
      align-items: center;
      gap: 10px;
      position: absolute;
      top: 10px;
      left: 120px;
    }

    .auth-image-container {
      width: 100%;
      svg {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;

    .auth-form-container {
      padding: 15px 40px;
    }
    .auth-image-container
      .auth-image-container-text
      .auth-image-container-text-welcome {
      padding: 0 30px;

      p,
      h1 {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 950px) {
    grid-template-columns: 1fr 1fr;

    .auth-form-container {
      padding: 15px 120px;
    }

    .auth-image-container
      .auth-image-container-text
      .auth-image-container-text-welcome {
      padding: 0 70px;
    }
  }
`;

export const UsersImageBox = styled.div`
  position: absolute;
  left: ${({ left }) => left + "px" || 0};
`;

export const ChildrenStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0;
    position: relative;

  @media screen and (min-width: 650px) {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;

  @media screen and (min-width: 650px) {
    margin: 40px 0 0 0;
  }
`;

export const FormTypeContainer = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  margin-bottom: 20px;

  @media screen and (min-width: 650px) {
    height: 48px;
    margin-bottom: 10px;
  }
`;

export const FormType = styled.div`
  background: ${({ active }) => (active ? "var(--primary7)" : "var(--white)")};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center !important;
  cursor: pointer;
  p {
    color: var(--primary2);
  }
`;
export const SignUpPlan = styled.div`
  margin: 50px 0;
  display: flex;
  justify-content: center;

`