import styled from "styled-components";

export const DropDownContainerStyle = styled.div`
  position: absolute;
  left:${({ left }) => left || "0px"};
right:${({ right }) => right || "0px"};
  top:${({ top }) => top || "50px"};
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
width: ${({ width }) => width};
border: ${({ border }) => border};
border-radius: ${({ radius }) => radius};
padding: ${({ padding }) => padding};
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  > .content {
    cursor: pointer;
    width: 100%;
    border-top: 1px solid var(--grey5);
    padding: 1rem;
    &:hover {
      background-color: var(--grey5);
    }
  }
  .select {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      border-top: 1px solid var(--grey5);
    }
  }
  /* .dropdown_text{
    border-bottom:1px solid red;
  } */
`;