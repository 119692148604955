import { TextStyling } from "app/components/atoms/typography";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import styled from "styled-components";

export const OrganizationSearchContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:20px;
  padding: 0 3rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0;
  }
`;
export const OrganizationInputCard = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 5px 0 20px;
  height: 4rem;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
    color: var(--accent);
  }
  /* @media screen and (max-width:1110px) {
  width:11rem;
   padding:  1.3rem 0;
   } */
  @media screen and (max-width: 768px) {
    padding: 15px 20px 10px 12px;
    border-radius: 8px;
    height:48px;
  }
`;
export const OrganisationFilters = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;
export const OrganisationFilter = styled.div`
  border: 0.5px solid var(--grey2);
  border-radius: 10px;
  background-color: var(--white);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 4rem;
  padding:10px;
  @media screen and (max-width: 768px) {
    padding: 5px;
    height: 100%;
    border-radius: 12px;
        margin-bottom:30px;
    p {
      padding: 0;
      margin: 0;
    }
  }
`;
export const FilterDropdown = styled(OrganisationFilter)`
  position: absolute;
  flex-direction: column;
  top: 54px;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0;
  border-radius: 0 0 10px 10px;
  .dropdown_options {
    width: 100%;
    border: 0.5px solid var(--grey2);
    background-color: white;
    padding: 2px;
  }
`;

export const OrganizationDetailsWrapper = styled.div`
    @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 114px;
    height:100vh;
    
  }
`
export const OrganisationDetailsContainer = styled.div`
.organization_img{
  width: 51px;
  height:51px;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--primary);
  img{
    width: 100%;
    object-fit: cover;
  }
   @media screen and (min-width: 768px) {
    width: 128px;
    height:128px;
   }
}
p{
text-align: left;
margin: 0;
}
`;
export const TextTitle = styled(TextStyling)`
  color:var(--grey2);
  font-size: 14px;
  word-wrap: break-word;
  width: 134px;
  @media screen and (min-width:800px) and (max-width:920px) {
       width: 90px;
  }
`

export const OrganisationInfo = styled(Flex)` 
  margin-bottom: 10px;
 @media screen and (max-width:768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const OrganisationFlex = styled(Flex)`
justify-content: flex-start;
align-items: flex-start;
gap:11px;
margin-bottom: 20px;
`


