import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "chat";
export const initialState = {
  totalCount: 0,
  chats: [],
  message: "",
  loading: true,
};

const getChatRoom = createAsyncThunk(
  `${entity}/search-chat`,
  async (input) => {
    
    const res = await api.chat.getChatRoom(input.invoiceNumber, input.pageSize, input.page);

    return res.data;
  }
);
const postChatMessage = createAsyncThunk(`${entity}/chat`, async (input) => {
  const res = await api.chat.postChatMessage(input);
  return res.data;
});

const chatSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChatRoom.fulfilled, (state, action) => {
        state.chats = action.payload.data;
        state.totalCount = action.payload.totalCount
        state.loading = false;
      })
      .addCase(getChatRoom.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getChatRoom.rejected, (state) => {
        return {
          ...state,
          loading: true,
        };
      });
    builder
      .addCase(postChatMessage.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(postChatMessage.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(postChatMessage.rejected, (state) => {
        return {
          ...state,
          loading: true,
        };
      });
  },
});
export const chatActions = {
  ...chatSlice.actions,
  getChatRoom,
  postChatMessage,
};

export const chatSelectors = {
  isLoading: (state) => state.chat?.loading,
  messages: (state) => state.chat.chats,
  totalCount: (state) => state.chat?.totalCount
}

export default chatSlice.reducer;
