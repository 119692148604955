import React, { useState } from "react";
import PlanCard from "../plan-card/plan-card.component";

import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PLAN_TYPE_NUMBER } from "utils/constants/constants.utils";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";

const PlanCardWrapper = styled.div`
  display: flex;
  max-width: 506px;

  @media screen and (max-width: 649px) {
    max-width: 350px;
  }

  @media screen and (min-width: 650px) {
    justify-content: space-between;
  }
`;


const PlanPanel = ({className, planType}) => {
  const [activeCard, setActiveCard] = useState();
    const organizationPlans = useSelector(planSelectors.organizationPlan) || [];
    const clientUserPlans = useSelector(planSelectors.clientUserPlan) || [];
    const { getPlans } = planActions;
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getPlans({}))
      // eslint-disable-next-line
  }, []);

  return (
    <div className={className}>
      {planType === PLAN_TYPE_NUMBER.ORGANIZATION && organizationPlans?.map((plan) => {
        return (
          <PlanCardWrapper onClick={() => setActiveCard(plan)} key={plan.id}>
            <PlanCard plan={plan} active={activeCard === plan ? true : false} />
          </PlanCardWrapper>
        );
      })}

      {planType === PLAN_TYPE_NUMBER.CLIENT && clientUserPlans?.map((plan) => {
        return (
          <PlanCardWrapper onClick={() => setActiveCard(plan)} key={plan.id}>
            <PlanCard plan={plan} active={activeCard === plan ? true : false} />
          </PlanCardWrapper>
        );
      })}
    </div>
  );
};

export default PlanPanel;
