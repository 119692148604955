import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "Organization";

export const initialState = {
  organizations: [],
  ForDropDown: [],
  totalCount: 0,
  pageCount: 0,
  loading: false,
  organizationId: '',
  organization: {}
};

const searchOrganizations = createAsyncThunk(
  `${entity}/search`,

  async (input) => {
    const {
      page,
      pageSize,
      isActive,
      name,
      planId,
      activePlan
    } = input;
    const res = await api.organization.search(
      page,
      pageSize,
      isActive,
      name,
      planId,
      activePlan
    );
    return res.data;
  }
);

const updatePartnership = createAsyncThunk(`${entity}/update-partnersip`, async (input) => {
  const res = await api.organization.updatePartnership(input);
  return res.data;
});

const getOrganizations = createAsyncThunk(
  `${entity}/organization`,

  async () => {
    const res = await api.organization.getAll();
    return res.data;
  }
);
const getById = createAsyncThunk(`${entity}/getById`, async (id) => {
  const res = await api.organization.getById(id);
  return res.data;
});

const organizationSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePartnership.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(updatePartnership.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(updatePartnership.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder
      .addCase(searchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.pageCount = action.payload.pageCount;
        state.loading = false;
      })
      .addCase(searchOrganizations.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(searchOrganizations.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.organizationsForDropDown = action.payload.data?.map(function (item) {
          return { value: item.id, label: item.name };
        });
        state.loading = false;
      })
      .addCase(getOrganizations.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getOrganizations.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getById.fulfilled, (state, action) => {
        state.organization = action.payload.data;
        state.loading = false;
      })
      .addCase(getById.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getById.rejected, (state) => {
        return { ...state, loading: false };
      });
  },

});

export const OrganizationActions =
{
  ...organizationSlice.actions,
  searchOrganizations,
  getOrganizations,
  updatePartnership,
  getById
};

export const organizationSelectors = {
  dropDownIsLoading: (state) => state.organization.dropDownIsLoading,
  isLoading: (state) => state.organization.loading,
  organizations: (state) => state.organization.organizations,
  totalCount: (state) => state.organization.totalCount,
  pageCount: (state) => state.organization.pageCount,
  organizationsForDropDown: (state) => state.organization.organizationsForDropDown,
  organizationId: (state) => state.payment.organizationId,
  organization: (state) => state.organization.organization
};

export default organizationSlice.reducer;