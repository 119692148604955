import Text from 'app/components/atoms/typography'
import { Flex } from 'app/pages/dashboard/dashboard.styles'
import React from 'react'
import { BsArrowUpRight } from 'react-icons/bs'
import { Vortex } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import '../../../../../src/index.css';
import { useState } from 'react'
import { CardStyle } from 'app/components/atoms/card/card.component'

const OrganisationMobile = ({ organizations, loading, onChangePage, pageCount}) => {

    const navigate = useNavigate();
    const [page, setPage] = useState(0);

    return (
        <CardStyle padding="0" margin="20px">

            {loading || organizations === undefined || organizations === null ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                    }}
                >
                    <Vortex
                        height="80"
                        width="80"
                        ariaLabel="vortex-loading"
                        wrapperClass="vortex-wrapper"
                        colors={["red", "blue", "yellow", "#fe9900"]}
                    />
                </div>
            ) : (
                organizations?.map((data) => (
                    <div key={data.id} style={{ borderBottom: "1px solid var(--primary5)", padding: "10px 10px 5px 20px" }}>
                        <Flex>
                            <Text weight={500} size={12} color="var(--grey1)" margin="2px">{data.plan}</Text>
                            <Text weight={400} size={12} color="var(--grey1)" margin="2px">Expiring date <span style={{ fontWeight: "700" }}>
                                {new Date(data.planEndDate).toLocaleDateString()}</span></Text>
                        </Flex>
                        <Flex>
                            <Text size={14} weight={500} color="var(--primary1)" margin="2px">{data.businessName}</Text>
                            <BsArrowUpRight
                                onClick={() => { navigate(`/organization/${data.id}`); }}
                            />
                        </Flex>
                        <Flex>
                            <Text weight={400} size={12} color="var(--grey2)" margin="2px">
                                {new Date(data.createdAt).toLocaleDateString()}
                            </Text>
                            <Text weight={400} size={12} color="var(--grey2)" margin="2px">
                                {data.businessEmail}
                            </Text>
                            <Text weight={400} size={12} margin="2px"
                                style={{ border: data.isActive ? ".5px solid green" : ".5px solid green", borderRadius: "5px", padding: "0 15px", color: data.isActive ? "green" : "var(--accent)" }}>
                                {data.isActive ? "Active" : "Inactive"}
                            </Text>
                        </Flex>
                    </div>
                ))
                    
                )}
                 <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, padding: 15 }}>
                    <ReactPaginate
                    breakLabel="..."
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    forcePage={page}
                    onPageChange={(e) => { 
                        setPage(e.selected);
                        onChangePage(e.selected + 1) }
                    }
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    />
                </div>
        </CardStyle>
    )
}

export default OrganisationMobile;

