import AccessDenied from "app/components/molecules/access-denied/accessDenied";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { authSelectors } from "store/reducers/auth/authSlice";
import styled from "styled-components";
import { PLAN_FEATURES } from "utils/constants/constants.utils";
import { validatePlanFeatureAccess } from "utils/helpers/validatePlanFeature/validatePlanFeatureAccess";
import AllReminders from "./allReminders";
import SendReminder from "./sendReminder";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { useEffect } from "react";
const Container = styled.div`
  @media screen and (max-width: 699px) {
    padding: 0;
    text-align: center;
  }
  .hide {
    display: none;
  }
`;
const Reminder = () => {
  const { state } = useLocation();

  const [newReminder, setNewReminder] = useState({
    value: (state && state.value) || false,
    data: {},
  });

  const allowedFeatures = useSelector(authSelectors.allowedFeatures);
  const isPlanActive = useSelector(authSelectors.isPlanActive);
  const dispatch = useDispatch();

  const {setHeaderText } = appDataActions;

  useEffect(()=>{    
    dispatch(setHeaderText({ value: 'Reminder', type: 'setHeaderText' }));
     // eslint-disable-next-line
  },[])

  if(!validatePlanFeatureAccess(isPlanActive, allowedFeatures, PLAN_FEATURES.Reminder)){
    return (<AccessDenied/>)
  }

  return (
    <Container className="dashboard-container">
      {newReminder.value && (
        <SendReminder setNewReminder={setNewReminder} reminder={newReminder} />
      )}
      <AllReminders setNewReminder={setNewReminder} reminder={newReminder} />
    </Container>
  );
};

export default Reminder;
