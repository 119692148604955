import { useState } from "react";
import { useSelector } from "react-redux";

import { authSelectors } from "store/reducers/auth/authSlice";

import PageMessage from "app/components/molecules/page-message/page-message.component";
import PeopleCard from "app/components/molecules/people/people-card.component";
import InviteContacts from "./invite-contacts.component";
import Button from "app/components/atoms/button/button.component";

import Text from "app/components/atoms/typography";

import { ReactComponent as PeopleImage } from "app/assets/svg/office-people.svg";
import { ImageContainer, ImportContactContainer } from "./people.styles";
import { WELCOME_TO_EASEI } from "utils/constants/constants.utils";
import "./modal.styles.css";
import { Empty } from "app/components/atoms/divs/div.styles";

const PeopleEmpty = () => {
  const [visible, setVisible] = useState(false);
  const userName = useSelector(authSelectors.userName);

  const inviteStaff = () => {
    "got in here";
    setVisible(!visible);
  };

  const importContact = () => {};
  return (
    <>
      <div id="dashboard-root">
        <div>
          <PageMessage
            heading={`Hello ${userName} 👋`}
            text={WELCOME_TO_EASEI}
          />
        </div>
        <PeopleCard minHeight="550px" maxWidth="700px" className="people">
          <Empty>
            <ImageContainer>
              <PeopleImage style={{ width: "100%" }} />
            </ImageContainer>

            <Text size={24} weight={700} style={{ marginTop: "20px" }}>
              Business is no fun without people
            </Text>
            <Text
              size={16}
              weight={400}
              color={"#7B8599"}
              style={{ marginTop: "20px" }}
            >
              Create and manage your vendors, all in one place
            </Text>

            <Button
              style={{ minWidth: "0", height: "auto" }}
              onClick={inviteStaff}
            >
              Invite your staff
            </Button>
            <ImportContactContainer onClick={importContact}>
              <Text size={"1.5rem"} weight={500} color={"#3777FF"}>
                Click here to import your staff’s contacts from file
              </Text>
            </ImportContactContainer>
          </Empty>
        </PeopleCard>
      </div>
      {visible && <InviteContacts visible={visible} setVisible={setVisible} />}
    </>
  );
};

export default PeopleEmpty;
