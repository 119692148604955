import * as Yup from "yup";

import {
  ConsentValidator,
  EmailValidator,
  GeneralValidator,
  nameValidator,
  NumberValidator,
  PasswordValidator,
  PhoneNumberValidator,
} from "utils/validation/validation.utils";
import SignUp from "app/layouts/auth.layout/signup";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formActions, formSelectors } from "store/reducers/form/formSlice";
import { useNavigate } from "react-router-dom";
import { authActions, authSelectors } from "store/reducers/auth/authSlice";

const defaultFormFields = {
  industry: "",
  businessName: "",
  businessEmail: "",
  businessPhoneNumber: "",
  organizationSector: "",
  password: "",
  planId: "",
  acceptTerms: false,
};

const validate = Yup.object({
  industry: GeneralValidator,
  businessName: nameValidator("Business Name"),
  businessEmail: EmailValidator,
  businessPhoneNumber: PhoneNumberValidator,
  organizationSector: GeneralValidator,
  password: PasswordValidator,
  planId: NumberValidator,
  acceptTerms: ConsentValidator,
});

export const OrganizationSignUp = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { organizationSignUp } = authActions;
  const { getPlans } = planActions;

  const signUpHandler = async (values) => {
    dispatch(organizationSignUp(values));
    navigate("/sign-in");
  };

  const isLoading = useSelector(authSelectors.isLoading);
  const organizationPlans = useSelector(planSelectors.organizationPlan);
  const organizationSignUpForm = useSelector(
    formSelectors.organizationSignUpForm
  );

  const { getOrganizationSignUpForm } = formActions;

  useEffect(() => {
    dispatch(getPlans());

    if (organizationPlans.length) dispatch(getOrganizationSignUpForm(organizationPlans));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationPlans]);

  return (
    <SignUp
      formContent={organizationSignUpForm}
      validationSchema={validate}
      defaultFormFields={defaultFormFields}
      handleSubmit={signUpHandler}
      showSocialMedia={false}
      plans={organizationPlans}
      isLoading={isLoading}
    />
  );
};
