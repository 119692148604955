import React from 'react'
import { getInvoiceStatusCount } from "utils/helpers/getInvoiceStatusCount/getInvoiceStatusCount";
import Text from 'app/components/atoms/typography';
import { INVOICE_STATUS_DROPDOWN_DATA } from 'utils/constants/constants.utils';
import { SummaryLink } from 'app/components/atoms/divs/div.styles';
import { useState } from 'react';
import { Flex } from 'app/pages/dashboard/dashboard.styles';
import { useViewport } from 'hooks';

const InvoiceSummaryLink = ({
    pendingCount,
    approvedCount,
    declinedCount,
    changeRequestCount,
    totalCount,
    setStatusInput,
}) => {
    
    const [statusInput, setStatusLabelInput] = useState("All");
    const { mobile } = useViewport();

    const handleStatusClick = (status) => {
        const newStatusLabelInput = status === "All" ? null : status.label;
        const newStatusInput = status === "All" ? null : status.value;
      
        setStatusLabelInput(newStatusLabelInput);
        setStatusInput(newStatusInput);
      };
      
    return (
                <SummaryLink style={{flexWrap: mobile ? "wrap" : "nowrap"}}>
                    {INVOICE_STATUS_DROPDOWN_DATA.option?.map((status, index) => {
                        let count = getInvoiceStatusCount(
                            status.label,
                            pendingCount,
                            approvedCount,
                            declinedCount,
                            changeRequestCount,
                            totalCount
                        );
                        return (
                            <div
                                key={index}
                                className={`${
                                    (statusInput === status.label && status.label) ||
                                    ((statusInput === "" || statusInput === "Status") && status.label === "All")
                                      ? "active"
                                      : "inactive"
                                  }`}                                  
                                onClick={()=> handleStatusClick(status)}
                            >
                                <Flex gap="5px" justifyContent="true">
                                      <Text size={16}>
                                    {status.label}
                                </Text>
                                    <Text size={16}> ({count})</Text>
                                </Flex>
                            </div>
                        );
                    })}
                </SummaryLink>
    )
}

export default InvoiceSummaryLink