import styled from "styled-components";
import backgroundImage from "app/assets/img/How-it-works.png";
import Text, { Heading } from "app/components/atoms/typography";
import Img from "app/assets/img/Frame 627048.png";
import { Fragment } from "react";
import BgImg from "../../../assets/img/howItWorks.png";
import { useViewport } from "hooks";
const HowItWorksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  background-color: black;
  background-image: url(${BgImg});
  padding: 20px 20px;

  p,
  h1 {
    color: #fff;
  }

  .main-header {
    h1 {
      padding-left: 45px;
    }
  }

  .how-it-works-flex-one {
    display: none;
    align-items: center;
    margin-left: 130px;
    justify-content: center;
    img {
      width: 250px;
    }
  }

  .how-it-works-flex-two {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-content-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 20px;
  }

  .how-it-works-content {
    display: flex;
    column-gap: 16px;
    align-items: center;
    row-gap: 100px;
  }

  @media screen and (min-width: 768px) {
    background-image: url(${backgroundImage});
    background-size: cover;
    grid-template-columns: 1fr 1fr;

    .how-it-works-flex-one {
      display: flex;
      justify-content: center;
    }
      .how-it-works-content{
        column-gap: 50px;
      }
  }

  @media screen and (min-width: 950px) {
    .how-it-works-content-container {
      padding-right: 100px;
    }

    .main-header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      h1 {
        padding: 0;
        position: relative;
        left: -120px;
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .how-it-works-content {
      #how-it-works {
        padding-right: 100px;
      }
    }
  }
`;

const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 64px;
  height: 80px;
  background: rgba(181, 204, 254, 0.11);
  border-radius: 11px;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #82aaff;
  @media screen and (max-width: 768px) {
    color: white;
    width: 32px;
    height: 40px;
    font-size: 20px;
  }
`;

const steps = [
  {
    title: "Sign Up",
    body: "Have your account setup in as little as 30 seconds without any hasle",
  },
  {
    title: "Generate Invoice",
    body: "Generate and send invoices to your clients in just 4 clicks",
  },
  {
    title: "Keep Track",
    body: "An elaborate monitoring dashboard, you see all and know all",
  },
];

const HowItWorks = () => {
  const { mobile } = useViewport();
  return (
    <HowItWorksContainer id="about">
      <div className="how-it-works-flex-one">
        <img src={Img} alt="" />
      </div>
      <div className="how-it-works-flex-two">
        <div className="main-header">
          <Heading size={mobile ? 20 : 40}>How it works</Heading>
        </div>
        <div className="how-it-works-content-container">
          {steps?.map((step, index) => (
            <Fragment key={index}>
              <div className="how-it-works-content">
                <IndexContainer>{++index}</IndexContainer>
                <div id="how-it-works">
                  <Heading size={mobile ? 16 : 28}>{step.title}</Heading>
                  <Text size={mobile ? 14 : 16}>{step.body}</Text>
                </div>
              </div>
              <div className="divider"></div>
            </Fragment>
          ))}
        </div>
      </div>
    </HowItWorksContainer>
  );
};

export default HowItWorks;
