import DataTable from "react-data-table-component";
import { IoIosAddCircleOutline } from "react-icons/io";

import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
 from {
   transform: rotate(0deg);
 }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  button {
    border: none;
    background: none;
    display: flex;
    justify-contents: center;
    align-items: center;
    gap: 15px;
    font-weight: bold;
    color: #0148de;
    cursor: pointer;
  }
`;

const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
    <div>Fetching data...</div>
  </div>
);
//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderCollapse: "separate",
      borderSpacing: "0 7px",
      border: "1px solid #F2F6FF",
    },
  },
  headCells: {
    style: {
      background: "var(--primary8)",
      color: "#3a3e47",
      fontWeight: "bold",
      height: "63px",
      fontSize: "13px",
      textDecoration: "underline",
    },
  },
  cells: {
    style: {
      borderTop: "1px solid #dbe7ff",
      borderBottom: "1px solid #dbe7ff",
    },
  },
  pagination: {
    style: {
      zIndex: 1,
    },
  },
};


export const NoData = ({ onClick }) => (
  <Div>
    <button onClick={onClick}>
      <IoIosAddCircleOutline size={20} />
      Add a new item
    </button>
  </Div>
);

const Table = ({ ...rest }) => {
  return (
    <DataTable
      noHeader
      customStyles={customStyles}
      progressComponent={<CustomLoader />}
      {...rest}
    />
  );
};

export default Table;
