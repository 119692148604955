import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import Spinner from "app/components/atoms/spinner/spinner.component";
import { ReactComponent as DownloadSvg } from "app/assets/svg/link-square.svg";
import { useDispatch, useSelector } from "react-redux";
import { chatActions, chatSelectors } from "store/reducers/chat/chatSlice";
import Button from "../atoms/button/button.component";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import Text from "../atoms/typography";
import moment from "moment";
import { SettingsActions } from "store/reducers/settings/settingsSlice";
import { BsFillFileEarmarkArrowDownFill } from "react-icons/bs";
import ProfilePicture from "../molecules/profile-picture/profile-picture.component";
import { ProfilePictureStyle } from "../molecules/user-profile/user-profile.styles";
import { getRandomColor } from "utils/helpers/getRandomColor/getRandomColor";

const NotificationContainer = styled.div`
  transition: all 0.5s;
  position: fixed;
  max-width: 460px;
  width: 100%;
  top: 10px;
  right: ${({ open }) => (open ? "0" : "-460px")};
  height: 95%;
  margin: 4rem auto 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  z-index: 1000;
  .chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    position: absolute;
    left: 20px;
    right: 40px;
    bottom: 8%;
    button {
      padding: 10px;
      width: auto;
      height: auto;
      min-width: auto;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }
    button:disabled {
      cursor: not-allowed;
      background-color: var(--primary7);
    }
  }
  .notification-nav-items {
    width: 100%;
    display: flex;
    align-items: center;
    left: 0;
    justify-content: space-between;
    background: var(--primary2);

    color: var(--accent);
  }

  .notification-nav-link {
    padding: 0.6em 1em;
    cursor: pointer;
    color: white;
    font-weight: bold;
  }

  .notification-content {
    background: white;
    padding: 0.6em 1em;
    height: 100%;
    overflow: scroll;

    .test {
      ::-webkit-scrollbar {
        display: none;
      }
      > * + * {
        margin-top: 20px;
      }
      max-height: 70%;
      overflow: scroll;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        max-height: 60%;
      }
    }
  }

  .notification-nav-link-container {
    display: flex;
    justify-content: flex-start;
  }

  .close-btn {
    cursor: pointer;
    padding-right: 0.5em;
  }
`;

const NotificationItem = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  p {
    margin: 0;
  }
  .chat-dp {
    width: 60px;
    height: 56px;
    background: #f6f6f6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chat-details {
    flex: 1;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }
  }
`;

const ChatBox = styled.textarea`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 135px;
  flex: 1;
  outline: var(--accent);
  border: 1px solid #dbe7ff;
  border-radius: 4px;
`;

const Chat = ({ display, handleClose, chatId: invoiceNumber, paidInvoice }) => {

  const [open, setOpen] = useState(false);
  const isLoading = useSelector(chatSelectors.isLoading);
  const totalCount = useSelector(chatSelectors.totalCount);
  const [currentPage, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [messageData, setMessageData] = useState([]);
  const [message, setMessage] = useState("");
  const [isTop, setIsTop] = useState(false);
  const bottomScrollRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loadin, setLoadin] = useState(false);

  const { postChatMessage, getChatRoom } = chatActions;
  const { handleApiResponse, handleDownload } = useHandleApiResponse();
  const dispatch = useDispatch();
  const { uploadFile } = SettingsActions;

  useEffect(() => {
    if (invoiceNumber && display) {
      let input = {
        invoiceNumber: invoiceNumber,
        page: currentPage,
        pageSize: 10,
      };

      dispatch(getChatRoom(input)).then((res) => {
        let chats = res.payload.data;

        messageData.forEach((existingChat) => {
          if (
            !chats.some(
              (newChat) => newChat.invoiceNumber === existingChat.invoiceNumber
            )
          ) {
            messageData.length = 0;
          }
        });

        const filteredArray = chats?.filter(
          (item) => !messageData.some((x) => x.id === item.id)
        );

        setMessageData([...filteredArray, ...messageData]);
      });
    } // eslint-disable-next-line
  }, [invoiceNumber, display]);

  useEffect(() => {
    if (bottomScrollRef.current && !isTop) {
      bottomScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageData, isTop]);

  const dispatchMessage = (input) => {
    dispatch(postChatMessage(input)).then((res) => {
      handleApiResponse(res, null);

      let input = {
        invoiceNumber: invoiceNumber,
        page: 1,
        pageSize: pageSize,
      };

      dispatch(getChatRoom(input)).then((res) => {
        setPage(1);

        const filteredArray = res.payload.data?.filter(
          (item) => !messageData.some((x) => x.id === item.id)
        );

        setMessageData([...filteredArray, ...messageData]);
      });

      setMessage("");
      setLoadin(false);
      setFile(null);
    });
  };
  
  const handleMessageSend = async () => {
    setLoadin(true);
    let input = { invoiceNumber, message };
    if (file) {
      dispatch(uploadFile(file)).then((res) => {
        input["fileUrl"] = res?.payload?.data;
        dispatchMessage({ ...input });
      });
      return;
    }
    dispatchMessage(input);
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;

    if (scrollTop === 0) {
      setIsTop(true);

      if (currentPage * pageSize < totalCount) {
        let input = {
          invoiceNumber: invoiceNumber,
          page: currentPage + 1,
          pageSize: pageSize,
        };

        dispatch(getChatRoom(input)).then((res) => {
          setPage(res.payload.page);

          const filteredArray = res.payload.data?.filter(
            (item) => !messageData.some((x) => x.id === item.id)
          );

          setMessageData([...messageData, ...filteredArray]);
        });

        setIsTop(false);
      }
    }
  };

  return (
    <NotificationContainer open={display}>
      <div className={`notification-nav-items ${open && "open"}`}>
        <div className="notification-nav-link-container">
          {["Chat"].map((el, index) => (
            <div
              className="notification-nav-link"
              key={index}
              onClick={() => setOpen(!open)}
            >
              {el}
            </div>
          ))}
        </div>
        <div onClick={() => handleClose(false)} className="close-btn">
          <AiOutlineClose color="white" />
        </div>
      </div>

      <div className="notification-content">
        <div
          className="test"
          onScroll={handleScroll}
          style={{ overflowY: "scroll" }}
        >
          {messageData?.length > 0 &&
            messageData
              .slice()
              .reverse()
              .map((message, index) => {
                return (
                  <NotificationItem key={index} style={{margin: "35px 10px"}}>
                    <div className="chat-dp">
                      {message?.profilePicture ? (
                        <img
                          src={message?.profilePicture}
                          alt="profilePic"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <ProfilePictureStyle style={{backgroundColor: getRandomColor()}}>
                        <ProfilePicture
                          name={message?.createdBy}>
                        </ProfilePicture>
                      </ProfilePictureStyle>
                      )}
                    </div>
                    <div className="chat-details">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="chat-details__header">
                          <Text weight="700" size="20px" color="#3A3E47">
                            {message.createdBy}
                          </Text>
                          <Text color="#7B8599" size="16px">
                            {moment(
                              new Date(
                                moment(
                                  message.createdOn,
                                  "YYYY/MM/DD HH:mm A"
                                ).toDate()
                              )
                            ).format("LT")}
                          </Text>
                        </div>
                        {message.fileUrl && (
                          <BsFillFileEarmarkArrowDownFill
                            className="download"
                            size={30}
                            onClick={() => handleDownload(message.fileUrl, 'easei_chat')}
                          />
                        )}
                      </div>
                      <Text
                        color="#7B8599"
                        size="16px"
                        className="chat-details__body"
                      >
                        {message.message}
                      </Text>
                    </div>
                    <div ref={bottomScrollRef} />
                  </NotificationItem>
                );
              })}
        </div>
        {isLoading && (
          <NotificationItem>
            <Spinner />
          </NotificationItem>
        )}
        {!isLoading && messageData?.length === 0 && <div>No Chat</div>}
      </div>

     { !paidInvoice && <div className="chat">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            position: "relative",
          }}
        >
          {file && (
            <Text style={{ position: "absolute", top: -35 }}>{file.name}</Text>
          )}
          <ChatBox
            placeholder="Type a message here...."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <label htmlFor="file" style={{marginLeft: "20px", cursor: "pointer"}}>
            <DownloadSvg />
          </label>
          <input
            id="file"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </div>
        <Button
          disabled={isLoading || message.length === 0 || loadin}
          isLoading={loadin}
          onClick={handleMessageSend}
        >
          Send
        </Button>
      </div>}
    </NotificationContainer>
  );
};

export default Chat;
