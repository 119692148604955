import { useCallback, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import differenceBy from "lodash/differenceBy";
import _ from "lodash";
import Button from "app/components/atoms/button/button.component";
import {
  BaseButton,
  InvertedButton,
  InvertedStatusButton,
  StatusButton,
} from "app/components/atoms/button/button.styles";
import Table, { NoData } from "app/components/molecules/table/table.component";
import Text, { Heading } from "app/components/atoms/typography";
import { ROUTES } from "utils/constants/constants.utils";
import { useCheckAdmin, useViewport } from "hooks";
import {
  CurrencyActions,
  CurrencySelectors,
} from "store/reducers/currency/currencySlice";
import { invoiceSelectors } from "store/reducers/invoice/invoiceSlice";
import { getUnitTypeName } from "utils/helpers/getUnitTypeName/getUnitTypeName";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import CustomModal from "app/components/atoms/modal/modal.component";
import {
  getInvoiceTotalAmount,
  getInvoiceTotalCost,
  getInvoiceTotalTax,
} from "utils/helpers/getInvoiceCalculations/getInvoiceCalculations";
import { FlexDiv } from "app/components/atoms/divs/div.styles";
import { NewInvoiceItem } from "app/components/cell/invoiceItem/invoiceItem.component";
import ModalHouse from "app/components/molecules/modals/modalHouse";
import { InvoiceRecordSummary } from "app/components/cell/invoiceRecordSummary/InvoiceRecordSummary";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { ButtonContainer,
   ContentWrapper,
   CustomSelect,
   InvoiceBody,
   InvoiceInputDataHouse,
   InvoiceSubjectInput,
   Label,
   OptionsSection, 
  } from "app/components/atoms/generalInvoiceStyles/general.sytlyes";

//#endregion

const BaseInvoiceSetup = ({handleSave, ClientDropDown, invoice, backRoute}) => {

  const { getCurrencies } = CurrencyActions;
  const { resetPreviewState } = appDataActions;

  const [isDeleteLoading] = useState(false);
  const isInvoiceLoading = useSelector(invoiceSelectors.isLoading);
  const currencies = useSelector(CurrencySelectors.currencies);
  const isCurrencyLoading = useSelector(CurrencySelectors.isLoading);

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [subject, setSubject] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [approveDel, setApproveDel] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const { role } = useCheckAdmin();
  const { mobile } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (row) => {
    setApproveDel(true);
    setDeleteItem(row.serviceName);
  };

  const handleCancel = () =>{

    dispatch(resetPreviewState());
    navigate(ROUTES.INVOICE);
  }

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.serviceName
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, "serviceName"));
      }
    };
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
        height="44px"
      >
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  const columns = [
    {
      name: "Order Name",
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 2,
    },
    {
      name: "UnitType",
      selector: (row) => getUnitTypeName(row.unitType),
    },
    {
      name: "Unit",
      selector: (row) => row.itemUnit,
    },
    {
      name: "Unit Price",
      selector: (row) => row.cost,
    },
    {
      name: "Unit Tax",
      selector: (row) => row.tax,
    },
    {
      name: "Price",
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: "",
      selector: (row) => (
        <ActionMenu
          popupList={["Delete"]}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  const mobileColumns = [
    {
      name: "Order Name",
      selector: (row) => row.serviceName,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      grow: 3,
    },

    {
      name: "Unit",
      selector: (row) => row.itemUnit,
    },
    {
      name: "Price",
      selector: (row) => getInvoiceTotalAmount([row]),
    },
    {
      name: "",
      selector: (row) => (
        <ActionMenu
          popupList={["Delete"]}
          row={row}
          handleDelete={handleDelete}
        />
      ),
      button: true,
    },
  ];

  useEffect(() => {
    dispatch(getCurrencies());
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (!_.isEmpty(invoice)) {

      setCurrency(
        currencies.filter(function (item) {
          return item.label === invoice.currency;
        })[0] || null
      );

      setInvoiceNo(invoice.invoiceNo);
      setData(invoice.services);
      setAdditionalInformation(invoice.additionalInformation);
      setSubject(invoice.subject);
      
    }
  }, [invoice, currencies]);

  const handleCloseModal = (e) => {
    setShowModal(false);
  };

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDataSave = (isPreview) =>{

    const payload = {
      subject: subject,
      services: data,
      currency: currency?.label,
      additionalInformation,
      invoiceNo: invoice.invoiceNo,
      id: invoice.id
    };
    
    handleSave(payload, isPreview)
  }

  return (
    <div style={{ margin: mobile ? "38px 10px" : "38px 100px" }}>
      {mobile ? (
        ""
      ) : (
        <FlexDiv>
          <Heading>{invoiceNo ? invoiceNo : "New Invoice"}</Heading>
          <BaseButton width="true" onClick={() => _.isEmpty(backRoute) ? navigate(-1) : navigate(backRoute)}>
            <Text color="white">Back</Text>
          </BaseButton>
        </FlexDiv>
      )}
     <OptionsSection>
        <ContentWrapper>
          {ClientDropDown}
          <InvoiceInputDataHouse>
            <Label>Currency</Label>
            <CustomSelect
              isDisabled={isCurrencyLoading}
              isLoading={isCurrencyLoading}
              placeholder="Select currency"
              options={currencies}
              onChange={handleCurrencyChange}
              className="react-select-container"
              classNamePrefix="react-select"
              name="currency"
              value={currency}
            />
          </InvoiceInputDataHouse>
          <InvoiceInputDataHouse>
          <Label>Subject</Label> 
            <InvoiceSubjectInput
              placeholder="what is the invoice for?"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </InvoiceInputDataHouse>
        </ContentWrapper>
      </OptionsSection>
      <InvoiceBody style={{ marginTop: "50px" }}>
        <Table
          title="Invoice Details"
          className="table"
          columns={mobile ? mobileColumns : columns}
          data={data}
          persistTableHead
          selectableRows
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          actions={<></>}
        />
        <div className="details">
          <NoData onClick={() => setShowModal(!showModal)} />
          <ModalHouse
            className="modal modal__extra"
            close={handleCloseModal}
            isOpen={showModal}
            form={<NewInvoiceItem data={data} setData={setData} />}
            title="New Invoice"
            height="100%"
          />
          {data.length > 0 && (
            <InvoiceRecordSummary
              summaryDisplayCondition={data.length !== 0}
              totalAmount={getInvoiceTotalAmount(data)}
              subTotal={getInvoiceTotalCost(data)}
              vat={getInvoiceTotalTax(data)}
              currency={currency?.label}
              comment={additionalInformation}
              setComment={setAdditionalInformation}
              role={role}
              placeholder="Enter additional details..."
            />
          )}
        </div>
      </InvoiceBody>
      <ButtonContainer>
        {data.length > 0 && (
          <>
            <InvertedButton
              onClick={()=>{ handleDataSave(true) }}
            >
              Preview
            </InvertedButton>
            <Button
              onClick={()=>{ handleDataSave(false) }}
              isLoading={isInvoiceLoading}
            >
              Save
            </Button>
          </>
        )}
        <div style={{ flex: data.length > 0 ? 1 : 3 }}></div>
        <InvertedButton
          className="cancel"
          onClick={handleCancel}
        >
          Cancel
        </InvertedButton>
      </ButtonContainer>
      
      {approveDel && (
        <CustomModal
          isOpen={approveDel}
          className="modal modal-del"
          close={setApproveDel}
        >
          <form
            style={{ marginTop: "65px", textAlign: "center" }}
            onSubmit={(e) => {
              e.preventDefault();
              setData(data.filter((item) => item.serviceName !== deleteItem));
              setApproveDel(false);
            }}
          >
            <Text>Are you sure you want to remove this item?</Text>
            <div className="btn" style={{ marginBottom: "20ox" }}>
              <InvertedStatusButton
                margin="0 10px 0 0"
                disabled={isDeleteLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setApproveDel(false);
                }}
              >
                {" "}
                NO
              </InvertedStatusButton>
              <StatusButton
                isLoading={isDeleteLoading}
                disabled={isDeleteLoading}
                type="submit"
              >
                YES
              </StatusButton>
            </div>
          </form>
        </CustomModal>
      )}
    </div>
  );
};

export default BaseInvoiceSetup;