import { formatDateForPlanExpiry } from "utils/helpers/formatDatForPlanExpiry/formatDateForPlanExpiry";
import { getBrowserDateTimeFromUTC } from "utils/helpers/getBrowserDateTime/getBrowserDateTime";
import { addCommasToNumber } from "utils/helpers/addCommaToNumber/addCommaToNumbers";
import { setDecimalPlaces } from "utils/helpers/setDecimalPlaces/setDecimalPlaces";
import PlanPaymentDetails from "app/components/cell/planPaymentDetails/plan-payment-details";

const RenderPlans = ({plans, myPlan}) => {
  return plans?.map(({ id, name, description, cost, features }) => {
    const status = name === myPlan?.planName;

    const state = status ? (myPlan?.isActive ? "Active" : "Expired") : "";
    const formattedCost = "₦ " + addCommasToNumber(setDecimalPlaces(cost));
    const expiryText = status
      ? formatDateForPlanExpiry(
          getBrowserDateTimeFromUTC(new Date(myPlan?.planExpiryDate)))
      : "Per Month";
    return (
      <PlanPaymentDetails
        id={id}
        name={name}
        description={description}
        features={features}
        actualCost={cost}
        state={state}
        formattedCost={formattedCost}
        expiryText={expiryText}
      />
    );
  });
};

export default RenderPlans;
