import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import OrganizationSelectDropdown from "app/components/molecules/organization/organization-dropdown";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import { recurringInvoiceActions } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import BaseRecurringInvoiceSetup from "../baseSetup/baseSetupRecurringInvoice";

const CreateInternalRecurringInvoice = () => {
  const { createInternal } = recurringInvoiceActions;
  const { resetPreviewRecurringInvoiceState, setPreviewRecurringInvoiceState } = appDataActions;
  const recurringInvoice = useSelector(appdataSelector.previewRecurringInvoice);

  const [organization, setOrganization] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiResponse } = useHandleApiResponse();
  
  const handleSave = (data, isPreview) =>{
    let payload = {
        organizationId: organization.value,
        type: INVOICE_TYPE.Internal,
        ...data
    }
    
    if(isPreview){
        dispatch(setPreviewRecurringInvoiceState(payload));
        navigate(ROUTES.PREVIEW_RECURRING_INVOICE)
    }
    else{
      dispatch(createInternal(payload)).then((res)=>{
        dispatch(resetPreviewRecurringInvoiceState());
        handleApiResponse(res, ROUTES.RECURRINGINVOICE);
      })
    }
  }

  return (
      <BaseRecurringInvoiceSetup
        recurringInvoice={recurringInvoice}
        ClientDropDown={
          <OrganizationSelectDropdown
            invoice={recurringInvoice}
            organization={organization}
            setOrganization={setOrganization}
          />
        }
        handleSave={handleSave}
      />
  );
};
export default CreateInternalRecurringInvoice;