import { ACTIVATION_STATUS, BOOLEAN_STATUS, INVOICE_STATUS, INVOICE_PAYMENT_STATUS } from "utils/constants/constants.utils";

export const createStatusStyle = (status) => {
  if (!status) return;

  switch (status) {
    case INVOICE_STATUS.APPROVED:
      return "#00b733";
    case INVOICE_STATUS.PENDING:
      return "#e2b93b";
    case INVOICE_STATUS.DECLINED:
      return "#e20001";
    case INVOICE_STATUS.CHANGE_REQUESTED:
      return "var(--primary)";
    default:
      return "";
  }
};

export const createPaymentStatusStyle = (paymentStatus) => {
  if (!paymentStatus) return;

  switch (paymentStatus) {
    case INVOICE_PAYMENT_STATUS.PAID:
      return "#00b733";
    case INVOICE_PAYMENT_STATUS.UNPAID:
      return "";
    case INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED:
      return "var(--primary)";
    default:
      return "";
  }
};

export const getStatusName = (invoiceStatus, paymentStatus) => {
  if (!invoiceStatus) return;
  switch (invoiceStatus.toLowerCase()) {
    case INVOICE_STATUS.PENDING.toLowerCase():
      return INVOICE_STATUS.PENDING;
    case INVOICE_STATUS.APPROVED.toLowerCase():
      if(paymentStatus === INVOICE_PAYMENT_STATUS.PAID)
      return INVOICE_PAYMENT_STATUS.PAID;
      else{
        return INVOICE_STATUS.APPROVED;
      }
    case INVOICE_STATUS.DECLINED.toLowerCase():
      return INVOICE_STATUS.DECLINED;
    case INVOICE_STATUS.CHANGE_REQUESTED.toLowerCase():
      return "Change Requested";
    default:
      return invoiceStatus;
  }
};

export const getPaymentStatusName = (paymentStatus) => {
  if (!paymentStatus) return;
  switch (paymentStatus.toLowerCase()) {
    case INVOICE_PAYMENT_STATUS.PAID.toLowerCase():
      return INVOICE_PAYMENT_STATUS.PAID;
    case INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED.toLowerCase():
      return INVOICE_PAYMENT_STATUS.VALIDATIONREQUESTED;
    case INVOICE_PAYMENT_STATUS.UNPAID.toLowerCase():
      return INVOICE_PAYMENT_STATUS.UNPAID;
    default:
      return paymentStatus;
  }
};

export const getBooleanStatusName = (status) => {
  switch (status) {
    case true:
      return BOOLEAN_STATUS.True;
    case false:
     return BOOLEAN_STATUS.False;
    default:
      return status;
  }
};

export const getActivationStatusName = (status) => {
  switch (status) {
    case true:
      return ACTIVATION_STATUS.Deactivate;
    case false:
     return ACTIVATION_STATUS.Activate;
    default:
      return status;
  }
};

export const getActiveStatusName = (status) => {
  switch (status) {
    case true:
      return BOOLEAN_STATUS.True;
    case false:
     return BOOLEAN_STATUS.False;
    default:
      return status;
  }
};

export const getBooleanStatusStyle = (status) => {
  switch (status) {
    case true:
      return "var(--primary)";
    case false:
      return "#e20001";
    default:
      return "";
  }
};