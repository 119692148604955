import { useViewport } from "hooks";
import ReminderCardMobile from "./reminderCardMobile";
import ReminderCardDesktop from "./reminderCardDesktop";


const SingleReminder = ({ data: datesData, setEditReminder, count }) => {
  const { mobile } = useViewport();
  
  const alternatingColorClass = count % 2 === 0 ? "var(--accent1)" : "var(--grey10)"; // Alternating background color


  return (
    <>
      {mobile ?
        <ReminderCardMobile
        datesData={datesData}
        setEditReminder={setEditReminder}
        alternatingColorClass={alternatingColorClass}
        />
        :
       <ReminderCardDesktop
        datesData={datesData}
        setEditReminder={setEditReminder}/>
      }
    </>
  );
};

export default SingleReminder;
