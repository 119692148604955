import styled, { css } from "styled-components";
import Button from "./button.component";

export const BaseButton = styled.button`
  min-width: ${({ minWidth }) => minWidth || "200px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "60px"};
  letter-spacing: 0.5px;
  padding: ${({ padding }) => padding || "16px 32px"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
   margin: ${({ margin }) => margin};
  background-color: #0148de;
  color: ${({ color }) => color || "#f2f6ff"};
  font-family: "DM Sans";
  font-weight: bolder;
  border: none;
      border-radius: ${({ radius }) => radius || "10px"};
  display: flex;
  justify-content: center;
  align-items: center;
p{
  margin:0;
}
  button[disabled="disabled"],
  button:disabled {
    cursor: not-allowed;
    background-color: var(--primary7);
  }

  &:hover:not([disabled]):not(:disabled){
  cursor: pointer;
    background-color: #3777ff;
    color: #f2f6ff;
    border: none;
  }
  @media screen and (max-width:768px) {
     ${({ responsiveWidth }) =>
    responsiveWidth &&
    css`
        width: ${(responsiveWidth) => responsiveWidth || "100%"};
      `};
        ;
  }
    @media screen and (max-width:520px) {
    min-width: ${({ minWidthM }) => minWidthM};
    }
    @media screen and (max-width:330px) {
    min-width: ${({ minWidthS }) => minWidthS};
    }
`;

export const InvertedButton = styled(BaseButton)`
  background-color: var(--white);
  color: #002776;
  border: 1px solid #0148de;
   max-width: ${({ width }) => width};
  margin-right: ${({ marginRight }) => marginRight};
  &:hover {
    background-color: #0148de;
    color: #f2f6ff;
    border: none;
  }
  
`;

export const SaveButton = styled.button`
  width: 120px;
  height: 70px;
  border-radius: 7px;
  margin-left: 300px;
  margin-top: 30px;
  border: 1px solid #b8c6e4;
  background-color: #f2f6ff;
  color: #002776;
  cursor: pointer;
`;

export const MobileBaseButton = styled(BaseButton)`
border-radius: 20px;
  height: fit-content;
  padding:${({padding})=> padding || ".6rem "};
  font-size: .75rem;
  width:${({ width }) => width || "fit-content"};
  min-width: fit-content;
  max-width:${({ maxWidth }) => maxWidth };
  margin:${({margin}) =>margin};
  max-height: ${({ maxHeight }) => maxHeight };;
  p{
    margin: 0;
  }
    @media screen and (max-width:350px) {
  font-size: .5rem;
   padding:${({ paddingRes }) => paddingRes};
    }
`

export const InvertedStatusButton = styled(BaseButton)`
  background-color: #f2f6ff;
  color: #002776;
  border: 1px solid #0148de;
  min-width: 150px;
  margin-right: ${({ marginRight }) => marginRight};
  &:hover {
    background-color: #d11a2a;
    color: #f2f6ff;
    border: none;
  }
`;

export const StatusButton = styled(BaseButton)`
  background-color: #f2f6ff;
  color: #002776;
  border: 1px solid #0148de;
  min-width: 150px;
`;

export const LogoutButton = styled(Button)`
  @media screen and (max-width:768px) {
    padding:500px;
    min-width: 100px;
    height: 50px;
  }
`;