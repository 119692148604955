import { useDispatch, useSelector } from "react-redux";
import PeopleEmpty from "./people.empty.component";
import PeopleList from "./people.list.component";

import { PeopleContainer } from "./people.styles";
import {
  SettingsActions,
  SettingsSelectors,
} from "store/reducers/settings/settingsSlice";
import {
  externalUserActions,
  externalUserSelectors,
} from "store/reducers/externalUser/externalUserSlice";
import { USER_ROLES } from "store/reducers/auth/utils";
import { useCheckAdmin } from "hooks";
import { useEffect } from "react";
import { appDataActions } from "store/reducers/appData/appDataSlice";

const People = () => {
  const users = useSelector(SettingsSelectors.users);
  const searchCount = useSelector(SettingsSelectors.totalCount);
  const externalCount = useSelector(externalUserSelectors.totalCount);
  const externalUsers = useSelector(externalUserSelectors.pagExternalUsers);

  const { role } = useCheckAdmin();
  const dispatch = useDispatch();

  const { setHeaderText } = appDataActions;
  const { getPaginatedExternalUsers } = externalUserActions;
  const { searchUsers } = SettingsActions;

  useEffect(() => {

    dispatch(setHeaderText({ value: 'People', type: 'setHeaderText' }));

    const input = { page: 1 };

    if (role === USER_ROLES.CLIENT_USER) {
      dispatch(getPaginatedExternalUsers(input));
    } else {
      dispatch(searchUsers(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PeopleContainer >
      {!users || !externalUsers ? (
        <PeopleEmpty />
      ) : (
        <PeopleList
          users={role === USER_ROLES.CLIENT_USER ? externalUsers : users}
          totalCount={
            role === USER_ROLES.CLIENT_USER ? externalCount : searchCount
          }
        />
      )}
    </PeopleContainer>
  );
};

export default People;
