import { useCallback, useRef } from "react";

export const useElementWithin = (callback, deps = []) => {
  const observer = useRef(null);
  const ref = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...deps]
  );

  return { ref };
};
