import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "MerchantConfiguration";

export const initialState = {
    data: [],
    totalCount: 0,
    merchantConfiguration :{},
    loading: false,
  };

  const searchMerchantConfigurations = createAsyncThunk(
    `${entity}/search`,
    
    async (input) => {
      const {
        page = 1,
        pageSize = 20,
        status,
        minimumAmount,
        maximumAmount,
        merchantId
      } = input;
      const res = await api.merchantConfiguration.search(
        page,
        pageSize,
        status,
        minimumAmount,
        maximumAmount,
        merchantId
      );
      return res.data;
    }
  );

  const updateStatus = createAsyncThunk(
    `${entity}/update-status`,
    async (input) => {
      const {
        id,
        status
      } = input;
      const res = await api.merchantConfiguration.updateStatus(
        id,
        status
      );
      return res.data;
    }
  );

  const create = createAsyncThunk(
    `${entity}/create`,
    async (input) => {
      const res = await api.merchantConfiguration.create(input);
      return res.data;
    }
  );

  const update = createAsyncThunk(
    `${entity}/update`,
    async (input) => {
      const res = await api.merchantConfiguration.update(input);
      return res.data;
    }
  );

  const merchantConfigurationSlice = createSlice({
    name: entity,
    initialState,
    reducers: { getMerchantCount: (state) => {
        state.merchantCount = state.data.length;
      }, },
    extraReducers: (builder) => {
      builder
        .addCase(searchMerchantConfigurations.fulfilled, (state, action) => {  
          return { ...state, loading: false, ...action.payload };
        })
        .addCase(searchMerchantConfigurations.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(searchMerchantConfigurations.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(create.fulfilled, (state, action) => {  
          return { ...state, loading: false, ...action.payload };
        })
        .addCase(create.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(create.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
        builder
        .addCase(update.fulfilled, (state, action) => {  
          return { ...state, loading: false, ...action.payload };
        })
        .addCase(update.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(update.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
    },
  });

  export const merchantConfigurationActions = 
  {
  ...merchantConfigurationSlice.actions,
    searchMerchantConfigurations,
    updateStatus,
    create,
    update
  };
  
  export const merchantConfigurationSelectors = {
    isLoading: (state) => state.merchantConfigurations.loading,
    merchantConfigurations: (state) => state.merchantConfigurations.data,
    totalCount: (state) => state.merchantConfigurations.totalCount,
    merchantConfiguration: (state) => state.merchantConfigurations.merchantConfiguration,
  };
  
  export default merchantConfigurationSlice.reducer;