import Text from "app/components/atoms/typography";
import styled from "styled-components";

const TabContainer = styled.div`
  min-height: 80px;
  padding: ${({ p }) => p || ""};

  p {
    margin: 0;
  }
  @media screen and (min-width: 650px) {
  }
`;

const Tab = ({ text, ...props }) => {
  const { padding } = props;
  return (
    <TabContainer p={padding}>
      <Text weight="700" size="24px">
        {text}
      </Text>
    </TabContainer>
  );
};

export default Tab;
