import Popup from "reactjs-popup";
import PopupContent from "app/components/atoms/popup/popup-content.component";
import React, { useState } from "react";
import { BsArrowUpRight, BsThreeDotsVertical } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ACTIVATION_STATUS, ROUTES } from "utils/constants/constants.utils";
import { useDispatch } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
const ActionButtonContainer = styled.button`
  background: #fff;
  cursor: pointer;
  border: none;
  svg {
    color: ${({ iconColor }) => iconColor || ""};
    width: 18.75px;
    height: 18.75px;
  }
`;

const ActionMenu = ({
  row,
  popupList,
  updateStatus = null,
  navigateTo = null,
  handleEdit = null,
  setActiveStatus,
  setId = null,
  activeStatus,
  handleDelete = null,
  downloadLink,
  downloadFileName,
  ext = false,
}) => {
  const { id, email } = row;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { handleApiResponse, handleDownload } = useHandleApiResponse();

  const handleViewOrEdit = (disabled, isEdit) => {
    if (isEdit) {
      if (navigateTo !== null) {
        navigate(navigateTo, { state: { data: row, disabled: disabled } });
      } else if (handleEdit !== null) {
        handleEdit(row);
      }
    } else {
      navigate(
      `${location.pathname === ROUTES.DASHBOARD
          ? ROUTES.INVOICE
          : location.pathname
        }/${id}`,
        { state: row }
      );
    }
  };

  const handleClick = (val) => {
    switch (val.toLowerCase()) {
      case "view":
        handleViewOrEdit(true, false);
        break;
      case "edit":
        handleViewOrEdit(false, true);
        break;
      case "delete":
        handleDelete(row);
        if (id) {
          setId(id);
        }
        break;
      case "download":
        handleDownload(downloadLink, downloadFileName);
        break;
      case ACTIVATION_STATUS.Activate.toLocaleLowerCase():
        if (ext) {
          updateStatus();
          dispatch(
            updateStatus({
              email: email,
              status: true,
            })
          ).then((res) => {
            setActiveStatus(!activeStatus);
          });
        } else {
          dispatch(
            updateStatus({
              id: id,
              status: true,
            })
          ).then((res) => {
            handleApiResponse(res, 0);
          });
        }
        break;
      case ACTIVATION_STATUS.Deactivate.toLocaleLowerCase():
        if (ext) {
          dispatch(
            updateStatus({
              email: email,
              status: false,
            })
          ).then((res) => {
            setActiveStatus(!activeStatus);
          });
        } else {
          dispatch(
            updateStatus({
              id: id,
              status: false,
            })
          ).then((res) => {
            handleApiResponse(res, 0);
          });
        }
        break;
      default:
        break;
    }
    setIsOpen(false);
  };

  return (
    <Popup
      trigger={() => (
        <ActionButtonContainer
          className="button"
          onClick={() => setIsOpen(true)}
        >
          {location.pathname==='/organization' ?
            <BsArrowUpRight onClick={() => setIsOpen(true)} /> : 
          <BsThreeDotsVertical onClick={() => setIsOpen(true)} />
        }
        </ActionButtonContainer>
      )}
      open={isOpen}
      position="center center"
      closeOnDocumentClick
    >
      {isOpen && <PopupContent list={popupList} handleClick={handleClick} />}
    </Popup>
  );
};

export default ActionMenu;
