import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IoIosAddCircleOutline } from "react-icons/io";
import _ from "lodash";

import {
  externalUserActions,
  externalUserSelectors,
} from "store/reducers/externalUser/externalUserSlice";
import ModalHouse from "../modals/modalHouse";
import { ExternalUserModal } from "app/components/cell/externalUserModal/externalUserModal";
import { CustomSelect, InputDataHouse, Label } from "app/components/atoms/generalInvoiceStyles/general.sytlyes";

//#region Styles

const AddNewClient = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #011747;
  cursor: pointer;
`;

//#endregion

const ExternalUser = ({ externalUser, setExternalUser, isDisabled, invoice }) => {
  const { getExternalUsers } = externalUserActions;
  const isExternalUserLoading = useSelector(externalUserSelectors.isLoading);
  const externalUsers = useSelector(
    externalUserSelectors.externalUsersForDropdown
  );
  const [reload, setReload] = useState(false);
  const [showNewClientModal, setShowNewClientModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExternalUsers());
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_.isEmpty(invoice)) {
      setExternalUser(
        externalUsers.filter(
          (item) => item.value === invoice.externalUserId
        )[0] || null
      );
    }
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = (e) => {
    setShowNewClientModal(false);
  };

  return (
    <>
      <InputDataHouse>
        <Label>Customer</Label>
        <CustomSelect
          isClearable
          placeholder="Select Customer"
          className="react-select-container"
          classNamePrefix="react-select"
          options={externalUsers}
          isDisabled={isExternalUserLoading || isDisabled}
          isLoading={isExternalUserLoading}
          onChange={(user) => setExternalUser(user)}
          formatCreateLabel={(userInput) => `Add ${userInput} as new client`}
          value={externalUser}
        />

      <AddNewClient onClick={() => setShowNewClientModal(!showNewClientModal)}>
        <IoIosAddCircleOutline size={20} /> Add New Client
      </AddNewClient>
      </InputDataHouse>

      <ModalHouse
        className="modal modal__extra"
        close={handleCloseModal}
        isOpen={showNewClientModal}
        form={
          <ExternalUserModal
            reload={reload}
            setReloaad={setReload}
            close={setShowNewClientModal}
          />
        }
        title="New Client"
        height="500px"
      />
    </>
  );
};

export default ExternalUser;