import React from 'react'
import Text, { Heading } from 'app/components/atoms/typography'
import { PlanContainer, PlanContentBox, CheckListContainer } from './plan.style'
import CheckList from './plan-checkList'
import { useLocation } from 'react-router-dom'
import { appDataActions } from 'store/reducers/appData/appDataSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const MobilePlanContent = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { setHeaderText } = appDataActions;

    useEffect(() => {
        dispatch(setHeaderText({ value: "Plan Details", type: 'setHeaderText' }));

        // eslint-disable-next-line
    }, []);
    return (
    <PlanContainer className="dashboard-container">
        <Heading size={32} color="var(--primary3)">
            Organization
        </Heading>
        <PlanContentBox maxWidth="25rem">
            <Heading responsiveFontSize={14} size={32}>{location.state?.name}</Heading>
            <Text responsiveFontSize={14}>{location.state?.description}</Text>
            <Heading responsiveFontSize={14} size={32}>{location.state?.price}</Heading>
            <Text responsiveFontSize={14} borderBottom=" 1px solid red">{location.state?.duration}</Text>
                <div style={{ height: "1px", width: "75%", backgroundColor: "var(--primary2)" }}></div>           
                <CheckListContainer>
                <Heading level={4} color="black" weight="500" margin="40px 0 0">
                    Manage tasks and personal to-dos:
                </Heading>
                {location.state?.lists?.map(({description, id})=>{ 
                    return <CheckList key={id} checkList={description} />})}
                </CheckListContainer>

        </PlanContentBox>
    </PlanContainer>
    )
}

export default MobilePlanContent