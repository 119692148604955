import styled from "styled-components";

export const OrganizationTopBanner = styled.div`
background: white;
box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
border-top: 2px solid #dbe7ff;
width: 100%;

.organization__new {
  display: flex;
  justify-content: space-between;
  div {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.organization__close {
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    opacity: 0.65;
    filter: alpha(opacity=65);
    color: #808080;
    width: 25px;
    height: 25px;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
}
`;

export const CreateNewContainer = styled.div`
  width: 246px;
  height: 80px;
  letter-spacing: 0.5px;
  line-height: 60px;
  padding: 0 30px 0 30px;
  background-color: #0148de;
  color: #f2f6ff;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  @media screen and (max-width: 650px) {
    padding: 10px;
  }
`;

export const FormContainer = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 40px 0;
  input {
    margin-top: 5px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
`;

export const BoxColumn = styled.td`
padding: 0px 5px 0px 5px;
max-width: 200px;
`;
