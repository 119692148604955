import Text, { Heading } from "app/components/atoms/typography";
import { TableHeader } from "app/pages/reminder/clientsTable";
import { useViewport } from "hooks";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { recurringInvoiceActions, recurringInvoiceSelectors } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import Card from "app/components/atoms/card/card.component";
import { useSelector } from "react-redux";
import { getActivationStatusName, getBooleanStatusName, getBooleanStatusStyle } from "utils/helpers/createStatusStyle/createStatusStyle";
import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import RecurringInvoiceSummary from "../recurringInvoiceSummary/recurring-invoice-summary";
import Table from "app/components/molecules/table/table.component";
import RecurringInvoiceMobileView from "../recurringInvoiceMobileView/recurring-invoice-mobile";

const headerStyle = {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 0,
  };

const RecurringInvoiceList = ({
    recurringInvoices,
    activeCount,
    inActiveCount,
    totalCount,
    loading,
    status,
    setStatus,
    reload,
    setReload,
    ...rest
}) =>{
  const [page, setPage] = useState(1);

  const componentRef = useRef();
  const { mobile } = useViewport();
  const navigate = useNavigate();
  const pageCount = useSelector(recurringInvoiceSelectors.pageCount);

  const { updateStatus } = recurringInvoiceActions;

  const handleEdit = (data) => {
    if(data.typeName === INVOICE_TYPE.External){
      navigate(`${ROUTES.EDIT_EXTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${data.id}`);
    }
    else{
      navigate(`${ROUTES.EDIT_INTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${data.id}`);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const columns = [
    {
      name: "Date Created",
      selector: (row) => row.createdDate,
      format: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "BillTo",
      selector: (row) => row.billTo,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Amount",
      selector: (row) => (row.totalAmount + row.totalTax).toLocaleString(),
    },
    {
      name: "RecurType",
      selector: (row) => row.recurTypeName,
    },
    {
      name: "Status",
      selector: (row) => row.isActive,
      format: (row) => (
        <p
          style={{
            color: getBooleanStatusStyle(row.isActive),
          }}
        >
          {getBooleanStatusName(row.isActive)}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <ActionMenu
          popupList={["View", "Edit", getActivationStatusName(row.isActive)]}
          row={row}
          updateStatus={updateStatus}
          handleEdit={handleEdit}
        />
      ),
      button: true,
    },
  ];

  return(   
  <Card>
    <div ref={componentRef}>
        <TableHeader style={headerStyle}>
            <div style={{ borderBottom: "1px solid #F2F6FF", width: "100%" }}>
              <Heading
                color=" var(--primary2)"
                size={mobile ? 12.24 : 24}>
               Scheduled Invoices
              </Heading>
              <Text
                color="var(--grey2)"
                size={mobile ? 10 : 16}
                margin="0">
                These invoices will be sent out automatically on their recur dates.
              </Text>
            </div>

            <RecurringInvoiceSummary
               activeCount={activeCount}
               inActiveCount={inActiveCount}
                totalCount={totalCount}
                setStatus={setStatus}
              />
            {mobile ?
                <RecurringInvoiceMobileView
                recurringInvoices={recurringInvoices}
                pageCount={pageCount}
                page={page}
                setpage={page}
                onChangePage={handlePageChange}
                handleEdit={handleEdit}
                reload={reload}
                setReload={setReload}
                />
                :
                <Table
                columns={columns}
                data={recurringInvoices}
                pagination
                progressPending={loading}
                actions={<></>}
                paginationServer
                paginationTotalRows={totalCount}
                {...rest}
                />
            }
        </TableHeader>
      
    </div>
  </Card>)
}

export default RecurringInvoiceList;