import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as NoDataSvg } from "app/assets/svg/rafiki2.svg";
import Card from "app/components/atoms/card/card.component";
import Text, { Heading } from "app/components/atoms/typography";
import { Flex, OrgUserDashboardWrapper as OrgDashboardWrapper } from "./dashboard.styles";
import { TableHeader } from "../reminder/clientsTable";
import { getInvoiceStatusCount } from "utils/helpers/getInvoiceStatusCount/getInvoiceStatusCount";
import { useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import {
  createPaymentStatusStyle,
  createStatusStyle,
  getPaymentStatusName,
  getStatusName,
} from "utils/helpers/createStatusStyle/createStatusStyle";
import BarChart from "app/components/chart";

import ChartLabel from "app/components/chart/ChartLabel";

import {
  dashboardSelectors,
  dashboardActions,
} from "store/reducers/dashboard/dashboardSlice";
import { useCheckAdmin, useViewport } from "hooks";
import { USER_ROLES } from "store/reducers/auth/utils";
import Carousel from "app/components/MetricCarousel";
import CircularProgressBar from "app/components/progress-bar/CircularProgressBar";
import { OptionSelectCircle, SelectOccurance, SummaryLink } from "app/components/atoms/divs/div.styles";
import InvoiceList from "app/components/cell/invoiceList/invoiceList";
import { INVOICE_STATUS_DROPDOWN_DATA } from "utils/constants/constants.utils";

export const WeekActive = styled.div`
  position: relative;
  width: fit-content;
  @media screen and (max-width: 800px) {
    bottom: 20%;
    margin-inline: auto;
  }
  > p {
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: 50%;
    transform: translate(-30%, -10%);
    @media screen and (max-width: 800px) {
      bottom: 20%;
    }
  }
  .activate {
    background: #5cbc77;
    border-radius: 50px;
    width: 112px;
    height: 44px;
    position: relative;
    display: inline-block;
    border: none;
    align-self: flex-end;
    ::after {
      content: "";
      position: absolute;
      right: 0.5em;
      top: 0.7em;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
    }
  }
  .inactive {
    background: #7b8599;
    ::after {
      left: 0.5em;
      right: auto;
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
flex-direction: column;
justify-content: flex-start;
width: fit-content;
margin-right: auto;

  @media screen and (min-width: 800px) {
    margin: 0 0 38px auto;
  }
`;

const OrganizationDashboard = ({
  active,
  setActive,
  timeInput,
  setTimeInput,
}) => {
  const dispatch = useDispatch();
  const [statusInput] = useState("Status");
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { usersDashboard } = dashboardActions;

  const lastFiveInvoices = useSelector(dashboardSelectors.lastFiveInvoices);
  const timeData = useSelector(dashboardSelectors.timeData);
  const invoiceMetrics = useSelector(dashboardSelectors.invoiceMetrics);
  const isLoading = useSelector(dashboardSelectors.isLoading);

  const { role } = useCheckAdmin();
  const { mobile } = useViewport()
  
  useEffect(() => {
    dispatch(usersDashboard(timeInput));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInput]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      format: (row) => new Date(row.date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Invoice No",
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) =>
        role === USER_ROLES.CLIENT_USER ? row.billTo : row.from,
      sortable: true,
      span: 2,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Amount",
      selector: (row) => row.totalAmount + row.totalTax,
      sortable: true,
      format: (row) =>
        (row.totalAmount + row.totalTax)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
    {
      name: "Invoice status",
      selector: (row) => row.statusName,
      sortable: true,
      format: (row) => (
        <p
          style={{
            color: createStatusStyle(row.statusName),
          }}
        >
          {getStatusName(row.statusName)}
        </p>
      ),
    },
    {
      name: "Payment status",
      selector: (row) => row.invoicePaymentStatus,
      sortable: true,
      format: (row) => (
        <p
          style={{
            color: createPaymentStatusStyle(row.invoicePaymentStatusName),
          }}
        >
          {getPaymentStatusName(row.invoicePaymentStatusName)}
        </p>
      ),
    },
  ];

  return (
    <OrgDashboardWrapper>
      <Carousel invoiceMetrics={invoiceMetrics} />
      {mobile ? ""
        :
        <div style={{ marginTop: "24px" }}>
          <InvoiceList
            invoices={lastFiveInvoices}
            pendingCount={
              invoiceMetrics?.filter(function (item) {
                return item.name === "Pending";
              })[0]?.Count || 0
            }
            approvedCount={
              invoiceMetrics?.filter(function (item) {
                return item.name === "Approved";
              })[0]?.Count || 0
            }
            declinedCount={
              invoiceMetrics?.filter(function (item) {
                return item.name === "Declined";
              })[0]?.Count || 0
            }
            changeRequestCount={
              invoiceMetrics?.filter(function (item) {
                return item.name === "ChangesRequested";
              })[0]?.Count || 0
            }
            totalCount={
              invoiceMetrics?.filter(function (item) {
                return item.name === "Total";
              })[0]?.Count || 0
            }
            loading={isLoading}
            columnsOrg={columns}
            selectableRows={false}
            pagination={false}
            tableHeader={
              <TableHeader
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 0,
                  margin: 0,
                }}
              >
                <div style={{ borderBottom: "1px solid #F2F6FF", width: "100%" }}>
                  <Heading color=" var(--primary2)" level={2}>
                    Invoice Status
                  </Heading>
                  <Text color="var(--grey2)">
                    Monitor the progress of your invoice
                  </Text>
                </div>

                <SummaryLink style={{ borderBottom: "none", margin: 0 }}>
                  {INVOICE_STATUS_DROPDOWN_DATA.option.map((status, index) => {
                    let count = getInvoiceStatusCount(
                      status.label,
                      invoiceMetrics?.filter(function (item) {
                        return item.name === 'Pending';
                      })[0]?.count || 0,
                      invoiceMetrics?.filter(function (item) {
                        return item.name === "Approved";
                      })[0]?.count || 0,
                      invoiceMetrics?.filter(function (item) {
                        return item.name === "Declined";
                      })[0]?.count || 0,
                      invoiceMetrics?.filter(function (item) {
                        return item.name === "ChangesRequested";
                      })[0]?.count || 0,
                      invoiceMetrics?.filter(function (item) {
                        return item.name === "Total";
                      })[0]?.count || 0
                    );
                    return (
                      <div
                        key={index}
                        className={`${(statusInput === status.label && status.label) ||
                          ((statusInput === "" || statusInput === "Status") &&
                            status.label === "All")
                          ? "active"
                          : ""
                          }`}
                      >
                        <Text
                          size={18}
                          weight={500}
                          color={createStatusStyle(status.label)}
                        >
                          {status.label}
                        </Text>
                        <div className="summary-link-count">
                          <p>{count}</p>
                        </div>
                      </div>
                    );
                  })}
                </SummaryLink>
              </TableHeader>
            }
            noDataComponent={
              <NoData>
                <NoDataSvg width={"100%"} />
                <Text color="#7B8599" style={{ marginTop: "16px" }}>
                  You have no invoice to review yet. Create an invoice to get
                  started.
                </Text>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                    marginTop: "40px",
                  }}
                ></div>
              </NoData>
            }
          />
        </div>
      }

      <Card style={{ marginTop: "24px" }}>
        {mobile ?
          <>
            <TableHeader style={{ display: "block" }}>
              <div
                className="week-active"
                style={{ borderBottom: "1px solid #F2F6FF" }}
              >
                <Flex justifyContent="flex-end">
                  <Flex>
                    <OptionSelectCircle
                      border="2px solid var(--primary2)"
                      style={{
                        backgroundColor: selectedDuration === 'Weekly' ? 'var(--primary2)' : ''
                      }}>

                    </OptionSelectCircle>
                    <SelectOccurance
                      margin="0 0 0 15px"
                      size={14}
                      onClick={() => {
                        setActive(active);
                        setTimeInput({ ...timeInput, weekly: active });
                        setSelectedDuration('Weekly')
                      }}
                    >
                      Weekly
                    </SelectOccurance>
                  </Flex>
                  <Flex>
                    <OptionSelectCircle
                      border="2px solid var(--primary2)"
                      style={{
                        backgroundColor: selectedDuration === 'Monthly' ? 'var(--primary2)' : ''
                      }}
                    ></OptionSelectCircle>
                    <SelectOccurance
                      size={14}
                      onClick={() => setSelectedDuration('Monthly')}

                    >
                      Monthly
                    </SelectOccurance>
                  </Flex>


                </Flex>
                <div>
                  <Heading
                    color=" var(--primary2)"
                    size={mobile ? 16 : 18.72}>
                    Invoice statistics
                  </Heading>
                  <Text
                    color="var(--grey2)"
                    size={mobile ? 12 : 16}
                  >
                    Track your invoice flow for any given period
                  </Text>
                </div>


                {/* <WeekActive
              onClick={() => {
                setActive(!active);
                setTimeInput({ ...timeInput, weekly: !active });
              }}
            >
              <button
                disabled={isLoading}
                className={`activate ${active ? "" : "inactive"}`}
              ></button>
              <Text
                color={`${active ? "var(--primary1) " : "#fff"}`}
                weight="700"
                style={{ left: `${active ? "30px" : "50%"}` }}
              >
                WEEKLY
              </Text>
            </WeekActive> */}


              </div>
            </TableHeader>

            <LabelContainer>
              <ChartLabel color={"#6C98F3"} invoice="Approved Invoices" />
              <ChartLabel color={"#BF4545"} invoice="Declined Invoices" />
              <ChartLabel color={"#C99A0A"} invoice="Pending Invoices" />
            </LabelContainer>
          </>
          :
          <>
            <TableHeader style={{ display: "block" }}>
              <div
                className="week-active"
                style={{ borderBottom: "1px solid #F2F6FF" }}
              >
                <div>
                  <Heading
                    color=" var(--primary2)"
                    level={3}>
                    Invoice statistics
                  </Heading>

                  <Text
                    color="var(--grey2)"
                    size={16}
                  >
                    Track your invoice flow for any given period
                  </Text>
                </div>
                <Flex justifyContent="flex-end">
                  <Flex>
                    <OptionSelectCircle
                      border="2px solid var(--primary2)"
                      style={{
                        backgroundColor: selectedDuration === 'Weekly' ? 'var(--primary2)' : ''
                      }}
                    ></OptionSelectCircle>
                    <SelectOccurance
                      margin="0 0 0 15px"
                      size={14}
                      onClick={() => {
                        setActive(!active);
                        setTimeInput({ ...timeInput, weekly: !active });
                        setSelectedDuration('Weekly')
                      }}
                    >
                      Weekly
                    </SelectOccurance>
                  </Flex>

                  <Flex>
                    <OptionSelectCircle
                      border="2px solid var(--primary2)"
                      style={{
                        backgroundColor: selectedDuration === 'Monthly' ? 'var(--primary2)' : ''
                      }}
                    ></OptionSelectCircle>
                    <SelectOccurance
                      size={14}
                      onClick={() => {
                        setActive(active);
                        setTimeInput({ ...timeInput, weekly: active })
                        setSelectedDuration('Monthly')
                      }}

                    >
                      Monthly
                    </SelectOccurance>
                  </Flex>
                </Flex>


              </div>
            </TableHeader>

            <LabelContainer>
              <ChartLabel color={"#6C98F3"} invoice="Approved Invoices" />
              <ChartLabel color={"#BF4545"} invoice="Declined Invoices" />
              <ChartLabel color={"#C99A0A"} invoice="Pending Invoices" />
            </LabelContainer>
          </>
        }

        <BarChart timeData={timeData} />
      </Card>

      <div style={{ marginTop: "20px" }}>
        {mobile ?
          <Card h="400px" className="cheat-card" style={{ position: "relative" }}>
            <Text
              textAlign="center"
              size={14}
              weight={700}
              color="#23218B"
              text="External Clients vs Organisation"
            />
            <CircularProgressBar
              // count={clientUserInfo?.freelancers}
              count={80}
              width={200}
              radius={90}
              color="#FE9900"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text size="20px" weight="700" style={{ margin: 0 }}>
                {/* {clientUserInfo?.freelancers + clientUserInfo?.contractors || 0} */}
              </Text>
              <Text weight="700" style={{ margin: 0 }}>
                Total
              </Text>
            </div>
            <CircularProgressBar
              count={20}
              width={180}
              radius={72}
              color="#00C8F0"
            />
            <div
              style={{
                position: "absolute",
                bottom: "10px",
              }}
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <div>
                  <ChartLabel size={12} color={"#6C98F3"} invoice="External Clients" />
                  <Text>80&</Text>
                </div>
                <div>
                  <ChartLabel size={12} color={"#FE9900"} invoice=" Organisation" />
                  <Text>20%</Text>
                </div>
              </div>
            </div>
          </Card>
          : ""}
      </div>
    </OrgDashboardWrapper>
  );
};

export default OrganizationDashboard;
