import React, { useState } from "react";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FilterDropdown, OrganisationFilter, OrganisationFilters, OrganizationInputCard, OrganizationSearchContainer } from "./organization.styles";
import Text from "app/components/atoms/typography";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import { BsFilter } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "app/components/atoms/form-input/form-input.component";
import { PLAN_TYPE_NUMBER } from "utils/constants/constants.utils";
import { useOnClickOutside } from "hooks";

const statusObj = [
  {
    value: null,
    label: "All",
  },
  {
    value: false,
    label: "In-Active",
  },
  {
    value: true,
    label: "Active",
  },
];

const OrganizationSearch = ({setName, setIsActive, setPlanId}) => {

  const dispatch = useDispatch();

  const { getPlans } = planActions;
  const plans = useSelector(planSelectors.organizationPlansForDropdown) || [];
  const [filter, setFilter] = useState(null);
  const [planSelectedOption, setPlanSelectedOption] = useState(null);
  const [statusSelectedOption, setStatusSelectedOption] = useState(null);
  const { visible: planVisible, setVisible: setPlanVisible, ref: planRef } = useOnClickOutside(false);
  const { visible: statusVisible, setVisible: setStatusVisible, ref: statusRef } = useOnClickOutside(false);

  useEffect(() => {
    dispatch(getPlans({
        planType: PLAN_TYPE_NUMBER.ORGANIZATION}));
  },[]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <OrganizationSearchContainer>
        <OrganizationInputCard>
          <AiOutlineSearch style={{ width: "40px" }} onClick={()=>{setName(filter)}}/>
          <Input
            placeholder="Name"
            type="text"
            onChange={(e) => setFilter(e.target.value)}
          />
        </OrganizationInputCard>

        <OrganisationFilters>
          <OrganisationFilter
            onClick={() => setPlanVisible(!planVisible)}
            >
            <Flex>
              <Text>{planSelectedOption || "Filter by Plan"}</Text>
              <BsFilter/>
            </Flex>
            {planVisible && (
              <FilterDropdown ref={planRef}>
                {plans?.map((option) => (
                  <div
                    className="dropdown_options"
                    key={option.value}
                    onClick={() => {
                      setPlanId(option.value);
                      setPlanSelectedOption(option.label);
                      setPlanVisible(!planVisible);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </FilterDropdown>
            )}
          </OrganisationFilter>

          <OrganisationFilter onClick={() => setStatusVisible(!statusVisible)}>
            <Flex>
              <Text>{statusSelectedOption || "Filter by Status"}</Text>

              <BsFilter />
            </Flex>
            {statusVisible && (
              <FilterDropdown ref={statusRef}>
                {statusObj?.map((option) => (
                  <div
                    className="dropdown_options"
                    key={option.value}
                    onClick={() => {
                      setIsActive(option.value);
                      setStatusSelectedOption(option.label);
                      setStatusVisible(!statusVisible); 
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </FilterDropdown>
            )}
          </OrganisationFilter>
        </OrganisationFilters>

       
      
      </OrganizationSearchContainer>
  );
};

export default OrganizationSearch;
