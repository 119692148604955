export const OrganizationUserSignUpformContent = [
  {
    name: "businessName",
    label: "Business Name",
    type: "text",
    placeholder: "Easei",
  },
  {
    name: "businessEmail",
    label: "Email",
    type: "email",
    placeholder: "info@easei.com",
  },
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "Jane",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  {
    name: "businessPhoneNumber",
    label: "PhoneNumber",
    type: "text",
    placeholder: "+234 998 4848 383",
  },
  {
    name: "industry",
    label: "Industry",
    type: "text",
    placeholder: "Technology",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "********",
  }
];

export const clientUserContent = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "John",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  {
    name: "email",
    label: "Email Address",
    type: "email",
    placeholder: "john.doe@gmail.com",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "phone",
    placeholder: "+2348036021425",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "********",
  }
];
