import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "adminDashboard";

export const initialState = {
  invoiceMetrics: [],
  organizationMetrics: [],
  graphData: [],
  topOrBottomFiveClients: [],
  topOrBottomFiveOrganizations: [],
  clientUserInfo: {},
  loading: false,
};

const adminDashboard = createAsyncThunk(
  `${entity}/dashboard-admin-details`,
  async (input) => {
    const { day, month, year, topOrganization, topClient } = input;
    const res = await api.dashboard.easeiDashboard(
      day,
      month,
      year,
      topOrganization,
      topClient
    );
    return res.data.data;
  }
);

const adminDashboardSlice = createSlice({
  name: entity,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(adminDashboard.fulfilled, (state, action) => {
        return { ...state, loading: false, ...action.payload };
      })
      .addCase(adminDashboard.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(adminDashboard.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});
export const adminDashboardActions = {
  ...adminDashboardSlice.actions,
  adminDashboard,
};

export const adminDashboardSelectors = {
  isLoading: (state) => state.adminDashboard.loading,
  invoiceMetrics: (state) => state.adminDashboard.invoiceMetrics,
  organizationMetrics: (state) => state.adminDashboard.organizationMetrics,
  graphData: (state) => state.adminDashboard.graphData,
  topOrBottomFiveClients: (state) =>
    state.adminDashboard.topOrBottomFiveClients,
  clientUsersInfo: (state) => state.adminDashboard.clientUsersInfo,
  topOrBottomFiveOrganizations: (state) =>
    state.adminDashboard.topOrBottomFiveOrganizations,
};
export default adminDashboardSlice.reducer;
