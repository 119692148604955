/**
 * @readonly
 * @enum {string}
 */
export const USER_ROLES = {
  EASEI_ADMIN: "EaseiAdmin",
  EASEI_USER: "EaseiUser",
  CLIENT_USER: "ClientUser",
  ORGANIZATION_ADMIN: "OrganizationAdmin",
  ORGANIZATION_USER: "OrganizationUser",
  MERCHANT_ADMIN: "MerchantAdmin"
};
