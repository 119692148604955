import { useRef } from "react";
import Text, { Heading } from "app/components/atoms/typography";
import Card from "app/components/atoms/card/card.component";
import { addCommasToNumber } from "utils/helpers/addCommaToNumber/addCommaToNumbers";
import { setDecimalPlaces } from "utils/helpers/setDecimalPlaces/setDecimalPlaces";
import { useCheckAdmin, useViewport } from "hooks";
import React from "react";
import { RiArrowLeftSFill} from "react-icons/ri";
import { externalUserSelectors } from "store/reducers/externalUser/externalUserSlice";
import { authSelectors } from "store/reducers/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { convertCurrencyToSign } from "utils/helpers/convertCurrencyToSign/convertCurrencyToSign";
import {
  getInvoiceTotalAmount,
  getInvoiceTotalCost,
  getInvoiceTotalTax,
} from "utils/helpers/getInvoiceCalculations/getInvoiceCalculations";
import { BaseButton } from "app/components/atoms/button/button.styles";
import { useNavigate } from "react-router-dom";
import { AccountDetails, AdditionalInfo, AdditionalNoteArea, InvoiceDetailsContent, VeryFlexedDiv } from "app/components/atoms/divs/div.styles";
import { InvoiceRecordSummary } from "app/components/cell/invoiceRecordSummary/InvoiceRecordSummary";
import LoadingDots from "app/components/atoms/loading-dots/loading-dots.component";
import {
  getActiveStatusName,
  getBooleanStatusStyle,
} from "utils/helpers/createStatusStyle/createStatusStyle";
import { InvoiceHeader } from "app/pages/invoice/invoice.styles";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { useEffect } from "react";

const BaseRecurringInvoiceDetails = ({
  recurringInvoice,
  accountInformation,
  loading,
  backRoute,
  extraComponent
}) => {
  const {
    subject='',
    billTo,
    services = [],
    additionalInformation,
    currency,
    typeName,
    externalUserId,
    organizationId
    } = recurringInvoice;

  const { mobile } = useViewport();
  const { role } = useCheckAdmin();
  const { setHeaderText } = appDataActions;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const componentRef = useRef();
  const externalUsersForDropdown = useSelector(
    externalUserSelectors.externalUsersForDropdown
  );

  const organizationsForDropdown = useSelector(
    authSelectors.organizationsForDropdown
  );

   const handleNavigateBack = (e) => {
    e.preventDefault()
    navigate(backRoute);
  }

  useEffect(() => {

    dispatch(setHeaderText({ value: 'Recurring Invoice', type: 'setHeaderText' }));

    // eslint-disable-next-line 
  }, []);

  //#region handle events

  const handleGetBillTo = () => {
    if (billTo) {
      return billTo;
    } else if (typeName === "Internal" || organizationId > 0) {
      let organization =
        organizationsForDropdown.filter(function (org) {
          return org.value === organizationId;
        })[0] || null;
      return organization?.label;
    } else if (typeName === "External" || externalUserId > 0) {
      let externalUser =
        externalUsersForDropdown.filter(function (ext) {
          return ext.value === externalUserId;
        })[0] || null;
      return externalUser?.label;
    }
  };

  if (loading || _.isEmpty(services)) {
    return <LoadingDots text={"Hold on"}/>;
  }

  //#endregion

  return (
    <div>
        <div className="invoice-details__close">
         {!mobile && 
         <Card padding="32px 40px 0">
            <InvoiceHeader>
              <Heading/>
              <BaseButton width="true" onClick={handleNavigateBack}>
                <RiArrowLeftSFill color="white" /> <Text color="white">Back</Text>
              </BaseButton>
            </InvoiceHeader>
        <VeryFlexedDiv
          margin="14px 0"
          justifyContent="true"
          className="invoice-details__banner"
        >
          <Text color={getBooleanStatusStyle(recurringInvoice?.isActive)}>
            {getActiveStatusName(
              recurringInvoice?.isActive
            )}
          </Text>
        </VeryFlexedDiv>
       
        </Card> 
        }
        {mobile &&
          <VeryFlexedDiv
            margin={mobile ? "5px" : "5px 40px"}
            justifyContent="true"
            className="invoice-details__banner"
          >
            <Text color={getBooleanStatusStyle(recurringInvoice?.isActive)}>
                {getActiveStatusName(recurringInvoice?.isActive)}
          </Text>
          </VeryFlexedDiv>
        }

        {extraComponent}
        
        </div>

      <div style={{ margin:mobile ? "5px" : "20px 40px" }}>
        <InvoiceDetailsContent ref={componentRef}>
          <Card className="card">
            <div className="card__header">
              <Heading
                size={mobile ? 18 : ""}
                color="#f2f6ff">
                {subject}
              </Heading>
            </div>

            <div className="card__body">
              <div className="flex">
                <div>
                  <Text
                    size={mobile ? 12 : ""}
                    color="var(--grey2)"
                    weight="700"
                  >Invoice Number</Text>
                  <Text size={mobile ? 12 : ""} weight="700">
                    {"________"}
                  </Text>
                </div>
                <div className="right">
                  <Text
                    size={mobile ? 10 : ""}
                    color="var(--grey1)"
                    weight="700">Bill To</Text>
                  <Text
                    size={mobile ? 14 : ""}
                    color="var(--grey1)"
                    weight="700">  {handleGetBillTo()}</Text>
                </div>
              </div>

              <div className="grid">
                <div className="grid__header">
                <Text style={{fontSize: '14px' }}>Service</Text>
                  <Text style={{fontSize: '14px' }}>Description</Text>
                  <Text style={{fontSize: '14px' }}>Units</Text>
                  <Text style={{fontSize: '14px' }}>Unit Price</Text>
                  <Text style={{fontSize: '14px' }}>Unit Tax</Text>
                </div>
                <div>
                  {services?.map(
                    (
                      {
                        serviceName,
                        description,
                        itemUnit,
                        cost,
                        tax,
                      },
                      key
                    ) => (
                      <div className="grid__body" key={key}>
                        <Text weight="400">{serviceName}</Text>
                        <Text>{description}</Text>
                        <Text>{itemUnit}</Text>
                        <Text>
                          {convertCurrencyToSign(currency)}
                          {addCommasToNumber(setDecimalPlaces(cost))}
                        </Text>
                        <Text>
                          {convertCurrencyToSign(currency)}
                          {addCommasToNumber(setDecimalPlaces(tax))}
                        </Text>
                      </div>
                    )
                  )}
                </div>
                  <InvoiceRecordSummary
                    summaryDisplayCondition={Object.keys(recurringInvoice)?.length !== 0}
                    totalAmount={getInvoiceTotalAmount(services)}
                    subTotal={getInvoiceTotalCost(services)}
                    vat={getInvoiceTotalTax(services)}
                    currency={currency}
                    comment={additionalInformation}
                    role={role}
                    preview={true}
                  />
                
              </div>
            </div>
            <div className="card__footer">
              <AccountDetails>
                <div className="sub">
                  <Text>Account Name:</Text>
                  <Text>{accountInformation?.accountName}</Text>
                </div>
                <div className="sub">
                  <Text>Account No:</Text>
                  <Text>{accountInformation?.accountNumber}</Text>
                </div>
                <div className="sub">
                  <Text>Bank Name:</Text>
                  <Text>{accountInformation?.bankName}</Text>
                </div>
                <div className="sub">
                  <Text>Sort Code:</Text>
                  <Text>{accountInformation?.sortCodeIBAN}</Text>
                </div>

              </AccountDetails>
              {role && (
                <AdditionalNoteArea>
                  <Text
                    margin="5px 0"
                    size={mobile ? 10 : ""}
                    color="var(--grey1)"
                    weight="700" >Additional  notes</Text>
                  <AdditionalInfo style={{fontSize: '15px' }}>
                    {additionalInformation}
                  </AdditionalInfo>

                </AdditionalNoteArea>
              )}
              <div className="footer">
                Powered by Easei
                <img
                  src="https://easeitestac8f.blob.core.windows.net/uploadblob-test/easeiInvoiceLogo.png"
                  alt=""
                  width={mobile ? 11 : 18}
                  height={mobile ? 11 : 18}
                />
              </div>
            </div>
          </Card>
        </InvoiceDetailsContent>
      </div>
    </div >
  );
};

export default BaseRecurringInvoiceDetails;