import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from "styled-components";
import { pxToRem } from '../../../../utils/helpers/pxToRem/pxToRem';

export const TextStyling = styled.p`
    font-size: ${({ size }) => size && pxToRem(size)};
    font-weight: ${({ weight }) => weight};
    max-width:${({maxWidth})=>maxWidth};
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    color: ${({ color }) => color || '#011747'};
    text-align: ${({ textAlign }) => textAlign};
    word-break: ${({ wordBreak }) => wordBreak};
    border-left: ${({ borderL }) => borderL};
     border-right: ${({ borderR }) => borderR};
     padding: ${({ padding }) => padding};
     @media (max-width: 768px) {
    ${({ responsiveFontSize }) =>
    responsiveFontSize &&
    css`
        font-size: ${pxToRem(responsiveFontSize)};
      `};
     }
      @media (max-width: 400px) {
    ${({ responsiveMobileFontSize }) =>
    responsiveMobileFontSize &&
    css`
        font-size: ${pxToRem(responsiveMobileFontSize)};
      `};
      }
`;

const Text = ({ text, children, ...props }) => {
  return (
    <TextStyling {...props}>{text || children}</TextStyling>
  )
}

export const Span = ({ text, children, ...props }) => {
  return React.createElement(TextStyling, { ...props, as: 'span' }, text || children)
}

export const Heading = ({ level, text, children, ...props }) => {
  return React.createElement(TextStyling, { ...props, as: `h${level || 1}` }, text || children)
}

Text.propTypes = {}

Heading.propTypes = {
  level: PropTypes.number,
}

Span.propTypes = {
  level: PropTypes.number,
}

Text.Heading = Heading;
Text.Span = Span;

export default Text;
