import React from 'react';
import Text from "app/components/atoms/typography";
import dp1 from "../../assets/img/dp1.png";
import dp2 from "../../assets/img/dp2.png";
import dp3 from "../../assets/img/dp3.png";
import dp4 from "../../assets/img/dp4.png";
import dp5 from "../../assets/img/dp5.png";
import { ReminderDesktopContainer, ReminderDesktopSubContainer } from './reminder.styles';

const ReminderCardDesktop = ({ datesData, setEditReminder }) => {

    const defaultDps = [dp1, dp2, dp3, dp4, dp5]

return (
    <ReminderDesktopContainer>
        <Text weight={500} size={14} color="var(--primary2)" width="100px">
        {datesData?.dayOfWeekName}, {datesData.day}
        </Text>
    
        <ReminderDesktopSubContainer>
        {datesData?.data?.map((reminder, index) => {
            const alternatingColorClass = index % 2 === 0 ? "var(--accent1)" : "var(--grey10)"; // Alternating background color
    
            return (
            <div
                style={{ backgroundColor: alternatingColorClass, marginTop: "15px" }}
                className={"card"}
                key={reminder.id}
                onClick={() => {
                setEditReminder({ value: true, data: reminder });
                }}
            >
                <Text weight="700" size="12px" color="var(--grey1)">
                {reminder.title}
                </Text>
    
                <div className="dp">
                {defaultDps?.map((dp, index) => (
                    <img key={index} src={dp} alt="dp" />
                ))}
                </div>
            </div>
            );
        })}
        </ReminderDesktopSubContainer>
    </ReminderDesktopContainer>
    );
      
};

export default ReminderCardDesktop;
