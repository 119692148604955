import * as Yup from "yup";
import SignUp from "app/layouts/auth.layout/signup";
import { EmailValidator, nameValidator, PasswordValidator, PhoneNumberValidator } from "utils/validation/validation.utils";
import { authActions, authSelectors } from "store/reducers/auth/authSlice";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { ROUTES } from "utils/constants/constants.utils";
import { useDispatch, useSelector } from "react-redux";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { formActions, formSelectors } from "store/reducers/form/formSlice";
import { useEffect } from "react";

const defaultFormFields = {
  firstName: "",
  lastName: "",
  businessPhoneNumber: "",
  businessName: "",
  businessEmail: "",
  password: "",
  industry: ""
};

const validate = Yup.object({
  businessPhoneNumber: PhoneNumberValidator,
  businessEmail: EmailValidator,
  password: PasswordValidator,
  businessName: nameValidator("Business Name"),
  firstName: nameValidator("First Name"),
  lastName: nameValidator("Last Name"),
  industry: Yup.string().required()
});

export const OrganizationUserSignUp = () => {
  
  const dispatch = useDispatch();

  const organizationSignUpFormContent = useSelector(formSelectors.organizationSignUpForm);
  const organizationUserPlans = useSelector(planSelectors.organizationPlan);
  const isLoading = useSelector(authSelectors.isLoading);
  const { organizationSignUp } = authActions;
  const { getOrganizationSignUpForm } = formActions;
  const { getPlans } = planActions;
  const { handleApiResponse } = useHandleApiResponse(); 

  const signUpHandler = async (values) => {
    dispatch(organizationSignUp(values)).then((res)=>
    {
      handleApiResponse(res, ROUTES.HOME);
    })
  };

  useEffect(() => {

    dispatch(getPlans());

    if (organizationUserPlans.length) {
      dispatch(getOrganizationSignUpForm(organizationUserPlans));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationUserPlans]);

  return (
    <SignUp
      formContent={organizationSignUpFormContent}
      validationSchema={validate}
      defaultFormFields={defaultFormFields}
      handleSubmit={signUpHandler}
      showSocialMedia={false}
      plans={organizationUserPlans}
      isLoading={isLoading}
    />
  );
};
