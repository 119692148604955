import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "Currency";

export const initialState = {
    currencies: [],
    loading: false
  };

  const getCurrencies = createAsyncThunk(
    `${entity}/currency`,
    
    async () => {
      const res = await api.currency.getAll();
      return res.data;
    }
  );

  const currencySlice = createSlice({
    name: entity,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencies = action.payload.data?.map(function (item) {
            return { value: item.id, label: item.isoCode };
        });
        state.loading = false;
      })
        .addCase(getCurrencies.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(getCurrencies.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
    },
  });

  export const CurrencyActions = 
  {
  ...currencySlice.actions,
     getCurrencies
  };
  
  export const CurrencySelectors = {
    isLoading: (state) => state.currency.loading,
    currencies: (state) => state.currency.currencies
  };

  export default currencySlice.reducer;