import Text, { Span } from "app/components/atoms/typography";
import React, { useState, useEffect } from "react";
import { FormContent } from "./settings.styles";
import { countries as countryList } from "../../../utils/contents/countryStateList";
import { useFormikContext } from "formik";
export default function CountryStateDropdowns(country, state) {
  const [countries] = useState(countryList);
  const formikProps = useFormikContext();
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(country.country);
  const [selectedState, setSelectedState] = useState(country.state);
  useEffect(() => {
    countryList.forEach((country) => {
      if (selectedCountry === country.country) {
        setStates(country.states);
      }
    });
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSelectedState("");
    formikProps.setFieldValue("country", e.target.value);
    formikProps.setFieldValue("state", "");
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    formikProps.setFieldValue("state", e.target.value);
  };

  return (
    <div>
        <FormContent>
        <Text>
          Country <Span color="red">*</Span>
        </Text>
        <select
          className={`dropdown ${selectedCountry.length > 0 ? "active" : ""}`}
          id="country"
          value={formikProps.values["country"]}
          onChange={handleCountryChange}
        >
          <option value="">Select a country</option>
          {countries?.map((country) => (
            <option key={country.country} value={country.country}>
              {country.country}
            </option>
          ))}
        </select>
      </FormContent>
      
      <FormContent>
        <Text>
          State <Span color="red">*</Span>
        </Text>
        <select
          className={`dropdown ${selectedState.length > 0 ? "active" : ""}`}
          id="state"
          name="state"
          value={formikProps.values["state"]}
          onChange={handleStateChange}
        >
          <option value="">Select a state</option>
          {states?.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </FormContent>
    </div>
  );
}
