import { useState } from "react";
import Text, { Heading } from "app/components/atoms/typography";
import PageMessage from "app/components/molecules/page-message/page-message.component";
import { BsChevronDown, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Button from "app/components/atoms/button/button.component";
import { CardContainer } from "app/components/molecules/people/people-card.component";
import pana from "app/assets/img/pana.png";

import { useDispatch, useSelector } from "react-redux";
import {
  invoiceReminderSelectors,
  invoiceReminderActions,
} from "store/reducers/invoiceReminder/invoiceReminderSlice";
import { useEffect } from "react";
import { getWeekRanges } from "utils/helpers/getWeekRanges/getWeekRanges";
import SingleReminder from "./singleReminder";
import Spinner from "app/components/atoms/spinner/spinner.component";
import { authSelectors } from "store/reducers/auth/authSlice";
import { validatePlanFeatureAccess } from "utils/helpers/validatePlanFeature/validatePlanFeatureAccess";
import { MONTH_NAMES, PLAN_FEATURES, REMINDER_DIRECTION, REMINDER_SCHEDULE_TEXT } from "utils/constants/constants.utils";
import AccessDenied from "app/components/molecules/access-denied/accessDenied";
import { useOnClickOutside, useViewport } from "hooks";
import { Flex } from "../dashboard/dashboard.styles";
import { MobileBaseButton } from "app/components/atoms/button/button.styles";
import Card from "app/components/atoms/card/card.component";
import { FilterButton, MonthPicker, MonthWeekDropdown, ReminderContainer, ReminderContainerBody, ReminderContainerHeader } from "./reminder.styles";
import { Empty } from "app/components/atoms/divs/div.styles";
import { addHoursToDate, getFirstAndLastDayOfCurrentMonth, getFirstAndLastDayOfNextMonth, getFirstAndLastDayOfPreviousMonth, getMonthInfo } from "utils/helpers/getMonthInfo/getMonthInfo";

const AllReminders = ({ setNewReminder, reminder }) => {

  const allowedFeatures = useSelector(authSelectors.allowedFeatures);
  const isPlanActive = useSelector(authSelectors.isPlanActive);
  const isLoading = useSelector(invoiceReminderSelectors.isLoading);
  const invoiceReminders = useSelector(invoiceReminderSelectors.invoiceReminders) || [];

  const [date, setDate] = useState(new Date(new Date().setDate(1)));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const [period, setPeriod] = useState(REMINDER_SCHEDULE_TEXT.Month);
  const [dropShow, setDropShow] = useState(false);
  const { visible: pickMonth, setVisible: setPickMonth, ref } = useOnClickOutside(false);
  const [activeId, setActiveId] = useState(null);
  const [weeks, setWeeks] = useState(getWeekRanges(date));
  const { monthName, shortMonthName } = getMonthInfo(date);
  const [week, setWeek] = useState(1);

  const dispatch = useDispatch();
  const { mobile } = useViewport();

  const { search } = invoiceReminderActions;
  
  const handlePeriodChange = (direction) => {

    if (period === REMINDER_SCHEDULE_TEXT.Month) {
      let newDate;
      let newEndDate;

      if (direction === REMINDER_DIRECTION.left) {
          const { firstDay, lastDay} = getFirstAndLastDayOfPreviousMonth(date);
          newDate = firstDay;
          newEndDate = lastDay;
      }
      else {
        const { firstDay, lastDay} = getFirstAndLastDayOfNextMonth(date);
        newDate = firstDay;
        newEndDate = lastDay;
      }

       setDate(newDate);
       setEndDate(newEndDate);
       setWeek(1);
  }
    else{
    let newDate;
    let newEndDate;
    
    if (direction === REMINDER_DIRECTION.left) {

      const newWeek = week - 1;

        if(newWeek === 0){
          const { lastDay } = getFirstAndLastDayOfPreviousMonth(date);
          newEndDate = new Date(lastDay);
          newDate = new Date(lastDay.setDate(lastDay.getDate() - 7)); // remove 7 days;
          const latMonthWeeks = getWeekRanges(newDate);
          setWeek(latMonthWeeks[latMonthWeeks.length -1].weekNo);
        }
        else{
          const currentWeek = weeks.find(w => w.weekNo === week - 1);
          newDate = currentWeek.start;
          newEndDate = currentWeek.end;
          setWeek(newWeek)
        }
    }
    else {
      const newWeek = week + 1;

      const maxWeek = weeks.reduce((max, obj) => Math.max(max, obj.weekNo), -Infinity);

      if(newWeek > maxWeek){
        const { firstDay } = getFirstAndLastDayOfNextMonth(date);
        newDate = new Date(firstDay);
        newEndDate = new Date(firstDay.setDate(firstDay.getDate() + 7)); // Add 7 days
        setWeek(1);
      }
      else{
        const currentWeek = weeks.find(w => w.weekNo === newWeek);
        newDate = currentWeek.start;
        newEndDate = currentWeek.end;
        setWeek(newWeek)
      }
    }

    setDate(newDate);
    setEndDate(newEndDate);
  }
  };

  const handleMonthChanged = (newMonth) =>{
    const newDate = new Date(date.getFullYear(), newMonth , 1);
    const newEndDate = new Date(date.getFullYear(), newMonth + 1, 0);

    setEndDate(newEndDate);
    setDate(newDate);
    setWeek(1);
    setPickMonth(!pickMonth);
  }

  const EmptyReminder = () => {
    return (
      <CardContainer className="noReminderCard" style={{ marginTop: "3em" }}>
        <Empty>
          <img src={pana} alt="reminder" />
  
          <Heading color={"var(--primary2)"}>
            Remind your staff!
          </Heading>
          <Text color={"var(--grey2)"}>
            Early invoices mean early settlements, efficient
            organization, and happy staff.
          </Text>
          {mobile ? (
            <MobileBaseButton
              onClick={() => setNewReminder({ value: true, data: {} })}
            >
              Send a Reminder
            </MobileBaseButton>
          ) : (
            <Button
              onClick={() => setNewReminder({ value: true, data: {} })}
            >
              Send a Reminder
            </Button>
          )}
        </Empty>
      </CardContainer>
    );
  };

  useEffect(() => {

    if (period === REMINDER_SCHEDULE_TEXT.Month) {
      const { firstDay, lastDay } = getFirstAndLastDayOfCurrentMonth(date);

      setDate(firstDay);
      setEndDate(lastDay)
      setWeek(1);
    } else {

      const currentWeek = weeks.find(w => {
        const startDate = new Date(w.start);
        startDate.setHours(0, 0, 0, 0); // Set time to midnight
    
        const endDate = new Date(w.end);
        endDate.setHours(0, 0, 0, 0); // Set time to midnight
    
        const dateToCheck = new Date(date);
        dateToCheck.setHours(0, 0, 0, 0); // Set time to midnight
    
        return dateToCheck >= startDate && dateToCheck <= endDate;
    });

      setDate(currentWeek.start);
      setEndDate(currentWeek.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {

    setWeeks(getWeekRanges(date));

    if (validatePlanFeatureAccess(isPlanActive, allowedFeatures, PLAN_FEATURES.Reminder)) {

      dispatch(search({
        startDate: addHoursToDate(date, 5),
        endDate: addHoursToDate(endDate, 5)
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, period, reminder, isPlanActive, allowedFeatures]);

  if (!validatePlanFeatureAccess(isPlanActive, allowedFeatures, PLAN_FEATURES.Reminder)) {
    return (<AccessDenied />)
  }
  
  return (
    <div
      className={`${reminder.value ? "hide" : ""}`}
      style={{ marginBottom: "20px" }}
      onClick={() => setActiveId(null)}>
      {mobile ? 
          <ReminderContainer>
            <Flex>
              <ReminderContainerHeader>
                <div className="month">
                  {pickMonth ? ( 
                    <MonthPicker style={{ gap: 0 }} ref={ref}>
                      {MONTH_NAMES.map((name) => {
                        return (
                          <Heading
                            level={3}
                            key={name}
                            className="select"
                            onClick={(e) => {
                              handleMonthChanged((MONTH_NAMES.indexOf(name)));
                              e.stopPropagation();
                            }}
                          >
                            {name}
                          </Heading>
                        );
                      })}
                    </MonthPicker>
                  ) : (
                    <Heading
                      size={12}
                      weight={700}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setPickMonth(!pickMonth);
                        e.stopPropagation();
                      }}
                    >
                      {shortMonthName} {period === REMINDER_SCHEDULE_TEXT.Week && `Week ${week}`  }  

                    </Heading>
                  )}
                  <div>
                    <span
                      className="arrow"
                      onClick={() => handlePeriodChange(REMINDER_DIRECTION.left)}
                    >
                      <BsChevronLeft />
                    </span>
                    <span
                      className="arrow"
                      onClick={() => handlePeriodChange(REMINDER_DIRECTION.right)}
                    >
                      <BsChevronRight />
                    </span>
                  </div>
                </div>
              </ReminderContainerHeader>

              <MobileBaseButton
                width="true"
                onClick={() => setNewReminder({ value: true, data: {} })}>
                Create new Reminder
              </MobileBaseButton>
            </Flex>

            <Card m="47.6px 0">
              {isLoading ?
                <Spinner />
               : invoiceReminders?.datesData?.length > 0 ? 
                <div>
                  <Flex margin="0 0 19px 0" justifyContent="true">
                    <FilterButton
                      active={period === REMINDER_SCHEDULE_TEXT.Month}
                      onClick={() => { setPeriod(REMINDER_SCHEDULE_TEXT.Month)}}>
                      Monthly
                    </FilterButton>

                    <FilterButton
                      active={period === REMINDER_SCHEDULE_TEXT.Week}
                      margin="0 0 0 15px"
                      onClick={() => { setPeriod(REMINDER_SCHEDULE_TEXT.Week)}}>
                      Weekly
                    </FilterButton>
                  </Flex>

                  <ReminderContainerBody>
                    {invoiceReminders?.datesData.map((data, key) => {
                      return (
                        <SingleReminder
                          setEditReminder={setNewReminder}
                          data={data}
                          key={key}
                          count={key}
                          activeId={activeId}
                          setActiveId={setActiveId}
                        />
                      );
                    })}
                  </ReminderContainerBody>
                </div>
               : 
              <EmptyReminder/>
              }
            </Card>
          </ReminderContainer>
       : (
        <div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <PageMessage heading="Reminders" color={"var(--primary2)"} />
            <Button width="true" onClick={() => setNewReminder({ value: true, data: {} })}>
              Create new Reminder
            </Button>
          </div>
          
          <ReminderContainer>
            <ReminderContainerHeader>
              <div className="month">
                {pickMonth ? (
                  <MonthPicker style={{ gap: 0 }} ref={ref}>
                    {MONTH_NAMES.map((name) => {
                      return (
                        <Heading
                          level={3}
                          key={name}
                          className="select"
                          onClick={(e) => {
                            handleMonthChanged((MONTH_NAMES.indexOf(name)));
                            e.stopPropagation();
                          }}
                        >
                          {name}
                        </Heading>
                      );
                    })}
                  </MonthPicker>
                ) : (
                  <Heading
                    level={3}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setPickMonth(!pickMonth);
                      e.stopPropagation();
                    }}
                  >
                  {monthName} {period === REMINDER_SCHEDULE_TEXT.Week && `Week ${week}` }  
                  </Heading>
                )}

                <div>
                  <span
                    className="arrow"
                    onClick={() => handlePeriodChange(REMINDER_DIRECTION.left)}
                  >
                    <BsChevronLeft />
                  </span>
                  <span
                    className="arrow"
                    onClick={() => handlePeriodChange(REMINDER_DIRECTION.right)}
                  >
                    <BsChevronRight />
                  </span>
                </div>
              </div>

              <MonthWeekDropdown
                onClick={() => {
                  setDropShow(!dropShow);
                }}
              >
                <Text color={"var(--grey2)"}>{period}</Text>
                <BsChevronDown style={{ color: "var(--grey2)" }} />
                {dropShow && (
                  <div
                    className="dropdown"
                    onClick={() => {
                      setPeriod(period === REMINDER_SCHEDULE_TEXT.Month ? REMINDER_SCHEDULE_TEXT.Week : REMINDER_SCHEDULE_TEXT.Month);
                    }}
                  >
                    {period === REMINDER_SCHEDULE_TEXT.Month ? REMINDER_SCHEDULE_TEXT.Week : REMINDER_SCHEDULE_TEXT.Month}
                  </div>
                )}
              </MonthWeekDropdown>
            </ReminderContainerHeader>

            {isLoading ? <Spinner />  : 
            invoiceReminders?.datesData?.length > 0 ? (
              <ReminderContainerBody>
                {invoiceReminders.datesData.map((data, key) => {
                  return (
                    <SingleReminder
                      setEditReminder={setNewReminder}
                      data={data}
                      key={key}
                      activeId={activeId}
                      setActiveId={setActiveId}
                    />
                  );
                })}
              </ReminderContainerBody>
            ) :
              <EmptyReminder/>
            }
          </ReminderContainer>
        </div>
      )}
    </div>
  );
};
export default AllReminders;
