import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { Heading } from "app/components/atoms/typography";

import BackgroundImage from "app/assets/img/OutroBackground.png";
import Arrow from "app/assets/img/Arrow.png";
import { InvertedButton } from "app/components/atoms/button/button.styles";
import { ROUTES } from "utils/constants/constants.utils";

const PreFooterContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  background-image: url(${BackgroundImage});
  background-size: cover;
  padding: 69px 159px;
  position: relative;
  z-index: 3;
  div {
    margin: 16px;
  }

  h1 p {
    all: unset;
  }

  button {
    min-width: 250px;
  }

  .prefooter-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      color: white;
    }
  }

  .prefooter-arrow {
    position: relative;
    top: -100px;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 5.3rem;
      color: var(--white);
    }
  }

  .prefooter-button {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: -450px;
  }

  @media screen and (min-width: 768px) {
    background-size: cover;
    .prefooter-arrow{
         top: 0;
    }
  }

  @media screen and (min-width: 950px) {
    grid-template-columns: 2fr 1fr 3fr;
    height: 305px;
    background-size: 100% 100%;

    .prefooter-button {
      justify-content: flex-start;
    }

    .prefooter-text {
      justify-content: flex-start;
      text-align: start;
    }

    .prefooter-arrow {
      display: flex;
    }
  }
`;

const PreFooter = () => {
  let navigate = useNavigate();

  const createAccountHandler = () => {
    navigate(ROUTES.SIGN_UP.ORGANIZATION);
  };
  return (
    <PreFooterContainer>
      <div className="prefooter-text">
        <Heading>We are here to help your business grow.</Heading>
      </div>
      <div className="prefooter-arrow">
        <img src={Arrow} alt="arrow" />
      </div>
      <div className="prefooter-button">
        <InvertedButton width="true" onClick={createAccountHandler}>
          Get Started
        </InvertedButton>
      </div>
    </PreFooterContainer>
  );
};

export default PreFooter;
