import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "Support";

export const initialState = {
    loading: false
  };

const contactUs = createAsyncThunk(
    `${entity}/contact-us`,
    
    async (input) => {
      const res = await api.support.contactUs(input);
      return res.data;
    }
  );

  const gdpr = createAsyncThunk(
    `${entity}/gdpr`,
    
    async (input) => {
      const res = await api.support.gdpr(input);
      return res.data;
    }
  );

  const supportSlice = createSlice({
    name: entity,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(contactUs.fulfilled, (state, action) => {
        return { ...state, ...action, loading: false};
      })
        .addCase(contactUs.pending, (state) => {
          return { ...state, loading: true };
        })
        .addCase(contactUs.rejected, (state) => {
          return { ...state, loading: false };
        });
      builder
      .addCase(gdpr.fulfilled, (state, action) => {
        return { ...state, ...action, loading: false};
      })
        .addCase(gdpr.pending, (state) => {
          return { ...state, loading: true };
        })
        .addCase(gdpr.rejected, (state) => {
          return { ...state, loading: false };
        });
    },
  });

  export const supportAction = 
  {
  ...supportSlice.actions,
    contactUs,
    gdpr
  };
  
  export const SettingsSelectors = {
    isLoading: (state) => state.support.loading,
  };

  export default supportSlice.reducer;