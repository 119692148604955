import Text from "app/components/atoms/typography";
import FeaturesCard from "../../atoms/features-card/features-card.component";
import styled from "styled-components";
import { useViewport } from "hooks";
import ManageClient from '../../../assets/img/manage-client-img.png'
import InvoiceTemplate from '../../../assets/img/invoice-template-img.png'
import TrackClient from '../../../assets/img/track-invoice-img.png'
import Payment from '../../../assets/img/payment-img.png'
const FeaturesContainer = styled.section`
  padding: 0 17px;

  p {
    margin: 0;
  }
  
  .features-header {
    margin: 68px 0 81px;
    text-align: center;
  }

.texts{
  max-width: 601px;
}
  @media screen and (min-width: 768px) {
     padding: 50px 30px;
    .features-header {
      grid-template-columns: 1fr;
      text-align: left;
      }

    .header{
  max-width: 601px;
}

.paragraph{
  max-width:580px;
  margin-top: 25px;
}
  }

  @media screen and (min-width: 950px) {
    .features-header {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const menuItems = [
  {
    id: 1,
    item: "Custom Invoicing Template",
    description:
      "Free and simple professional templates to help your vendors and employees request timely payments with ease.",
    image: <img src={InvoiceTemplate} alt="Invoice Generator " />
  },
  {
    id: 2,
    item: "Easy Payment",
    description:
      "Receive invoice from your clients' and settle their payments easily with our powerful invoicing tool.",
    image: <img src={ManageClient} alt="Manage Clients " />
  },
  {
    id: 3,
    item: "Track Clients",
    description:
      "Track the payment status of your invoices in real-time and receive notifications when clients exceed payment deadlines.",
    image: <img src={TrackClient} alt="Track Invoice" />
  },
  {
    id: 4,
    item: "Estimates",
    description:
      "Free up time for your vendors and eliminate errors with recurring invoices and monthly reminder of invoice submission.",
    image: <img src={Payment} alt="Estimate" />
  },
];

const Features = () => {
  const { mobile } = useViewport();
  return (
    <FeaturesContainer className="container">
      <div className="features-header">
          <div className="header">
            <Text size={mobile ? 20 : 40} weight={700}>
              All the Tools You Need for Your Company's Billing Process
            </Text>
          </div>
        <div className="paragraph">
          {mobile ? 
            <Text>Have your account
              setup in as little as 60 seconds without much hassle</Text> : 
            <Text size={mobile ? 16 : 20} weight={200} className="">
              Set up a company account within minutes and send an invite to your staff to get onboarded on Easie.
            </Text>
              } 
          </div>
          </div>
      <div>
        <FeaturesCard menuItems={menuItems} />
      </div>
    </FeaturesContainer>
  );
};

export default Features;
