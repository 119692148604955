import Text, { Heading } from "app/components/atoms/typography";

const PageMessage = ({ heading, text, color, size, tSize }) => {
  return (
    <div>
      <Heading size={size} color={color}>{heading}</Heading>
      <Text size={tSize} style={{ marginLeft: "0px" }}>{text}</Text>
    </div>
  );
};

export default PageMessage;
