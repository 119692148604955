export const FAQContent = [
  {
    index: 1,
    question: "Do I need to request a demo to setup an account? ",
    answer: "No, you can onboard yourself and the flow is seamless.",
    links: [],
  },
  {
    index: 2,
    question: "How do I sign up as an organization?",
    answer: "To sign up as an organization  click here",
    links: [
      {
        text: "here",
        url: "/organization-user-signup",
      },
    ],
  },
  {
    index: 3,
    question: " How do I sign up as a freelancer?",
    answer: "To sign up as an freelancer click here.",
    links: [
      {
        text: "here.",
        url: "/client-user-signup",
      },
    ],
  },
  {
    index: 4,
    question:
      "What is the different between signing up as a freelancer and an organization?",
    answer:
      "When you sign up as an organization, the email you provide becomes the Admin of the organization and you can invite your other admin users and your clients to experience Easei. As a freelancer, you get to sign up and start sending out invoices, you can create recurring invoices, work with organizations and much more. Checkout the video here  for more details.",
    links: [
      {
        text: "here",
        url: "https://www.youtube.com",
      },
    ],
  },
  {
    index: 5,
    question: "What are your pricing plan and what benefits do they have?",
    answer: "Our pricing plans and benefits are explicitly stated here.",
    links: [
      {
        text: "here.",
        url: "/",
      },
    ],
  },
  {
    index: 6,
    question:
      "What happens if for any reason, I am debited but the payment is not completed?",
    answer: "Contact us here,  we typically respond in minutes.",
    links: [
      {
        text: "here,",
        url: "/contact-us",
      },
    ],
  },
];
