import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { InvertedButton } from "app/components/atoms/button/button.styles";
import {ReactComponent as NotFoundImage} from "app/assets/svg/404 1.svg"

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: var(--primary1);
height: 100vh;
color: var(--white);
svg {
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
}
`

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container className="container">
      <NotFoundImage/>
      <div className="info">
        <h3>You seem to have lost your way around</h3>
        <InvertedButton onClick={() => navigate(-1)}>GO BACK</InvertedButton>
      </div>
    </Container>
  );
};

export default NotFound;
