import { createSlice } from "@reduxjs/toolkit";
import { monthlyData, weeklyData } from "api/data/bar-chart.data";
import { INVOICE_BAR_CHART_TYPE } from "utils/constants/constants.utils";

const entity = "chart";

export const initialState = {
  invoiceBarChartData: [],
  barChartTitle: "",
  loading: false,
};

const chartSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    getInvoiceBarChartData: (state, action) => {
      state.loading = true;
      let chartData;
      switch (action.payload) {
        case INVOICE_BAR_CHART_TYPE.WEEKLY:
          chartData = weeklyData;
          break;
        case INVOICE_BAR_CHART_TYPE.MONTHLY:
          chartData = monthlyData;
          break;
        default:
          chartData = [];
          break;
      }
      state.invoiceBarChartData = chartData;
      state.loading = false;
    },
    getBarChartTitle: (state, action) => {
      let type = "";
      switch (action.payload) {
        case INVOICE_BAR_CHART_TYPE.WEEKLY:
          type = "Weekly";
          break;
        case INVOICE_BAR_CHART_TYPE.MONTHLY:
          type = "Monthly";
          break;
        case INVOICE_BAR_CHART_TYPE.YEARLY:
          type = "Yearly";
          break;
        default:
          type = "Monthly";
          break;
      }

      state.barChartTitle = `${type} Invoice Distribution`;
    },
  },
  extraReducers: () => {},
});

export const chartActions = {
  ...chartSlice.actions,
};

export const chartSelectors = {
  isLoading: (state) => state.chart.loading,
  invoiceBarChartData: (state) => state.chart.invoiceBarChartData,
  barChartTitle: (state) => state.chart.barChartTitle,
};

export default chartSlice.reducer;
