import Verify from "app/layouts/auth.layout/verify";
import { useLocation } from "react-router-dom";

export const CheckYourEmail = () => {
  const location = useLocation();
  const handleClick = () => {
    window.location.href = `https://www.${location.state.split("@")[1]}`;
  };
  return (
    <Verify
      title="Check your email"
      message="We sent a password reset link to your email  if it is on our system"
      buttonText="Open your mailbox"
      handleClick={handleClick}
    />
  );
};
