import { useNavigate } from "react-router-dom";
import Text, { Heading } from "app/components/atoms/typography";
import { IoIosArrowBack } from "react-icons/io";
import { useViewport } from "hooks";
import AuthLogo from "app/assets/img/authLogo.png";
import { AuthContainer, UsersImageBox } from "./auth.styles";

const usersImages = [
  {
    id: 1,
    img: "https://images.unsplash.com/photo-1611432579402-7037e3e2c1e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80",
  },
  {
    id: 2,
    img: "https://images.unsplash.com/photo-1523477800337-966dbabe060b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
  },
  {
    id: 3,
    img: "https://images.unsplash.com/photo-1612214070475-1e73f478188c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80",
  },
  {
    id: 4,
    img: "https://images.unsplash.com/photo-1589156191108-c762ff4b96ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80",
  },
  {
    id: 5,
    img: "https://images.unsplash.com/photo-1612720779828-8ba209f069ff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  },
];

export const AuthLayout = ({ children, text, subText }) => {
  const navigate = useNavigate();
  const { mobile } = useViewport();

  let left = 0;
  return (
    <AuthContainer mobile={mobile}>
      <div className="auth-form-container">
        <div className="auth-back-arrow">
          <IoIosArrowBack onClick={() => navigate("/")} />
        </div>
        <div>
          <Heading size={28} color="var(--primary1)">
            {text}
          </Heading>
          {subText && <Text color="var(--grey1)">{subText}</Text>}
        </div>
        {children}
      </div>
      <div className="auth-image-container">
        <div className="auth-image-container-text">
          <div className="auth-image-container-text-welcome">
            <img
              style={{ width: "100px", height: "100px" }}
              src={AuthLogo}
              alt=""
            />
            <Heading>Invoicing made easy</Heading>
            <Text>
              Easie is a 360° Electronic billing management platform that aims
              to provide an all in one solution to Billing, Invoicing, Payment
              and Receipting.
            </Text>
          </div>
          <div className="auth-image-container-text-people">
            <div className="user-image-container">
              {usersImages?.map((user) => {
                return (
                  <UsersImageBox
                    key={user.id}
                    left={user.id === 1 ? 0 : (left += 16)}
                    className="auth-image-container-box"
                  >
                    <img src={user.img} alt="user" />
                  </UsersImageBox>
                );
              })}
            </div>
            <div>
              <Text>
                4k+ individuals and Organizations have joined us, now it’s your
                turn.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
