import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import BaseCreateInvoice from "./base-create-invoice.component";
import { invoiceActions } from "store/reducers/invoice/invoiceSlice";
import OrganizationSelectDropdown from "app/components/molecules/organization/organization-dropdown";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";

const CreateInternalInvoice = () => {
  const { createInternal } = invoiceActions;
  const { resetPreviewState, setPreviewInvoiceState, setHeaderText } = appDataActions;

  const [organization, setOrganization] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiResponse } = useHandleApiResponse();
  
  const invoice = useSelector(appdataSelector.previewInvoice);

  useEffect(()=>{
    dispatch(setHeaderText({ value: 'Create Invoice', type: 'setHeaderText' }));
    // eslint-disable-next-line
},[])

  const handleSave = (data, isPreview) =>{
    let payload = {
        organizationId: organization.value,
        type: INVOICE_TYPE.Internal,
        ...data
    }
    
    if(isPreview){
        dispatch(setPreviewInvoiceState(payload));
        navigate(ROUTES.PREVIEW_INVOICE)
    }
    else{
      dispatch(createInternal(payload)).then((res)=>{
        dispatch(resetPreviewState());
        handleApiResponse(res, '/invoices');
      })
    }
  }

  return (
      <BaseCreateInvoice
        ClientDropDown={
          <OrganizationSelectDropdown
            invoice={invoice}
            organization={organization}
            setOrganization={setOrganization}
          />
        }
        handleSave={handleSave}
      />
  );
};
export default CreateInternalInvoice;