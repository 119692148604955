import { createSlice } from "@reduxjs/toolkit";
import { peopleData } from "api/data/people.data";

const entity = "people";

export const initialState = {
  peopleList: [],
  peopleCount: 0,
  loading: false,
};

const peopleSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    getDummyPeople: (state) => {
      const list = peopleData?.map((person) => {
        return { select: false, ...person };
      });
      state.peopleList = list;
    },
    selectAll: (state, action) => {
      state.peopleList = state.peopleList?.map((p) => {
        p.select = action.payload;
        return p;
      });
    },
    selectById: (state, action) => {
      const { id, checked } = action.payload;
      state.peopleList = state.peopleList?.map((p) => {
        if (p.id === id) {
          p.select = checked;
        }
        return p;
      });
    },
    getPeopleCount: (state) => {
      state.peopleCount = state.peopleList?.length;
    }
  },
  extraReducers: () => {},
});

export const peopleActions = {
  ...peopleSlice.actions,
};

export const peopleSelectors = {
  isLoading: (state) => state.people.loading,
  peopleList: (state) => state.people.peopleList,
  peopleCount: (state) => state.people.peopleCount,
};

export default peopleSlice.reducer;
