import { useState } from "react";

import styled, { css } from "styled-components";
import Text from "../typography";
import { useViewport } from "hooks";

const activeCardStyle = css`
  p {
    color: var(--primary1);
  }
  background: #fff;
`;

const FeaturesCardContainer = styled.div`
  display: grid;
  grid-template-columns:repeat(2, 1fr);
   grid-template-rows: repeat(2, 1fr);
   overflow: hidden;

  .features-card-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    background: var(--primary1);
    border-radius: 8px 8px 0 0;
    padding: 17px 5px;
  }

.feature-card-image{
  background-color: white;
  display: grid;
  place-items: center;
  width: fit-content;
  img{
    width: 100%;
  }
}
.features-card-message{
  grid-column: 1/span 2;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary1);
    border-radius: 0 8px 0 0;
    margin-top: 1px;
  
}
`;

const FeatureCardContainerDesktop = styled(FeaturesCardContainer)`
display: flex;

.line{
  height: 1px;
  width:100%;
  background-color:white;
  display: grid;
  place-items: center;

}
    .features-card-menu {
         padding: 52px 20px;
      border-radius: 8px 0 0 8px;
    }

    .features-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 0 0 8px 8px;

      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      div > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
`
const FeaturesCardContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: .5em;
  max-width: 334px;
  margin-top: 10px;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 43px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: var(--grey8);
  }

  ${({ active }) => active && activeCardStyle};
`;

const FeaturesCard = ({ menuItems }) => {
  const [title, setTitle] = useState(menuItems && menuItems[0].item);
  const [summary, setSummary] = useState(menuItems && menuItems[0].description);
  const [activeCard, setActiveCard] = useState(menuItems && menuItems[0]);

  const summaryHandler = (menuItem) => () => {
    const { item, description } = menuItem;
    setActiveCard(menuItem);
    setTitle(item);
    setSummary(description);
  };

  const { mobile } = useViewport()

  return (
    <>
      {mobile ?
        <FeaturesCardContainer>
          <div className="features-card-menu">
            <div>
              {menuItems?.map((menuItem) => {
                const { id, item } = menuItem;
                return (
                    <MenuItem
                      key={id}
                      onClick={summaryHandler(menuItem)}
                      active={activeCard === menuItem ? true : false}
                    >
                      <Text size={mobile ? 14 : 18}>{item}</Text>
                    </MenuItem>
                );
              })}
            </div>
          </div>


          <div className="feature-card-image">
            {activeCard && <div>{activeCard.image}</div>}
          </div>

          <div className="features-card-message">
            <FeaturesCardContainerDescription>
              {title && (
                <Text weight={500} size={24} color={"white"} >
                  {title}
                </Text>
              )}
              {summary && (
                <Text weight={400} size={16} color={"white"}>
                  {summary}
                </Text>
              )}
            </FeaturesCardContainerDescription>
          </div>
        </FeaturesCardContainer>
        :
        <FeatureCardContainerDesktop>
          <div className="features-card-menu">
            <div>
              {menuItems?.map((menuItem) => {
                const { id, item } = menuItem;
                return (
                    <MenuItem
                      key={id}
                      onClick={summaryHandler(menuItem)}
                      active={activeCard === menuItem ? true : false}
                    >
                      <Text size={mobile ? 14 : 18}>{item}</Text>
                    </MenuItem>
                );
              })}
            </div>
            <div className="features-card-menu features-card-message">
              <div className="line"></div>
              <FeaturesCardContainerDescription>
                {title && (
                  <Text weight={500} size={24} color={"white"} >
                    {title}
                  </Text>
                )}
                {summary && (
                  <Text weight={400} size={16} color={"white"}>
                    {summary}
                  </Text>
                )}
              </FeaturesCardContainerDescription>
            </div>
          </div>

          <div className="feature-card-image">
            {activeCard && <div>{activeCard.image}</div>}
          </div>

        </FeatureCardContainerDesktop>
      }

    </>
  );
};

export default FeaturesCard;

