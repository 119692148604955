import { useSelector } from "react-redux";
import { appdataSelector } from "store/reducers/appData/appDataSlice";
import BaseInvoiceSetup from "../baseSetup/baseSetupInvoice";

const BaseCreateInvoice = ({handleSave, ClientDropDown}) => {
 
  const invoice = useSelector(appdataSelector.previewInvoice);

 return (
    <BaseInvoiceSetup
    invoice={invoice}
    handleSave={handleSave}
    ClientDropDown={ClientDropDown}>
    </BaseInvoiceSetup>)
};

export default BaseCreateInvoice;