import styled from "styled-components";

export const GraphContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const TableItem = styled.div`
  background: ${({ active }) => (active ? "var(--grey4)" : "")};
  border-radius: 10px;
     padding: ${({ padding }) => padding || "10px 24px"};
  font-weight: 700;
  font-size: ${({ size }) => size || "24px"};
  color: #011747;
`;