import { BaseButton, MobileBaseButton } from "app/components/atoms/button/button.styles";
import Text, { Heading } from "app/components/atoms/typography";
import { Flex } from "app/pages/dashboard/dashboard.styles";
import CheckList from "app/pages/plans/plan-checkList";
import { PaymentPlatform, Plan, StatusBar } from "app/pages/settings/settings.styles";
import { useViewport } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { planActions } from "store/reducers/plan/planSlice";

const PlanPaymentDetails = ({
    id,
    name,
    description,
    features,
    expiryText,
    formattedCost,
    actualCost,
    state 
}) => {
    const [isPlanDetails, setPlanDetails] = useState(false);
    const [months, setMonths] = useState(12);

    const { mobile } = useViewport();
    const dispatch = useDispatch();
    const { makePlanPayment } = planActions;

    const handleMakePlanPayment = () => {
        const payload = {
          planId: id,
          paymentProvider: 1,
          months: months
        };
    
        dispatch(makePlanPayment(payload)).then((res) => {
          window.open(res.payload.data);
        });
      }

    return (
        <>
            <Flex
                alignItems="flex-start"
                justifyContent="true"
                width="100%"
                gap="5px"
                margin="0"
            >
                <Plan className="premium" key={id}>
                    <StatusBar className={state}>{state}</StatusBar>
                    <Heading size={mobile ? "14px" : "25px"}>{name}</Heading>
                    <Text size={mobile ? 14 : 25} style={{ marginTop: "16px" }}>
                        {description}
                    </Text>
                    <Heading size={mobile ? 14 : ""} style={{ marginTop: "16px" }}>
                        {formattedCost}
                    </Heading>
                    <Text size={mobile ? 14 : ""} style={{ marginTop: "16px" }}>
                        {expiryText}
                    </Text>
                    <BaseButton
                        margin={mobile ? "10px 0" : "30px 0"}
                        fontSize={mobile ? "10px" : ""}
                        minWidth={mobile ? "0px" : ""}
                        height={mobile ? "0px" : "60px"}
                        onClick={() => setPlanDetails(() => !isPlanDetails)}
                    >
                        {isPlanDetails ? "Close Plan" : "View Plan" }
                    </BaseButton>
                    {isPlanDetails && (
                            <div className="plan_features">
                                <Heading level={mobile ? 5 : 3} color="black" weight="500">
                                    Manage tasks and personal to-dos:
                                </Heading>
                                {features?.map(({ description, id }) => {
                                    return <CheckList key={id} checkList={description} />;
                                })}
                            </div>
                            )}
                </Plan>
            </Flex>

            {isPlanDetails && actualCost > 0 && (
                <>
                    <PaymentPlatform>
                        <div className="payment_platform_details">
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                alignItems="flex-start"
                                gap="10px"
                            >
                                <Text>Select payment Platform</Text>
                                <Flex
                                    justifyContent="true"
                                    gap="5px"
                                    margin={mobile ? "0" : "20px 0"}
                                >
                                    <input type="radio" checked />
                                    <label>Paystack</label>
                                </Flex>
                            </Flex>

                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                alignItems="flex-start"
                                margin={mobile ? "30px 0" : "0"}
                                gap="10px"
                            >
                                <Text>Input subscription duration</Text>
                                <Flex
                                    justifyContent="true"
                                    gap="0 20px"
                                    margin={mobile ? "0" : "20px 0"}
                                >
                                    <label>
                                        No of Months<span>*</span>
                                    </label>
                                    <input type="text" value={months} onChange={(e)=>{setMonths(e.target.value)}} />
                                </Flex>
                            </Flex>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {mobile ? (
                                <MobileBaseButton
                                    width="70%"
                                    onClick={handleMakePlanPayment}
                                >
                                    Make Payment
                                </MobileBaseButton>
                            ) : (
                                <BaseButton
                                    width="10rem"
                                    onClick={handleMakePlanPayment}
                                >
                                    Make Payment
                                </BaseButton>
                            )}
                        </div>
                    </PaymentPlatform>
                </>
            )}
        </>
    )
}

export default PlanPaymentDetails;