import { useViewport } from "hooks";

import { useField, ErrorMessage } from "formik";
import { FormGroup, FormInputLabel, Input } from "./form-input-two.styles";
import styled from "styled-components";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";

export const ErrorText = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  color: red;
`;

const FormInputTwo = ({ label, stretch, ...otherProps }) => {
  const { mobile } = useViewport();
  const [field, meta] = useField(otherProps);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isPasswordInput = otherProps.type === "password";
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  return (
    <FormGroup>
      <Input
        {...otherProps}
        {...field} error={meta.touched && meta.error}
        type={isPasswordInput && passwordVisible ? "text" : otherProps.type}/>
      {isPasswordInput && (
        <div onClick={togglePasswordVisibility} className="passwordToggler">
          {passwordVisible ? (
            <AiOutlineEyeInvisible />
          ) : (
            <AiOutlineEye />
          )}
        </div>
      )}
      {label && (
        <FormInputLabel
          stretch={stretch}
          shrink={field.value?.length}
          htmlFor={field?.name}
          color={mobile ? "#fff" : null}
        >
          {label}
        </FormInputLabel>
      )}
      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </FormGroup>
  );
};

export default FormInputTwo;
