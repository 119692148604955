import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const entity = "receipt";

export const initialState = {
  loading: false,
  receipts: [],
  totalCount: 0,
  pageCount: 0,
  receipt: {}
};

const search = createAsyncThunk(`${entity}`, async (input) => {
    const {
        page,
        pageSize,
        status,
        startDate,
        endDate,
        filter,
        organizationId 
      } = input;

    const res = await api.receipt.search(page,
      pageSize,
      status,
      startDate,
      endDate,
      filter,
      organizationId);
  return res.data;
});

const getById = createAsyncThunk(`${entity}/getById`, async (id) => {
    const res = await api.receipt.getById(id);
    return res.data;
  });

const receiptSlice = createSlice({
    name: entity,
    initialState,
    resetState: (state) => {
      state.receipt = {};
      state.receipts = [];
    },
    extraReducers: (builder) => {
      builder
        .addCase(search.fulfilled, (state, action) => {
          state.receipts = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.pageCount = action.payload.pageCount;
          state.loading = false;
      })
        .addCase(search.pending, (state) => {
          return {
            ...state,
            loading: true,
          };
        })
        .addCase(search.rejected, (state) => {
          return {
            ...state,
            loading: false,
          };
        });
  
      builder
        .addCase(getById.fulfilled, (state, action) => {
            state.receipt = action.payload.data;
            state.loading = false;
        })
        .addCase(getById.pending, (state) => {
          return {...state, loading: true };
        })
        .addCase(getById.rejected, (state) => {
          return {...state, loading: false };
        });
    },
  });

export const receiptActions = {
    ...receiptSlice.actions,
    search,
    getById
  };
  
  export const receiptSelectors = {
    isLoading: (state) => state.receipt.loading,
    receipts: (state) => state.receipt.receipts,
    receipt: (state) => state.receipt.receipt,
    totalCount: (state) => state.receipt.totalCount,
    pageCount: (state) => state.receipt.pageCount
  };

  export default receiptSlice.reducer;