import { useOnClickOutside, useViewport } from "hooks";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { recurringInvoiceActions, recurringInvoiceSelectors } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import { RecurringInvoiceTopBanner } from "./recurringinvoice.styles";
import { InvoiceTypes, ROUTES } from "utils/constants/constants.utils";
import Text, { Heading } from "app/components/atoms/typography";
import PopupContent from "app/components/atoms/popup/popup-content.component";
import { CreateDropdown } from "../invoice/invoiceComponents/subComponents";
import { CreateNewContainer, MobileResponsiveButton, SimpleSearchContainer } from "app/components/atoms/divs/div.styles";
import SearchComponent from "app/components/cell/searchComponent/searchComponent";
import RecurringInvoiceList from "app/components/cell/recurringInvoiceList/recurring-invoice-list.component";
import { appDataActions } from "store/reducers/appData/appDataSlice";

const initialState = {
    searchInput: "",
    organization: "",
    pageSize: 10,
    page: 1,
    recurType: null,
    startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    status: null
  };

const RecurringInvoice = () =>{

  const { visible, setVisible, ref } = useOnClickOutside(false);
  const [state, setState] = useState(initialState);
  const [reload, setReload] = useState(false);
  const { searchInput, organization, pageSize, page, recurType, startDate, endDate, status } = state;

  const inActiveCount = useSelector(recurringInvoiceSelectors.inActiveCount);
  const activeCount = useSelector(recurringInvoiceSelectors.activeCount);
  const recurringInvoices = useSelector(recurringInvoiceSelectors.recurringInvoices);
  const isLoading = useSelector(recurringInvoiceSelectors.isLoading);
  const totalCount = useSelector(recurringInvoiceSelectors.totalCount);
  const { resetPreviewRecurringInvoiceState, resetState } = appDataActions;
  const { searchRecurringInvoices } = recurringInvoiceActions;

  const { mobile } = useViewport();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = () => {
    dispatch(
      searchRecurringInvoices({
        page,
        pageSize,
        startDate,
        status,
        endDate,
        client: searchInput,
        organizationId: organization,
        recurType,
      })
    );
  };

  const handlePerRowsChange = async (newPageSize) => {
    setState((prevState) => ({ ...prevState, pageSize: newPageSize }));
  };

  const handlePageChange = (newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  };

  const handlePopupClicked = () => {
    setVisible(!visible);
  };

  const handleCreate = (e) => {
    if (e === InvoiceTypes.External_Recurring) {
      navigate(ROUTES.CREATE_EXTERNAL_RECURRING_INVOICE);
    } else {
      navigate(ROUTES.CREATE_INTERNAL_RECURRING_INVOICE);
    }
  };

  useEffect(()=>{
    dispatch(resetPreviewRecurringInvoiceState());
    dispatch(resetState());
  }, 
  // eslint-disable-next-line
  []);
  
  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, organization, recurType, startDate, endDate, status, page, pageSize, reload]);

      return(
            <>
              <RecurringInvoiceTopBanner>
                <div className="dashboard-container recurring-invoice__new">
                  <div>
                    <Heading color="#002776">Recurring Invoices</Heading>
                    <Text>Schedule your invoices and cash out with Ease</Text>
                  </div>
                  <div ref={ref} className="client-invoice__new_button">
                    {mobile ? 
                      <MobileResponsiveButton
                        onClick={handlePopupClicked}
                        paddingRes=".6px 8px"
                      >
                        <CreateDropdown/>
                      </MobileResponsiveButton>
                     : 
                      <CreateNewContainer onClick={handlePopupClicked}>
                        <CreateDropdown/>
                      </CreateNewContainer>
                    }
                    {visible &&
                      <PopupContent
                        list={[InvoiceTypes.External_Recurring,
                                  InvoiceTypes.Internal_Recurring,]}
                        handleClick={(e) => handleCreate(e)}
                        top={mobile ? "170px" : "210px"}
                        left={mobile ? "0" : "180px"}
                      />
                    }
                  </div>
                </div>
              </RecurringInvoiceTopBanner>

              <SimpleSearchContainer>
              <SearchComponent
                startDate={startDate}
                endDate={endDate}
                organization={organization}
                setOrganization={(value) => setState((prevState) => ({ ...prevState, organization: value }))}
                setSearchInput={(value) => setState((prevState) => ({ ...prevState, searchInput: value }))}
                setEndDate={(value) => setState((prevState) => ({ ...prevState, endDate: value }))}
                setStartDate={(value) => setState((prevState) => ({ ...prevState, startDate: value }))}
                setRecurType={(value) => setState((prevState) => ({ ...prevState, recurType: value }))}
                isInvoice={false}
                isRecurring={true}
              />
              </SimpleSearchContainer>

              <div>
                <RecurringInvoiceList
                  recurringInvoices={recurringInvoices}
                  activeCount={activeCount}
                  inActiveCount={inActiveCount}
                  totalCount={totalCount}
                  loading={isLoading}
                  paginationServer
                  status={status}
                  reload={reload}
                  setReload={setReload}
                  setStatus={(value) => setState((prevState) => ({ ...prevState, status: value }))}
                  paginationTotalRows={totalCount}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
            </div>
            </>
      )
}

export default RecurringInvoice