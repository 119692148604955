import React, { useState, useEffect } from "react";
import "./CircularProgressBar.css";

const CircularProgressBar = ({ count, width, radius, color, onMouseEnter }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let intervalId;
    if (progress < count) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 1);
      }, 20);
    }
    return () => clearInterval(intervalId);
  }, [progress, count]);
  const svgWidth = width;
  const svgHeight = width;

  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;
  const strokeLength = circumference * (progress / 100);

  return (
    <div className="progress-bar">
      <svg className="progress-ring" width={svgWidth} height={svgHeight}>
        <circle
          className="progress-ring__circle progress-ring__circle--grey"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius - strokeWidth / 2}
          cx={svgWidth / 2}
          cy={svgHeight / 2}
        />
        <circle
          className="progress-ring__circle progress-ring__circle--progress"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius - strokeWidth / 2}
          cx={svgWidth / 2}
          cy={svgHeight / 2}
          style={{
            strokeDasharray: `${strokeLength} ${circumference}`,
            stroke: color,
          }}
          onMouseEnter={onMouseEnter}
        />
      </svg>
    </div>
  );
};

export default CircularProgressBar;
