import { createSlice } from "@reduxjs/toolkit";
import {
  clientUserContent,
  OrganizationUserSignUpformContent,
} from "utils/contents/forms.content";

const entity = "form";

export const initialState = {
  organizationSignupForm: [],
  clientUserSignupForm: [],
  loading: false,
};


const formSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    getOrganizationSignUpForm: (state, action) => {
      state.organizationSignupForm = OrganizationUserSignUpformContent;
    },
    getClientUserSignUpForm: (state, action) => {
      state.clientUserSignupForm = clientUserContent;
    },
  },
});

export const formActions = { ...formSlice.actions };

export const formSelectors = {
  isLoading: (state) => state.form.loading,
  organizationSignUpForm: (state) => state.form.organizationSignupForm,
  clientUserSignUpForm: (state) => state.form.clientUserSignupForm,
};

export default formSlice.reducer;
