import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import _ from "lodash";
import OrganizationSelectDropdown from "app/components/molecules/organization/organization-dropdown";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate, useParams } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";
import { recurringInvoiceActions, recurringInvoiceSelectors } from "store/reducers/recurringInvoice/recurringInvoiceSlice";
import BaseEditRecurringInvoice from "./base-edit";

const EditInternalRecurringInvoice = () => {
  const [organization, setOrganization] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleApiResponse } = useHandleApiResponse();
  const { id } = useParams();

  const { update } = recurringInvoiceActions;
  const { resetPreviewRecurringInvoiceState, setPreviewRecurringInvoiceState } = appDataActions;
  const { getDetails } = recurringInvoiceActions;

  const recurringInvoice = useSelector(recurringInvoiceSelectors.recurringInvoice);
  const previewRecurringInvoice = useSelector(appdataSelector.previewRecurringInvoice);

  useEffect(() => {

    if(id !== ROUTES.PREVIEW_ROUTE){
      dispatch(getDetails(id));
    }   
      // eslint-disable-next-line
    }, [id]);

  const handleSave = (data, isPreview) =>{
    let payload = {
        organizationId: organization.value,
        type: INVOICE_TYPE.Internal,
        ...data
    }

    if(isPreview){
        dispatch(setPreviewRecurringInvoiceState(payload));
        navigate(ROUTES.PREVIEW_RECURRING_INVOICE)
    }
    else{
      dispatch(update(payload)).then((res)=>{
        dispatch(resetPreviewRecurringInvoiceState());
        handleApiResponse(res, ROUTES.RECURRINGINVOICE);
      })
    }
  }

  return (
      <BaseEditRecurringInvoice
        invoice={_.isEmpty(previewRecurringInvoice) ? recurringInvoice : previewRecurringInvoice}
        backRoute={_.isEmpty(previewRecurringInvoice) ? '' : `${ROUTES.EDIT_INTERNAL_RECURRING_INVOICE_ROUTE_HEAD}/${id}`}
        ClientDropDown={
          <OrganizationSelectDropdown
            invoice={_.isEmpty(previewRecurringInvoice) ? recurringInvoice : previewRecurringInvoice }
            organization={organization}
            setOrganization={setOrganization}
          />
        }
        handleSave={handleSave}
      />
  );
};
export default EditInternalRecurringInvoice;