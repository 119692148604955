import styled from "styled-components";

const ActionButtonContainer = styled.button`
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: ${({ padding }) => padding || " 15px 20px"};
  cursor: pointer;
  font-size: ${({ size }) => size || ""};
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  height: ${({ height }) => height || "40px"};
    width: ${({ width }) => width || ""};
  box-shadow: 0px 4px 4px rgba(38, 50, 56, 0.05);
  border: ${({ border }) => border || "none"};;

  svg {
    color: ${({ iconColor }) => iconColor || ""};
    width: 18.75px;
    height: 18.75px;
  }
  @media screen and (max-width:768px) {
 padding: ${({ padding }) => padding || "5px"};
height: 30px;
border-radius:12px ;
background: #FBFCFF;
border: 0.25px solid var(--primary8);
svg {
    width:${({ svgWidth }) => svgWidth || "11px"};;
    height:${({ svgHeight }) => svgHeight || "11px"};;
  }
  }
`;
export const ExtraActionButton = styled.button`
background: none;
     color: ${({ iconColor }) => iconColor || ""};
     border:0.10px  solid black;
     display: grid;
     border-radius: 4px;
     place-items: center;
     padding:${({ padding }) => padding || "10px"};;
     font-size: 15.5px;
`;

const ActionButton = ({
  iconColor,
  children,
  height,
  width,
  marginRight,
  marginLeft,
  fontSize,
  borderRadius,
  onClick,
  ...props
}) => {
  return (
    <ActionButtonContainer
      iconColor={iconColor}
      height={height}
      width={width}
      marginRight={marginRight}
      fontSize={fontSize}
      borderRadius={borderRadius}
      onClick={onClick}
      {...props}
    >
      {children}
    </ActionButtonContainer>
  );
};

export default ActionButton;
