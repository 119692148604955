export const createOptionsObject = (arr, key = "name", value = "planType") => {
  let result = [];
  if (arr.length) {
    arr?.map((val) => {
      if (val.hasOwnProperty(key) && val.hasOwnProperty(value)) {
        result.push({
          value: val[key],
          label: val[value]
        });
        return val;
      }
      return val;
    });

    return result;
  }
};
