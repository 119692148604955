import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import FormInput from "app/components/atoms/form-input/form-input.component";
import Button from "app/components/atoms/button/button.component";
import {
  EmailValidator,
  PhoneNumberValidator,
  nameValidator,
} from "utils/validation/validation.utils";

import { useDispatch } from "react-redux";
import { externalUserActions } from "store/reducers/externalUser/externalUserSlice";
import { FormInputLable } from "app/components/atoms/form-input/form-input.styles";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;

  > div {
    width: 100%;
  }
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const validate = Yup.object({
  fullName: nameValidator("Full Name"),
  email: EmailValidator,
  phoneNumber: PhoneNumberValidator,
});

export const ExternalUserModal = ({ reload, setReloaad, close, data }) => {
  const defaultFormFields = {
    fullName: (data && data.fullName) || "",
    email: (data && data.email) || "",
    phoneNumber: (data && data.phoneNumber) || "",
  };
  const { handleApiResponse } = useHandleApiResponse();
  const dispatch = useDispatch();
  const { createExternalUser, updateExternalUser } = externalUserActions;

  const handleSubmit = (values, actions) => {
    if (data) {
      let id = data.id;
      dispatch(updateExternalUser({ ...values, id })).then((res) => {
        handleApiResponse(res, null);
        setReloaad(!reload);
        close(false);
        actions.setSubmitting(false);
      });
    } else {
      dispatch(createExternalUser(values)).then((res) => {
        handleApiResponse(res, null);
        setReloaad(!reload);
        close(false);
        actions.setSubmitting(false);
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={defaultFormFields}
        validationSchema={validate}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {(props) => (
          <form style={{ width: "100%" }} onSubmit={props.handleSubmit}>
            <FormContainer>
              <div>
                <FormInputLable>Full Name</FormInputLable>
                <FormInput
                  placeholder="John Doe"
                  type="text"
                  name="fullName"
                  required
                />
              </div>
              <div>
                <FormInputLable>Email Address</FormInputLable>
                <FormInput
                  placeholder="example@email.com"
                  type="email"
                  name="email"
                  required
                />
              </div>
              <div>
                <FormInputLable>Phone Number</FormInputLable>
                <FormInput
                  placeholder="+234 998 4848 383"
                  type="text"
                  name="phoneNumber"
                  required
                />
              </div>
              <AddButtonContainer>
                <Button
                margin="15px 0"
                  isLoading={props.isSubmitting}
                  disabled={props.isSubmitting}
                  type="submit"
                >
                  {data ? "Update" : "Add"}
                </Button>
              </AddButtonContainer>
            </FormContainer>
          </form>
        )}
      </Formik>
    </>
  );
};