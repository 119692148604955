
import Text from "app/components/atoms/typography";
import { OrganizationSettingsContainer, PlanContainer } from "./settings.styles";
import { useDispatch, useSelector } from "react-redux";
import { planActions, planSelectors } from "store/reducers/plan/planSlice";
import { useEffect } from "react";
import { authSelectors } from "store/reducers/auth/authSlice";
import { USER_ROLES } from "store/reducers/auth/utils";
import { Vortex } from "react-loader-spinner";
import RenderPlans from "app/components/molecules/setting/payment-setting-render-plan";

const PaymentSettings = () => {

  const dispatch = useDispatch();
  const role = useSelector(authSelectors.userRole);
  const myPlan = useSelector(planSelectors.myPlan);
  const isLoading = useSelector(planSelectors.isLoading);
  const organizationPlans = useSelector(planSelectors.organizationPlan);
  const clientUserPlans = useSelector(planSelectors.clientUserPlan);
  const { getPlans, myPlan: getMyPlan } = planActions;

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getMyPlan());
  }, [dispatch, getMyPlan, getPlans]);

  return (
    <OrganizationSettingsContainer>
      <Text color="#002776">
        Select a subscription plan that suits your needs.
      </Text>

      {isLoading ? <Vortex
        height="200"
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        ariaLabel="vortex-loading"
        wrapperClass="vortex-wrapper"
        colors={['red', 'blue', 'yellow', '#fe9900',]}
      /> :

        <PlanContainer columnTemplate="1fr" gap="10px" margin=" 40px 0">
          <RenderPlans
           plans={role === USER_ROLES.CLIENT_USER ? clientUserPlans : organizationPlans}
           myPlan={myPlan}
           >
          </RenderPlans>
        </PlanContainer>
      }
      
    </OrganizationSettingsContainer>
  );
};

export default PaymentSettings;

