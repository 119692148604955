import FormInputTwo from "app/components/atoms/form-input-two/form-input-two.component";
import SignupPanel from "app/components/molecules/signup-panel/signup-panel.component";
import { AuthLayout } from "app/layouts";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "utils/constants/constants.utils";
import Text from "app/components/atoms/typography";
import * as Yup from "yup";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import { authActions, authSelectors } from "store/reducers/auth/authSlice";
import { PasswordValidator,
    ConfirmPasswordValidator, 
    nameValidator, 
    PhoneNumberValidator } from "utils/validation/validation.utils";

const defaultFormFields = {
   firstName: "",
   lastName: "",
   password: "",
   confirmPassword: "",
   phoneNumber: "",
  };
  
const validationSchema = Yup.object({
    firstName: nameValidator("First Name"),
    lastName: nameValidator("Last Name"),
    phoneNumber: PhoneNumberValidator,
    confirmPassword: ConfirmPasswordValidator,
    password: PasswordValidator
  });

const ConfirmInvite = () => {

  let { email, token } = useParams();

  const dispatch = useDispatch();
  const { handleApiResponse } = useHandleApiResponse(); 
  const { confirmInvite } = authActions;
  const isSubmitting = useSelector(authSelectors.isLoading);
  const handleSubmit = (payload) => {

    payload.email = email;
    payload.emailConfirmationToken = token;

    dispatch(confirmInvite(payload)).then((res)=>{
        handleApiResponse(res, ROUTES.SIGN_IN);
    })
  };

  return (
    <AuthLayout text="Welcome to Easei">
      <div className="auth-login-text">  
      <Text style={{"color":"var(--accent)"}}>
         We just need a few more details
        </Text>
      </div>
      <Formik
        initialValues={defaultFormFields}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}>
        <Form>
            <FormInputTwo label="FirstName" name="firstName" required />
            <FormInputTwo label="LastName" name="lastName" required />
            <FormInputTwo label="PhoneNumber" name="phoneNumber" required />
            <FormInputTwo
                label="Password"
                type="password"
                name="password"
                required
                />
            <FormInputTwo
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                required
                />
            <SignupPanel
                isSubmitting={isSubmitting}
                />
        </Form>
      </Formik>
    </AuthLayout>
  );
};

export default ConfirmInvite;
