import styled from "styled-components";

export const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  @media screen and (min-width: 900px) {
    right: 20px;
    top: 20px;
  }
`;
export const DownloadButton = styled.div`
  background: #002776;
  border-radius: 8px;
  display: flex;
  margin-top: 1em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
  cursor: pointer;
  color: white;
  @media screen and (min-width: 700px) {
    position: absolute;
    margin: 0;
    left: 10px;
    top: 10px;
  }
  @media screen and (min-width: 900px) {
    left: 20px;
    top: 20px;
  }
`;

export const customStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "55px",
    zIndex: 999,
    width: "500px",
    height: "500px",
    position: "absolute",
    top: "20%",
    left: "40%",
    background: "white",
    border: "1px solid #dbe7ff",
    borderRadius: "10px",
    transition: "1.1s ease-out",
    boxShadow: "-2rem 2rem 2rem rgba(black, 0.2)",
    filter: "blur(0)",
    transform: "scale(1)",
    opacity: 1,
  },
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
