import styled from "styled-components";

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--white);
  border-radius: 3px;
  right: ${({ right }) => right || "5px"};
  border: 1px solid #7b8599;
  border-radius: 8px;
  top: ${({ top }) => top || ""};
  overflow: hidden;
  z-index: 1;

  li:hover {
    background: #e5e5e5;
  }

  li {
    padding: 6px 20px;
    list-style-type: none;
  }
`;

const PopupContent = ({ list, handleClick, ...otherProps }) => {
  return (
    <PopupContainer {...otherProps}>
      {list?.map((item, index) => (
        <li key={index} onClick={() => handleClick(item)}>
          {item}
        </li>
      ))}
    </PopupContainer>
  );
};

export default PopupContent;
