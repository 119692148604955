import styled from "styled-components";

export const TextArea = styled.textarea`
  background-color: #e6eefe;
  font-size: ${({ size }) => size || "18px"};
  padding: 10px 10px 10px 20px;
  display: block;
  border: 0.1px solid black;
  width: ${({ width }) => width || "100%"};
  border: 1px solid #dbe7ff;
  border-radius: 5px;
  margin: ${({ margin }) => margin || "20px 0 10px"};
  background-color: #f2f6ff;
  color: #002776;
  border: 1px solid #dbe7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  ::placeholder {
    color: #3777ff;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

export const InvoiceTextArea = styled.textarea`
  margin-bottom: 20px;
  padding: 5px;
  resize: none;
  width: 100%;
  height: 90px;
`;