import ActionMenu from "app/components/molecules/action-menu/action-menu.component";
import { USER_ROLES } from "store/reducers/auth/utils";
import { ACTIVATION_STATUS } from "utils/constants/constants.utils";

export const getPeopleAction = (row, role, userActivation, setActiveStatus, activeStatus, handleEditUser, setApproveDel, setId) => {
    
    if(role === USER_ROLES.CLIENT_USER){
        return   <ActionMenu
        popupList={["Edit", "Delete"]}
        handleEdit={handleEditUser}
        handleDelete={() => { setApproveDel(true) }}
        setId={setId}
        row={row}
    />
    }

    else if (
    (role === USER_ROLES.EASEI_ADMIN && row.role === USER_ROLES.EASEI_USER)
        || (role === USER_ROLES.ORGANIZATION_ADMIN && row.role === USER_ROLES.ORGANIZATION_USER)){
      return (
        <ActionMenu
          updateStatus={userActivation}
          popupList={[ACTIVATION_STATUS.Activate, ACTIVATION_STATUS.Deactivate]}
          setActiveStatus={setActiveStatus}
          ext={true}
          activeStatus={activeStatus}
          row={row}
        />
      );
    }
    
    return null;
  };
  