import { Children } from "react";
import styled from "styled-components";
export const ListContainer = styled.div`
  list-style-position: outside;
  padding-inline: 20px;
`;

const List = ({ children, el, ...otherProps }) => {
  return (
    <ListContainer as={el} {...otherProps}>
      {Children?.map(children, (child) => (
        <li>{child}</li>
      ))}
    </ListContainer>
  );
};

export default List;
