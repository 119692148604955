import Text, { Span } from "app/components/atoms/typography";
import { USER_ROLES } from "store/reducers/auth/utils";

import {
  ButtonContainer,
  FormContainer,
} from "./people.styles";
import { Input } from "app/components/atoms/form-input/form-input.component";

import styled from "styled-components";
import { MdArrowDropDown } from "react-icons/md";
import { RiDownloadCloud2Line, RiUploadCloud2Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import { SettingsActions } from "store/reducers/settings/settingsSlice";
import { useDispatch } from "react-redux";
import Button from "app/components/atoms/button/button.component";
import { useCheckAdmin } from "hooks";
import { GeneralDivIII, TitleContainer } from "app/components/atoms/divs/div.styles";
import { DownloadButton } from "app/components/atoms/modal/modal.styles";
import { ReminderFormInput } from "../reminder/reminder.styles";

const RoleSelectInput = styled.div`

background-color: #f6f6f6;
padding: 10px 20px;
font-size: 14px;
position: relative;
padding: 10px 20px;
cursor: pointer;
width: ${({ width }) => width || "100%"};
margin: 20px 0 10px;
border: 1px solid #dbe7ff;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 20px;
color: var(--primary);
align-items: center;
.dropdown__content {
    color: var(--grey1);
    padding: 10px 20px;
    border-radius: 0;
  }
  .dropdown {
    position: absolute;
    left: 0;
    right: 0;

    top: 2.5em;
  }
  @media screen and (max-width: 345px) {
    font-size: 10px;
  }
`;

const EmailGrid = styled(ReminderFormInput)`
  @media screen and (min-width: 0px) {
    height: auto;
    max-height: 100px;
  }
  padding: 10px;
  overflow: scroll;
  background-color: #dbe7ff;
  .recipient__cancel {
    color: #6c98f3;
  }
`;

const Email = styled(GeneralDivIII)`
  padding: 8px 16px;
  background-color: #f6f6f6;
  border-radius: 4px;
  height: 30px;
`;

const SelectFile = styled.div`
  text-align: center;
  label {
    background-color: #f6f6f6;
    border: 2px dashed #7b8599;
    border-radius: 4px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    ::file-selector-button {
      display: none;
    }
  }
  .hidden {
    display: none;
  }
`;

const InviteForm = ({ fromImport, setInviteSuccessFull }) => {
  const [dropShow, setDropShow] = useState(false);
  const [show, setShow] = useState(true);
  const [file, setFile] = useState("");
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const { inviteUsers, uploadUsersCsv } = SettingsActions;

  const dispatch = useDispatch();
  const { role } = useCheckAdmin();

  const handleChange = (e) => {
    const emails = email.split(",").map((email) => email.trim());
    const filtEmails = emails.filter((email) => email.length !== 0);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmails = filtEmails.every((email) => regex.test(email));
    setIsValid(isValidEmails);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    let input = {};
    if (fromImport) {
      input = {
        CsvFile: file,
        Role: selectedRole,
      };
      dispatch(uploadUsersCsv(input)).then((res) => {
        if (res.payload.succeeded) setInviteSuccessFull(true);
        setSubmitting(false);
      });
    } else {
      input = {
        email: emails,
        role: selectedRole,
      };
      dispatch(inviteUsers(input)).then((res) => {
        if (res?.payload?.succeeded) {
          setInviteSuccessFull(true);
        }
        setSubmitting(false);
      });
    }
  };

  useEffect(() => {
    if (role === USER_ROLES.EASEI_ADMIN) {
      setAvailableRoles([USER_ROLES.EASEI_USER]);
      setSelectedRole(USER_ROLES.EASEI_USER);
    } else if (role === USER_ROLES.ORGANIZATION_ADMIN) {
      setAvailableRoles([USER_ROLES.CLIENT_USER, USER_ROLES.ORGANIZATION_USER]);
    } else if (role === USER_ROLES.ORGANIZATION_USER) {
      setAvailableRoles([USER_ROLES.CLIENT_USER]);
      setSelectedRole(USER_ROLES.CLIENT_USER);
    }
  }, [role]);

  return (
    <form
      autoComplete="off"
      onClick={() => {
        if (email.length > 0) {
          setEmails(email.split(",").filter((email) => email.length !== 0));
          setShow(false);
        }
      }}
      onSubmit={handleSubmit}
    >
      <TitleContainer>
        {fromImport &&  <DownloadButton
              as={"a"}
              href="https://easeitestac8f.blob.core.windows.net/uploadblob-test/UsersUploadTemplate.csv"
              target="_blank"
            >
              <RiDownloadCloud2Line />
              <Text color="white">Download Template</Text>
            </DownloadButton>}
        <Text size={20} weight={700} color="var(--primary2)">
          Invite Contacts to your Workspace
        </Text>
        <Text>
          Your contact will receive an email that gives them access to your
          workspace.
        </Text>
      </TitleContainer>
      <FormContainer>
        {!fromImport && !show && emails.length > 0 && (
          <>
            <Text weight={700}>
              Email Address <Span>(Separated by comma)</Span>{" "}
            </Text>
            <EmailGrid
              onClick={(e) => {
                setShow(true);
                setEmail(emails.join());
                e.stopPropagation();
              }}
            >
              {emails.map((email) => (
                <Email key={email}>
                  <Text>{email}</Text>
                  <div
                    className="recipient__cancel"
                    onClick={(e) => {
                      setEmails(emails.filter((em) => em !== email));
                      if (emails.length === 1) {
                        setEmail("");
                      } else {
                        setEmail(emails.filter((em) => em !== email).join());
                      }
                      e.stopPropagation();
                    }}
                  ></div>
                </Email>
              ))}
            </EmailGrid>
          </>
        )}
        {fromImport ? (
          <SelectFile>
            <label htmlFor="file">
              {file ? file.name : "Drag & drop files here or select from your computer"}
            </label>
            <input
              type="file"
              id="file"
              className="hidden"
              accept=".csv"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <Text color="black">
              File format: CSV. Maximum file size: 5 MB
            </Text>
          </SelectFile>
        ) : (
          <div>
            <Text
              className={`${show || emails.length === 0 ? "" : "hidden_email"}`}
              weight={700}
            >
              Email Address <Span size={"10"}>(Separated by comma)</Span>
            </Text>
            <Input
              type="text"
              name="email"
              className={`${show || emails.length === 0 ? "" : "hidden_email"}`}
              placeholder="Enter email address"
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                backgroundColor: "#F6F6F6",
                padding: "25px 20px",
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleChange();
              }}
            />
          </div>
        )}

        <div>
          <Text weight={700}>Role</Text>
          <RoleSelectInput onClick={() => setDropShow(!dropShow)}>
          <div style={{display:"flex", alignItems:"center"}}>
            <Text color="#7B8599">
              {" "}
              {selectedRole ? selectedRole : "Select Role"}
            </Text>
            <MdArrowDropDown color="#3A3E47" size={30}/>
            </div>
            {dropShow && availableRoles.length > 1 && (
              <div className="dropdown">
                {availableRoles?.map((role) => (
                  <div
                    key={role}
                    className="dropdown__content"
                    onClick={() => {
                      setSelectedRole(role);
                    }}
                  >
                    {role}
                  </div>
                ))}
              </div>
            )}
          </RoleSelectInput>
        </div>
      </FormContainer>

      <ButtonContainer>
        {fromImport ? (
          <Button
            type="submit"
            width="r"
            isLoading={submitting}
            disabled={submitting || !file}
            style={{
              minWidth: "auto",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <RiUploadCloud2Line />
            Import Users
          </Button>
        ) : (
          <Button
            type="submit"
            isLoading={submitting}
            disabled={submitting || !email || !isValid}
            style={{ minWidth: "auto" }}
            width="true"
          >
            Send Invitation
          </Button>
        )}
      </ButtonContainer>
    </form>
  );
};

export default InviteForm;
