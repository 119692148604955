import Text from "app/components/atoms/typography";

const InvoiceSettings = () => {
  return (
    <>
      <Text>Invoice Settings</Text>
    </>
  );
};

export default InvoiceSettings;
