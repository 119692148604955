import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleApiResponse } from "hooks/useHandleApiResponse";
import BaseCreateInvoice from "./base-create-invoice.component";
import { invoiceActions } from "store/reducers/invoice/invoiceSlice";
import ExternalUser from "app/components/molecules/external-user/external-user";
import { appDataActions, appdataSelector } from "store/reducers/appData/appDataSlice";
import { useNavigate } from "react-router-dom";
import { INVOICE_TYPE, ROUTES } from "utils/constants/constants.utils";

const CreateExternalInvoice = () => {
  
  const { createExternal } = invoiceActions;
  const { resetPreviewState, setPreviewInvoiceState, setHeaderText } = appDataActions;
  const invoice = useSelector(appdataSelector.previewInvoice);

  const { handleApiResponse } = useHandleApiResponse();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [externalUser, setExternalUser] = useState();
  
  useEffect(()=>{
    dispatch(setHeaderText({ value: 'Create Invoice', type: 'setHeaderText' }));
    // eslint-disable-next-line
},[])
  const handleSave = (data, isPreview) =>{
    let payload = {
      externalUserId: externalUser.value,
      type: INVOICE_TYPE.External,
      ...data
    }
    
      if(isPreview){
        dispatch(setPreviewInvoiceState(payload));
        navigate(ROUTES.PREVIEW_INVOICE)
      }
    else{
      dispatch(createExternal(payload)).then((res)=>{
        dispatch(resetPreviewState());
        handleApiResponse(res, '/invoices');
      })
    }
  }

return (
    <BaseCreateInvoice
    ClientDropDown={
    <ExternalUser 
    invoice={invoice}
    externalUser={externalUser}
    setExternalUser={setExternalUser}/>
  }
    handleSave={handleSave}/>
)

};
export default CreateExternalInvoice;