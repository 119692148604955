export const getInvoiceTotalCost = (services) => {

    let totalAmount = 0;
   for (let index = 0; index < services.length; index++) {
    const element = services[index];

    if (element.unitType === 0) {
        if (!Number.isInteger(parseInt(element.itemUnit))) {
            return 0;
        }

        totalAmount += parseInt(element.itemUnit) * element.cost;
    }

    else if (element.unitType === 1) {

        let unit = element?.itemUnit?.split('-');
        if (unit.length < 1 || !Number.isInteger(parseInt(unit[0]))) {
            return 0;
        }
        totalAmount += parseInt(unit[0]) * element.cost;
    }
   }

   return totalAmount;
};  

export const getInvoiceTotalTax = (services) => {
   
    let totalAmount = 0;

   for (let index = 0; index < services.length; index++) {
    const element = services[index];

       if (element.unitType === 0) {
        if (!Number.isInteger(parseInt(element.itemUnit))) {
            return 0;
        }
        totalAmount += parseInt(element.itemUnit) * element.tax;   
    }

    else if (element.unitType === 1) {

        let unit = element?.itemUnit?.split('-');

        if (unit.length < 1 || !Number.isInteger(parseInt(unit[0]))) {
            return 0;
        }
        totalAmount += parseInt(unit[0]) * element.tax;
    }
   }

   return totalAmount;
};  

export const getInvoiceTotalAmount = (services) =>{
    return getInvoiceTotalCost(services) + getInvoiceTotalTax(services)
}