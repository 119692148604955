import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { appDataActions } from "store/reducers/appData/appDataSlice";
import { useDispatch } from "react-redux";
import InvoiceView from "./invoiceComponents/ClientInvoice";
import { useCheckAdmin } from "hooks";

const Invoice = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { role } = useCheckAdmin();
    const { resetPreviewState } = appDataActions;

    useEffect(()=>{dispatch(resetPreviewState()); }, 
    // eslint-disable-next-line
    []);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    return <InvoiceView role={role}/>;
  };
  
  export default Invoice;